import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { couponApi } from "../apis";
import { toast } from "react-hot-toast";

export const getAllCouponForAdmin = createAsyncThunk(
  "/coupon/getAllCouponForAdmin",
  async (query) => {
    try {
      const response = await couponApi.getAllCouponForAdmin({ q: query });
      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getAllUserByCoupon = createAsyncThunk(
  "/coupon/getAllUserByCoupon",
  async (query) => {
    try {
      const response = await couponApi.getAllUserByCoupon({ code: query });
      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getAllUserFilterByRank = createAsyncThunk(
  "/coupon/getAllUserFilterByRank",
  async (query) => {
    try {
      const response = await couponApi.getAllUserFilterByRank(query);
      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getAllCouponByStartPoints = createAsyncThunk(
  "/coupon/getAllCouponByStartPoints",
  async (query) => {
    try {
      const response = await couponApi.getAllCouponByStartPoint(query);
      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const createCoupon = async (query) => {
  try {
    const payload = {
      data: query,
    };

    await couponApi.createCoupon(payload);
  } catch (error) {
    throw new Error(error);
  }
};

export const couponSlice = createSlice({
  name: "coupon",
  initialState: {
    coupons: {
      list: [],
      total: 0,
    },

    couponByStartPoint: {
      list: [],
      total: 0,
    },

    userList: {
      list: [],
      total: 0,
    },

    userListByCode: {
      list: [],
      total: 0,
    },
  },

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getAllCouponForAdmin.fulfilled, (state, { payload }) => {
      state.coupons.list = payload?.data || [];
      state.coupons.total = payload?.total || 0;
    });

    builder.addCase(
      getAllCouponByStartPoints.fulfilled,
      (state, { payload }) => {
        state.couponByStartPoint.list = payload?.data || [];
        state.couponByStartPoint.total = payload?.total || 0;
      }
    );

    builder.addCase(getAllUserFilterByRank.fulfilled, (state, { payload }) => {
      state.userList.list = payload?.data || [{}];
      state.userList.total = payload?.total || 0;
    });

    builder.addCase(getAllUserByCoupon.fulfilled, (state, { payload }) => {
      state.userListByCode.list = payload?.data || [];
      state.userListByCode.total = payload?.total || 0;
    });
  },
});

export default couponSlice.reducer;
