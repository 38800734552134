import { gql } from "@apollo/client";
import { mutate, query } from "configs/http-config";

const couponAction = {
  getAllCouponForAdmin: gql`
    query getAllCouponForAdmin($q: QueryGetListInput) {
      getAllCouponForAdmin(q: $q) {
        data {
          code
          createdAt
          discountCondition
          discountAmount
          carCategories
          discountRate
          effectiveDate
          expireDate
          id
          maxDiscount
          updatedAt
          status
          checkExpired
          workPlaces {
            id
            name
          }
          workPlaceIds
          serviceTypes
          type
          title
          category
          description
          bannerImages
          smallBannerImages
          quantity
          isActived
          pointToReward
          isUpdated
          isForAllUser
        }
        total
      }
    }
  `,

  getAllUserFilterByRank: gql`
    query getAllUserFilterByRank(
      $page: Int
      $limit: Int
      $search: String
      $filter: Mixed
      $rank: String
    ) {
      getAllUserFilterByRank(
        q: {
          limit: $limit
          page: $page
          search: $search
          filter: $filter
          order: { createdAt: -1 }
        }
        rank: $rank
      ) {
        data {
          id
          name
          username
          phone
          email
          password
          isBlock
          key
          role
          identityCard
          avatar
          address
          signInProvider
          isActived
          createdAt
          updatedAt
          trips {
            id
            code
            couponIds
            coupons {
              code
              discountCondition
              discountRate
              expireDate
              effectiveDate
              id
              maxDiscount
              quantity
              status
              title
              type
            }
            discountAmount
            distance
            dispatch {
              driver {
                name
                id
              }
            }
            dispatchId
            driverId
            finalCost
            initialCost
            startAddress
            endAddress
            status
            travelTime
            userId
            waitTime
            workPlace {
              id
              name
            }
            workPlaceId
          }
          workPlace {
            id
            name
          }
          workPlaceId
          location

          car {
            id
            carCode
            images
            driverId
            status
            createdAt
            updatedAt
          }
        }
        total
        getStatUser
      }
    }
  `,

  getAllCouponByStartPoint: gql`
    query getAllCouponByStartPoint(
      $page: Int
      $limit: Int
      $search: String
      $filter: Mixed
      $startPoint: [Float]!
      $phone: String!
    ) {
      getAllCouponByStartPoint(
        q: {
          limit: $limit
          page: $page
          search: $search
          filter: $filter
          order: { createdAt: -1 }
        }
        startPoint: $startPoint
        phone: $phone
      ) {
        data {
          code
          createdAt
          discountCondition
          discountRate
          effectiveDate
          expireDate
          id
          maxDiscount
          updatedAt
          status
          workPlaces {
            id
            name
          }
          workPlaceIds
          type
          title
        }
        total
      }
    }
  `,

  getOneCoupon: gql`
    query getOneCoupon($id: ID!) {
      getOneCoupon(id: $id) {
        code
        createdAt
        discountCondition
        discountRate
        effectiveDate
        expireDate
        carCategories
        id
        maxDiscount
        updatedAt
        typeDiscount
        description
        category
      }
    }
  `,

  getAllUserByCoupon: gql`
    query getAllUserByCoupon($code: String) {
      getAllUserByCoupon(code: $code) {
        id
        name
      }
    }
  `,

  createCoupon: gql`
    mutation createCoupon($data: CreateCouponInput!) {
      createCoupon(data: $data) {
        id
      }
    }
  `,

  deleteOneCoupon: gql`
    mutation deleteOneCoupon($id: ID!) {
      deleteOneCoupon(id: $id) {
        id
        discountRate
      }
    }
  `,

  updateCoupon: gql`
    mutation updateCoupon($id: ID!, $data: UpdateCouponInput!) {
      updateCoupon(id: $id, data: $data) {
        id
        discountRate
      }
    }
  `,
};

export const couponApi = {
  getAllCouponForAdmin: (payload) => {
    return query(
      "getAllCouponForAdmin",
      couponAction.getAllCouponForAdmin,
      payload
    );
  },

  getAllUserFilterByRank: (payload) => {
    return query(
      "getAllUserFilterByRank",
      couponAction.getAllUserFilterByRank,
      payload
    );
  },

  getAllCouponByStartPoint: (payload) => {
    return query(
      "getAllCouponByStartPoint",
      couponAction.getAllCouponByStartPoint,
      payload
    );
  },

  getOneCoupon: (payload) => {
    return mutate("getOneCoupon", couponAction.getOneCoupon, payload);
  },

  getAllUserByCoupon: (payload) => {
    return mutate(
      "getAllUserByCoupon",
      couponAction.getAllUserByCoupon,
      payload
    );
  },

  createCoupon: (payload) => {
    return mutate("createCoupon", couponAction.createCoupon, payload);
  },

  deleteOneCoupon: (payload) => {
    return mutate("deleteOneCoupon", couponAction.deleteOneCoupon, payload);
  },

  updateCoupon: (payload) => {
    return mutate("updateCoupon", couponAction.updateCoupon, payload);
  },
};
