import ButtonSystem from "components/e-control/button-system";
import InputSystem from "components/e-control/forms/form-elements/input-system";
import { Controller, useForm } from "react-hook-form";
import { Form } from "reactstrap";

const StartFinishTripActionForm = ({ start, finish, arrived, cancel, requestPaid }) => {
    const { control, resetField, getValues } = useForm({});

    const startTrip = async () => {
        const { tripId } = getValues();
        start?.(tripId)
    };

    const finishTrip = () => {
        const { tripId, location } = getValues();
        const locations = location.split(',').map(loc => parseFloat(loc));
        finish?.(tripId, locations);
    };


    const checkPaid = () => {
        const { tripId, location } = getValues();
        const locations = location.split(',').map(loc => parseFloat(loc));
        requestPaid?.(tripId, locations);
    };

    const arriveBooking = () => {
        const { tripId } = getValues();
        arrived?.(tripId);
    };

    const cancelBooking = () => {
        const { tripId } = getValues();
        cancel?.(tripId);
    };

    return (
        <Form
            className="flex flex-col items-start gap-y-2"
        >
            <div className="flex items-end gap-x-2">
                <div className="form-item">
                    <label>Nhập trip id</label>
                    <Controller
                        name="tripId"
                        control={control}

                        render={({ field }) => (
                            <InputSystem 
                                placeholder='nhập trip id'
                                {...field}
                            />
                        )}
                    />
                </div>

                <div className="form-item">
                    <label>Nhập vị trí kết thúc</label>
                    <Controller
                        name="location"
                        control={control}

                        render={({ field }) => (
                            <InputSystem 
                                placeholder='nhập location'
                                {...field}
                            />
                        )}
                    />
                </div>
            </div>

            <div className="flex items-center gap-x-2">
                <ButtonSystem
                    type='button'
                    className='me-1'
                    color='primary'

                    onClick={arriveBooking}
                >
                    Đã đến
                </ButtonSystem>

                <ButtonSystem
                    type='button'
                    className='me-1'
                    color='primary'

                    onClick={startTrip}
                >
                    Bắt đầu
                </ButtonSystem>

                <ButtonSystem
                    type='button'
                    className='me-1'
                    color='primary'
                    onClick={checkPaid}
                >
                    Thanh toán
                </ButtonSystem>

                <ButtonSystem
                    type='button'
                    className='me-1'
                    color='primary'
                    onClick={finishTrip}
                >
                    Kết thúc
                </ButtonSystem>

                <ButtonSystem
                    type='button'
                    className='me-1'
                    color='primary'
                    onClick={cancelBooking}
                >
                    Hủy
                </ButtonSystem>
            </div>

        </Form>
    )
};

export default StartFinishTripActionForm;