import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import en from "./locale/en.json";
import vi from "./locale/vi.json";

import { LANGUAGE_OPTION, LOCALE } from "constants";
import localUtils from "utility/local-utils";

// ----------------------------------------------------------------------

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { common: en },
      vi: { common: vi },
    },
    lng: localUtils.get(LOCALE) || LANGUAGE_OPTION.VN.LANG,
    fallbackLng: "vi",
    debug: false,
    ns: ["common"],
    defaultNS: "common",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
