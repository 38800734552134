import { createAsyncThunk } from "@reduxjs/toolkit";
import { wareHouseApi } from "modules/shop/ware-house/apis";
import { toast } from "react-hot-toast";

export const getAllShopWarehouseLocation = createAsyncThunk(
  "/ware/getAllShopWarehouseLocation",
  async (query) => {
    try {
      const response = await wareHouseApi.getAllShopWarehouseLocation(query);
      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getAllWarehouseActive = createAsyncThunk(
  "/ware/getAllWarehouseActive",
  async (partnerId) => {
    try {
      const response = await wareHouseApi.getAllShopWarehouseLocation({
        q: {
          page: 1,
          limit: 500,
        },
    
        partnerId,
      });
      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const warehouse = {
  list: [],
  total: 0,
  actives: [],
};

export const warehouseBuilder = (builder) => {
  builder.addCase(
    getAllShopWarehouseLocation.fulfilled,
    (state, { payload }) => {
      state.warehouse.list = payload?.data || [];
      state.warehouse.total = payload?.total || 0;
    }
  );

  builder.addCase(
    getAllWarehouseActive.fulfilled, (state, { payload }) => {
      state.warehouse.actives = payload?.data || [];
    }
  )
};
