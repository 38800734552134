import { DESKTOP_WIDTH, TABLET_WIDTH } from 'constants';
import { useLayoutEffect, useState } from 'react';

export const DEVICE_DETECT = {
    DESKTOP: 'desktop',
    TABLET: 'tablet',
    MOBILE: 'mobile'
}

const useResponsive = () => {
    const [view, setView] = useState(DEVICE_DETECT.MOBILE);
    const [screen, setScreen] = useState({ width: window.innerWidth, height: window.innerHeight });

    const detectDeviceByScreenSize = (width) => {
        if (width >= DESKTOP_WIDTH) {
            setView(DEVICE_DETECT.DESKTOP)
            return
        }

        if (width < DESKTOP_WIDTH && width >= TABLET_WIDTH) {
            setView(DEVICE_DETECT.TABLET)
            return
        }

        setView(DEVICE_DETECT.MOBILE)
    }

    useLayoutEffect(() => {
        const handleWindowResize = () => {
            setScreen({
                width: window.innerWidth,
                height: window.innerHeight,
            });

            detectDeviceByScreenSize(window.innerWidth);
        }

        handleWindowResize()

        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [])

    return {
        view,
        screen,
    }
}

export default useResponsive;