import { gql } from "@apollo/client";
import { query } from "configs/http-config";

const reportPartnerAction = {
  getStatReportForPartner: gql`
    query getStatReportForPartner($partnerId: ID!) {
      getStatReportForPartner(partnerId: $partnerId)
    }
  `,

  getStatReportRevenue: gql`
  query getStatReportRevenue($partnerId: ID!, $type: String!,$q: QueryGetListInput, $fromDate: DateTime, $toDate: DateTime ) {
    getStatReportRevenue(partnerId: $partnerId, type: $type, q: $q, fromDate: $fromDate, toDate: $toDate)
  }
`,


};

export const reportPartnerApi = {
  getStatReportForPartner: (payload) => {
    return query(
      "getStatReportForPartner",
      reportPartnerAction.getStatReportForPartner,
      payload
    );
  },

  getStatReportRevenue: (payload) => {
    return query(
      "getStatReportRevenue",
      reportPartnerAction.getStatReportRevenue,
      payload
    );
  },
};
