import { formatPhoneNumberToShow } from "utils";

import "./index.scss";
import Avatar from "components/controls/avatar";

const CallClientConfirm = ({ client }) => {
  return (
    <div className="call-client-confirm">
      <p className="call-client-confirm__message">
        Chắc chắn gọi cho khách hàng này không?
      </p>

      <div className="call-client-confirm__client">
        <Avatar img={client.avatar} />

        <div className="call-client-confirm__client-info">
          <p className="call-client-confirm__client-name">{client?.name}</p>
          <p className="call-client-confirm__client-phone">
            {formatPhoneNumberToShow(client?.phone)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CallClientConfirm;
