import { mutate, query } from "configs/http-config";
import { categoryAction } from "./schema";

export const UnitTypeApi = {
  getAllShopProductUnit: (payload) => {
    return query(
      "getAllShopProductUnit",
      categoryAction.getAllShopProductUnit,
      { q: payload }
    );
  },

  getOneShopProductUnit: (id) => {
    return query(
      "getOneShopProductUnit",
      categoryAction.getOneShopProductUnit,
      {
        getOneShopProductTypeId: id,
      }
    );
  },

  createShopProductUnit: (payload) => {
    return mutate(
      "createShopProductUnit",
      categoryAction.createShopProductUnit,
      payload
    );
  },

  updateShopProductUnit: (payload) => {
    return mutate(
      "updateShopProductUnit",
      categoryAction.updateShopProductUnit,
      payload
    );
  },

  deleteOneShopProductUnit: (payload) => {
    return mutate(
      "deleteOneShopProductUnit",
      categoryAction.deleteOneShopProductUnit,
      payload
    );
  },
};
