import { gql } from "@apollo/client";

export const UnitFragment = gql`
    fragment UnitData on ShopProductUnit {
      id
      code
      name
    }
`

export const categoryAction = {
  getAllShopProductUnit: gql`
    ${UnitFragment}
    query getAllShopProductUnit($q: QueryGetListInput) {
      getAllShopProductUnit(q: $q) {
        data {
          ...UnitData
          createdAt
          updatedAt
        }
        pagination {
          total
          page
        }
        total
      }
    }
  `,

  getOneShopProductUnit: gql`
    query getOneShopProductUnit($getOneShopProductTypeId: ID!) {
      getOneShopProductUnit(id: $getOneShopProductTypeId) {
        id
        name
        code
        createdAt
        updatedAt
      }
    }
  `,

  createShopProductUnit: gql`
    mutation createShopProductUnit($data: CreateShopProductUnitInput!) {
      createShopProductUnit(data: $data) {
        id
      }
    }
  `,

  updateShopProductUnit: gql`
    mutation updateShopProductUnit(
      $id: ID!
      $data: UpdateShopProductUnitInput!
    ) {
      updateShopProductUnit(id: $id, data: $data) {
        id
      }
    }
  `,

  deleteOneShopProductUnit: gql`
    mutation deleteOneShopProductUnit($id: ID!) {
      deleteOneShopProductUnit(id: $id) {
        id
      }
    }
  `,
};
