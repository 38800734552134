import classNames from "classnames";

const AvatarSystem = ({ srcImg, imgClass, name }) => {
  return (
    <div>
      {srcImg ? (
        <img
          className={classNames(imgClass, "w-10 h-10 rounded-full object-cover")}
          src={srcImg}
          alt="name"
        />
      ) : (
        <div
          className={classNames(
            imgClass,
            "w-10 h-10 bg-primary text-white text-lg rounded-full flex justify-center items-center uppercase font-bold"
          )}
        >
          {name?.charAt(0)}
        </div>
      )}
    </div>
  );
};

export default AvatarSystem;
