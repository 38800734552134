import { createAsyncThunk } from "@reduxjs/toolkit";
import { productApi } from "modules/shop/product/apis";
import { MAX_LIMIT } from "utility/hooks/usePagination";

// products
export const getAllShopProduct = createAsyncThunk(
    "/product/getAllShopProduct",
    async (query) => {
        try {
            const response = await productApi.getAllShopProduct(query);
            return response;
        } catch (error) {
            console.log(error);
            throw new Error(error);
        }
    }
);

export const getAllShopProductByCategories = createAsyncThunk(
    "/product/getAllShopProductByCategories",
    async (query) => {
        try {
            const response = await productApi.getAllShopProduct(query);
            return response;
        } catch (error) {
            console.log(error);
            throw new Error(error);
        }
    }
);

export const getOneShopProduct = createAsyncThunk(
    "/product/getOneShopProduct",
    async (query) => {
        try {
            const response = await productApi.getOneShopProduct(query);
            return response;
        } catch (error) {
            console.log(error);
            throw new Error(error);
        }
    }
);

export const getAllActiveProduct = createAsyncThunk(
    "/product/getAllActiveProduct",
    async () => {
        try {
            const response = await productApi.getAllShopProduct({
                page: 1,
                limit: MAX_LIMIT,
                filter: {
                    isActived: true
                },
            });

            return response?.data || []
        } catch (error) {
            console.log(error);
            throw new Error(error);
        }
    }
);

export const products = {
    list: [],
    total: 0,

    productByCategories: [],
    productByCategoriesTotal: 0,
    
    activeProducts: [],
    productAction: null,
};

export const productBuilder = (builder) => {
    builder.addCase(getAllShopProduct.fulfilled, (state, { payload }) => {
        state.products.list = payload?.data || [];
        state.products.total = payload?.total || 0;
    });

    builder.addCase(getAllShopProductByCategories.fulfilled, (state, { payload }) => {
        state.products.productByCategories = payload?.data || [];
        state.products.productByCategoriesTotal = payload?.total || 0;
    });

    builder.addCase(getAllActiveProduct.fulfilled, (state, { payload }) => {
        state.products.activeProducts = payload || [];
    });

    builder.addCase(getOneShopProduct.fulfilled, (state, { payload }) => {
        state.products.productAction = payload
    })
}