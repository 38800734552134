import { useCallback, useMemo } from "react";

// ** Third Party Components
import classNames from "classnames";
import PropTypes from "prop-types";
import Select from "react-select";

// ** Utils
import { selectThemeColors } from "utils";

// ** Style
import "./index.scss";

const SelectSystem = (props) => {
  const {
    value,
    onChange,
    data = [],
    labelKey = "label",
    valueKey = "value",
    className,
    classNamePrefix,
    disable,
    ...restProps
  } = props;

  const selectOptions = useMemo(() => {
    if (!data || !data?.length) {
      return [];
    }

    return data?.map((item) => {
      return { label: item?.[labelKey], value: item?.[valueKey] };
    });
  }, [labelKey, valueKey, data]);

  const selectedValue = useMemo(() => {
    return (
      selectOptions.find((option) => {
        return option.value === value;
      }) || null
    );
  }, [selectOptions, value]);

  const changeSelect = useCallback(
    (selected) => {
      onChange?.(selected.value);
    },
    [selectOptions, value]
  );

  return (
    <Select
      menuPlacement="auto"
      isClearable={false}
      value={selectedValue}
      options={selectOptions}
      menuPortalTarget={document.body}
      theme={selectThemeColors}
      className={classNames(["select-system", className])}
      classNamePrefix={classNames(["select-system-prefix select", classNamePrefix])}
      inputClassName="select__input"
      isSearchable={false}
      isMulti={false}
      isDisabled={disable}
      onChange={changeSelect}
      styles={{
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
        menuPortal: (provided) => ({
          ...provided,
          zIndex: 9999,
        }),
      }}
      {...restProps}
    />
  );
};

SelectSystem.propTypes = {
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.any.isRequired,
  data: PropTypes.array.isRequired,
  classNamePrefix: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default SelectSystem;
