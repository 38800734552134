import InputSystem from 'components/e-control/forms/form-elements/input-system';
import { Controller, useForm } from "react-hook-form";
import { Form } from "reactstrap";

import ButtonSystem from 'components/e-control/button-system';
import './index.scss';

const ActiveDriverForm = ({ active }) => {
    const { control, resetField, handleSubmit } = useForm({});

    const handleToggleDriverStatus = async (formData) => {

        if (!formData.start || !formData.end) {
            return;
        }

        const startPoint = formData.start.split(',').map(point => parseFloat(point))
        const endPoint = formData.end.split(',').map(point => parseFloat(point))

        active?.({ start: startPoint, end: endPoint});

        resetField('start');
        resetField('end');
    };

    return (
        <Form 
            className="forn-wrapper"
            onSubmit={handleSubmit(handleToggleDriverStatus)} 
        >
                <div className="flex items-end gap-x-4">
                    <div className="">
                        <label>Nhập vị trí đi</label>
                        <Controller
                            name="start"
                            control={control}

                            render={({ field }) => (
                                <InputSystem 
                                    placeholder='nhập tọa độ' 
                                    {...field} 
                                />
                            )}
                        />
                    </div>

                    <div className="">
                        <label>Nhập vị trí đến</label>
                        
                        <Controller
                            name="end"
                            control={control}

                            render={({ field }) => (
                                <InputSystem 
                                    placeholder='nhập tọa độ' 
                                    {...field} 
                                />
                            )}
                        />
                    </div>
                    <ButtonSystem
                        type='submit' 
                        className='me-1' 
                        color='primary'
                    >
                        Move
                    </ButtonSystem>
                </div>
            </Form>
    )
};

export default ActiveDriverForm;