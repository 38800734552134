import { useAuth } from "components/containers/auth";
import { Copy, Hash, Share2 } from "lucide-react";
import { QRCodeSVG } from "qrcode.react";
import { useSelector } from "react-redux";

import { copyContent } from "utils";

const QRScreen = ({ coin }) => {
  const { selectedCoin } = useSelector((state) => state.web3);
  const { user } = useAuth();
  const { web3Wallet } = user;

  return (
    <div className="">
      <div className="flex justify-center items-center gap-x-1 mt-8">
        <img src={selectedCoin?.logo} alt="coin" className="size-8" />

        <span className="font-bold text-xl">{selectedCoin?.symbol}</span>

        <span className="p-1 bg-slate-200 rounded-md text-xs text-[#313131]">
          {selectedCoin?.network}
        </span>
      </div>

      <div className="mt-8 flex justify-center">
        <QRCodeSVG
          className="w-[220px] h-[220px]"
          value={`${selectedCoin.address}`}
        />
      </div>

      <div className="max-w-[220px] mx-auto mt-8 break-words text-center text-sm text-primary">
        {selectedCoin?.address}
      </div>

      <div className="mt-8 max-w-[220px] mx-auto">
        <div className="flex justify-center gap-x-8">
          <div className="flex flex-col gap-y-2 items-center justify-center cursor-pointer">
            <div
              onClick={() => copyContent(`${selectedCoin.address}`)}
              className="p-4 bg-slate-200 hover:opacity-80 rounded-full"
            >
              <Copy className="size-4" />
            </div>

            <span className="font-semibold text-base">Copy</span>
          </div>

          {/* <div className="flex flex-col gap-y-2 items-center justify-center cursor-pointer">
            <div
              onClick={() => copyContent(`${selectedCoin.address}`)}
              className="p-4 bg-slate-200 hover:opacity-80 rounded-full"
            >
              <Hash className="size-4" />
            </div>

            <span className="font-semibold text-base">Set Amount</span>
          </div> */}

          <div className="flex flex-col gap-y-2 items-center justify-center cursor-pointer">
            <div
              onClick={() => copyContent(`${selectedCoin.address}`)}
              className="p-4 bg-slate-200 hover:opacity-80 rounded-full"
            >
              <Share2 className="size-4" />
            </div>

            <span className="font-semibold text-base">Share</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QRScreen;
