import { mutate, query } from "configs/http-config";
import {
  createSavingsPackage,
  getAllEgoTransaction,
  getAllSavingsPackage,
  getAllSavingsPlan,
  getOneSavingsPlan,
  getOneSavingsPackage,
  paymentSavingsPackage,
  sendConfirmPaymentSavingsPackage,
  createSavingsPlan,
  updateSavingPlan,
  deleteOneSavingsPlan,
  confirmPaymentSavingsPackage,
} from "./schema";

export const accumulateApi = {
  getAllSavingsPlan: (params) => {
    return query("getAllSavingsPlan", getAllSavingsPlan, {
      q: { ...params },
    });
  },

  getAllSavingsPackage: (params) => {
    return query("getAllSavingsPackage", getAllSavingsPackage, {
      q: { ...params },
    });
  },

  getAllEgoTransaction: (params) => {
    return query("getAllEgoTransaction", getAllEgoTransaction, {
      q: { ...params },
    });
  },

  getOneSavingsPlan: (id) => {
    return query("getOneSavingsPlan", getOneSavingsPlan, {
      id: id,
    });
  },

  getOneSavingsPackage: (id) => {
    return query("getOneSavingsPackage", getOneSavingsPackage, {
      id: id,
    });
  },

  createSavingsPackage: (payload) => {
    return mutate("createSavingsPackage", createSavingsPackage, {
      data: payload,
    });
  },

  createSavingsPlan: (payload) => {
    return mutate("createSavingsPlan", createSavingsPlan, {
      ...payload,
    });
  },

  updateSavingPlan: (payload) => {
    return mutate("updateSavingPlan", updateSavingPlan, {
      ...payload,
    });
  },

  deleteOneSavingsPlan: (payload) => {
    return mutate("deleteOneSavingsPlan", deleteOneSavingsPlan, {
      ...payload,
    });
  },

  paymentSavingsPackage: (payload) => {
    return mutate("paymentSavingsPackage", paymentSavingsPackage, {
      ...payload,
    });
  },

  sendConfirmPaymentSavingsPackage: (id) => {
    return query(
      "sendConfirmPaymentSavingsPackage",
      sendConfirmPaymentSavingsPackage,
      { savingsPackageId: id }
    );
  },

  confirmPaymentSavingsPackage: (id) => {
    return query("confirmPaymentSavingsPackage", confirmPaymentSavingsPackage, {
      savingsPackageId: id,
    });
  },
};
