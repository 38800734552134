import classNames from "classnames";
import ComponentSpinner from "components/controls/spinner/Loading-spinner";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Trash2, Upload } from "react-feather";
import toast from "react-hot-toast";
import CompressMultiImage from "utility/compressMultiImage";

import { getDownloadURL, ref, uploadBytesResumable } from "@firebase/storage";
import { storage } from "configs/firebase";

import cloneDeep from "clone-deep";
import { useSelector } from "react-redux";
import LazyImage from "./components/progressing-image";
import "./index.scss";

const UploadImageSystem = (props) => {
  // ** Props
  const { value, onChange, ...uploadProps } = props;

  const maxSizeMB = uploadProps?.maxSizeMB || 0.5;

  // ** state
  const [imagesUrl, setImageUrl] = useState([]);
  const [loading, setLoading] = useState(false);

  const [localFileUploads, setLocalFileUploads] = useState([]);

  // ** store
  const { userData } = useSelector((state) => state.auth);

  const compressImageFiles = async (files) => {
    try {
      setLoading(true);
      const compressedFiles = await CompressMultiImage(files, maxSizeMB, 1920);

      return compressedFiles;
    } catch (error) {
      toast.error(error?.message);
      return;
    } finally {
      setLoading(false);
    }
  };

  const handleUploadFiles = async (files) => {
    // let newArray = files.slice(0, 5);
    // get tối đa 5 ảnh

    try {
      const compressedFiles = await compressImageFiles(files);

      for (let fileIndex in compressedFiles) {
        const file = compressedFiles[fileIndex];

        const storageRef = ref(
          storage,
          `tripcar/${userData?.id}/images/${file.name}/${Math.floor(
            Date.now() / 1000
          )}`
        );
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_change",
          (snapshot) => {},
          (err) => {
            console.log("error", err);
            toast.error("Tải hình không thành công");
          },

          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              setImageUrl((prev) => {
                if (uploadProps.multiple) {
                  onChange?.([...prev, url]);
                  return [...prev, url];
                }
                onChange?.(url);
                return [url];
              });

              setLocalFileUploads((prev) => {
                const cloneLocalFileUploads = cloneDeep(prev);

                if (fileIndex) {
                  cloneLocalFileUploads.splice(fileIndex - 1, 1);
                } else {
                  cloneLocalFileUploads.splice(fileIndex, 1);
                }

                return cloneLocalFileUploads;
              });
            });
          }
        );
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleRemoveImage = (imageUrlIndex) => {
    const cloneImageUrl = cloneDeep(imagesUrl);
    cloneImageUrl.splice(imageUrlIndex, 1);
    setImageUrl(cloneImageUrl);

    if (uploadProps.multiple) {
      onChange?.(cloneImageUrl?.length ? cloneImageUrl : undefined);
    } else {
      onChange?.(undefined);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg", ".gif"],
    },
    ...uploadProps,
    onDrop: async (acceptedFiles, rejectedFiles) => {
      if (!uploadProps.multiple) {
        setImageUrl([]);
      }

      if (rejectedFiles.length) {
        toast.error("You can only upload the same type Files!.");
        return;
      }

      const uploadedFiles = acceptedFiles.map((file) => ({
        item: file,
        isLoading: true,
      }));

      setLocalFileUploads(uploadedFiles);
      handleUploadFiles(acceptedFiles);
    },
  });

  useEffect(() => {
    if (Array.isArray(value)) {
      if (value && value.length) {
        setImageUrl(value);
      }
    } else {
      if (value) {
        setImageUrl([value]);
      }
    }
  }, [value]);

  useEffect(() => {
    return () => {
      setImageUrl([]);
      setLocalFileUploads([]);
    };
  }, []);

  return (
    <div className="upload-image-system">
      <div className="upload-image-system__button" {...getRootProps()}>
        <input {...getInputProps()} />
        <Upload />

        <span>Tải ảnh lên</span>
      </div>

      <div className="upload-image-system__file-list">
        {imagesUrl.map((url, index) => {
          return (
            <div
              key={index}
              className={classNames(["upload-image-system__item-wrapper"])}
            >
              <LazyImage alt={index} src={url} />

              <div className="upload-image-system__item-action">
                <span
                  className="upload-image-system__item-delete"
                  onClick={() => handleRemoveImage(index)}
                >
                  <Trash2 />
                </span>
              </div>
            </div>
          );
        })}

        {loading && <ComponentSpinner />}

        {/* {localFileUploads?.map((file, index) => (
          <div
            key={`${file.item.name}_${index}`}
            className={classNames(["upload-image-system__item-wrapper"])}
          >
            <img alt={file.name} src={URL.createObjectURL(file.item)} />

            {file.isLoading && (
              <div className="upload-image-system__item-loading">
                <ComponentSpinner />
              </div>
            )}
          </div>
        ))} */}
      </div>
    </div>
  );
};

export default UploadImageSystem;
