import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { bookingPartnerApi } from "../apis";

export const getAllBookingsPartner = createAsyncThunk(
  "/driver/getAllBookingPartner",
  async (query) => {
    try {
      const response = await bookingPartnerApi.getAllPartnerTrip(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getBookingDetail = createAsyncThunk(
  "/driver/getOneBooking",
  async (query) => {
    try {
      const response = await bookingPartnerApi.getOneBooking({ id: query });

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getAllActivityHistory = createAsyncThunk(
  "/driver/getAllActivityHistory",
  async (query) => {
    try {
      const response = await bookingPartnerApi.getAllActivityHistory({ filter: query });

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const BookingSlice = createSlice({
  name: "bookingsPartner",
  initialState: {
    list: [],
    total: 0,
    stats: {},
    bookingDetail: {},

    historyBookings: {},

  },

  extraReducers: (builder) => {
    builder.addCase(getAllBookingsPartner.fulfilled, (state, { payload }) => {
      state.list = payload?.data || [];
      state.total = payload?.total || 0;
      state.stats = payload?.getStatBooking?.[0] || {};
    });

    builder.addCase(getBookingDetail.fulfilled, (state, { payload }) => {
      state.bookingDetail = payload || {};
    });

    builder.addCase(getAllActivityHistory.fulfilled, (state, { payload }) => {
      state.historyBookings = payload || {};
    });
  },
});

// export const {} = driverSlice.actions;

export default BookingSlice.reducer;
