import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";

import './index.scss';
import classNames from "classnames";

const DriverList = ({ driverId, onClick }) => {

    const { drivers } = useSelector(state => state.map);

    console.log(drivers);

    return (
        <Row className="driver-list">
            {
                drivers.map(driver => (
                    <Col
                    span={3}
                        key={driver.id}
                        className={
                            classNames([
                                "driver-item",
                                {
                                    'driver-selected': driver.id === driverId
                                }
                            ])
                        }

                        onClick={() => onClick(driver)}
                    >
                        {driver.phone}
                    </Col>
                ))
            }
        </Row>
    )
};

export default DriverList;