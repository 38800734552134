import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { carPartnerApi } from "../apis";

export const getAllPartnerCar = createAsyncThunk(
  "/car/getAllPartnerCar",
  async (query) => {
    try {
      const response = await carPartnerApi.getAllPartnerCar(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const createPartnerCar = async (query) => {
  try {
    const payload = {
      data: {
        carCode: query?.carCode,
        licensePlateNumber: query?.licensePlate,
        carTypeId: query?.carType,
        images: query?.images,
        workPlaceId: query?.workPlaceId,
      },
    };

    await carPartnerApi.createPartnerCar(payload);
  } catch (error) {
    throw new Error(error);
  }
};

export const getAllCarTypeWOPagingForPartner = createAsyncThunk(
  "/car/getAllCarTypeWOPagingForPartner",
  async (query) => {
    try {
      const response = await carPartnerApi.getAllCarTypeWOPagingForPartner({
        businessTypes: query?.businessTypes,
        category: query?.category,
        partnerId: query?.partnerId,
      });

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getAllCarOwner = createAsyncThunk(
  "/car/getAllCarOwner",
  async (query) => {
    try {
      const response = await carPartnerApi.getAllCarOwner(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const carSlice = createSlice({
  name: "carPartner",
  initialState: {
    cars: {
      list: [],
      total: 0,
    },

    carTypes: {
      list: [],
      total: 0,
    },

    carHost: {
      list: [],
      total: 0,
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllPartnerCar.fulfilled, (state, { payload }) => {
      state.cars.list = payload?.data || [];
      state.cars.total = payload?.total || 0;
    });

    builder.addCase(
      getAllCarTypeWOPagingForPartner.fulfilled,
      (state, { payload }) => {
        state.carTypes.list = payload || [];
        // state.carTypes.total = payload?.total || 0;
      }
    );

    builder.addCase(getAllCarOwner.fulfilled, (state, { payload }) => {
      state.carHost.list = payload?.data || [];
      state.carHost.total = payload?.total || 0;
    });
  },
});

export default carSlice.reducer;
