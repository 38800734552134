import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { dispatch } from "root-stores";

import { getAllSavingsPackage, getAllSavingsPlan } from "./stores";

import TabPackage from "./components/tab-package";
import TabLinkAccumulate from "./components/tab-link";
import TabRateAccumulate from "./components/tab-rate";
import OrderHistoryAccumulatePage from "./page-order-history";

const TabEnum = {
  INVEST: 1,
  HISTORY: 2,
  LINK: 3,
  RATE: 4,
};

const AccumulatePage = () => {
  const [tab, setTab] = useState(TabEnum.INVEST);

  const loadListAccumulate = async () => {
    const params = {
      page: 1,
      limit: 100,
    };

    await dispatch(getAllSavingsPlan(params));
  };
  useEffect(() => {
    loadListAccumulate();

    dispatch(
      getAllSavingsPackage({
        page: 1,
        limit: 5,
        //   filter: { status: { $ne: "DELETED" } },
      })
    );
  }, []);

  const screenRef = useRef();

  useEffect(() => {
    if (!screenRef.current) {
      return;
    }

    const screenEle = screenRef.current;
    const screenRect = screenEle.getBoundingClientRect();
    screenEle.style.height = window.innerHeight - (screenRect.top ?? 0) + "px";
  }, []);

  return (
    <div
      ref={screenRef}
      className="max-w-[900px] m-auto overflow-y-hidden pb-6"
    >
      <div className="flex flex-col relative h-full">
        <div className="p-3 flex items-center overflow-x-auto gap-x-3">
          <div
            className={classNames(
              "text-sm text-blue-950 flex-[2] p-2 rounded-md text-center whitespace-nowrap",
              tab === TabEnum.INVEST && "bg-primary text-white"
            )}
            onClick={() => setTab(TabEnum.INVEST)}
          >
            Tích luỹ
          </div>

          <div
            className={classNames(
              "text-sm text-blue-950 flex-[2] p-2 rounded-md text-center whitespace-nowrap",
              tab === TabEnum.HISTORY && "bg-primary text-white"
            )}
            onClick={() => setTab(TabEnum.HISTORY)}
          >
            Lịch sử tích luỹ
          </div>

          <div
            className={classNames(
              "text-sm text-blue-950 flex-[2] p-2 rounded-md text-center whitespace-nowrap",
              tab === TabEnum.RATE && "bg-primary text-white"
            )}
            onClick={() => setTab(TabEnum.RATE)}
          >
            Lịch sử nhận lãi
          </div>

          <div
            className={classNames(
              "text-sm text-blue-950 flex-[2] p-2 rounded-md text-center whitespace-nowrap",
              tab === TabEnum.LINK && "bg-primary text-white"
            )}
            onClick={() => setTab(TabEnum.LINK)}
          >
            Liên kết
          </div>
        </div>

        <div className="px-3 overflow-y-auto h-full flex-[2]">
          {tab === TabEnum.INVEST && <TabPackage />}

          {tab === TabEnum.HISTORY && <OrderHistoryAccumulatePage />}

          {tab === TabEnum.LINK && <TabLinkAccumulate />}

          {tab === TabEnum.RATE && <TabRateAccumulate />}
        </div>
      </div>
    </div>
  );
};

export default AccumulatePage;
