export const HOTLINE_OPTIONS = {
    VIEW: {
        ADD_FORM: 1,
        BOOKING_LIST: 0,
        BOOKING_DETAIL: 2,
    },

    BOOKING_CREATE_TYPE: {
        BOOKING: 1,
        REQUEST: 2,
    }
}