import { gql } from "@apollo/client";
import { mutate, query } from "configs/http-config";

const partnerAction = {
  getAllPartner: gql`
    query getAllPartner(
      $q: QueryGetListInput
    ) {
      getAllPartner(
        q: $q
      ) {
        data {
          id
          name
          shortName
          username
          phone
          email
          password
          isBlock
          key
          role
          location
          identityCard
          avatar
          address
          signInProvider
          isActived
          carId
          workPlaceId
          checkPointIds
          tripIds
          distanceCalculated
          status
          usedCouponIds
          salary
          bonus
          walletId
          revenueToday
          carTypeId
          point
          rank
          workPlaceIds
          carTypeIds
          partnerTypes
          travelTypes
          transportationTypes
          shoppingTypes
          workPlaces {
            id
            location
            name
          }
          carTypes {
            id
            name
          }
          businessRules
          workPlace {
            id
            name
          }
          trips {
            id
          }
          car {
            id
            carCode
            carTypeId
          }
          carType {
            id
            icon
            name
          }
          wallet {
            id
            userId
            balance
          }
          createdAt
          updatedAt
        }
        getStatUser
        total
      }
    }
  `,

  createPartnerForAdmin: gql`
    mutation createPartnerForAdmin(
      $name: String!
      $shortName: String!
      $phoneNumber: String!
      $address: String
      $password: String!
      $avatar: String
      $workPlaceIds: [ID]
      $carTypeIds: [ID]
      $email: String
      $partnerTypes: [String]
      $transportationTypes: [String]
      $shoppingTypes: [String]
      $travelTypes: [String]
      $businessRules: [String]
    ) {
      createPartnerForAdmin(
        name: $name
        shortName: $shortName
        phoneNumber: $phoneNumber
        address: $address
        avatar: $avatar
        workPlaceIds: $workPlaceIds
        carTypeIds: $carTypeIds
        email: $email
        partnerTypes: $partnerTypes
        transportationTypes: $transportationTypes
        shoppingTypes: $shoppingTypes
        travelTypes: $travelTypes
        businessRules: $businessRules
        password: $password
      ) {
        id
      }
    }
  `,

  getAllCarTypeWOPaging: gql`
    query getAllCarTypeWOPaging($businessTypes: [String]) {
      getAllCarTypeWOPaging(businessTypes: $businessTypes) {
        id
        name
        isActived
        createdAt
        updatedAt
      }
    }
  `,

  getAllWorkPlaceWOPaging: gql`
    query getAllWorkPlaceWOPaging {
      getAllWorkPlaceWOPaging {
        id
        name
        location
        landMarkIds
        landMarks {
          id
          name
        }
      }
    }
  `,

  updateUser: gql`
    mutation updateUser($id: ID!, $data: UpdateUserInput!) {
      updateUser(id: $id, data: $data) {
        id
        name
      }
    }
  `,

  changePasswordForAdmin: gql`
    mutation changePasswordForAdmin(
      $userId: ID!
      $newPassword: String!
    ) {
      changePasswordForAdmin(userId: $userId, newPassword: $newPassword) {
        id
        phone
      }
    }
  `,

  deleteOneUser: gql`
    mutation deleteOneUser($id: ID!) {
      deleteOneUser(id: $id) {
        id
        phone
      }
    }
  `,
};

export const partenrApi = {
  getAllPartner: (payload) => {
    return query("getAllPartner", partnerAction.getAllPartner, payload);
  },

  createPartnerForAdmin: (payload) => {
    return mutate(
      "createPartnerForAdmin",
      partnerAction.createPartnerForAdmin,
      payload
    );
  },

  getAllCarTypeWOPaging: (payload) => {
    return query(
      "getAllCarTypeWOPaging",
      partnerAction.getAllCarTypeWOPaging,
      payload
    );
  },

  getAllWorkPlaceWOPaging: () => {
    return query(
      "getAllWorkPlaceWOPaging",
      partnerAction.getAllWorkPlaceWOPaging
    );
  },

  updateUser: (payload) => {
    return mutate("updateUser", partnerAction.updateUser, payload);
  },

  deleteOneUser: (payload) => {
    return mutate("deleteOneUser", partnerAction.deleteOneUser, payload);
  },

  changePasswordForAdmin: (payload) => {
    console.log('payload', payload)
    return mutate("changePasswordForAdmin", partnerAction.changePasswordForAdmin, payload);
  },
};
