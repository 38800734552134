import { Controller, useForm, useWatch } from "react-hook-form";
import { Form, FormGroup, Label } from "reactstrap";

import "./index.scss";

import classNames from "classnames";
import { useAuth } from "components/containers/auth";
import ButtonSystem from "components/e-control/button-system";
import CheckBoxSystem from "components/e-control/forms/form-elements/checkbox-system";
import InputNumberSystem from "components/e-control/forms/form-elements/input-number-system/input-number-system";
import { investApi } from "modules/invest/apis";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { INVEST_ROUTES } from "router/invest-routes";
import { formatNumberToAmount } from "utility/number-utils";

// const StyleSelect = styled(SelectSystem)`
//  .select__single-value {
//   color: rgb(23 37 84/var(--tw-text-opacity)) !important;
//  }

// `

export const PAYMENT_METHODS = {
  EWALLET: "E_WALLET",
  QRCODE: "QR_CODE",
  COMPANY: "COMPANY",
};

export const regex9pay = /https:\/\/sand-payment\.9pay\.vn\//;

const BuyForm = ({
  onCancel,
  campaign,
  handleSetInfoQrCode,
  isPaymentAgain = false,
  setOrderId,
}) => {
  const form = useForm({
    mode: "onChange",
    defaultValues: {
      paymentMethods: PAYMENT_METHODS.EWALLET,
      quantity: campaign?.quantity || 1000,
    },
  });

  const navigate = useNavigate();
  const { user } = useAuth();

  const isRestStock = campaign?.totalStock - campaign?.totalSoldTock;

  const [isAgree, setIsAgree] = useState(false);

  const { control, formState } = form;

  const { errors } = formState;

  const quantity = useWatch({ control, name: "quantity" });

  useEffect(() => {
    if (quantity > isRestStock) {
      form.setValue("quantity", isRestStock);

      return;
    }

    // if (quantity < 1000 || quantity > 1000000) {
    //   form.setValue("quantity", 1000);

    //   return;
    // }
  }, [quantity]);

  const handlePaymentAgain = async (payment) => {
    try {
      const paymentPayload = {
        orderId: campaign?.orderId,
        paymentMethod: payment,
      };

      setOrderId?.(campaign?.orderId);

      const paymentResponse = await investApi.paymentInvestmentOrder(
        paymentPayload
      );

      if (paymentResponse === "success") {
        toast.success("Giao dịch thành công");
        setTimeout(() => {
          navigate(INVEST_ROUTES.INVEST_WEBVIEW);
        }, [1000]);
        return;
      }

      if (paymentResponse?.QRCode) {
        toast?.success("Vui lòng quét mã QR để thanh toán");

        setTimeout(() => {
          handleSetInfoQrCode(paymentResponse);
        }, [1000]);
        return;
      }

      if (regex9pay.test(paymentResponse)) {
        window.location.assign(paymentResponse);
        return;
      }

      throw new Error("Lỗi không thể thanh toán");
    } catch (error) {
      // if (error?.message === "Wallet balance is not enough!") {
      //   navigate("/deposit-wallet");

      //   return;
      // }

      toast.error(error.message);
    }
  };

  const submitOrder = async (formData) => {
    if (
      quantity * campaign?.pricePerStock > user?.wallet?.balance &&
      formData?.paymentMethods === PAYMENT_METHODS.EWALLET
    ) {
      toast.error(
        "Không thể thanh toán do không đủ tiền. Xin thử lại phương thức khác"
      );
      return;
    }

    if (isPaymentAgain) {
      handlePaymentAgain(formData?.paymentMethods);

      return;
    }

    try {
      const payload = {
        quantity: formData?.quantity,
        investmentProjectId: campaign.id,
        paymentMethod: formData.paymentMethods,
      };

      const response = await investApi.createInvestmentOrder(payload);

      if (!response?.id) {
        throw new Error("Lỗi không thể thanh toán");
      }

      const paymentPayload = {
        orderId: response?.id,
        paymentMethod: response?.paymentMethod,
      };

      setOrderId?.(response?.id);

      const paymentResponse = await investApi.paymentInvestmentOrder(
        paymentPayload
      );

      if (paymentResponse === "success") {
        toast.success("Giao dịch thành công");
        setTimeout(() => {
          navigate(INVEST_ROUTES.INVEST_WEBVIEW);
        }, [1000]);
        return;
      }

      if (paymentResponse?.QRCode) {
        toast?.success("Vui lòng quét mã QR để thanh toán");

        setTimeout(() => {
          handleSetInfoQrCode(paymentResponse);
        }, [1000]);
        return;
      }

      if (regex9pay.test(paymentResponse)) {
        window.location.assign(paymentResponse);
        return;
      }

      throw new Error("Lỗi không thể thanh toán");
    } catch (error) {
      // if (error?.message === "Wallet balance is not enough!") {
      //   // navigate("/deposit-wallet");
      //   toast.error(
      //     "Không thể thanh toán do không đủ tiền. Xin thử lại phương thức khác"
      //   );

      //   return;
      // }
      toast.error(error.message);
    }
  };

  return (
    <>
      <Form
        control={control}
        className="buy-form-invest pb-4"
        onSubmit={form.handleSubmit(submitOrder)}
      >
        <div className="flex flex-col mb-4">
          <span className="text-blue-950">{campaign?.name}</span>

          <div className="flex items-center justify-between">
            <div className="flex items-center gap-x-2">
              <span className="text-sm text-secondary-2">
                Số lượng phát hành:
              </span>
              <span className="text-blue-950">
                {formatNumberToAmount(campaign?.totalStock)}
              </span>
            </div>

            <div className="flex items-center gap-x-2">
              <span className="text-sm text-secondary-2">Đơn giá:</span>
              <span className="text-blue-950">
                {formatNumberToAmount(campaign?.pricePerStock)}
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap gap-2">
          <FormGroup className="!mb-0 flex-1">
            <Label className="text-primary">Số cổ phiếu</Label>
            <Controller
              control={control}
              name="quantity"
              render={({ field }) => (
                <InputNumberSystem
                  {...field}
                  className="[&>div>input]:bg-white [&>div>input]:text-[16px] text-blue-950"
                  disabled={isPaymentAgain}
                  min={1000}
                  max={1000000}
                />
              )}
              rules={{
                required: "Vui lòng nhập số cổ phiếu",
              }}
            />

            <div className="text-[red] text-xs">
              {errors?.quantity?.message}
            </div>
          </FormGroup>

          <FormGroup className="!mb-0 flex-1">
            <Label className="text-primary">Dự toán</Label>
            <Controller
              control={control}
              name="amount"
              render={({ field: { value } }) => (
                <InputNumberSystem
                  readOnly
                  value={(quantity || 0) * campaign?.pricePerStock || undefined}
                  className="[&>div>input]:bg-white [&>div>input]:text-[16px] text-blue-950"
                />
              )}
            />
          </FormGroup>
        </div>

        <FormGroup className="!mb-0">
          <Label className="text-primary">Phương thức thanh toán</Label>
          <Controller
            control={control}
            name="paymentMethods"
            render={({ field: { value, onChange } }) => (
              <div className="flex flex-col">
                <div
                  className="flex items-center justify-between py-2 border-b"
                  onClick={() => onChange(PAYMENT_METHODS.EWALLET)}
                >
                  <div className="flex items-center gap-x-2">
                    <div
                      className={classNames(
                        "size-5 rounded-full border border-primary",
                        value === PAYMENT_METHODS.EWALLET && "bg-primary"
                      )}
                    />
                    <h3 className="text-blue-950 text-sm">
                      {`Ví điện tử EPay (${formatNumberToAmount(
                        user?.wallet?.balance || 0
                      )})`}
                    </h3>
                  </div>
                </div>

                <div
                  className="flex items-center justify-between py-2 border-b"
                  onClick={() => onChange(PAYMENT_METHODS.COMPANY)}
                >
                  <div className="flex items-center gap-x-2">
                    <div
                      className={classNames(
                        "size-5 rounded-full border border-primary",
                        value === PAYMENT_METHODS.COMPANY && "bg-primary"
                      )}
                    />
                    <h3 className="text-blue-950 text-sm">
                      Chuyển khoản công ty
                    </h3>
                  </div>
                </div>
              </div>
            )}
          />
        </FormGroup>

        <h4 className="text-primary">
          Thanh toán:{" "}
          {formatNumberToAmount((quantity || 0) * campaign?.pricePerStock) || 0}
          đ{" "}
        </h4>

        <CheckBoxSystem
          value={isAgree}
          onChange={(e) => {
            setIsAgree(e);
          }}
          classNameLabel="text-black"
          classNameInput="w-5 h-5"
          label=<div>
            Lưu ý: Bằng việc xác nhận bạn đã xác nhận đọc và hiểu, đồng ý với{" "}
            {""}
            <a
              href="https://www.ecorp.global/policy"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              Điều khoản và Điều kiện{" "}
            </a>
            của sản phẩm đầu tư
          </div>
        />

        <div className="flex items-center gap-x-2 mt-8">
          <ButtonSystem
            type="button"
            color="danger"
            className="bg-red-500 text-white"
            onClick={onCancel}
          >
            Thoát
          </ButtonSystem>

          <ButtonSystem
            type="submit"
            color="primary"
            className="flex-[2]"
            disabled={!isAgree}
          >
            Thanh toán
          </ButtonSystem>
        </div>
      </Form>
    </>
  );
};

export default BuyForm;
