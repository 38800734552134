/* eslint-disable multiline-ternary */
import classNames from "classnames";
import ButtonSystem from "components/e-control/button-system";
import DrawerFilterSystem from "components/e-control/drawer-system-filter";
import ViewDevice from "components/utils/view-device";
import { SlidersHorizontal } from "lucide-react";
import {
  Children,
  cloneElement,
  isValidElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { Card } from "reactstrap";
import { DEVICE_DETECT } from "utility/hooks/useResponsive";

export const TableFilter = ({ children, className }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ViewDevice>{children}</ViewDevice>

      <ViewDevice is={[DEVICE_DETECT.MOBILE, DEVICE_DETECT.TABLET]}>
        <ButtonSystem
          className="!bg-[rgb(130, 134, 139)]"
          onClick={() => setOpen(true)}
        >
          <SlidersHorizontal className="w-4 h-4" />
        </ButtonSystem>
      </ViewDevice>

      {open && (
        <DrawerFilterSystem
          isOpen={open}
          header="Lọc"
          
          onClose={() => setOpen(false)}
        >
          {cloneElement(children, { onClose: () => setOpen(false), open })}
        </DrawerFilterSystem>
      )}
    </>
  );
};

export const Slot = ({ children }) => {
  return children;
};

Slot.displayName = "Slot";

const TablePageContent = ({ children, className }) => {
  let header = null;
  let content = [];

  Children.forEach(children, (child, index) => {
    if (isValidElement(child) && child.type?.displayName === "Slot") {
      if (child.props.name === "header") {
        header = child.props.children
          ? cloneElement(child.props.children)
          : null;
      }

      if (!child.props.name || child.props.name === "default") {
        content = child.props.children
          ? cloneElement(child.props.children)
          : null;
      }
    } else {
      content.push(children[index]);
    }
  });

  const contentRef = useRef();

  useEffect(() => {
    if (!contentRef.current) {
      return;
    }

    const contentRect = contentRef.current.getBoundingClientRect();

    contentRef.current.style.setProperty(
      "--table-content-top",
      contentRect.top + "px"
    );
    contentRef.current.style.setProperty(
      "--table-content-width",
      contentRect.width + "px"
    );
    contentRef.current.style.setProperty(
      "--table-content-height",
      contentRect.height + "px"
    );
  }, []);

  return (
    <>
      <div
        ref={contentRef}
        className={classNames(
          "transition-all flex items-stretch flex-row space-x-[16px]",
        )}
      >
        <ViewDevice is={[DEVICE_DETECT.DESKTOP, DEVICE_DETECT.TABLET]}>
          <Card
            className={classNames(
              `block m-0 flex flex-col flex-[1_0_100%] max-w-full sm:flex-[2] p-2`,
              className
            )}
          >
            {header && <div>{header}</div>}

            <div className="flex flex-col">{content}</div>
          </Card>
        </ViewDevice>

        <ViewDevice is={[DEVICE_DETECT.MOBILE]}>
          <div
            className={classNames(
              `block m-0 flex flex-col flex-[1_0_100%] max-w-full sm:flex-[2] gap-y-2`,
              className
            )}
          >
            {header && (
              <Card className="px-3 py-4 mb-0">
                <div>{header}</div>
              </Card>
            )}

            <div className="flex flex-col">{content}</div>
          </div>
        </ViewDevice>
      </div>
    </>
  );
};

TablePageContent.Filter = TableFilter;

export default TablePageContent;
