import DrawerSystem from "components/e-control/drawer-system";

import './index.scss';


const Popup = ({children, ...props}) => {
    return (
        <DrawerSystem
            className=""
            wrapperClassName="modal-slide-up popup-invest-client"
            {...props}
            hasClose
        >
            { children}
        </DrawerSystem>
    )
}

export default Popup;