import { gql } from "@apollo/client";
import { CategorySheama } from "modules/shop/product-category/apis/schema";
import { UnitFragment } from "modules/shop/unit/apis/schema";

export const ProductFragment = gql`
  ${CategorySheama}
  fragment ProductData on ShopProduct {
    id
    name
    code
    unit
    image
    price
    element
    optionGroupIds

    tax
    shopSupplierId
    shopSupplier {
      id
      name
    }

    optionGroups {
      id
      name
      min
      max

      options {
        id
        name
        optionGroupIds
        price
        shopProductIds
        isCountable
        image
      }
    }

    combos {
      id
      name
      description
      price
      details {
        shopProduct {
          code
          id
          name
        }
      }
    }

    quantity
    isActived
    description
    warehouseDate
    shortDescription
    shopProductTypeId
    shopProductWarehouseId
    shopProductWarehouse {
      id
      name
      quantity
      sellPrice
      soldQuantity
    }
    shopProductType {
      ...Categories
    }
  }
`;

export const productAction = {
  getAllShopProduct: gql`
    ${ProductFragment}
    query GetAllShopProduct($q: QueryGetListInput) {
      getAllShopProduct(q: $q) {
        data {
          createdAt
          updatedAt
          ...ProductData
          shopProductUnit {
            id
            name
          }
        }
        pagination {
          page
          total
        }
        total
      }
    }
  `,

  getAllShopOrder: gql`
    query getAllShopOrder($q: QueryGetListInput) {
      getAllShopOrder(q: $q) {
        data {
          id
          initialCost
          finalCost
          code
          discount
          createdAt
          quantity
          shipAmount
          status
          isPaid
          paymentMethod

          shopCarts {
            id
            price

            shopProduct {
              id
              price
              image
            }
          }
        }
        pagination {
          page
          total
        }
        total
      }
    }
  `,

  getOneShopOrder: gql`
    query getOneShopOrder($id: ID!) {
      getOneShopOrder(id: $id) {
        id
        initialCost
        finalCost
        code
        discount
        createdAt
        quantity
        shipAmount
        status
        isPaid
        paymentMethod

        distance
        note
        pickupAddress
        tax

        user {
          id
          name
          phone
        }

        shopCarts {
          id
          price
          status
          quantity

          options {
            quantity

            option {
              image
              name
              price
              isCountable
            }
          }

          shopProduct {
            id
            price
            image
            name
            quantity
          }
        }
      }
    }
  `,

  getOneShopProduct: gql`
    ${ProductFragment}
    ${UnitFragment}
    query getOneShopProduct($id: ID!) {
      getOneShopProduct(id: $id) {
        ...ProductData
        shopProductUnit {
          ...UnitData
        }
        createdAt
        updatedAt
      }
    }
  `,

  createShopProduct: gql`
    mutation createShopProduct($data: CreateShopProductInput!) {
      createShopProduct(data: $data) {
        id
        name
      }
    }
  `,

  updateShopProduct: gql`
    mutation updateShopProduct($id: ID!, $data: UpdateShopProductInput!) {
      updateShopProduct(id: $id, data: $data) {
        id
      }
    }
  `,

  deleteOneShopProduct: gql`
    mutation deleteOneShopProduct($id: ID!) {
      deleteOneShopProduct(id: $id) {
        id
      }
    }
  `,
};
