import { gql } from "@apollo/client";
import { mutate, query } from "configs/http-config";

const expenseAction = {
  getAllExpense: gql`
    query getAllExpense(
      $page: Int
      $limit: Int
      $filter: Mixed
      $order: Mixed
      $search: String
      $partnerId: ID!
    ) {
      getAllExpense(
        q: {
          limit: $limit
          page: $page
          filter: $filter
          order: $order
          search: $search
        }
        partnerId: $partnerId
      ) {
        data {
          id
          name
          partner {
            id
            name
          }
          partnerId
          expenseTypeId
          expenseType {
            id
            name
          }
          cost
          createdAt
          updatedAt
          paymentDate
        }
        getStatExpense
        total
      }
    }
  `,

  getAllExpenseType: gql`
    query getAllExpenseType(
      $page: Int
      $limit: Int
      $filter: Mixed
      $order: Mixed
      $search: String
    ) {
      getAllExpenseType(
        q: {
          page: $page
          limit: $limit
          filter: $filter
          order: $order
          search: $search
        }
      ) {
        data {
          id
          name
          description
        }
      }
    }
  `,

  getOneExpense: gql`
    query getOneExpense($id: ID!) {
      getOneExpense(id: $id) {
        cost
        id
        name
        expenseType {
          id
          name
        }
        expenseTypeId
        createdAt
        partnerId
      }
    }
  `,

  createExpense: gql`
    mutation createExpense($data: CreateExpenseInput!) {
      createExpense(data: $data) {
        id
      }
    }
  `,

  updateExpense: gql`
    mutation updateExpense($id: ID!, $data: UpdateExpenseInput!) {
      updateExpense(id: $id, data: $data) {
        id
      }
    }
  `,

  deleteOneExpense: gql`
    mutation deleteOneExpense($id: ID!) {
      deleteOneExpense(id: $id) {
        id
      }
    }
  `,
};

export const expenseApi = {
  getAllExpense: (payload) => {
    return query("getAllExpense", expenseAction.getAllExpense, payload);
  },

  getAllExpenseType: (payload) => {
    return query("getAllExpenseType", expenseAction.getAllExpenseType, payload);
  },

  getOneExpense: (payload) => {
    return query("getOneExpense", expenseAction.getOneExpense, payload);
  },

  createExpense: (payload) => {
    return mutate("createExpense", expenseAction.createExpense, payload);
  },

  updateExpense: (payload) => {
    return mutate("updateExpense", expenseAction.updateExpense, payload);
  },

  deleteOneExpense: (payload) => {
    return mutate("deleteOneExpense", expenseAction.deleteOneExpense, payload);
  },
};
