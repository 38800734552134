import ButtonSystem from "components/e-control/button-system";
import React from "react";
import { useNavigate } from "react-router-dom";
import { INVEST_ROUTES } from "router/invest-routes";

const BlockButtonStake = () => {
  const navigate = useNavigate();

  return (
    <div className="mt-4 flex items-center justify-between gap-x-2">
      <ButtonSystem
        onClick={() => navigate(INVEST_ROUTES.STAKING_ETH)}
        className="flex-1"
      >
        Rút
      </ButtonSystem>

      <ButtonSystem
        onClick={() => navigate(INVEST_ROUTES.STAKING_ETH)}
        className="flex-1"
        color="primary"
      >
        Staking USDT
      </ButtonSystem>
    </div>
  );
};

export default BlockButtonStake;
