import React from "react";
import { Bolt, BookLock, BookUser, CircleDot, HandHelping } from "lucide-react";

const CardDescriptionStake = () => {
  const LIST_DES = [
    {
      icon: <HandHelping className="w-5 h-5" />,
      title: "Bắt đầu chỉ với 0,00001 USDT",
    },
    {
      icon: <CircleDot className="w-5 h-5" />,
      title: "Các hình phạt cắt giảm do EGO áp dụng",
    },
    {
      icon: <BookLock className="w-5 h-5" />,
      title: "Chính sách thoát linh hoạt",
    },
    {
      icon: <BookUser className="w-5 h-5" />,
      title:
        "Được người dùng của chúng tôi tin cậy với tổng số USDT đã stake là 1.366.917,33484201",
    },
    {
      icon: <Bolt className="w-5 h-5" />,
      title: "Các trường hợp sử dụng WBUSDT trong DeFi",
    },
  ];

  return (
    <div className="-mt-2">
      <h4 className="text-[#000] text-lg font-semibold">Ego có tất cả</h4>

      <div className="mt-4 flex flex-col gap-3">
        {LIST_DES?.map((item, index) => (
          <div key={index} className="flex items-center gap-x-2">
            <div className="flex-shrink-0">{item?.icon}</div>

            <span className="text-[#000]">{item?.title}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardDescriptionStake;
