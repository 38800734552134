import i18next from "i18next";
import moment from "moment";
import { toast } from "react-hot-toast";
// import { Toast } from "./notification";
// import numeral from "numeral";

const Utils = {
  convertToServerTime(date) {
    if (!date) {
      return "";
    }
    return date.toISOString();
  },

  formatDate(date) {
    return moment(date).format("h:mm, MMM DD, YYYY");
  },

  formatDateOnlyNumber(date) {
    return moment(date).format("HH:mm, DD/MM/YYYY");
  },

  getTimeString(time) {
    return moment(time).format("HH:mm, DD/MM/YYYY").toString();
  },

  formatDateUtc(date) {
    return moment.utc(date).format("HH:mm, DD/MM/YYYY");
  },

  formatOnyDate(date, formatDate = "DD/MM/YYYY") {
    return moment(date).format(formatDate);
  },

  formatOnyDates(date, formatDate = "YYYY-MM-DD") {
    return moment(date).format(formatDate);
  },

  copyToClipboard(text) {
    try {
      navigator.clipboard.writeText(text);
      toast.success(i18next.t("Sao chép thành công"));
    } catch (err) {
      toast.error(err);
    }
  },

  randomTwoNumber(length) {
    const randomList = [];
    while (randomList.length < length) {
      const firstDigit = Math.floor(Math.random() * 10);
      const secondDigit = Math.floor(Math.random() * 10);
      const randomNumber = `${firstDigit}${secondDigit}`;
      if (!randomList.includes(randomNumber)) {
        randomList.push(randomNumber);
      }
    }

    return randomList
      .sort(function (a, b) {
        return a - b;
      })
      .toString()
      .split(",")
      .join(" ");
  },

  randomFourNumber(length) {
    const randomList = [];
    while (randomList.length < length) {
      const firstDigit = Math.floor(Math.random() * 10);
      const secondDigit = Math.floor(Math.random() * 10);
      const thirdDigit = Math.floor(Math.random() * 10);
      const fourDigit = Math.floor(Math.random() * 10);

      const randomNumber = `${firstDigit}${secondDigit}${thirdDigit}${fourDigit}`;
      if (!randomList.includes(randomNumber)) {
        randomList.push(randomNumber);
      }
    }

    return randomList
      .sort(function (a, b) {
        return a - b;
      })
      .toString()
      .split(",")
      .join(" ");
  },

  // formatBalance(balance) {
  //   return numeral(parseFloat(balance)).format("0,0.000");
  // },

  // formatBtc(price) {
  //   return numeral(parseFloat(price)).format("0,0.00");
  // },

  sliceString(str, num) {
    if (str.length <= num * 2) {
      return str;
    } else {
      const firstChars = str.slice(0, num);
      const lastChars = str.slice(-num);
      return `${firstChars}...${lastChars}`;
    }
  },

  formatNumberPercent(num, numfix = 4) {
    const number = Number(num)?.toFixed(numfix);
    return number;
  },
};

export default Utils;
