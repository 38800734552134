import classNames from "classnames";
import Cleave from "cleave.js/react";

import 'cleave.js/dist/addons/cleave-phone.vi'
import 'cleave.js/dist/addons/cleave-phone.la'

const PhoneInputSystem = (props) => {
    const { isError, value, className, ...restProps } = props;

    return (
        <Cleave
            value={value}
            className={classNames('phone-input-system', className, {
                'is-invalid': isError
            })}
            options={{
                phone: true,
                numeral: true,
                numericOnly: true,
            }}
            inputMode="numeric"

            {...restProps}
        />
    )
};

export default PhoneInputSystem;