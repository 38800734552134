import HotlineBookingList from "../booking-list";
import HotlineBookingFilter from "../filter-form";

import ButtonSystem from "components/e-control/button-system";
import { bookingApi } from "modules/ego-system/bookings/apis";
import { HOTLINE_OPTIONS } from "modules/ego-system/hotline/constants";
import {
    getBookingDetailById
} from "modules/ego-system/hotline/store";
import {
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo
} from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { dispatch } from "root-stores";
import "./index.scss";

const HotlineBookings = ({
  bookingListRef,
  onAddBooking,
  onSelectBooking,
  page,
  setPage,
  loadBookingByFilter
}) => {
  // ** State
  // const [page, setPage] = useState(1);

  // ** Store
  const { status, type, bookings, bookingTotal } = useSelector(
    (state) => state.hotline
  );

  const pagination = useMemo(() => {
    return {
      page: page,
      total: bookingTotal,

      onPageChange: (page) => setPage(page),
    };
  }, [page, bookingTotal]);

  /**
   * @description get all booking today by filter
   *
   */
  // const loadBookingByFilter = async (filter = {}) => {
  //   try {
  //     const payload = {
  //       page: page,
  //       fromDate: moment()
  //         .subtract(30, "days")
  //         .format(DATE_TIME_FORMAT.YMD_SNAKE),
  //       toDate: moment().format(DATE_TIME_FORMAT.YMD_SNAKE),

  //       status: [
  //         MAP_OPTIONS.BOOKING_STATUS.PENDING,
  //         MAP_OPTIONS.BOOKING_STATUS.REFUSED,
  //       ],
  //       search: filter?.search,
  //       type: filter?.type,
  //     };

  //     await dispatch(getAllBookingCreatedByHotlineToday(payload));
  //   } catch (error) {
  //     toast.error(error);
  //   }
  // };

  /**
   * @description cancel booking
   */
  const cancelBooking = async (bookingId) => {
    try {
      const payload = {
        tripId: bookingId,
        reasonCancel: "",
      };

      console.log("payload :>>", payload);

      await bookingApi.cancelBookingByHotline(payload);

      loadBookingByFilter();
    } catch (error) {
      toast.error(error.message);
    }
  };

  /**
   * @description cancel request
   */
  const cancelRequest = async (requestId) => {
    try {
      const payload = {
        taxiRequestId: requestId,
      };

      await bookingApi.cancelRequest(payload);

      loadBookingByFilter();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getBookingRequestDetail = useCallback(
    async (selectedId) => {
      if (type === HOTLINE_OPTIONS.BOOKING_CREATE_TYPE.BOOKING) {
        await dispatch(getBookingDetailById(selectedId));
      }

      onSelectBooking?.();
    },
    [type]
  );

  useEffect(() => {
    loadBookingByFilter();
  }, [page]);

  useImperativeHandle(bookingListRef, () => ({
    loadBookingByFilter,
  }));

  return (
    <div className="hotline-booking-list">
      <div className="hotline-booking-list__header">
        <div className="hotline-booking__button">
          <ButtonSystem color="primary" disabled={false} onClick={onAddBooking}>
            Thêm chuyến xe
          </ButtonSystem>
        </div>

        <div className="hotline-booking__filter">
          <HotlineBookingFilter
            initialFilter={{
              bookingType: type,
            }}
            onFilter={loadBookingByFilter}
          />
        </div>
      </div>

      <div className="hotline-booking-list__content">
        <HotlineBookingList
          list={bookings}
          bookingType={type}
          pagination={pagination}
          cancelBooking={cancelBooking}
          cancelRequest={cancelRequest}
          selectBooking={getBookingRequestDetail}
        />
      </div>
    </div>
  );
};

export default HotlineBookings;
