import classNames from "classnames";
import { Button } from "reactstrap";
import { Loader } from "react-feather";

import ViewCondition from "components/utils/view-condition";

import "./index.scss";

const ButtonSystem = ({ children, className, loading, ...props }) => {
  return (
    <Button
      className={classNames([
        "button-system flex items-center justify-center h-9 text-sm whitespace-nowrap",
        className,
      ])}
      {...props}
    >
      <ViewCondition rIf={loading}>
        <Loader className="loading" />
      </ViewCondition>

      <ViewCondition rIf={!loading}>{children}</ViewCondition>
    </Button>
  );
};

export default ButtonSystem;
