import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { departmentPartnerApi } from "../apis";

export const getAllPartnerDepartment = createAsyncThunk(
  "/department/getAllPartnerDepartment",
  async (query) => {
    console.log('query',query)
    try {
      const response = await departmentPartnerApi.getAllPartnerDepartment(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const createPartnerDepartment = async (query) => {
  try {
    const payload = {
      data: {
        name: query?.name,
      },
    };

    await departmentPartnerApi.createPartnerDepartment(payload);
  } catch (error) {
    throw new Error(error);
  }
};

export const departmentPartnerSlice = createSlice({
  name: "departmentPartner",
  initialState: {
    department: {
      list: [],
      total: 0,
    },

  },

  extraReducers: (builder) => {
    builder.addCase(getAllPartnerDepartment.fulfilled, (state, { payload }) => {
      state.department.list = payload?.data || [];
      state.department.total = payload?.total || 0;
    });
  },
});

export default departmentPartnerSlice.reducer;
