import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { incurredPartnerApi } from "../apis";

export const getAllReasonIncurred = createAsyncThunk(
  "/department/getAllReasonIncurred",
  async (query) => {
    try {
      const response = await incurredPartnerApi.getAllReasonIncurred(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getDescriptionForReasonIncurred = createAsyncThunk(
    "/department/getDescriptionForReasonIncurred",
    async () => {
      try {
        const response = await incurredPartnerApi.getDescriptionForReasonIncurred();
  
        return response;
      } catch (error) {
        toast.error(error?.message);
        throw new Error(error);
      }
    }
  );

export const createReasonIncurred = async (query,descriptionIncurred) => {
    try {
      const payload = {
        data: {
            cleanFee: parseFloat(query?.cleanFee),
            deodoriFee: parseFloat(query?.deodoriFee),
            desCleanFee: descriptionIncurred?.desCleanFee,
            desDeodoriFee: descriptionIncurred?.desDeodoriFee,
            desOverlimtFee: descriptionIncurred?.desOverlimtFee,
            desOvertimeFee: descriptionIncurred?.desOvertimeFee,
            overlimitFee: parseFloat(query?.overlimitFee),
            overtimeFee: parseFloat(query?.overtimeFee),
            type: "OTO"
        },
      };
  
      await incurredPartnerApi.createReasonIncurred(payload);
    } catch (error) {
      throw new Error(error);
    }
  };

export const incurredPartnerSlice = createSlice({
  name: "incurredPartner",
  initialState: {
    incurred: {
      list: [],
      total: 0,
    },

    descriptionIncurred: {

    }

  },

  extraReducers: (builder) => {
    builder.addCase(getAllReasonIncurred.fulfilled, (state, { payload }) => {
      state.incurred.list = payload?.data || [];
      state.incurred.total = payload?.total || 0;
    });

    builder.addCase(getDescriptionForReasonIncurred.fulfilled, (state, { payload }) => {
        state.descriptionIncurred = payload || {};
      });
  },
});

export default incurredPartnerSlice.reducer;
