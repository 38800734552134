import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { driverApi } from "../apis";
import { toast } from "react-hot-toast";

export const getAllCurriculumVitae = createAsyncThunk(
  "/driver/getAllCurriculumVitae",
  async (query) => {
    try {
      const response = await driverApi.getAllCurriculumVitae(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getAllDriverLicenseTypeWOPaging = createAsyncThunk(
  "/driver/getAllDriverLicenseTypeWOPaging",
  async (query) => {
    console.log("querry", query)
    try {
      const response = await driverApi.getAllDriverLicenseTypeWOPaging(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getAllDrivers = createAsyncThunk(
  "/driver/getAllDrivers",
  async (query) => {
    try {
      const response = await driverApi.getAllDriver(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getAllDriversNoPagination = createAsyncThunk(
  "/driver/getAllDriversNoPagination",
  async (query) => {
    try {
      const response = await driverApi.getAllDriver({
        limit: 1000,
      });

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getAllBookingByDrivers = createAsyncThunk(
  "/driver/getAllBookingByDrivers",
  async (query) => {
    try {
      const response = await driverApi.getAllBooking(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getAllBookingByDriversCarRental = createAsyncThunk(
  "/driver/getAllBookingByDriversCarRental",
  async (query) => {
    try {
      const response = await driverApi.getAllBooking(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getStatBookingByDrivers = createAsyncThunk(
  "/driver/getStatBookingByDrivers",
  async (query) => {
    try {
      const response = await driverApi.getStatBookingByDriver(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getDriverDetail = createAsyncThunk(
  "/driver/getOneDrivers",
  async (query) => {
    try {
      const response = await driverApi.getOneUser(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getAllCarWOPaging = createAsyncThunk(
  "/driver/getAllCars",
  async (query) => {
    try {
      const response = await driverApi.getAllCarWOPaging(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const createNewDriver = async ({ driverInfo, activeWorkPlace }) => {
  try {
    const payload = {
      email: driverInfo?.email,
      avatar: driverInfo?.avatar,
      // password: driverInfo?.password,
      address: driverInfo?.address,
      identityCard: driverInfo?.identityCard,
      phoneNumber: driverInfo?.phoneNumber?.replace(
        driverInfo?.phoneNumber[0],
        "+84"
      ),
      name: driverInfo?.name,
      workPlaceId: activeWorkPlace,
      // salary: parseFloat(driverInfo?.salary),
      driverLicense: driverInfo?.driverLicense,
      // districtId: driverInfo?.districtId
    };
    await driverApi.createUserForDriver(payload);
  } catch (error) {
    throw new Error(error);
  }
};

export const driverSlice = createSlice({
  name: "driver",
  initialState: {
    drivers: {
      list: [],
      total: 0,
      countDrivers: 0,
      countActiveDrivers: 0,
      countInactiveDrivers: 0,
    },

    driverDetail: {},

    bookings: {
      list: [],
      total: 0,
    },

    bills: {
      list: [],
      total: 0,
      stats: {},
      charts: {},
    },

    cars: {
      list: [],
    },

    newDrivers: {
      list: [],
      total: 0
    },

    driverLicenseType: []
  },

  reducers: {
    setDefaultCarList: (state, { payload }) => {
      state.cars.list = [];
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllDrivers.fulfilled, (state, { payload }) => {
      state.drivers.list = payload?.data || [];
      state.drivers.total = payload?.total || 0;
      state.drivers.countDrivers = payload?.getStatDriver[0]?.countDrivers || 0;
      state.drivers.countActiveDrivers =
        payload?.getStatDriver[0]?.countActiveDrivers || 0;
      state.drivers.countInactiveDrivers =
        payload?.getStatDriver[0]?.countInactiveDrivers || 0;
    });

    builder.addCase(
      getAllDriversNoPagination.fulfilled,
      (state, { payload }) => {
        state.drivers.list = payload?.data || [];
        state.drivers.total = payload?.total || 0;
      }
    );

    builder.addCase(getAllBookingByDrivers.fulfilled, (state, { payload }) => {
      state.bookings.list = payload?.data || [];
      state.bookings.total = payload?.total || 0;
    });

    builder.addCase(getStatBookingByDrivers.fulfilled, (state, { payload }) => {
      state.bills.list = payload?.data || [];
      state.bills.total = payload?.total || 0;
      state.bills.stats = payload?.getStatDriver || [];
    });

    builder.addCase(getDriverDetail.fulfilled, (state, { payload }) => {
      state.driverDetail = payload || {};
    });

    builder.addCase(getAllCarWOPaging.fulfilled, (state, { payload }) => {
      state.cars.list = payload || [];
    });

    builder.addCase(
      getAllCurriculumVitae.fulfilled,
      (state, { payload }) => {
        state.newDrivers.list = payload?.data || [];
        state.newDrivers.total = payload?.total || 0;
      }
    );

    builder.addCase(
      getAllDriverLicenseTypeWOPaging.fulfilled,
      (state, { payload }) => {
        state.driverLicenseType = payload || [];
      }
    );

  },
});

export const { setDefaultCarList } = driverSlice.actions;

export default driverSlice.reducer;
