import cloneDeep from "clone-deep";
import toastNotice from "components/utils/toast-notice";
import MAP_OPTIONS from "modules/ego-system/gg-map/constants";
import { acceptBookingByDriver, cancelBooking, endBookingByDriver, getBookingDetailByTripId, refusedBooking, setNewBooking, startBookingByDriver } from "modules/ego-system/gg-map/stores";
import { mapExpressAction } from "modules/ego-system/maps/apis";
import useSocket from "modules/ego-system/maps/hooks/useSocket";
import { getAllNotifications } from "modules/ego-system/notice/store";
import { useCallback, useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { dispatch } from "root-stores";
import { MAP_ROUTES } from "router/ego-admin-routes/maps";
import { formatNumberToAmount } from "utility/number-utils";

export const useNewExpressBooking = (workplaceIdsByRoles) => {
    const navigate = useNavigate();

    // ** New booking subscription
    const newBooking = useSocket(
        mapExpressAction.subNewExpressBooking,
        { workplaceIds: [...workplaceIdsByRoles] },
        "newBookingExpress"
    );

    /**
 * @description Click to booking notice
 */
    const handleConfirmClick = useCallback(() => {
        navigate(MAP_ROUTES.MAIN);
    }, []);

    useEffect(() => {
        if (newBooking) {
            toastNotice({
                title: 'Đơn giao hàng mới',
                description: `${newBooking.distance}km - ${formatNumberToAmount(newBooking.finalCost)}`,
                callback: handleConfirmClick,
                options: {
                    duration: 4000,
                }
            })
            dispatch(getAllNotifications({ page: 1, limit: 10, onPage: false }));
            dispatch(setNewBooking({ ...newBooking, type: MAP_OPTIONS.BOOKING_TYPE.EXPRESS }));
        }
    }, [newBooking])
};

export const useExpressBookingUpdate = (booking) => {
    const { bookingListRef, workplaceIdsByRoles, isBookingDetailView, isBookingView, store } = booking;
    const { bookingAction, onlineDriverByIds, pendingBookingByIds, processBookingByIds, runningBookingByIds } = store;

    // ** update booking
    const updateBooking = useCallback(
        (booking) => {
            const { status, expressId: id, driver, reasonCancel } = booking;

            if (!id) {
                return
            }

            if (isBookingDetailView) {
                if (bookingAction.id === id) {
                    dispatch(getBookingDetailByTripId(bookingAction));
                }
            }

            if (isBookingView) {
                bookingListRef.current.loadBookingByStatus?.();
            }

            if (status === MAP_OPTIONS.UPDATE_BOOKING_STATUS.CANCEL) {
                toast.error(`Đặt chuyến bị hủy!. ${reasonCancel}`);
                console.log('pendingBookingByIds[tripId]', pendingBookingByIds, pendingBookingByIds[id])
                const booking = (() => {
                    const pendingBooking = cloneDeep(pendingBookingByIds[id]);

                    if (pendingBooking) {
                        return pendingBooking;
                    }

                    const processingBooking = cloneDeep(processBookingByIds[id]);

                    if (processingBooking) {
                        return processingBooking;
                    }

                    return null;
                })();

                if (!booking) {
                    return;
                }

                booking.status = MAP_OPTIONS.BOOKING_STATUS.CANCEL;
                dispatch(cancelBooking({ tripId: id, driver, booking }));
            }

            if (status === MAP_OPTIONS.UPDATE_BOOKING_STATUS.REFUSED) {
                toast.error(`Tài xế ${driver?.name} hủy đặt chuyến! ${reasonCancel}`);
                dispatch(refusedBooking({ tripId: id, driverId: driver?._id }));
            }

            if (status === MAP_OPTIONS.UPDATE_BOOKING_STATUS.READY) {
                toast.success(`Tài xế ${driver?.name} chấp nhận chuyến xe!`);

                const pendingBooking = cloneDeep(pendingBookingByIds[id]);

                if (!pendingBooking) {
                    return;
                }

                pendingBooking.status = MAP_OPTIONS.BOOKING_STATUS.READY;
                pendingBooking.driver = { ...driver, id: driver?._id };
                dispatch(
                    acceptBookingByDriver({
                        tripId: id,
                        driverId: driver?._id,
                        driverStatus: driver?.status,
                        booking: pendingBooking,
                    })
                );
            }

            if (status === MAP_OPTIONS.UPDATE_BOOKING_STATUS.STARTING) {
                toast.success(`Chuyến xe đang bắt đầu`);
                const processingBooking = cloneDeep(processBookingByIds[id]);

                if (!processingBooking) {
                    return;
                }

                processingBooking.status = MAP_OPTIONS.BOOKING_STATUS.PROCESS;

                dispatch(
                    startBookingByDriver({
                        tripId: id,
                        driverId: driver?._id,
                        booking: processingBooking,
                    })
                );
            }

            if (status === MAP_OPTIONS.BOOKING_STATUS.COMPLETED) {
                toast.success(`Chuyến xe đã thành công`);
                const runningBooking = cloneDeep(runningBookingByIds[id]);

                console.log('runningBooking', runningBooking)
                if (!runningBooking) {
                    return;
                }

                runningBooking.status = MAP_OPTIONS.BOOKING_STATUS.COMPLETED;

                dispatch(
                    endBookingByDriver({
                        tripId: id,
                        driverId: driver?._id,
                        booking: runningBooking,
                    })
                );
            }
        },

        [
            bookingAction,
            isBookingView,
            onlineDriverByIds,
            pendingBookingByIds,
            processBookingByIds,
            runningBookingByIds,
            isBookingDetailView,
        ]
    );

    const updateBookingSub = useSocket(
        mapExpressAction.subExporessBookingStatus,
        { workplaceIds: [...workplaceIdsByRoles] },
        'newUpdateBookingExpress4Admin',
    )

    useEffect(() => {
        if (updateBookingSub) {
            updateBooking(updateBookingSub)
        }
    }, [updateBookingSub])
};