import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { stringeeApi } from "../apis";
import { toast } from "react-hot-toast";

export const getStringeeToken = createAsyncThunk(
  "/user/getStringeeToken",
  async (query) => {
    try {
      const response = await stringeeApi.getStringeeToken(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const stringeeSlice = createSlice({
  name: "stringee",
  initialState: {
    infoUserStringee: {},
    tokenStringee: "",
  },

  reducers: {
    setUserStringee: (state, { payload }) => {
      state.infoUserStringee = payload || {};
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getStringeeToken.fulfilled, (state, { payload }) => {
      state.tokenStringee = payload || {};
    });
  },
});

export const { setUserStringee } = stringeeSlice.actions;

export default stringeeSlice.reducer;
