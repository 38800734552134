import MAP_OPTIONS from "modules/ego-system/gg-map/constants"

export class TaxiBookingModel {
    bookings = []

    static mapOneBookingFromApi(booking) {
        return { ...booking, type: MAP_OPTIONS.BOOKING_TYPE.TAXI }
    }

    static mapBookingsFromApi(bookings) {
        return bookings.map(booking => ({ ...booking, type: MAP_OPTIONS.BOOKING_TYPE.TAXI }))
    }
}