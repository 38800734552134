import DropdownSystem from "components/e-control/forms/form-elements/dropdown-system";
import { useTableWrapperContext } from "components/e-control/table";
import MoreDropdown from "components/views/more-dropdown";
import { BadgeDollarSign, Calendar } from "lucide-react";
import { Card } from "reactstrap";
import { formatNumberToAmount } from "utility/number-utils";
import Utils from "utility/time";

const AccuMulateProgramTemplate = ({ item, colValue }) => {
  const { menuDropdown } = useTableWrapperContext();

  return (
    <Card className="mb-2 p-3 space-y-2 relative">
      <div className="flex justify-between">
        <div className="flex items-center gap-x-2">
          <div className="flex items-center gap-x-2 text-sm flex-shrink-0">
            {colValue("image", item)}
          </div>

          <span className="text-primary line-clamp-1 text-sm">
            {colValue("name", item)}
          </span>
        </div>

        <div>
          <DropdownSystem
            options={menuDropdown(item?.status)}
            onMenuSelect={(callback) => callback?.(item)}
          >
            <MoreDropdown />
          </DropdownSystem>
        </div>
      </div>

      <div className="flex items-center justify-between">
        <div className="flex flex-col items-start gap-x-2 text-sm">
          <span className="text-secondary-2">Lãi suất(%)</span>

          <span>{colValue("ratePYear", item)}</span>
        </div>

        <div className="flex flex-col items-end gap-x-2 text-sm">
          <span className="text-secondary-2">Kì hạn(tháng)</span>

          <span>{colValue("savingsTerm", item)}</span>
        </div>
      </div>

      <div className="flex items-center justify-between">
        <div className="flex flex-col items-start gap-x-2 text-sm">
          <span className="text-secondary-2">Tối đa</span>

          <span>{colValue("max", item)}</span>
        </div>

        <div className="flex flex-col items-end gap-x-2 text-sm">
          <span className="text-secondary-2">Tối thiểu</span>

          <span>{colValue("min", item)}</span>
        </div>
      </div>
    </Card>
  );
};

export default AccuMulateProgramTemplate;
