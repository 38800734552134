import React from "react";
import { useSelector } from "react-redux";
import { formatRoundNumber } from "utility/number-utils";

const CardCondition = () => {
  const { details } = useSelector((state) => state.accumulate);

  const LIST_CONDITION = [
    "Tất cả người dùng là công dân việt nam trên 18 tuổi",
    "Tải khoản đã được xác thực số điện thoại",
    "Rút tiền dễ dàng mọi lúc mọi nơi, với điều kiện khoản rút tối thiểu là 50.000 VNĐ",
  ];

  const LIST_DESCRIPTION = [
    "Phù hợp cho nhu cầu tích luỹ ngắn hạn",
    `Lãi suất thực nhận là ${formatRoundNumber(
      details?.ratePYear * 95,
      2
    )}%/năm (sau thuế)`,
  ];

  return (
    <div className="p-4 rounded-lg bg-white shadow-sm mt-4">
      <h3 className="text-lg font-bold text-black">Điều kiện</h3>

      <div className="flex flex-col gap-2 mt-3">
        {LIST_CONDITION?.map((title, index) => (
          <div key={index} className="flex items-center gap-x-3">
            <span className="bg-primary w-3 h-3 rounded-full flex-shrink-0" />

            <p className="text-base text-black font-normal">{title}</p>
          </div>
        ))}
      </div>

      <div className="border border-gray-100 my-4" />

      <h3 className="text-lg font-bold text-black">Mô tả</h3>

      <div className="flex flex-col gap-2 mt-3">
        {LIST_DESCRIPTION?.map((title, index) => (
          <div key={index} className="flex items-center gap-x-3">
            <span className="bg-primary w-3 h-3 rounded-full flex-shrink-0" />

            <p className="text-base text-black font-normal">{title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardCondition;
