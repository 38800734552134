import toast from "react-hot-toast";
import { dispatch } from "root-stores";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import CardFeature from "../components/card-feature";
import CardCondition from "../components/card-condition";
import Popup from "modules/invest/client/components/popup";
import CardDescription from "../components/card-desciption";
import ButtonSystem from "components/e-control/button-system";
import BuyFormAccumulate from "../components/buy-form-accumulate";
import FormInfoQrCode from "modules/invest/client/components/form-info-qr";

import { accumulateApi } from "../apis";
import { getOneSavingsPlan } from "../stores";

const AccumulateDetailPage = () => {
  const [orderId, setOrderId] = useState("");
  const [infoQrCode, setInfoQrCode] = useState({});
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupOpenPayment, setIsPopupOpenPayment] = useState(false);

  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const { details } = useSelector((state) => state.accumulate);

  useEffect(() => {
    dispatch(getOneSavingsPlan(id));
  }, []);

  const handleSetInfoQrCode = (item) => {
    setIsPopupOpenPayment(true);
    setIsPopupOpen(false);
    setInfoQrCode(item);
  };

  const handleConfirmPayment = async () => {
    try {
      await accumulateApi.sendConfirmPaymentSavingsPackage(orderId);

      toast.success("Đã xác thực chuyển tiền thành công");
      setTimeout(() => {
        setIsPopupOpenPayment(false);
      }, [1000]);
    } catch (e) {
      toast.error(e?.message);
    }
  };

  return (
    <>
      <div className="max-w-[900px] m-auto">
        <div>
          <img
            className="w-full h-full object-contain"
            alt="banner"
            src={details?.bannerImage}
          />
        </div>

        <div className="p-4">
          <div className="p-4 rounded-lg bg-white shadow-sm">
            <h3 className="text-lg font-bold text-black">{details?.name}</h3>

            {/* <div className="w-full flex items-stretch rounded-full overflow-hidden h-2 border border-primary/30 mt-2">
              <div
                style={{
                  width: (30 / 100) * 100 + "%",
                }}
                className={"bg-primary"}
              />
              <div className={"bg-white flex-[2]"} />
            </div> */}
          </div>

          <CardFeature />

          <CardCondition />

          <CardDescription />

          <div className="mt-4">
            <ButtonSystem
              className="w-full"
              color="primary"
              onClick={() => setIsPopupOpen(true)}
            >
              Tích luỹ ngay
            </ButtonSystem>
          </div>
        </div>
      </div>

      <Popup
        header="Tích luỹ"
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
      >
        <BuyFormAccumulate
          campaign={details}
          onCancel={() => setIsPopupOpen(false)}
          handleSetInfoQrCode={handleSetInfoQrCode}
          setOrderId={setOrderId}
        />
      </Popup>

      <Popup
        header="Thông tin thanh toán"
        isOpen={isPopupOpenPayment}
        onClose={() => setIsPopupOpenPayment(false)}
      >
        <FormInfoQrCode
          handleConfirmPayment={handleConfirmPayment}
          info={infoQrCode}
        />
      </Popup>
    </>
  );
};

export default AccumulateDetailPage;
