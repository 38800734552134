import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { bookingApi } from "modules/ego-system/bookings/apis";
import MAP_OPTIONS from "modules/ego-system/gg-map/constants";
import { HOTLINE_OPTIONS } from "../constants";
import { driverApi } from "modules/ego-system/drivers/apis";

export const getAllBookingCreatedByHotlineToday = createAsyncThunk(
    "/map/getBookingHotlineToday",
    async (query) => {
        try {
            const payload = {
                page: query?.page || 1,
                limit: query?.limit || 10,

                fromDate: query?.fromDate,
                toDate: query?.toDate,

                order: {
                    createdAt: -1,
                },

                filter: {
                    status: [MAP_OPTIONS.BOOKING_STATUS.PENDING, MAP_OPTIONS.BOOKING_STATUS.REFUSED],
                },
                search: query?.search,
            };

            let response = null;
            if (query.type === HOTLINE_OPTIONS.BOOKING_CREATE_TYPE.REQUEST) {
                response = await bookingApi.getAllRequestByHotline(payload)
            } else {
                response = await bookingApi.getAllBookingByHotline(payload);
            }

            return response;

        } catch (error) {
            throw error;
        }
    }
);

export const getBookingDetailById = createAsyncThunk(
    '/hotline/getBookingDetailById',
    async (bookingId) => {
        try {
            const response = await bookingApi.getOneBooking({ id: bookingId });

            return response;
        } catch (error) {
            throw error;
        }
    }
);

export const getDriverNearBooking = createAsyncThunk(
    '/hotline/getDriverNearBooking',
    async (params) => {
        try {
            const response = await driverApi.getDriversNearBooking({
                isActived: true,
                tripId: params?.tripId,
                startPoint: params?.startPoint,
                status: MAP_OPTIONS.DRIVER_STATUS.NORMAL,
            });

            return response?.data || [];
        } catch (error) {
            throw error
        }
    }
)

export const hotlineSlice = createSlice({
    name: "hotline",
    initialState: {
        status: { value: MAP_OPTIONS.BOOKING_STATUS.PENDING, label: 'Pending' },
        type: HOTLINE_OPTIONS.BOOKING_CREATE_TYPE.BOOKING,
        bookings: [],
        bookingTotal: 0,
        driverByIds: null,
        bookingActions: null,
        driverActionId: null,
        bookingFormAction: null,
    },

    reducers: {
        setBookingActions: (state, { payload }) => {
            console.log(state, payload);
        },

        setStatus: (state, { payload }) => {
            state.status = payload;
        },

        setType: (state, { payload }) => {
            state.type = payload;
        },

        resetBookingActions: (state) => {
            state.bookingActions = null;
        },

        removeDriverIds: (state) => {
            state.driverByIds = null;
        },

        setBookingFormAction: (state, { payload = null }) => {
            state.bookingFormAction = payload
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(getAllBookingCreatedByHotlineToday.fulfilled, (state, { payload }) => {
                state.bookings = payload?.data || [];
                state.bookingTotal = payload?.total || 0;
            })

            .addCase(getBookingDetailById.fulfilled, (state, { payload }) => {
                state.bookingActions = payload || {};
            })

            .addCase(getDriverNearBooking.fulfilled, (state, { payload }) => {
                const driverByIds = payload.reduce((driverById, driver) => {
                    return {
                        ...driverById,
                        [driver?.id]: {
                            ...driver,
                            initialLocation: driver.location,
                        },
                    }
                }, {})

                state.driverByIds = driverByIds;
            })
    },
})

export const {
    setType,
    setStatus,
    removeDriverIds,
    setBookingActions,
    resetBookingActions,
    setBookingFormAction,
} = hotlineSlice.actions;

export default hotlineSlice.reducer;