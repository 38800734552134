import { gql } from "@apollo/client";
import { mutate, query } from "configs/http-config";

const workPlaceShema = {
  getAllPartnerWorkPlace: gql`
    query getAllPartnerWorkPlace(
      $page: Int
      $limit: Int
      $filter: Mixed
      $order: Mixed
      $search: String
    ) {
      getAllPartnerWorkPlace(
        q: {
          limit: $limit
          page: $page
          filter: $filter
          order: $order
          search: $search
        }
      ) {
        data {
          address
          createdAt
          district
          districtId
          id
          name
          partner {
            address
            avatar
            car {
              id
              address
            }
            carId
            id
          }
          partnerId
          province
          provinceId
          updatedAt
          ward
          wardId
        }
        total
      }
    }
  `,

  getOnePartnerWorkPlace: gql`
    query getOnePartnerWorkPlace($id: ID!) {
      getOnePartnerWorkPlace(id: $id) {
        address
        id
        districtId
        district
        name
        partner {
          id
          name
        }
        partnerId
        province
        provinceId
        updatedAt
        ward
        wardId
      }
    }
  `,

  getProvince: gql`
    query getProvince($countryId: ID) {
      getProvince(countryId: $countryId) {
        id
        province
      }
    }
  `,

  getDistrict: gql`
    query getDistrict($provinceId: String!, $countryId: ID) {
      getDistrict(provinceId: $provinceId, countryId: $countryId) {
        district
        id
      }
    }
  `,

  getWard: gql`
    query getWard($districtId: String!, $countryId: ID) {
      getWard(districtId: $districtId, countryId: $countryId) {
        ward
        id
      }
    }
  `,

  createPartnerWorkPlace: gql`
    mutation createPartnerWorkPlace($data: CreatePartnerWorkPlaceInput!) {
      createPartnerWorkPlace(data: $data) {
        id
      }
    }
  `,

  updatePartnerWorkPlace: gql`
    mutation updatePartnerWorkPlace(
      $id: ID!
      $data: UpdatePartnerWorkPlaceInput!
    ) {
      updatePartnerWorkPlace(id: $id, data: $data) {
        id
      }
    }
  `,

  deleteOnePartnerWorkPlace: gql`
    mutation deleteOnePartnerWorkPlace($id: ID!) {
      deleteOnePartnerWorkPlace(id: $id) {
        id
      }
    }
  `,
};

export const workPlacePartnerApi = {
  getAllPartnerWorkPlace: (payload) => {
    return query(
      "getAllPartnerWorkPlace",
      workPlaceShema.getAllPartnerWorkPlace,
      payload
    );
  },

  createPartnerWorkPlace: (payload) => {
    return mutate(
      "createPartnerWorkPlace",
      workPlaceShema.createPartnerWorkPlace,
      payload
    );
  },

  updatePartnerWorkPlace: (payload) => {
    return mutate(
      "updatePartnerWorkPlace",
      workPlaceShema.updatePartnerWorkPlace,
      payload
    );
  },

  deleteOnePartnerWorkPlace: (payload) => {
    return mutate(
      "deleteOnePartnerWorkPlace",
      workPlaceShema.deleteOnePartnerWorkPlace,
      payload
    );
  },

  getWard: (payload) => {
    return query("getWard", workPlaceShema.getWard, payload);
  },

  getDistrict: (payload) => {
    return query("getDistrict", workPlaceShema.getDistrict, payload);
  },

  getProvince: (payload) => {
    return query("getProvince", workPlaceShema.getProvince, payload);
  },

  getOnePartnerWorkPlace: (payload) => {
    return query(
      "getOnePartnerWorkPlace",
      workPlaceShema.getOnePartnerWorkPlace,
      payload
    );
  },
};
