/* eslint-disable multiline-ternary */
/* eslint-disable no-unsafe-optional-chaining */
import React from "react";
import { Card, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import "./index.scss";
import ButtonSystem from "components/e-control/button-system";
import CampaignInvestItem from "modules/invest/client/components/campaign-card-item";
import { formatNumberToAmount } from "utility/number-utils";
import TooltipSystem from "components/e-control/tooltip-system";
import classNames from "classnames";
import { Clock, Tags } from "lucide-react";
import Utils from "utility/time";
import { formatPhoneNumberToShow } from "utils";

const ModalDetailOrder = ({
  isOpen = true,
  data,
  toggle = () => false,
  approvePayment,
  editAmount,
}) => {
  return (
    <Modal
      className="modal-detail-order"
      isOpen={isOpen}
      toggle={toggle}
      centered
    >
      <ModalHeader toggle={toggle}>
        <h3 className="title-header-payment">Chi tiết đầu tư</h3>
      </ModalHeader>
      <ModalBody>
        <CampaignInvestItem
          isInvestDetail={true}
          item={data?.investmentProject}
          isClient={true}
        />

        <Card className="mt-3 mb-0 p-2 space-y-2 relative border border-primary">
          <div className="flex items-center justify-between gap-2">
            <div className="flex items-center gap-x-2">
              <span className="flex items-center gap-x-2 text-sm ">
                <Tags size={14} />
              </span>

              <span className="text-primary">{data?.code}</span>
            </div>
            <div className="flex justify-between items-center">
              <div
                className={classNames(
                  "flex items-center gap-x-2 py-1 px-2 rounded-md group text-xs flex-shrink-0 whitespace-nowrap",
                  data?.isPaid && "text-primary bg-primary/30",
                  !data?.isPaid && "text-red-500 bg-red-500/30"
                )}
              >
                {data?.status === "DELETED"
                  ? "Đã xoá"
                  : data?.isPaid
                  ? "Đã thanh toán"
                  : "Chưa thanh toán"}
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center">
            <div className="flex items-center gap-x-2">
              <span className="text-sm">Số lượng:</span>
              <span className="text-sm">
                {formatNumberToAmount(data?.quantity)}
              </span>
            </div>

            <div className="flex items-center gap-x-2">
              <span className="text-sm ">Tổng giá:</span>
              <span className="text-sm">
                {formatNumberToAmount(data?.finalCost)}
              </span>
            </div>
          </div>

          <div className="flex justify-between items-center text-sm gap-2">
            <div className="flex items-center gap-x-2 whitespace-nowrap">
              <Clock color="#29ab68" size={14} />
              <span className="text-sm">
                {Utils.formatDateOnlyNumber(data?.createdAt)}
              </span>
            </div>

            <TooltipSystem
              title={`${data?.user?.name} / ${formatPhoneNumberToShow(
                data?.user?.phone
              )}`}
            >
              <div className="line-clamp-1">
                <div className="flex gap-x-1 whitespace-nowrap">
                  <p>{data?.user?.name}</p>
                  <span>/</span>
                  <p>{formatPhoneNumberToShow(data?.user?.phone)}</p>
                </div>
              </div>
            </TooltipSystem>
          </div>
        </Card>
      </ModalBody>

      {!data?.isPaid &&
        data?.paymentMethod === "COMPANY" &&
        data?.status !== "DELETED" && (
          <ModalFooter>
            <div className="flex justify-center items-center gap-2 w-full">
              <ButtonSystem color="info" onClick={() => editAmount?.(data)}>
                Chỉnh sửa
              </ButtonSystem>

              <ButtonSystem
                color="primary"
                onClick={() => approvePayment?.(data)}
              >
                Xác nhận đã thanh toán
              </ButtonSystem>
            </div>
          </ModalFooter>
        )}
    </Modal>
  );
};

export default ModalDetailOrder;
