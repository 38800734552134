// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

const date = new Date()
const prevDay = new Date().getDate() - 1
const nextDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)

// prettier-ignore
const nextMonth = date.getMonth() === 11 ? new Date(date.getFullYear() + 1, 0, 1) : new Date(date.getFullYear(), date.getMonth() + 1, 1)
// prettier-ignore
const prevMonth = date.getMonth() === 11 ? new Date(date.getFullYear() - 1, 0, 1) : new Date(date.getFullYear(), date.getMonth() - 1, 1)

export const appCalendarSlice = createSlice({
  name: 'appCalendar',
  initialState: {
    events: [
      {
        id: 1,
        url: '',
        title: 'Nguyễn Hùng \r\n Mazda',
        start: date,
        end: nextDay,
        allDay: true,
        extendedProps: {
          calendar: 'Business'
        }
      },
      {
        id: 2,
        url: '',
        title: 'Nguyễn Văn Thuận (Xe Mazda x9)',
        start: new Date(date.getFullYear(), date.getMonth() + 1, -11),
        end: new Date(date.getFullYear(), date.getMonth() + 1, -10),
        allDay: true,
        extendedProps: {
          calendar: 'Business'
        }
      },
      {
        id: 3,
        url: '',
        title: 'Anh Tâm (Xe Toyota innova)',
        allDay: true,
        start: new Date(date.getFullYear(), date.getMonth() + 1, -9),
        end: new Date(date.getFullYear(), date.getMonth() + 1, -7),
        extendedProps: {
          calendar: 'Holiday'
        }
      },
      {
        id: 4,
        url: '',
        title: "Huấn (Xe Audi)",
        start: new Date(date.getFullYear(), date.getMonth() + 1, -11),
        end: new Date(date.getFullYear(), date.getMonth() + 1, -10),
        allDay: true,
        extendedProps: {
          calendar: 'Personal'
        }
      },
    ],
    selectedEvent: {},
    selectedCalendars: ['Personal', 'Business', 'Family', 'Holiday', 'ETC']
  },
  reducers: {
    selectEvent: (state, action) => {
      state.selectedEvent = action.payload
    }
  },
  extraReducers: builder => {
  
  }
})

export const { selectEvent } = appCalendarSlice.actions

export default appCalendarSlice.reducer
