import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ROLE } from "constants";
import { bookingApi } from "modules/ego-system/bookings/apis";
import { deliveryApis } from "modules/ego-system/delivery-booking/apis";
import { driverApi } from "modules/ego-system/drivers/apis";
import { LocationApi } from "modules/ego-system/location/apis";
import { driverPartnerApi } from "modules/transportation-system/modules/car-driver/components/drivers/apis";
import { PartnerMapModel } from "modules/transportation-system/modules/maps/model";
import { store } from "root-stores";
import { isObjEmpty } from "utils";
import { mapApis } from "../../maps/apis";
import { ExpressBookingModel } from "../components/driver-map/models/express-booking-model";
import { TaxiBookingModel } from "../components/driver-map/models/taxi-booking-model";
import MAP_OPTIONS from "../constants";
import toast from "react-hot-toast";

export const getAllDriverForAdmin = createAsyncThunk(
  "/map/getAllDriverByWorkPlace",
  async () => {
    try {
      const payload = {
        page: 1,
        limit: 100,
      };

      const response = await driverApi.getAllDriver(payload);

      return response;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getAllDriverByWorkplace = createAsyncThunk(
  "/map/getAllDriverByWorkPlace",
  async () => {
    try {
      const { auth } = store.getState();
      const { role, workPlaceId } = auth.userData;

      if (role === ROLE.ADMIN) {
        const payload = {
          page: 1,
          limit: 100,
          filter: {
            isConnectedCar: true,
          },
        };

        const response = await driverApi.getAllDriver(payload);

        return response;
      }

      if (role === ROLE.BOOKING_MANAGER) {
        const payload = {
          workPlaceId: workPlaceId,
          filter: {
            isConnectedCar: true,
          },
        };

        const response = await driverApi.getAllDriverByWorkplace(payload);

        return response;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const getAllDriverPartner = createAsyncThunk(
  "/map/getAllDriverPartner",
  async (query) => {
    try {
      const payload = {
        page: 1,
        limit: 1000,
        filter: {
          isConnectedCar: true,
        },
        ...query,
      };

      const response = await driverPartnerApi.getAllPartnerDriver(payload);

      return response;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getAllWorkPlace = createAsyncThunk("/map/workplaces", async () => {
  try {
    const payload = {
      page: 1,
      limit: 100,
    };

    const result = await LocationApi.getAllWorkPlace(payload);

    return result;
  } catch (error) {
    console.log(error);
  }
});

export const createTripDriver = createAsyncThunk(
  "/map/createTripByDriver",
  async (query) => {
    // try {
    const payload = {
      driverId: query?.driverId,
      tripId: query?.bookingId,
      type: query?.type,
    };

    const response = await mapApis.createTripDispatch(payload);

    console.log("response");

    return response;

    // } catch (error) {
    //   return error;
    // }
  }
);

export const getBookingToday = createAsyncThunk(
  "/map/getBookingToday",
  async (query) => {
    console.log("query", query)
    try {
      const bookingStatus = (() => {
        if (query?.filter?.status === MAP_OPTIONS.BOOKING_STATUS.PENDING || query?.filter?.status === MAP_OPTIONS.BOOKING_STATUS.REFUSED) {
          return [MAP_OPTIONS.BOOKING_STATUS.PENDING, MAP_OPTIONS.BOOKING_STATUS.REFUSED];
        }

        return query?.filter?.status;
      })();

      const payload = {
        page: 1,
        limit: 500,
        ...query,

        order: {
          createdAt: -1,
        },

        filter: {
          status: { $in: bookingStatus },
        },
      };

      const response = await bookingApi.getBookingToday({ q: payload });
      const expressResponse = await deliveryApis.getExpressBookingToday(
        payload
      );

      return { response, status: query?.filter?.status, expressResponse };
    } catch (error) {
      throw error;
    }
  }
);

export const getBookingTodayPartner = createAsyncThunk(
  "/map/getBookingTodayPartner",
  async (query) => {
    try {
      const bookingStatus = (() => {
        if (query?.filter?.status === MAP_OPTIONS.BOOKING_STATUS.PENDING) {
          return [
            MAP_OPTIONS.BOOKING_STATUS.PENDING,
            MAP_OPTIONS.BOOKING_STATUS.REFUSED,
          ];
        }

        return query?.filter?.status;
      })();

      const payload = {
        page: 1,
        limit: 100,
        ...query,

        order: {
          createdAt: -1,
        },

        filter: {
          status: bookingStatus,
        },
      };

      const response = await bookingApi.getAllBookingTodayForPartner({
        q: payload,
      });

      const reponseData = response?.data?.map((item) =>
        // eslint-disable-next-line implicit-arrow-linebreak
        new PartnerMapModel(item).generate()
      );

      return {
        response: { data: reponseData, total: response.total },
        status: query?.filter?.status,
      };
    } catch (error) {
      throw error;
    }
  }
);

export const getBookingDetailPartner = createAsyncThunk(
  "/map/getBookingDetailPartner",
  async ({ tripId, status }) => {
    try {
      const response = await bookingApi.getOnePartnerBooking({ id: tripId });

      return { response, status };
    } catch (error) {
      return;
    }
  }
);

export const getBookingDetailByTripId = createAsyncThunk(
  "/map/getBookingDetailByTripId",
  async ({ id, status, type }) => {
    try {
      let response = {};

      if (type === MAP_OPTIONS.BOOKING_TYPE.EXPRESS) {
        const responseData = await deliveryApis.getOneEgoExpress({ id: id });
        response = ExpressBookingModel.mapOneBookingFromApi(responseData);
      }

      if (type === MAP_OPTIONS.BOOKING_TYPE.TAXI) {
        const responseData = await bookingApi.getOneBooking({ id: id });
        response = TaxiBookingModel.mapOneBookingFromApi(responseData);
      }

      return { response, status };
    } catch (error) {
      return;
    }
  }
);

export const getDriversNearBooking = createAsyncThunk(
  "/map/getDriversNearBooking",
  async ({ tripId, startPoint, filter, type }) => {
    try {
      const response = await driverApi.getDriversNearBooking({
        startPoint,
        tripId: tripId,
        isActived: true,
        status: MAP_OPTIONS.DRIVER_STATUS.NORMAL,
        filter,
        type,
      });

      return response?.data || [];
    } catch (error) {
      toast.error(error?.message);
    }
  }
);

export const mapSlice = createSlice({
  name: "maps",
  initialState: {
    mapView: MAP_OPTIONS.MAP_VIEW_TYPE.DRIVERS,

    workplaces: [],
    workplaceIds: [],

    drivers: [],
    driverIds: [],
    driverByIds: {},
    driverActions: {},
    onlineDriverByIds: [],
    driverActionById: null,

    bookingByIds: {},
    bookingAction: {},
    pendingBookingByIds: {},
    processBookingByIds: {},
    runningBookingByIds: {},
    totalBookingByType: 0,

    completeBookings: [],
    cancelBookings: [],

    isShowNotices: false,
    bookingNotices: {},

    driverPickupBooking: {},
    bookingNearDriverIds: [],
  },

  reducers: {
    setMapView: (state, { payload }) => {
      state.mapView = payload;
    },

    selectDriver: (state, { payload }) => {
      if (isObjEmpty(payload)) {
        return;
      }

      const { id, location } = payload;
      const selectedDriver = state.driverByIds?.[id];
      if (selectedDriver) {
        state.driverActions = { ...selectedDriver, location: location };
      }
    },

    selectDriverId: (state, { payload }) => {
      state.driverActionById = payload;
    },

    resetSelectDriver: (state) => {
      state.driverActions = {};
      state.driverActionById = null;
    },

    getAllOnlineDriver: (state) => {
      const onlineDriverByIds = Object.keys(state.driverByIds).reduce(
        (online, driverId) => {
          const driver = state.driverByIds[driverId];

          if (driver.isActived) {
            return {
              ...online,
              [driverId]: {
                ...driver,
                initialLocation: driver?.location,
                location: null,
              },
            };
          }

          return online;
        },
        {}
      );

      state.onlineDriverByIds = onlineDriverByIds;
    },

    resetAllOnlineDriver: (state) => {
      state.onlineDriverByIds = {};
    },

    updateDriverActiveStatus: (state, { payload = {} }) => {
      if (isObjEmpty(payload)) {
        return;
      }

      const { driverId, isActived } = payload;

      if (!state.driverByIds[driverId]) {
        return;
      }

      if (state.driverByIds[driverId]) {
        state.driverByIds[driverId].isActived = isActived;
      }

      if (state.onlineDriverByIds[driverId]) {
        state.onlineDriverByIds[driverId].isActived = isActived;
      } else {
        state.onlineDriverByIds[driverId] = { ...state.driverByIds[driverId] };
      }
    },

    updateDriverStatus: (state, { payload = {} }) => {
      if (isObjEmpty(payload)) {
        return;
      }

      const { driverId, status } = payload;

      if (!state.driverByIds[driverId]) {
        return;
      }

      if (state.driverByIds[driverId]) {
        state.driverByIds[driverId].status = status;
      }

      if (state.onlineDriverByIds[driverId]) {
        state.onlineDriverByIds[driverId].status = status;
      }
    },

    updateDriverLocation: (state, { payload = {} }) => {
      if (isObjEmpty(payload)) {
        return;
      }

      const { driverId, location } = payload;

      if (!state.driverByIds[driverId]) {
        return;
      }

      if (state.driverByIds[driverId]) {
        state.driverByIds[driverId].location = location;
      }

      if (state.onlineDriverByIds[driverId]) {
        const hasInitialLocation =
          state.onlineDriverByIds[driverId]?.initialLocation;

        if (hasInitialLocation) {
          state.onlineDriverByIds[driverId].location = location;
        } else {
          state.onlineDriverByIds[driverId].initialLocation = location;
        }
      } else {
        if (!state.onlineDriverByIds[driverId]) {
          return;
        }
        state.onlineDriverByIds[driverId] = { ...state.driverByIds[driverId] };

        state.onlineDriverByIds[driverApi].isActived = true;
        state.onlineDriverByIds[driverApi].initialLocation = location;
      }
    },

    setNewBooking: (state, { payload = {} }) => {
      if (isObjEmpty(payload)) {
        return;
      }

      const bookingId = payload.id;

      if (!state.isShowNotices) {
        state.bookingNotices = { ...payload };
      }

      state.isShowNotices = true;
      state.pendingBookingByIds[bookingId] = { ...payload };
    },

    turnOffNotice: (state) => {
      state.bookingNotices = {};
      state.isShowNotices = false;
    },

    setBookingView: (state, { payload }) => {
      console.log("setBookingView", state.bookingAction);
      state.bookingAction = { ...payload };
    },

    exitBookingView: (state) => {
      state.bookingAction = {};
      state.driverPickupBooking = {};
      state.bookingNearDriverIds = [];
      state.mapView = MAP_OPTIONS.MAP_VIEW_TYPE.DRIVERS;
    },

    cancelBooking: (state, { payload }) => {
      // if (state.bookingAction.id === payload.tripId) {
      //   state.driverPickupBooking = {};
      // }

      state.cancelBookings.unshift(payload.booking);

      delete state.pendingBookingByIds[payload.tripId];
      delete state.processBookingByIds[payload.tripId];

      if (state.bookingAction.id === payload.tripId) {
        const booking = payload.booking;
        console.log("coordinates", booking);
        state.bookingAction = { ...booking };
        state.mapView = MAP_OPTIONS.MAP_VIEW_TYPE.BOOKINGS;
        return;
      } else {
        state.bookingAction = {};
        state.driverPickupBooking = {};
        state.mapView = MAP_OPTIONS.MAP_VIEW_TYPE.DRIVERS;
      }
    },

    refusedBooking: (state, { payload }) => {
      if (state.driverPickupBooking.driverId === payload.driverId) {
        state.driverPickupBooking = {};

        state.mapView = MAP_OPTIONS.MAP_VIEW_TYPE.BOOKINGS;
      }

      if (state.bookingAction.id === payload.tripId) {
        const booking = state.pendingBookingByIds[payload.tripId];
        state.bookingAction = { ...booking };
        state.mapView = MAP_OPTIONS.MAP_VIEW_TYPE.BOOKINGS;
      }
    },

    acceptBookingByDriver: (state, { payload }) => {
      if (state.driverPickupBooking.driverId === payload.driverId) {
        state.bookingAction = {};
        state.driverPickupBooking = {};
        state.mapView = MAP_OPTIONS.MAP_VIEW_TYPE.DRIVERS;
      }

      delete state.pendingBookingByIds[payload?.tripId];
      state.processBookingByIds[payload.tripId] = payload.booking;
    },

    startBookingByDriver: (state, { payload }) => {
      state.processBookingByIds[payload?.tripId] = {
        ...state.pendingBookingByIds[payload?.tripId],
      };

      delete state.processBookingByIds[payload?.tripId];
      state.bookingAction = payload.booking;
      state.runningBookingByIds[payload?.tripId] = payload.booking;
    },

    endBookingByDriver: (state, { payload }) => {
      console.log("payload.booking", payload.booking);
      state.completeBookings.unshift(payload.booking);

      delete state.runningBookingByIds[payload?.tripId];
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllDriverByWorkplace.fulfilled, (state, { payload }) => {
        if (!payload) {
          return;
        }

        const drivers = payload.data || payload;
        const driverIds = drivers.reduce((driverByIds, driver) => {
          return { ...driverByIds, [driver.id]: { ...driver } };
        }, {});

        const idDrivers = drivers.map((driver) => driver.id);

        state.drivers = [...drivers];
        state.driverByIds = { ...driverIds };
        state.driverIds = [...idDrivers];
      })

      .addCase(getAllDriverPartner.fulfilled, (state, { payload }) => {
        if (!payload) {
          return;
        }

        const drivers = payload.data || payload;
        const driverIds = drivers.reduce((driverByIds, driver) => {
          return { ...driverByIds, [driver.id]: { ...driver } };
        }, {});

        const idDrivers = drivers.map((driver) => driver.id);

        state.drivers = [...drivers];
        state.driverByIds = { ...driverIds };
        state.driverIds = [...idDrivers];
      })

      .addCase(createTripDriver.fulfilled, (state, { payload }) => {
        state.driverPickupBooking = { ...payload };
        state.mapView = MAP_OPTIONS.MAP_VIEW_TYPE.DRIVER_PICK_UP;
      })

      .addCase(getAllWorkPlace.fulfilled, (state, { payload }) => {
        state.workplaces = payload?.data;
        state.workplaceIds = payload?.data.map((workplace) => workplace.id);
      })

      .addCase(getBookingToday.fulfilled, (state, { payload }) => {
        const bookingStatus = payload.status;

        const expressBooking = payload.expressResponse;
        const taxiBooking = payload.response;

        const taxiBookingList = TaxiBookingModel.mapBookingsFromApi(
          taxiBooking?.data || []
        );
        const expressBookingList = ExpressBookingModel.mapBookingsFromApi(
          expressBooking?.data || []
        );

        const allBookings = [...expressBookingList, ...taxiBookingList];

        state.totalBookingByType = Math.max(
          expressBooking.total,
          taxiBooking.total
        );

        if (bookingStatus) {
          switch (bookingStatus) {
            case MAP_OPTIONS.BOOKING_STATUS.CANCEL:
              state.cancelBookings = allBookings;
              break;

            case MAP_OPTIONS.BOOKING_STATUS.COMPLETED:
              state.completeBookings = allBookings;
              break;

            case MAP_OPTIONS.BOOKING_STATUS.PENDING:
              state.pendingBookingByIds = allBookings.reduce(
                (bookingIds, booking) => ({
                  ...bookingIds,
                  [booking.id]: { ...booking },
                }),
                {}
              );
              break;

            case MAP_OPTIONS.BOOKING_STATUS.READY:
              state.processBookingByIds = allBookings.reduce(
                (bookingIds, booking) => ({
                  ...bookingIds,
                  [booking.id]: { ...booking },
                }),
                {}
              );
              break;

            case MAP_OPTIONS.BOOKING_STATUS.PROCESS:
              state.runningBookingByIds = allBookings.reduce(
                (bookingIds, booking) => ({
                  ...bookingIds,
                  [booking.id]: { ...booking },
                }),
                {}
              );
              break;
          }

          return;
        }

        const cancelBookings = [];
        const completeBookings = [];
        const processBookingByIds = {};
        const pendingBookingByIds = {};
        const runningBookingByIds = {};

        allBookings.forEach((booking) => {
          switch (booking.status) {
            case MAP_OPTIONS.BOOKING_STATUS.CANCEL:
              cancelBookings.push(booking);
              break;
            case MAP_OPTIONS.BOOKING_STATUS.COMPLETED:
              completeBookings.push(booking);
              break;
            case MAP_OPTIONS.BOOKING_STATUS.PENDING:
              pendingBookingByIds[booking.id] = { ...booking };
              break;
            case MAP_OPTIONS.BOOKING_STATUS.REFUSED:
              pendingBookingByIds[booking.id] = { ...booking };
              break;

            case MAP_OPTIONS.BOOKING_STATUS.READY:
              processBookingByIds[booking.id] = { ...booking };
              break;

            case MAP_OPTIONS.BOOKING_STATUS.PROCESS:
              runningBookingByIds[booking.id] = { ...booking };
              break;
          }
        });
        state.cancelBookings = cancelBookings;
        state.completeBookings = completeBookings;
        state.processBookingByIds = processBookingByIds;
        state.pendingBookingByIds = pendingBookingByIds;
        state.runningBookingByIds = runningBookingByIds;
      })

      .addCase(getBookingTodayPartner.fulfilled, (state, { payload }) => {
        const bookingStatus = payload.status;
        const allBookings = payload.response.data;
        state.totalBookingByType = payload.response.total;

        if (bookingStatus) {
          switch (bookingStatus) {
            case MAP_OPTIONS.BOOKING_STATUS.CANCEL:
              state.cancelBookings = allBookings;
              break;

            case MAP_OPTIONS.BOOKING_STATUS.COMPLETED:
              state.completeBookings = allBookings;
              break;

            case MAP_OPTIONS.BOOKING_STATUS.PENDING:
              state.pendingBookingByIds = allBookings.reduce(
                (bookingIds, booking) => ({
                  ...bookingIds,
                  [booking.id]: { ...booking },
                }),
                {}
              );
              break;

            case MAP_OPTIONS.BOOKING_STATUS.READY:
              state.processBookingByIds = allBookings.reduce(
                (bookingIds, booking) => ({
                  ...bookingIds,
                  [booking.id]: { ...booking },
                }),
                {}
              );
              break;

            case MAP_OPTIONS.BOOKING_STATUS.PROCESS:
              state.runningBookingByIds = allBookings.reduce(
                (bookingIds, booking) => ({
                  ...bookingIds,
                  [booking.id]: { ...booking },
                }),
                {}
              );
              break;
          }

          return;
        }

        const cancelBookings = [];
        const completeBookings = [];
        const processBookingByIds = {};
        const pendingBookingByIds = {};
        const runningBookingByIds = {};

        allBookings.forEach((booking) => {
          switch (booking.status) {
            case MAP_OPTIONS.BOOKING_STATUS.CANCEL:
              cancelBookings.push(booking);
              break;
            case MAP_OPTIONS.BOOKING_STATUS.COMPLETED:
              completeBookings.push(booking);
              break;
            case MAP_OPTIONS.BOOKING_STATUS.PENDING:
              pendingBookingByIds[booking.id] = { ...booking };
              break;
            case MAP_OPTIONS.BOOKING_STATUS.REFUSED:
              pendingBookingByIds[booking.id] = { ...booking };
              break;

            case MAP_OPTIONS.BOOKING_STATUS.READY:
              processBookingByIds[booking.id] = { ...booking };
              break;

            case MAP_OPTIONS.BOOKING_STATUS.PROCESS:
              runningBookingByIds[booking.id] = { ...booking };
              break;
          }
        });
        state.cancelBookings = cancelBookings;
        state.completeBookings = completeBookings;
        state.processBookingByIds = processBookingByIds;
        state.pendingBookingByIds = pendingBookingByIds;
        state.runningBookingByIds = runningBookingByIds;
      })

      .addCase(getBookingDetailByTripId.fulfilled, (state, { payload }) => {
        state.mapView = MAP_OPTIONS.MAP_VIEW_TYPE.BOOKING_DETAIL;
        if (!payload) {
          return;
        }

        // if (!payload.status) {
        state.bookingAction = { ...payload?.response };
        // return;
        // }

        // state.bookingAction = { ...payload?.response, status: payload.status };
      })

      .addCase(getBookingDetailPartner.fulfilled, (state, { payload }) => {
        state.mapView = MAP_OPTIONS.MAP_VIEW_TYPE.BOOKING_DETAIL;
        console.log("payload getBookingDetailPartner", payload);
        if (!payload) {
          return;
        }

        if (!payload.status) {
          state.bookingAction = {
            ...payload?.response,
            finalCost:
              payload?.response?.finalCost || payload?.response?.initialCost,
            user: payload?.response?.partner,
          };
          return;
        }

        state.bookingAction = {
          ...payload?.response,
          status: payload.status,
          user: payload?.response?.partner,
          finalCost:
            payload?.response?.finalCost || payload?.response?.initialCost,
        };
      })

      .addCase(getDriversNearBooking.fulfilled, (state, { payload = [] }) => {
        if (!Array.isArray(payload)) {
          return;
        }

        state.bookingNearDriverIds = payload.map((item) => item.id);
      });
  },
});

export const {
  setMapView,
  selectDriver,
  turnOffNotice,
  cancelBooking,
  setNewBooking,
  refusedBooking,
  selectDriverId,
  setBookingView,
  exitBookingView,
  resetSelectDriver,
  getAllOnlineDriver,
  endBookingByDriver,
  updateDriverStatus,
  startBookingByDriver,
  resetAllOnlineDriver,
  updateDriverLocation,
  acceptBookingByDriver,
  updateDriverActiveStatus,
} = mapSlice.actions;

export default mapSlice.reducer;
