import { useEffect } from "react";
import classNames from "classnames";
import { Controller, useForm } from "react-hook-form";
import { FormFeedback, FormGroup, Label } from "reactstrap";

import FormDrawer from "components/utils/form-drawer";
import InputSystem from "components/e-control/forms/form-elements/input-system";
import UploadImageSystem from "components/e-control/forms/form-elements/upload-image-system";
import InputNumberSystem from "components/e-control/forms/form-elements/input-number-system/input-number-system";

const AccumulateActionModal = (props) => {
  const { isEdit, dataEdit, isOpen, isLoading, onClose, submit } = props;

  const form = useForm({
    mode: "onChange",
  });

  const {
    formState: { errors },
  } = form;

  const closeModal = () => {
    form.reset();
    onClose?.();
  };

  useEffect(() => {
    if (!isOpen) {
      form.reset({
        name: "",
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      form.reset();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isEdit && dataEdit) {
      const {
        name,
        ratePYear,
        savingsTerm,
        max,
        min,
        image,
        bannerImage,
        earlyWithdrawRate,
      } = dataEdit;

      form.reset({
        name,
        ratePYear: ratePYear * 100,
        earlyWithdrawRate: earlyWithdrawRate * 100,
        savingsTerm,
        max,
        min,
        image,
        bannerImage,
      });
    }
  }, [dataEdit]);

  return (
    <FormDrawer
      form={form}
      header={isEdit ? "Chỉnh sửa" : "Tạo mới"}
      isOpen={isOpen}
      onSubmit={submit}
      loading={isLoading}
      onClose={closeModal}
    >
      <FormGroup>
        <Label>Tên chương trình</Label>

        <Controller
          control={form.control}
          name="name"
          rules={{
            required: { value: true, message: "Nhập tên chương trình" },
          }}
          render={({ field }) => {
            return (
              <div className="flex flex-col gap-y-1">
                <InputSystem
                  {...field}
                  invalid={errors.name && true}
                  placeholder="Nhập tên chương trình"
                />
                <FormFeedback className="block">
                  {errors?.name?.message}
                </FormFeedback>
              </div>
            );
          }}
        />
      </FormGroup>

      <FormGroup>
        <Label>Lãi suất(%)</Label>

        <Controller
          control={form.control}
          name="ratePYear"
          rules={{
            required: { value: true, message: "Nhập lãi suất" },
          }}
          render={({ field }) => (
            <div className="flex flex-col gap-y-1">
              <InputNumberSystem
                {...field}
                className={classNames({
                  "is-invalid": errors.ratePYear && true,
                })}
                min={0}
                max={100}
                placeholder="Nhập lãi suất"
              />
              <FormFeedback className="block">
                {errors?.ratePYear?.message}
              </FormFeedback>
            </div>
          )}
        />
      </FormGroup>

      <FormGroup>
        <Label>Lãi suất rút trước(%)</Label>

        <Controller
          control={form.control}
          name="earlyWithdrawRate"
          rules={{
            required: { value: true, message: "Nhập lãi suất rút trước" },
          }}
          render={({ field }) => (
            <div className="flex flex-col gap-y-1">
              <InputNumberSystem
                {...field}
                className={classNames({
                  "is-invalid": errors.earlyWithdrawRate && true,
                })}
                min={0}
                max={100}
                placeholder="Nhập lãi suất rút trước"
              />
              <FormFeedback className="block">
                {errors?.earlyWithdrawRate?.message}
              </FormFeedback>
            </div>
          )}
        />
      </FormGroup>

      <FormGroup>
        <Label>Thời hạn(tháng)</Label>

        <Controller
          control={form.control}
          name="savingsTerm"
          rules={{
            required: { value: true, message: "Nhập thời hạn" },
          }}
          render={({ field }) => (
            <div className="flex flex-col gap-y-1">
              <InputNumberSystem
                {...field}
                className={classNames({
                  "is-invalid": errors.savingsTerm && true,
                })}
                placeholder="Nhập thời hạn"
              />
              <FormFeedback className="block">
                {errors?.savingsTerm?.message}
              </FormFeedback>
            </div>
          )}
        />
      </FormGroup>

      <FormGroup>
        <Label>Số tiền tối đa</Label>

        <Controller
          control={form.control}
          name="max"
          rules={{
            required: { value: true, message: "Nhập số tiền" },
          }}
          render={({ field }) => (
            <div className="flex flex-col gap-y-1">
              <InputNumberSystem
                {...field}
                className={classNames({
                  "is-invalid": errors.max && true,
                })}
                placeholder="Nhập số tiền tối đa"
              />
              <FormFeedback className="block">
                {errors?.max?.message}
              </FormFeedback>
            </div>
          )}
        />
      </FormGroup>

      <FormGroup>
        <Label>Số tiền tối thiểu</Label>

        <Controller
          control={form.control}
          name="min"
          rules={{
            required: { value: true, message: "Nhập số tiền" },
          }}
          render={({ field }) => (
            <div className="flex flex-col gap-y-1">
              <InputNumberSystem
                {...field}
                className={classNames({
                  "is-invalid": errors.min && true,
                })}
                placeholder="Nhập số tiền tối thiểu"
              />
              <FormFeedback className="block">
                {errors?.min?.message}
              </FormFeedback>
            </div>
          )}
        />
      </FormGroup>

      <FormGroup>
        <Label className="partner-form__label">Hình ảnh</Label>
        <Controller
          name="image"
          rules={{
            required: {
              value: true,
              message: "Tải ảnh giới thiệu chương trình",
            },
          }}
          control={form.control}
          render={({ field }) => (
            <div className="space-y-1">
              <UploadImageSystem maxSizeMB={0.1} {...field} />
              <FormFeedback className="block">
                {errors?.image?.message}
              </FormFeedback>
            </div>
          )}
        />
      </FormGroup>

      <FormGroup>
        <Label className="partner-form__label">Banner</Label>
        <Controller
          name="bannerImage"
          rules={{
            required: {
              value: true,
              message: "Tải ảnh banner chương trình",
            },
          }}
          control={form.control}
          render={({ field }) => (
            <div className="space-y-1">
              <UploadImageSystem maxSizeMB={0.1} {...field} />
              <FormFeedback className="block">
                {errors?.bannerImage?.message}
              </FormFeedback>
            </div>
          )}
        />
      </FormGroup>
    </FormDrawer>
  );
};

export default AccumulateActionModal;
