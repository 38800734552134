import { gql } from "@apollo/client";
import { mutate, query } from "configs/http-config";

const priceAction = {
  getAllTripPrice: gql`
    query getAllTripPrice(
      $page: Int
      $limit: Int
      $search: String
      $filter: Mixed
    ) {
      getAllTripPrice(
        q: {
          limit: $limit
          page: $page
          search: $search
          filter: $filter
          order: { createdAt: -1 }
        }
      ) {
        data {
          id
          pricePerKm
          minPricePer2Km
          priceFrom13Km
          priceFrom1Km
          priceFrom26Km
          priceFrom2Km
          priceByTravelTime
          carTypeId
          carType {
            id
            name
          }
          createdAt
          updatedAt
          workPlace {
            id
            name
          }

          country {
            name
          }
          workPlaceId
        }
        total
      }
    }
  `,

  getOneTripPrice: gql`
    query getOneTripPrice($id: ID!) {
      getOneTripPrice(id: $id) {
        id
        pricePerKm
        createdAt
        updatedAt
        workPlace {
          id
          landMarkIds
          landMarks {
            address
            id
            image
            location
            name
            description
          }
        }
        workPlaceId
      }
    }
  `,

  createTripPrice: gql`
    mutation createTripPrice($data: CreateTripPriceInput!) {
      createTripPrice(data: $data) {
        id
      }
    }
  `,

  deleteOneTripPrice: gql`
    mutation deleteOneTripPrice($id: ID!) {
      deleteOneTripPrice(id: $id) {
        id
        pricePerKm
      }
    }
  `,

  updateTripPrice: gql`
    mutation updateTripPrice($id: ID!, $data: UpdateTripPriceInput!) {
      updateTripPrice(id: $id, data: $data) {
        id
        pricePerKm
      }
    }
  `,
};

export const priceApi = {
  getAllTripPrice: (payload) => {
    return query("getAllTripPrice", priceAction.getAllTripPrice, payload);
  },

  getOneTripPrice: (payload) => {
    return mutate("getOneTripPrice", priceAction.getOneTripPrice, payload);
  },

  createTripPrice: (payload) => {
    return mutate("createTripPrice", priceAction.createTripPrice, payload);
  },

  deleteOneTripPrice: (payload) => {
    return mutate(
      "deleteOneTripPrice",
      priceAction.deleteOneTripPrice,
      payload
    );
  },

  updateTripPrice: (payload) => {
    return mutate("updateTripPrice", priceAction.updateTripPrice, payload);
  },
};
