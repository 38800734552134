import imageCompression from "browser-image-compression";
import toast from "react-hot-toast";

const CompressMultiImage = async (files, maxMb, maxWidthOrHeight = 1920) => {
  let imagesList = [];

  const options = {
    maxSizeMB: maxMb,
    maxWidthOrHeight: maxWidthOrHeight,
    useWebWorker: true,
    fileType: "image/jpeg",
    maxIteration: 30,
  };

  for (let i of files) {
    const compressedFile = await imageCompression(i, options);

    console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);

    if (compressedFile.size / 1024 / 1024 < maxMb) {
      imagesList.push(compressedFile);
    } else {
      toast.error("Kích thước file quá lớn, không thể upload !!!");
    }
  }

  return imagesList;
};

export default CompressMultiImage;

import Pica from "pica";

export const generateBase64FromFile = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = () => {
      reject(new Error("Error reading file"));
    };

    fileReader.readAsDataURL(file);
  });
};

export function resizeImage(file, body) {
  const pica = Pica();

  const outputCanvas = document.createElement("canvas");

  outputCanvas.height = 480;
  outputCanvas.width = 640;

  return new Promise((resolve) => {
    const img = new Image();

    img.onload = () => {
      resolve(
        pica
          .resize(img, outputCanvas, {
            unsharpAmount: 80,
            unsharpRadius: 0.6,
            unsharpThreshold: 2,
          })
          .then((result) => pica.toBlob(result, "image/jpeg", 0.7))
      );
    };

    img.src = `data:${file.type};base64,${body}`;
  });
}
