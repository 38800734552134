export const formatNumberToAmountPrefix = (value, currency = "$") => {
  return `${currency} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * @description Format number to currency format
 * @param {Number | String} value
 * @returns {String}
 */
export const formatNumberToAmount = (value) => {
  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * @description Format currency format to number
 * @param {String} value
 * @returns {String}
 */
export const formatAmountToNumber = (value) => {
  return value?.replace(/\$\s?|(,*)/g, "");
};

/**
 * @description Format number and round
 * @param { Number } number
 * @param { Number } decimal
 * @returns {Number}
 */
export const formatRoundNumber = (number, decimal = 0) => {
  const fixedNumber = parseFloat(number).toFixed(decimal);
  return formatNumberToAmount(fixedNumber);
};

export const formatNumberToAmountEnableDecimal = (
  value,
  decimal = 0,
  currency = "VNĐ"
) => {
  const stringNumber = (() => {
    if (!value) {
      return "0";
    }

    const string = typeof value === "string" ? value : value?.toString();

    if (string.indexOf("-") !== -1) {
      return string?.replace("-", "");
    }

    return string;
  })();

  const number =
    Math.floor(parseFloat(stringNumber) * Math.pow(10, decimal)) /
    Math.pow(10, decimal);

  if (number > 0) {
    return ` ${number} ${currency}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return ` ${stringNumber} ${currency}`;
};

export const formatNumberToAmountKeepEnableDecimal = (
  value,
  decimal = 0,
  currency = "$"
) => {
  const stringNumber = (() => {
    if (!value) {
      return "0";
    }

    const string = typeof value === "string" ? value : value?.toString();

    if (string.indexOf("-") !== -1) {
      return string?.replace("-", "");
    }

    return string;
  })();

  const number =
    Math.floor(parseFloat(stringNumber) * Math.pow(10, decimal)) /
    Math.pow(10, decimal);

  if (number > 0) {
    let numberStrings = number.toString().split(".");
    if (numberStrings[1]?.length < 2) {
      numberStrings[1] = numberStrings[1] + "0";
    }

    if (numberStrings?.length < 2) {
      numberStrings.push("00");
    }

    numberStrings = numberStrings.join(".");

    return `${currency} ${numberStrings}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return `${currency} ${stringNumber}`;
};

export const formatAmountRound = (value) => {
  return Math.round(value / 1000) * 1000;
};
