import { gql } from "@apollo/client";
import { mutate, query } from "configs/http-config";

const InvoiceAction = {
  getStatInvoiceForPartner: gql`
    query getStatInvoiceForPartner(
      $page: Int
      $limit: Int
      $filter: Mixed
      $order: Mixed
      $fromDate: DateTime
      $toDate: DateTime
      $search: String
      $partnerId: ID!
    ) {
      getStatInvoiceForPartner(
        q: {
          limit: $limit
          page: $page
          filter: $filter
          order: $order
          search: $search
        }
        fromDate: $fromDate
        toDate: $toDate
        partnerId: $partnerId
      )
    }
  `,

  getStatInvoiceRentCarTrip: gql`
    query getStatInvoiceRentCarTrip(
      $page: Int
      $limit: Int
      $filter: Mixed
      $order: Mixed
      $fromDate: DateTime
      $toDate: DateTime
      $search: String
      $partnerId: ID!
    ) {
      getStatInvoiceRentCarTrip(
        q: {
          limit: $limit
          page: $page
          filter: $filter
          order: $order
          search: $search
        }
        fromDate: $fromDate
        toDate: $toDate
        partnerId: $partnerId
      )
    }
  `,
};

export const InvoiceApi = {
  getStatInvoiceForPartner: (payload) => {
    return query(
      "getStatInvoiceForPartner",
      InvoiceAction.getStatInvoiceForPartner,
      payload
    );
  },

  getStatInvoiceRentCarTrip: (payload) => {
    return query(
      "getStatInvoiceRentCarTrip",
      InvoiceAction.getStatInvoiceRentCarTrip,
      payload
    );
  },
};
