import { mutate, query } from "configs/http-config";
import { comboActions } from "./schema";

export const comboApis = {
  // combo
  getAllShopCombo: (params) => {
    return query("getAllShopCombo", comboActions.getAllShopCombo, {
      q: params,
    });
  },

  getOneShopCombo: (id) => {
    return query("getOneShopCombo", comboActions.getOneShopCombo, { id: id });
  },

  createShopCombo: (payload) => {
    return mutate("createShopCombo", comboActions.createShopCombo, {
      data: payload,
    });
  },

  updateShopCombo: ({ id, ...payload }) => {
    return mutate("updateShopCombo", comboActions.updateShopCombo, {
      id: id,
      data: payload,
    });
  },

  deleteOneShopCombo: (id) => {
    return mutate("deleteOneShopCombo", comboActions.deleteOneShopCombo, {
      id,
    });
  },
};
