import { CONSTANTS } from 'constants';
import React, { createContext, useContext, useState } from 'react';
import localUtils from 'utility/local-utils';

const Context = createContext();

const AccountSelectContainer = ({ children }) => {
    const [selectedAccount, setSelectedAccount] = useState(
        JSON.parse(localUtils.get(CONSTANTS.LOCAL_STORAGE.ORG_ACCOUNT)) || null 
    );

    const accountId = selectedAccount?.value || 0;

    const saveSelectedAccount = (account) => {
        setSelectedAccount(account);
        localUtils.set(CONSTANTS.LOCAL_STORAGE.ORG_ACCOUNT, JSON.stringify(account));
    }

    return (
        <Context.Provider value={{
            accountId,
            orgAccount: selectedAccount,

            saveSelectedAccount,
        }}>
            {children}
        </Context.Provider>
    );
};

export const useAccountSelectContext = () => useContext(Context);

export default AccountSelectContainer;