// ** React Imports
import { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// ** Redux Imports
import { Provider } from "react-redux";
import { store } from "./root-stores";

// ** Intl, CASL & ThemeColors Context
import ability from "./configs/acl/ability";
import { AbilityContext } from "./utility/context/Can";
import { ThemeContext } from "./utility/context/ThemeColors";

// ** ThemeConfig
import themeConfig from "./configs/themeConfig";

// ** Toast
import { Toaster } from "react-hot-toast";

// ** i18n
import "./configs/i18n";

// ** Spinner (Splash Screen)
import Spinner from "components/controls/spinner/fallback-spinner/index";

// ** Ripple Button
import "components/controls/ripple-button";

// ** PrismJS
import "prismjs";
import "prismjs/components/prism-jsx.min";
import "prismjs/themes/prism-tomorrow.css";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** Maps
import "@tomtom-international/web-sdk-maps/dist/maps.css";

// ** React Hot Toast Styles
import "template/react/libs/react-hot-toasts/react-hot-toasts.scss";

// ** React Tab Styles
import "react-tabs/style/react-tabs.css";

// ** Core styles
import "assets/fonts/feather/iconfont.css";
import "template/index.scss";

// ** bootstrap css
import "bootstrap/dist/css/bootstrap.min.css";

// ** tailwind
import "template/tailwind-index.scss";

// ** Service Worker
import { ApolloProvider } from "@apollo/client";
import service from "configs/http-config";
import * as serviceWorker from "./serviceWorker";

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

// ** Container Import
import AccountSelectContainer from "components/containers/account-select-container";
import Auth from "components/containers/auth";
import ConfirmContainer from "components/containers/confirm-container";
import ViewDetected from "components/containers/device";
import ErrorBoundaryContainer from "components/containers/error-boundary";
import WorkplaceContainer from "components/containers/workplace-wrapper";
import Language from "components/utils/language";
import StringClientComponent from "modules/ego-system/call-stringee";
import CreateHolineBookingContext from "modules/ego-system/hotline/context/create-booking-context";
import BookingNoticeUtils from "modules/ego-system/maps/components/booking-notice-utils";


if (process.env.NODE_ENV === 'production') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ApolloProvider client={service}>
        <ViewDetected>
          <Suspense fallback={<Spinner />}>
            <Auth>
              <Language>
                <ErrorBoundaryContainer>
                  <WorkplaceContainer>
                    <AccountSelectContainer>
                      <ConfirmContainer>
                        <AbilityContext.Provider value={ability}>
                          <ThemeContext>

                            <LazyApp />

                            <Toaster
                              position={themeConfig.layout.toastPosition}
                              toastOptions={{ className: "react-hot-toast" }}
                            />

                            <BookingNoticeUtils />

                            <CreateHolineBookingContext>
                              <StringClientComponent />
                            </CreateHolineBookingContext>

                          </ThemeContext>
                        </AbilityContext.Provider>
                      </ConfirmContainer>
                    </AccountSelectContainer>
                  </WorkplaceContainer>
                </ErrorBoundaryContainer>
              </Language>
            </Auth>
          </Suspense>
        </ViewDetected>
      </ApolloProvider>
    </Provider>
  </BrowserRouter>
);

serviceWorker.register();
