export class PartnerMapModel {
    constructor(data) {
        this.id = data.id
        this.createdAt = data.createdAt
        this.status = data.status
        this.driver = data.driver || {
            id: '',
            username: '',
            avatar: '',
            name: '',
            carId: '',
            status: '',
        }
        this.dispatchId = data.dispatchId
        this.distance = data.distance
        this.driverId = data.driverId
        this.finalCost = data.finalCost
        this.endAddress = data.endAddress || data.endFullAddress
        this.endPoint = data.endPoint || null
        this.endFullAddress = data.endFullAddress
        this.startAddress = data.startAddress || data.startFullAddress
        this.startPoint = data.startPoint || null
        this.startFullAddress = data.startFullAddress
        this.user = data.partner || {
            name: '',
            phone: '',
            username: ''
        }
    }

    generate() {
        return {
            id: this.id,
            createdAt: this.createdAt,
            status: this.status,
            driver: this.driver,
            dispatchId: this.dispatchId,
            distance: this.distance,
            driverId: this.driverId,
            finalCost: this.finalCost,
            endAddress: this.endAddress,
            endPoint: this.endPoint,
            endFullAddress: this.endFullAddress,
            startAddress: this.startAddress,
            startPoint: this.startPoint,
            startFullAddress: this.startFullAddress,
            user: this.user,
        }
    }
}