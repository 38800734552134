import { ROLE } from "constants";
import { lazy } from "react";
import { KEYS } from "utility/permission/key-config";

const GovernmentPage = lazy(() => import("modules/government/client"));

export const GOVERNMENT_ROUTES = {
    GOVERNMENT: "/government-report",
};

const GovernmentRoutes = [
    {
        path: GOVERNMENT_ROUTES.GOVERNMENT,
        element: <GovernmentPage />,
        title: 'Report',
        meta: {
            permissionKey: KEYS.GOVERNMENT.REPORT,
        },
    },
];

export default GovernmentRoutes;
