import { gql } from "@apollo/client";
import { mutate, query } from "configs/http-config";

const departmentShema = {
  getAllPartnerDepartment: gql`
    query getAllPartnerDepartment(
      $page: Int
      $limit: Int
      $filter: Mixed
      $order: Mixed
      $search: String
      $partnerId: ID!
    ) {
      getAllPartnerDepartment(
        q: {
          limit: $limit
          page: $page
          filter: $filter
          order: $order
          search: $search
        }
        partnerId: $partnerId
      ) {
        data {
          createdAt
          id
          name
          partner {
            id
            name
          }
          partnerId
          updatedAt
        }
        total
      }
    }
  `,

  getOnePartnerDepartment: gql`
    query getOnePartnerDepartment($id: ID!) {
      getOnePartnerDepartment(id: $id) {
        createdAt
        id
        name
        partner {
          id
          name
        }
        partnerId
        updatedAt
      }
    }
  `,

  createPartnerDepartment: gql`
    mutation createPartnerDepartment($data: CreatePartnerDepartmentInput!) {
      createPartnerDepartment(data: $data) {
        id
      }
    }
  `,

  updatePartnerDepartment: gql`
    mutation updatePartnerDepartment(
      $id: ID!
      $data: UpdatePartnerDepartmentInput!
    ) {
      updatePartnerDepartment(id: $id, data: $data) {
        id
      }
    }
  `,

  deleteOnePartnerDepartment: gql`
    mutation deleteOnePartnerDepartment($id: ID!) {
      deleteOnePartnerDepartment(id: $id) {
        id
      }
    }
  `,
};

export const departmentPartnerApi = {
  getAllPartnerDepartment: (payload) => {
    return query(
      "getAllPartnerDepartment",
      departmentShema.getAllPartnerDepartment,
      payload
    );
  },

  createPartnerDepartment: (payload) => {
    return mutate(
      "createPartnerDepartment",
      departmentShema.createPartnerDepartment,
      payload
    );
  },

  updatePartnerDepartment: (payload) => {
    return mutate(
      "updatePartnerDepartment",
      departmentShema.updatePartnerDepartment,
      payload
    );
  },

  deleteOnePartnerDepartment: (payload) => {
    return mutate(
      "deleteOnePartnerDepartment",
      departmentShema.deleteOnePartnerDepartment,
      payload
    );
  },

  getOnePartnerDepartment: (payload) => {
    return query(
      "getOnePartnerDepartment",
      departmentShema.getOnePartnerDepartment,
      payload
    );
  },
};
