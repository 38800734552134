import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { toast } from "react-hot-toast";
import { dashboardApi } from "../apis";

export const getAllBookingStats = createAsyncThunk(
  "/dashboard/getAllBookingStats",
  async () => {
    try {
      const response = await dashboardApi.getAllBooking();

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getStatDashboardForAdmin = createAsyncThunk(
  "/dashboard/getStatDashboardForAdmin",
  async () => {
    try {
      const response = await dashboardApi.getStatDashboardForAdmin();

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getStatForDashboardNoQuery = createAsyncThunk(
  "/dashboard/getStatForDashboardNoQuery",
  async () => {
    try {
      const response = await dashboardApi.getStatForDashboard();

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getStatForDashboard = createAsyncThunk(
  "/dashboard/getStatForDashboard",
  async (query) => {
    try {
      const response = await dashboardApi.getStatForDashboard(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    bookingChart: {},

    totalDashboardsCard: {},

    totalDashboardsCardByWorkplace: {},

    workplaceCardsChart: {},

    totalDashboarAdmin: {}
  },

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getAllBookingStats.fulfilled, (state, { payload }) => {
      state.bookingChart = payload?.getStatBooking?.[0] || {};
    });

    builder.addCase(
      getStatForDashboardNoQuery.fulfilled,
      (state, { payload }) => {
        state.totalDashboardsCard = payload || {};
      }
    );

    builder.addCase(
      getStatDashboardForAdmin.fulfilled,
      (state, { payload }) => {
        state.totalDashboarAdmin = payload || {};
      }
    );

    builder.addCase(getStatForDashboard.fulfilled, (state, { payload }) => {
      state.totalDashboardsCardByWorkplace = payload;

      state.workplaceCardsChart = {
        chartDriver: {
          statsWeek: payload?.getStatDriver?.[0]?.totalDriverThisWeek || 0,
          statsMonth: payload?.getStatDriver?.[0]?.totalDriversThisMonth || 0,
          statsYear: payload?.getStatDriver?.[0]?.totalDriversThisYear || 0,
        },

        chartCar: {
          statsWeek: payload?.getStatCar?.[0]?.totalCarsThisWeek || 0,
          statsMonth: payload?.getStatCar?.[0]?.totalCarsThisMonth || 0,
          statsYear: payload?.getStatCar?.[0]?.totalCarsThisYear || 0,
        },

        chartInvoice: {
          statsWeek: payload?.getStatInvoice?.[0]?.totalInvoiceThisWeek || 0,
          statsMonth: payload?.getStatInvoice?.[0]?.totalInvoiceThisMonth || 0,
          statsYear: payload?.getStatInvoice?.[0]?.totalInvoiceThisYear || 0,
        },
      };
    });
  },
});

export default dashboardSlice.reducer;
