import { Button, Col, Row } from "reactstrap";

import tt from "@tomtom-international/web-sdk-services";
import testDriverApis from "modules/ego-system/test-map/apis";
import { useState } from "react";
import ActiveDriverForm from "../active-driver-form";
import CreateBookingForm from "../create-booking";
import StartFinishTripActionForm from "../start-trip";
import TripActionForm from "../trip-action-form";
import "./index.scss";

const DriverAction = ({
  driver,
  loginDrivers,
  login,
  updateLocationDriver,
  deactiveDriver,
}) => {
  const [isLogin, setIsLogin] = useState(false);
  const [loginedDrivers, setLoginedDriver] = useState({});

  const getRoute = async (points = []) => {
    try {
      const [start, end] = points;

      let location = "";
      if (Array.isArray(start) && Array.isArray(end)) {
        location = start.join(",") + ":" + end.join(",");
      }

      if (!location) {
        return;
      }

      const response = await tt.services.calculateRoute({
        key: process.env.REACT_APP_TOMTOM_MAP_KEY,
        traffic: false,
        locations: location,
        vehicleMaxSpeed: 90,
        computeTravelTimeFor: "all",
      });

      if (!response?.toGeoJson) {
        return;
      }

      const geoJson = response.toGeoJson();

      return geoJson;
    } catch (error) {
      console.log(error);
    }
  };

  const loginDriver = async () => {
    const response = await testDriverApis.login({ username: driver?.phone, type: 'DRIVER' });

    login?.(response.token);
  };

  const activeDriver = async (locations) => {
    if (!locations?.start && !locations?.start) {
      return;
    }

    const response = await testDriverApis.openApp(
      loginDrivers[driver?.id] || ""
    );
    console.log(response);

    const geoJson = await getRoute([locations?.start, locations?.end]);
    console.log(geoJson);

    const driverLocations = geoJson?.features?.[0]?.geometry?.coordinates;

    console.log("driverLocations", driverLocations);
    updateLocationDriver?.(driverLocations);
  };

  const deactived = async () => {
    await testDriverApis.offApp(loginDrivers[driver?.id] || "");

    deactiveDriver?.(driver?.id);
  };

  const createBooking = async (booking) => {
    await testDriverApis.createBooking(booking, loginDrivers[driver?.id] || "");
  };

  const acceptTrip = async (tripId) => {
    const response = await testDriverApis.acceptBooking(
      { tripDispatchId: tripId },
      loginDrivers[driver?.id] || ""
    );
    console.log("response", response);
  };

  const refuseTrip = async (tripId) => {
    const response = await testDriverApis.refuseBooking(
      { tripDispatchId: tripId },
      loginDrivers[driver?.id] || ""
    );
    console.log("response", response);
  };

  const startTrip = async (tripId) => {
    const response = await testDriverApis.startBooking(
      { tripId: tripId },
      loginDrivers[driver?.id] || ""
    );
    console.log("response", response);
  };

  const requestPaid = async (tripId, location) => {
    console.log('requestPaid', { tripId, location })
    const response = await testDriverApis.requestPaid(
      { tripId: tripId, location: location, paymentMethod: 'CASH' },
      loginDrivers[driver?.id] || ""
    )

    console.log('response', response)
  }

  const finishTrip = async (tripId, location) => {
    const response = await testDriverApis.finishBooking(
      { tripId: tripId, location: location },
      loginDrivers[driver?.id] || ""
    );
    console.log("response", response);
  };

  const arrivedBooking = async (tripId) => {
    const response = await testDriverApis.arrivedBooking(
      { tripId: tripId },
      loginDrivers[driver?.id] || ""
    );
    console.log("response", response);
  };

  const cancelBooking = async (tripId) => {
    const response = await testDriverApis.cancelTrip(
      { tripId: tripId },
      loginDrivers[driver?.id] || ""
    );
    console.log("response", response);
  };

  return (
    <div className="driver-action">
      <Row className="driver-action__content">
        <Col xs={24} className="driver-action__button-group">
          <Button color="primary" onClick={loginDriver}>
            Login
          </Button>

          <Button color="secondary" onClick={deactived}>
            Deactive
          </Button>
        </Col>

        <Col xs={24}>
          <CreateBookingForm create={createBooking} />
        </Col>

        <Col xs={24}>
          <ActiveDriverForm active={activeDriver} />
        </Col>

        <Col xs={24}>
          <TripActionForm accept={acceptTrip} refuse={refuseTrip} />
        </Col>

        <Col xs={24}>
          <StartFinishTripActionForm
            start={startTrip}
            finish={finishTrip}
            cancel={cancelBooking}
            arrived={arrivedBooking}
            requestPaid={requestPaid}
          />
        </Col>
      </Row>
    </div>
  );
};

export default DriverAction;
