import { gql } from "@apollo/client";

export const InvestmentUnitFragment = gql`
  fragment InvestmentUnitInfo on InvestmentUnit {
    id
    name
    address
    taxCode
    nameAgent
    logo
    images
    companyInfo
    documents
    createdAt
    updatedAt
    description
  }
`;

export const investmentUnit = gql`
  ${InvestmentUnitFragment}
  query GetAllInvestmentUnit($q: QueryGetListInput) {
    getAllInvestmentUnit(q: $q) {
      data {
        ...InvestmentUnitInfo
      }
      total
    }
  }
`;

export const oneInvestmentUnit = gql`
  ${InvestmentUnitFragment}
  query GetOneInvestmentUnit($getOneInvestmentUnitId: ID!) {
    getOneInvestmentUnit(id: $getOneInvestmentUnitId) {
      ...InvestmentUnitInfo
    }
  }
`;

export const getOneInvestmentUnit = gql`
  ${InvestmentUnitFragment}
  query getOneInvestmentUnit($id: ID!) {
    getOneInvestmentUnit(id: $id) {
      ...InvestmentUnitInfo
      id
      images
      logo
      name
      nameAgent
      taxCode
      companyInfo
      address
      documents
    }
  }
`;

export const InvestmentProjectFragment = gql`
  ${InvestmentUnitFragment}
  fragment InvestmentPrjInfo on InvestmentProject {
    id
    name
    totalStock
    totalSoldTock
    pricePerStock
    startDate
    endDate
    images
    documents
    investmentUnitId
    investmentUnit {
      ...InvestmentUnitInfo
    }
    createdAt
    updatedAt
  }
`;

export const getAllInvestmentProject = gql`
  ${InvestmentProjectFragment}
  query GetAllInvestmentProject($q: QueryGetListInput) {
    getAllInvestmentProject(q: $q) {
      data {
        ...InvestmentPrjInfo
      }
      total
    }
  }
`;

export const getAllInvestmentOrder = gql`
  ${InvestmentProjectFragment}
  query GetAllInvestmentOrder($q: QueryGetListInput) {
    getAllInvestmentOrder(q: $q) {
      data {
        id
        userId
        investmentProjectId
        quantity
        finalCost
        paymentMethod
        isPaid
        status
        code
        user {
          id
          username
          name
          phone
          avatar
          wallet {
            id
            userId
            balance
            passcode
            isBlock
          }
        }
        investmentProject {
          ...InvestmentPrjInfo
        }
        createdAt
        updatedAt
      }
      total
      getStatInvestmentOrder
      pagination {
        page
      }
    }
  }
`;

export const getOneInvestmentOrderById = gql`
  ${InvestmentProjectFragment}
  query getOneInvestmentOrder($id: ID!) {
    getOneInvestmentOrder(id: $id) {
      id
      userId
      investmentProjectId
      quantity
      finalCost
      paymentMethod
      isPaid
      status
      code
      user {
        id
        username
        name
        phone
        avatar
        wallet {
          id
          userId
          balance
          passcode
          isBlock
        }
      }
      investmentProject {
        ...InvestmentPrjInfo
      }
      createdAt
      updatedAt
    }
  }
`;

export const getOneInvestmentOrder = gql`
  ${InvestmentProjectFragment}
  query GetOneInvestmentOrder($getOneInvestmentOrderId: ID!) {
    getOneInvestmentOrder(id: $getOneInvestmentOrderId) {
      id
      userId
      investmentProjectId
      quantity
      finalCost
      paymentMethod
      isPaid
      createdAt
      updatedAt
      investmentProject {
        ...InvestmentPrjInfo
      }
    }
  }
`;

export const createInvestmentOrder = gql`
  mutation CreateInvestmentOrder($data: CreateInvestmentOrderInput!) {
    createInvestmentOrder(data: $data) {
      id
      userId
      investmentProjectId
      quantity
      finalCost
      paymentMethod
      isPaid
      createdAt
      updatedAt
    }
  }
`;

export const paymentInvestmentOrder = gql`
  mutation PaymentInvestmentOrder($orderId: ID!, $paymentMethod: String!) {
    paymentInvestmentOrder(orderId: $orderId, paymentMethod: $paymentMethod)
  }
`;

export const InvestorAccountFragment = gql`
  fragment InvestorAccount on User {
    id
    name
    username
    phone
    email
    role
    avatar
    address
    typeCompanies
    dateOfBirth
    identityCardImages
    isVerify
    isAgency
    identityCardInfo {
      id
      userId
      dateOfExpiry
      no
      fullName
      dateOfBirth
      sex
      nationality
      placeOfOrigin
      placeOfResidence
      personalIdentification
      dateOfIssue
      createdAt
      updatedAt
    }
    wallet {
      id
      userId
      balance
      isBlock
      createdAt
      updatedAt
    }
    gender
    birthday
    paymentMethod
    eKycStatus
    identityCardFront
    identityCardBack
    portraitImage
    isInvestor
    createdAt
    updatedAt
  }
`;

export const investorAccount = gql`
  ${InvestorAccountFragment}
  query GetAllUser($q: QueryGetListInput) {
    getAllUser(q: $q) {
      getStatUser
      total
      data {
        ...InvestorAccount
      }
    }
  }
`;

export const getInvestStats = gql`
  query getInvestStats {
    getInvestStats
  }
`;

export const createInvestmentCampaign = gql`
  mutation CreateInvestmentProject($data: CreateInvestmentProjectInput!) {
    createInvestmentProject(data: $data) {
      id
    }
  }
`;
export const createInvestmentUnit = gql`
  mutation CreateInvestmentUnit($data: CreateInvestmentUnitInput!) {
    createInvestmentUnit(data: $data) {
      id
    }
  }
`;

export const updateInvestmentUnit = gql`
  mutation updateInvestmentUnit($id: ID!, $data: UpdateInvestmentUnitInput!) {
    updateInvestmentUnit(id: $id, data: $data) {
      id
    }
  }
`;

export const deleteOneInvestmentUnit = gql`
  mutation deleteOneInvestmentUnit($id: ID!) {
    deleteOneInvestmentUnit(id: $id) {
      id
    }
  }
`;

export const updateInvestmentProject = gql`
  mutation updateInvestmentProject(
    $id: ID!
    $data: UpdateInvestmentProjectInput!
  ) {
    updateInvestmentProject(id: $id, data: $data) {
      id
    }
  }
`;

export const deleteOneInvestmentProject = gql`
  mutation deleteOneInvestmentProject($id: ID!) {
    deleteOneInvestmentProject(id: $id) {
      id
    }
  }
`;

export const sendConfirmPaymentInvestmentOrder = gql`
  mutation sendConfirmPaymentInvestmentOrder($orderId: ID!) {
    sendConfirmPaymentInvestmentOrder(orderId: $orderId)
  }
`;

export const confirmPaymentInvestmentOrder = gql`
  mutation confirmPaymentInvestmentOrder($orderId: ID!) {
    confirmPaymentInvestmentOrder(orderId: $orderId) {
      id
      code
    }
  }
`;

export const updateInvestmentOrder = gql`
  mutation updateInvestmentOrder($id: ID!, $data: UpdateInvestmentOrderInput!) {
    updateInvestmentOrder(id: $id, data: $data) {
      id
      code
    }
  }
`;
