import { gql } from "@apollo/client";
import { mutate, query } from "configs/http-config";

const notificationAction = {
  getAllNotifications: gql`
    query getAllNotification(
      $page: Int
      $limit: Int
      $order: Mixed
      $filter: Mixed
      $search: String
    ) {
      getAllNotification(
        q: {
          page: $page
          limit: $limit
          order: $order
          filter: $filter
          search: $search
        }
      ) {
        data {
          id
          tag
          title
          body
          html
          seen
          seenAt
          data
          image
          toUserId
          fromUserId
          tripId
          taxiRequestId
          content
          transactionId
          rentCarTripId
          curriculumVitaeId
          expressId
          countryId
          express {
            id
          }
          transaction {
            id
          }
          fromUser {
            id
            name
            username
          }
          toUser {
            id
            name
            username
          }
          trip {
            id
          }
          taxiRequest {
            id
          }

          investmentOrderId

          savingsPackageId

          investmentOrder {
            id
            code
          }

          fromUserId

          fromUser {
            id
            name
          }

          createdAt
          updatedAt
        }
        pagination {
          limit
          offset
          page
          total
        }
        total
      }
    }
  `,

  seeNotification: gql`
    mutation seenNotification($id: ID!) {
      seenNotification(id: $id)
    }
  `,

  getOneNotification: gql`
    query GetOneNotification($id: ID!) {
      getOneNotification(id: $id) {
        id
        tag
        title
        body
        html
        seen
        seenAt
        data
        image
        toUserId
        fromUserId
        tripId
        taxiRequestId
        content
        transactionId
        rentCarTripId
        curriculumVitaeId
        expressId
        countryId
        express {
          id
        }
        transaction {
          id
        }
        fromUser {
          id
          name
          username
        }
        toUser {
          id
          name
          username
        }
        trip {
          id
        }
        taxiRequest {
          id
        }
        createdAt
        updatedAt
      }
    }
  `,
};

export const notificationApi = {
  getAllNotifications: (payload) => {
    return query("getAllNotification", notificationAction.getAllNotifications, {
      ...payload,
      order: { createdAt: -1 },
    });
  },

  getOneNotification: (id) => {
    return query("getOneNotification", notificationAction.getOneNotification, {
      id: id,
    });
  },

  seeNotification: (id) => {
    return mutate("seenNotification", notificationAction.seeNotification, {
      id,
    });
  },
};
