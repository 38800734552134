import { gql } from "@apollo/client";
import { mutate, query } from "configs/http-config";
import { BookingFragment, RequestFragment } from "./fragment";

const BookingsAction = {
  getAllBooking: gql`
    query getAllBooking(
      $q: QueryGetListInput
      $fromDate: DateTime
      $toDate: DateTime
    ) {
      getAllBooking(q: $q, fromDate: $fromDate, toDate: $toDate) {
        data {
          id
          startPoint
          endPoint
          startAddress
          endAddress
          couponIds
          status
          paymentMethod
          code
          driverId
          distance
          waitTime
          travelTime
          initialCost
          discountAmount
          finalCost
          userId
          driverPickupTime
          driverDropOffTime
          createdAt
          updatedAt
          code
          reasonCancel
          driver {
            name

            car {
              carCode
            }
          }

          user {
            name
            username
          }
        }

        getStatBooking

        total
      }
    }
  `,

  getBookingToday: gql`
    query getAllBookingToDay($q: QueryGetListInput) {
      getAllBookingToDay(q: $q) {
        data {
          id
          createdAt
          status
          driver {
            id
            username
            avatar
            name
            car {
              carCode
              id
            }
            carId
            status
          }
          type
          dispatchId
          distance
          driverId
          finalCost
          endAddress
          endPoint
          endFullAddress
          startAddress
          startPoint
          startFullAddress
          user {
            name
            phone
            username
          }
        }

        total
      }
    }
  `,

  getAllBookingTodayForPartner: gql`
    query getAllBookingTodayForPartner($q: QueryGetListInput, $partnerId: ID!) {
      getAllBookingTodayForPartner(q: $q, partnerId: $partnerId) {
        data {
          id
          createdAt
          status
          driver {
            id
            username
            avatar
            name
            car {
              carCode
              id
            }
            carId
            status
          }
          partnerId
          distance
          driverId
          finalCost
          initialCost
          endAddress
          endFullAddress
          startAddress
          startFullAddress
          partner {
            name
            phone
            username
          }
        }

        total
      }
    }
  `,

  getAllBookingByHotline: gql`
    ${BookingFragment}
    query getAllBookingCreatedByHotline(
      $page: Int
      $limit: Int
      $filter: Mixed
      $order: Mixed
      $fromDate: DateTime
      $toDate: DateTime
      $search: String
      $partnerId: ID
    ) {
      getAllBookingCreatedByHotline(
        q: {
          limit: $limit
          page: $page
          filter: $filter
          order: $order
          search: $search
        }
        fromDate: $fromDate
        toDate: $toDate
        partnerId: $partnerId
      ) {
        data {
          ...BookingInfo

          driver {
            name

            car {
              carCode
            }
          }

          user {
            name
            phone
            username
          }
        }
        total
      }
    }
  `,

  getAllTaxiRequestBooking: gql`
    ${RequestFragment}
    query getAllTaxiRequestBooking(
      $q: QueryGetListInput
      $fromDate: DateTime
      $toDate: DateTime
      $partnerId: ID
    ) {
      getAllTaxiRequestBooking(
        q: $q
        fromDate: $fromDate
        toDate: $toDate
        partnerId: $partnerId
      ) {
        data {
          ...BookingInfo

          driver {
            name

            car {
              carCode
            }
          }
        }

        total
      }
    }
  `,

  cancelBookingByHotline: gql`
    mutation cancelTrip($tripId: ID!, $reasonCancel: String) {
      cancelTrip(tripId: $tripId, reasonCancel: $reasonCancel) {
        id
      }
    }
  `,

  cancelRequest: gql`
    mutation cancelTaxiRequestBooking($taxiRequestId: ID!) {
      cancelTaxiRequestBooking(taxiRequestId: $taxiRequestId) {
        id
      }
    }
  `,

  getOneTrip: gql`
    query getOneTrip($id: ID!) {
      getOneTrip(id: $id) {
        code
        couponIds
        coupons {
          code
        }
        createdAt
        discountAmount
        dispatch {
          status
        }
        dispatchId
        distance
        driver {
          name
          phone
          car {
            carCode
            licensePlateNumber
          }
        }
        egoDiscount
        netAmount
        egoDiscountPercent
        driverDropOffTime
        driverId
        driverPickupTime
        endAddress
        endFullAddress
        endPoint
        finalCost
        id
        initialCost
        paymentMethod
        startAddress
        startFullAddress
        startPoint
        status
        travelTime
        isPaid
        code
        VATFee
        updatedAt
        user {
          name
          phone
        }
        userId
        waitTime
        workPlace {
          id
          name
        }
        workPlaceId
      }
    }
  `,

  getOnePartnerTrip: gql`
    query getOnePartnerTrip($id: ID!) {
      getOnePartnerTrip(id: $id) {
        code
        createdAt
        discountAmount
        distance
        driver {
          name
          phone
          car {
            carCode
            licensePlateNumber
          }
        }
        driverId
        endAddress
        endFullAddress
        endPoint
        finalCost
        initialCost
        id
        initialCost
        startAddress
        startFullAddress
        startPoint
        status
        updatedAt
        partner {
          name
          phone
        }
        partnerId
        workPlace {
          id
          name
        }
        workPlaceId
      }
    }
  `,

  getAllActivityHistory: gql`
    query getAllActivityHistory($filter: Mixed) {
      getAllActivityHistory(q: { filter: $filter }) {
        data {
          user {
            id
            role
            name
          }

          driver {
            id
            name
          }

          tripId
          type
          oldValue
          newValue
          valueChange
          createdAt

          shopProduct {
            name
            id
            code
          }

          trip {
            code
            VATFee
            discountAmount
            distance
            endAddress
            finalCost
            isPaid
            startAddress
            reasonCancel
            status
            finalCost
          }
        }
      }
    }
  `,

  getConfigBookingDistance: gql`
    query getConfigBookingDistance {
      getConfigBookingDistance
    }
  `,

  createTripForSwitchboard: gql`
    mutation createTripForSwitchboard($data: CreateTripInputForSwitchboard) {
      createTripForSwitchboard(data: $data) {
        id
        code
      }
    }
  `,

  createTaxiRequestBooking: gql`
    mutation createTaxiRequestBooking($data: CreateTaxiRequestBookingInput!) {
      createTaxiRequestBooking(data: $data) {
        id
        name
      }
    }
  `,

  validateCoordinate: gql`
    mutation validateCoordinate(
      $long: Float!
      $lat: Float!
      $maxDistance: Float!
    ) {
      validateCoordinate(long: $long, lat: $lat, maxDistance: $maxDistance)
    }
  `,

  checkCarSolution: gql`
    mutation checkCarSolution($startPoint: [Float]!, $endPoint: [Float]!) {
      checkCarSolution(startPoint: $startPoint, endPoint: $endPoint) {
        _id
        name
      }
    }
  `,
};

export const bookingApi = {
  getAllBooking: (payload) => {
    return query("getAllBooking", BookingsAction.getAllBooking, payload);
  },

  getBookingToday: (payload) => {
    return query("getAllBookingToDay", BookingsAction.getBookingToday, payload);
  },

  getAllBookingTodayForPartner: (payload) => {
    return query(
      "getAllBookingTodayForPartner",
      BookingsAction.getAllBookingTodayForPartner,
      payload
    );
  },

  getAllBookingByHotline: (payload) => {
    return query(
      "getAllBookingCreatedByHotline",
      BookingsAction.getAllBookingByHotline,
      payload
    );
  },

  getAllRequestByHotline: (payload) => {
    return query(
      "getAllTaxiRequestBooking",
      BookingsAction.getAllTaxiRequestBooking,
      payload
    );
  },

  getOneBooking: (payload) => {
    return query("getOneTrip", BookingsAction.getOneTrip, payload);
  },

  getOnePartnerBooking: (payload) => {
    return query(
      "getOnePartnerTrip",
      BookingsAction.getOnePartnerTrip,
      payload
    );
  },

  getAllActivityHistory: (payload) => {
    return query(
      "getAllActivityHistory",
      BookingsAction.getAllActivityHistory,
      payload
    );
  },

  getConfigBookingDistance: () => {
    return query(
      "getConfigBookingDistance",
      BookingsAction.getConfigBookingDistance
    );
  },

  createTripForSwitchboard: (payload) => {
    return mutate(
      "createTripForSwitchboard",
      BookingsAction.createTripForSwitchboard,
      payload
    );
  },

  createTaxiRequestBooking: (payload) => {
    return mutate(
      "createTaxiRequestBooking",
      BookingsAction.createTaxiRequestBooking,
      payload
    );
  },

  cancelBookingByHotline: (payload) => {
    return mutate("cancelTrip", BookingsAction.cancelBookingByHotline, payload);
  },

  cancelRequest: (payload) => {
    return mutate(
      "cancelTaxiRequestBooking",
      BookingsAction.cancelRequest,
      payload
    );
  },

  validateCoordinate: (payload) => {
    return mutate(
      "validateCoordinate",
      BookingsAction.validateCoordinate,
      payload
    );
  },

  checkCarSolution: (payload) => {
    return mutate("checkCarSolution", BookingsAction.checkCarSolution, payload);
  },
};
