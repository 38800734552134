import { Label } from "reactstrap";
import toast from "react-hot-toast";
import React, { useState } from "react";
import { Activity, OctagonAlert } from "lucide-react";

import EthSvg from "../components/eth-svg";
import StepEthStaking from "../components/step-stake";
import ButtonSystem from "components/e-control/button-system";
import InputNumberSystem from "components/e-control/forms/form-elements/input-number-system/input-number-system";

const PageEthStake = () => {
  const [amount, setAmount] = useState("");

  const handleContinue = () => {
    toast("Tính năng đang phát triển", {
      icon: "📣",
      style: {
        borderRadius: "10px",
        color: "#0b823c",
      },
    });
  };

  const handleSetMaxAmount = () => {
    setAmount(0);
  };

  return (
    <div className="max-w-[900px] m-auto bg-white min-h-[100vh] p-3">
      <div>
        <Label className="text-primary text-base font-medium">
          Số tiền staking
        </Label>

        <div className="relative">
          <InputNumberSystem
            value={amount}
            className="[&>div>input]:bg-white [&>div>input]:text-[14px] text-blue-950"
            placeholder="Tối thiểu 0,00001"
            onChange={(value) => {
              setAmount(value);
            }}
          />

          <div className="flex items-center gap-x-2 font-medium absolute top-1/2 right-3 -translate-y-1/2">
            <span>USDT</span>

            <span
              className="cursor-pointer text-primary"
              onClick={handleSetMaxAmount}
            >
              Tối đa
            </span>
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center mt-2 text-sm">
        <p>Số dư khả dụng</p>

        <p className="font-medium">0 USDT</p>
      </div>

      <div className="mt-4 p-3 border border-gray-200 rounded-lg flex flex-col gap-2">
        <div className="flex items-center justify-between">
          <p>Nhận</p>

          <div className="flex items-center gap-x-1">
            <EthSvg />

            <span className="font-medium">0 WBUSDT</span>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <p>Tỉ lệ chuyển đổi</p>

          <div className="flex items-center gap-x-1">
            <span className="font-medium">1 USDT ~ 1.0489093 WBUSDT</span>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <p className="flex items-center gap-x-1">
            APR tham khảo
            <Activity size={12} color="#29ab68" />
          </p>

          <div className="flex items-center gap-x-1">
            <span className="font-medium text-primary">2.74%</span>
          </div>
        </div>

        <div className="border border-gray-100" />

        <div className="flex items-center justify-between">
          <p className="flex items-center gap-x-1">Ước tính phần thưởng</p>

          <div className="flex items-center gap-x-1">
            <span className="font-medium text-primary">0 USDT</span>
          </div>
        </div>
      </div>

      <div className="p-3 rounded-lg bg-[#6e491a28] flex gap-x-2 mt-3">
        <div className="flex-shrink-0 mt-[2px]">
          <OctagonAlert size={16} />
        </div>

        <p className="text-sm">
          Khi stake 1 USDT, bạn nhận được ít hơn 1 WBUSDT vì WBUSDT có giá trị
          cao hơn. Giá trị của WBUSDT tăng theo thời gian vì phần thưởng staking
          tích luỹ sau khi ra mắt. Sau khi rút thưởng, bạn sẽ nhận lại USDT ban
          đầu và phần thưởng.
        </p>
      </div>

      <div className="mt-8">
        <h3 className="text-[#000] text-base font-semibold underline">
          Lịch trình
        </h3>

        <StepEthStaking />
      </div>

      <div className="mt-4 fixed bottom-0 left-0 right-0 max-w-[500px] m-auto p-3 pb-6 z-50 bg-white">
        <ButtonSystem
          disabled={!amount}
          className="block w-full"
          color="primary"
          onClick={handleContinue}
        >
          Tiếp
        </ButtonSystem>
      </div>
    </div>
  );
};

export default PageEthStake;
