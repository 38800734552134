import { getAllDriverByWorkplace } from "modules/ego-system/gg-map/stores";
import { useEffect, useState } from "react";
import { dispatch } from "root-stores";
import DriverAction from "./components/driver-action";
import DriverList from "./components/driver-list";

import { useSelector } from "react-redux";
import testDriverApis from "./apis";
import "./index.scss";

const TestMap = () => {
  const [driver, setDriver] = useState(null);
  const [loginDrivers, setLoginDrivers] = useState({});
  const [driverLocations, setDriverLocations] = useState({});

  const loginDriver = (token) => {
    setLoginDrivers((prevDriver) => {
      if (token) {
        return { ...prevDriver, [driver.id]: token };
      }

      return prevDriver;
    });
  };

  const deactiveDriver = (driverId) => {
    setDriverLocations((prevLocation) => {
      if (!prevLocation[driverId]) {
        return prevLocation;
      }

      const locations = { ...prevLocation };
      delete locations?.[driverId];

      return locations;
    });

    setLoginDrivers((prevDriver) => {
      if (!prevDriver[driverId]) {
        return prevDriver;
      }

      const locations = { ...prevDriver };
      delete locations?.[driverId];

      return locations;
    });
  };

  const updateLocationDriver = (locations) => {
    setDriverLocations((prevLocation) => {
      if (locations) {
        return { ...prevLocation, [driver.id]: locations };
      }

      return prevLocation;
    });
  };

  const updateLocation = async (location, token) => {
    const response = await testDriverApis.updateLocation(
      { point: location },
      token
    );

    console.log(response);
  };

  const {
    bookingAction,
  } = useSelector((state) => state.map);

  console.log('bookingAction', bookingAction)

  useEffect(() => {
    let int = 0;
    let interval = null;
    const drivers = Object.keys(driverLocations);

    const update = () => {
      console.log();
      for (let value of drivers) {
        console.log(int);
        console.log(value);
        if (!driverLocations[value]?.[int]) {
          clearInterval(interval);
          continue;
        }

        updateLocation(driverLocations[value]?.[int], loginDrivers[value]);
      }
    };

    if (drivers?.length) {
      interval = setInterval(() => {
        int++;
        update();
      }, 1000);
    }

    return () => {
      clearInterval(interval);
      int = 0;
    };
  }, [driverLocations, loginDrivers]);

  useEffect(() => {
    dispatch(getAllDriverByWorkplace());
  }, []);
  return (
    <div className="test-wrapper">
      <div className="test__section">
        <DriverList
          driverId={driver?.id}
          onClick={(driver) => setDriver(driver)}
        />
      </div>

      <div className="test__section">
        <DriverAction
          driver={driver}
          loginDrivers={loginDrivers}
          login={loginDriver}
          deactiveDriver={deactiveDriver}
          updateLocationDriver={updateLocationDriver}
        />
      </div>
    </div>
  );
};

export default TestMap;
