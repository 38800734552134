import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { driverApi, driverPartnerApi } from "../apis";
import { toast } from "react-hot-toast";
import { formatPhoneNumberToServer } from "utils";

export const getAllDriversPartnerNoPagination = createAsyncThunk(
  "/driver/getAllDriversPartnerNoPagination",
  async (query) => {
    try {
      const response = await driverPartnerApi.getAllPartnerDriver(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

// export const getAllBookingByDrivers = createAsyncThunk(
//   "/driver/getAllBookingByDrivers",
//   async (query) => {
//     try {
//       const response = await driverApi.getAllBooking(query);

//       return response;
//     } catch (error) {
//       toast.error(error?.message);
//       throw new Error(error);
//     }
//   }
// );

// export const getStatBookingByDrivers = createAsyncThunk(
//   "/driver/getStatBookingByDrivers",
//   async (query) => {
//     try {
//       const response = await driverApi.getStatBookingByDriver(query);

//       return response;
//     } catch (error) {
//       toast.error(error?.message);
//       throw new Error(error);
//     }
//   }
// );

// export const getDriverDetail = createAsyncThunk(
//   "/driver/getOneDrivers",
//   async (query) => {
//     try {
//       const response = await driverApi.getOneUser(query);

//       return response;
//     } catch (error) {
//       toast.error(error?.message);
//       throw new Error(error);
//     }
//   }
// );

// export const getAllCarWOPaging = createAsyncThunk(
//   "/driver/getAllCars",
//   async (query) => {
//     try {
//       const response = await driverApi.getAllCarWOPaging(query);

//       return response;
//     } catch (error) {
//       toast.error(error?.message);
//       throw new Error(error);
//     }
//   }
// );

export const createNewPartnerDriver = async (query) => {
  try {
    const payload = {
      data: { ...query, phone: formatPhoneNumberToServer(query?.phone), }
    };

    await driverPartnerApi.createPartnerDriver(payload);
  } catch (error) {
    throw new Error(error);
  }
};

export const driverSlice = createSlice({
  name: "driverPartner",
  initialState: {
    drivers: {
      list: [],
      total: 0,
      countDrivers: 0,
      countActiveDrivers: 0,
      countInactiveDrivers: 0,
    },

    driverDetail: {},

    bookings: {
      list: [],
      total: 0,
    },

    bills: {
      list: [],
      total: 0,
      stats: {},
    },

    cars: {
      list: [],
    },
  },

  reducers: {
    setDefaultCarList: (state, { payload }) => {
      state.cars.list = [];
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      getAllDriversPartnerNoPagination.fulfilled,
      (state, { payload }) => {
        state.drivers.list = payload?.data || [];
        state.drivers.total = payload?.total || 0;
      }
    );
  },
});

export const { setDefaultCarList } = driverSlice.actions;

export default driverSlice.reducer;
