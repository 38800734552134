import { createAsyncThunk } from "@reduxjs/toolkit";
import { supplierApi } from "modules/shop/supplier/apis";
import { toast } from "react-hot-toast";

export const getAllShopSupplier = createAsyncThunk(
  "/supplier/getAllShopSupplier",
  async (query) => {
    try {
      const response = await supplierApi.getAllShopSupplier(query);
      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getAllShopSupplierActive = createAsyncThunk(
  "/supplier/getAllShopSupplierActive",
  async (partnerId) => {
    try {
      const response = await supplierApi.getAllShopSupplier({
        q: {
          page: 1,
          limit: 500,
        },
    
        partnerId,
      });
      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const supplier = {
  list: [],
  total: 0,
  actives: [],
};

export const supplierBuilder = (builder) => {
  builder.addCase(getAllShopSupplier.fulfilled, (state, { payload }) => {
    state.supplier.list = payload?.data || [];
    state.supplier.total = payload?.total || 0;
  });
  builder.addCase(getAllShopSupplierActive.fulfilled, (state, { payload }) => {
    state.supplier.actives = payload?.data || [];
  });
};
