import classNames from "classnames";
import InputNumberSystem from "components/e-control/forms/form-elements/input-number-system/input-number-system";
import FormDrawer from "components/utils/form-drawer";
import { useEffect } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { FormGroup, Label } from "reactstrap";


const UpdateOrderForm = (props) => {
  const { onSubmit, order, onClose, ...drawerProps } = props;

  const initQty = order?.quantity;

  const pricePerStock = order?.investmentProject?.pricePerStock || 1;

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      options: 1,
      quantity: 0,
    },
  });

  const {
    control,
    formState: { errors },
  } = form;

  const options = useWatch({ control, name: "options" });
  const quantity = useWatch({ control, name: "quantity" });
  const amount = useWatch({ control, name: "amount" });

  useEffect(() => {
    // if (options === 1 && !!quantity) {
    //   form.setValue("amount", quantity * pricePerStock);
    // }
    // if (options === 2 && !!amount) {
    //   form.setValue("quantity", Math.round(amount / pricePerStock));
    // }

    form.setValue("quantity", amount / pricePerStock);
  }, [options, quantity, amount]);

  return (
    <FormDrawer
      form={form}
      onClose={onClose}
      onSubmit={onSubmit}
      {...drawerProps}
    >
      <FormGroup>
        <Label>Số cổ phiếu đăng ký ban đầu</Label>
        <Controller
          name="_"
          control={control}
          render={() => (
            <InputNumberSystem
              value={initQty}
              readOnly
              className={classNames("price-input-box", {
                "is-invalid": errors.bookingAmount && true,
              })}
            />
          )}
        />
      </FormGroup>

      <FormGroup>
        <Label>Đơn giá</Label>
        <Controller
          name="_"
          control={control}
          render={() => (
            <InputNumberSystem
              value={pricePerStock}
              readOnly
              className={classNames("price-input-box", {
                "is-invalid": errors.bookingAmount && true,
              })}
            />
          )}
        />
      </FormGroup>

      {/* <FormGroup>
        <Label>Loại cập nhật</Label>
        <Controller
          name="options"
          control={control}
          render={({ field }) => (
            <RadioGroupSystem
              {...field}
              options={[
                {
                  label: "Cập nhật số lượng",
                  value: 1,
                },
                {
                  label: "Cập nhật số tiền",
                  value: 2,
                },
              ]}
            />
          )}
        />
      </FormGroup> */}

      <FormGroup>
        <Label>Số tiền nhận được</Label>
        <Controller
          name="amount"
          control={control}
          render={({ field }) => (
            <InputNumberSystem
              {...field}
              //   readOnly={options === 1}s
              className={classNames("price-input-box", {
                "is-invalid": errors.amount && true,
              })}
            />
          )}
          rules={{
            required: "Vui lòng nhập số tiền nhận được",
          }}
        />
      </FormGroup>

      <FormGroup>
        <Label>Số cổ phiếu cập nhật</Label>
        <Controller
          name="quantity"
          control={control}
          render={({ field }) => (
            <InputNumberSystem
              {...field}
              //   readOnly={options === 2}
              //   disabled={options === 2}
              readOnly
              className={classNames("price-input-box", {
                "is-invalid": errors.bookingAmount && true,
              })}
            />
          )}
        />
      </FormGroup>
    </FormDrawer>
  );
};

export default UpdateOrderForm;
