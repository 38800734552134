import { createSlice } from '@reduxjs/toolkit';

const device = createSlice({
    name: 'device',
    initialState: {
        deviceType: null,
        isIpad: false,
        isIphone: false,
        isTabletDevice: false,
        isMobileDevice: false,
        isDesktopDevice: false,
    },
    reducers: {
        setDevice: (state, { payload }) => {
            state.deviceType = payload.deviceType
            state.isIpad = payload.isIpad
            state.isIphone = payload.isIphone
            state.isTabletDevice = payload.isTabletDevice
            state.isMobileDevice = payload.isMobileDevice
            state.isDesktopDevice = payload.isDesktopDevice
        },
    },
});

export const {
    actions: {
        setDevice,
    },
    reducer,
} = device;

export default reducer;