import { gql } from "@apollo/client";

export const submitInviteCode = gql`
  mutation submitInviteCode($inviteCode: String!) {
    submitInviteCode(inviteCode: $inviteCode)
  }
`;

export const claimEgoToken = gql`
  mutation claimEgoToken {
    claimEgoToken
  }
`;
