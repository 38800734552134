import { gql } from "@apollo/client";
import { mutate } from "configs/http-config";

export const testDriver = {
    updateDriver: gql`
    mutation updateDriverForAdmin($userId: ID!, $point: [Float], $isActived: Boolean) {
        updateDriverForAdmin(userId: $userId, point: $point, isActived: $isActived) {
            id
            name
            phone
            location
            avatar
            isActived
            carId
            car {
                id
                carCode
                status
            }
      }
    }
  `,

    subDriverStatusUpdate: gql`
    subscription DriverStatusUpdated($driverIds: [ID]!) {
        driverStatusUpdate(driverIds: $driverIds) {
            driverId
            isActived
        }
    }
    
  `
}

export const mapActions = {
    subDriverStatusUpdate: gql`
        subscription DriverStatusUpdated($driverIds: [ID]!, $type: String!) {
            driverStatusUpdate(driverIds: $driverIds, type: $type) {
                driverId
                isActived
                status
            }
        }
    `,

    subDriverLocationUpdate: gql`
        subscription DriverPositionUpdated ($driverIds: [ID]!) {
            driverPositionUpdated(driverIds: $driverIds) {
                driverId
                location
            }
        }
    `,

    subBooking: gql`
        subscription newBookingTrip($workplaceIds: [ID]!) {
            newBookingTrip(workplaceIds: $workplaceIds) {
                id
                startPoint
                endPoint
                startAddress
                endAddress
                travelTime
                distance
                waitTime
                status
                dispatchId
                finalCost
                discountAmount
                driver {
                    tripIds
                    status
                }
                user {
                    username
                    avatar
                    name
                }
            }
        }
    `,

    BookingUpdate: gql`
        subscription newUpdateTrip4Admin($workplaceIds: [ID]!) {
            newUpdateTrip4Admin(workplaceIds: $workplaceIds) {
                tripId
                driver
                reasonCancel
                status
                workPlaceId
            }
        }
    `,

    RequestUpdate: gql`
    subscription newUpdateTaxiRequest($workplaceIds: [ID]!) {
        newUpdateTaxiRequest(workplaceIds: $workplaceIds) {
            tripId
            driver
            reasonCancel
            status
            workPlaceId
        }
    }
`,

    createTripDispatch: gql`
        mutation createTripDispatch(
            $driverId: ID!,
            $tripId: ID!,
            $type: String,
        ) {
            createTripDispatch (data: {
                driverId: $driverId
                tripId: $tripId
                type: $type
            }) {
                driverId
                id
                tripId
            }
        }
    `
};

export const mapExpressAction = {
    subNewExpressBooking: gql`
        subscription newBookingExpress($workplaceIds: [ID]!) {
            newBookingExpress(workplaceIds: $workplaceIds) {
                id
                code
                userId
                fromName
                startAddress
                startFullAddress
                startPoint
                fromPhone
                toName
                toPhone
                endAddress
                endFullAddress
                endPoint
                descriptionFrom
                descriptionTo
                note
                cargoVolumn
                typeCargos
                costCOD
                initialCost
                discountAmount
                finalCost
                paymentMethod
                originalImages
                lastImages
                isCOD
                status
                distance
                driverId
                workPlaceId
                countryId
                partnerId
                dispatchId
                dispatchedDriverIds
                isPaid
                driverAction
                clientAction
                clientImages
                startImages
                finishImages
                couponIds
                typeDelivery
                isReceiverPayment
                dispatch {
                    id
                    tripId
                    driverId
                    status
                    reasonForRefusal
                    workPlaceId
                    type
                    taxiRequestId
                    countryId
                    expressId
                }
                createdAt
                updatedAt
                user {
                    id
                    name
                    username
                    phone
                    email
                    createdAt
                    updatedAt
                }
            }
        }
    `,

    subExporessBookingStatus: gql`
        subscription newUpdateBookingExpress4Admin($workplaceIds: [ID]!) {
            newUpdateBookingExpress4Admin(workplaceIds: $workplaceIds) {
                expressId
                status
                driverId
                userID
                driver
                user
                carId
                car
                reasonCancel
                workPlaceId
                dispatchId
                paymentMethod
                distance
                initialCost
                finalCost
                driverAction
                clientAction
            }
        }
    `
}

export const mapApis = {
    updateDriverLocation: (payload) => {
        const variables = {
            isActived: true,
            ...payload,
        }

        return mutate("updateDriverForAdmin", testDriver.updateDriver, variables);
    },

    turnOnDriver: (payload) => {
        const variables = {
            isActived: true,
            ...payload,
        }

        return mutate("updateDriverForAdmin", testDriver.updateDriver, variables);
    },

    turnOffDriver: (payload) => {
        const variables = {
            isActived: false,
            ...payload,
        }

        return mutate("updateDriverForAdmin", testDriver.updateDriver, variables);
    },

    createTripDispatch: (payload) => {
        return mutate('createTripDispatch', mapActions.createTripDispatch, payload);
    }
}