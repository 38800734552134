import ConfirmSystem from "components/e-control/confirm-system";
import { createContext, useContext, useState } from "react";

const ConfirmContext = createContext(null);

const DEFAULT_CONFIRM_CONFIG = {
  title: "Xác nhận",
  messages: [],
  Component: "",
  hasFooter: true,
  onConfirm: () => null,
  okButtonProps: {
    color: "info",
    text: "Đồng ý",
  },

  onCancel: () => null,
  cancelButtonProps: {
    color: "danger",
    text: "Huỷ",
  },
};

const ConfirmContainer = ({ children }) => {
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [confirmConfig, setConfirmConfig] = useState(DEFAULT_CONFIRM_CONFIG);

  const confirmDialog = ({ messages = [], Component = "", options = {} }) => {
    return new Promise((resolve) => {
      setConfirmConfig({
        ...confirmConfig,
        ...options,
        messages,
        Component,
        onConfirm: (data) => resolve(data),
        onCancel: () => resolve(false),
      });

      setOpenConfirmation(true);
    });
  };

  const handleCloseConfirm = () => {
    setOpenConfirmation(false);
    setConfirmConfig(DEFAULT_CONFIRM_CONFIG);
  };

  return (
    <ConfirmContext.Provider
      value={{
        confirmDialog,
        setOpenConfirmation,
      }}
    >
      {children}

      <ConfirmSystem
        config={confirmConfig}
        isOpen={openConfirmation}
        onCloseConfirm={handleCloseConfirm}
      />
    </ConfirmContext.Provider>
  );
};

export const useConfirmContext = () => useContext(ConfirmContext);

export default ConfirmContainer;
