// ** React Imports
import { lazy } from "react";
import { PARTNER_ROUTES } from "router/partner-routes/routes-constants";
import { KEYS } from "utility/permission/key-config";
import HashTagRoutes from "./hashtag";

// ** Constants

// ** Page Component Imports
const AdminManagerPage = lazy(() => import("modules/supper-admin/admin-manager"));

export const SUPPER_ADMIN = {
    ADMIN_ACCOUNT: '/supper-admin/account'
}

const supperAccountRoutes = [
    {
        path: SUPPER_ADMIN.ADMIN_ACCOUNT,
        element: <AdminManagerPage />,
        title: 'Tài khoản Admin',
        meta: {
            permissionKey: KEYS.SUPPER_ADMIN.ACCOUNT,
        }
    },

    ...HashTagRoutes,
];

export default supperAccountRoutes;