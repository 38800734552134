import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { bookingPartnerApi } from "../apis";

export const getAllEgoTaxiBookingsPartner = createAsyncThunk(
  "/driver/getAllEgoTaxiBookingsPartner",
  async (query) => {
    try {
      const response = await bookingPartnerApi.getAllPartnerTrip(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const egoPartnerBookingSlice = createSlice({
  name: "egobookingsPartner",
  initialState: {
    list: [],
    total: 0,
    stats: {},
  },

  extraReducers: (builder) => {
    builder.addCase(
      getAllEgoTaxiBookingsPartner.fulfilled,
      (state, { payload }) => {
        state.list = payload?.data || [];
        state.total = payload?.total || 0;
        state.stats = payload?.getStatBooking || {};
      }
    );
  },
});

// export const {} = driverSlice.actions;

export default egoPartnerBookingSlice.reducer;
