import { Card } from "reactstrap";
import { dispatch } from "root-stores";
import { useSelector } from "react-redux";
import { LoaderCircle } from "lucide-react";
import usePagination from "utility/hooks/usePagination";
import { useCallback, useLayoutEffect, useMemo, useRef } from "react";
import useIntersectionObserver from "utility/hooks/useIntersectionObserver";

import ItemCardRate from "../rate-item";
import { getAllEgoTransaction } from "../../stores";

const TabRateAccumulate = () => {
  const { rate } = useSelector((state) => state.accumulate);
  const { clientRecords, total } = rate;

  const hasData = total > 0;

  const items = useMemo(() => {
    return clientRecords.reduce((arr, item) => [...arr, ...item], []);
  }, [clientRecords]);

  const { page, limit, setPage } = usePagination(total);

  const loadListRate = (page = 1) => {
    const params = {
      page,
      limit,
      filter: { type: "SAVING" },
    };

    dispatch(getAllEgoTransaction(params));
  };

  const isExceedTotal = useMemo(() => {
    return items.length >= total;
  }, [total, items]);

  const endListRef = useRef();
  const scrollRef = useRef();

  const handleLoadMore = useCallback(
    (entries) => {
      entries.forEach((entry) => {
        const loading = endListRef.current.querySelector("div");

        if (!entry.isIntersecting || isExceedTotal) {
          loading.classList.add("hidden");
          loading.classList.remove("animate__animated");
          loading.classList.remove("animate__slideInUp");
        }

        if (entry.isIntersecting && !isExceedTotal) {
          if (loading) {
            loading.classList.remove("hidden");
            loading.classList.add("animate__animated");
            loading.classList.add("animate__slideInUp");
          }

          setPage((prev) => prev + 1);
          loadListRate(page + 1);
        }
      });
    },
    [isExceedTotal, page]
  );

  useIntersectionObserver(endListRef.current, handleLoadMore, {
    root: scrollRef.current?.parentNode,
  });

  useLayoutEffect(() => {
    loadListRate();
  }, []);

  return (
    <div ref={scrollRef} className="max-w-[900px] w-full m-auto pb-4">
      {hasData && (
        <div className="py-3 space-y-4 h-full flex flex-col">
          <div className="flex flex-col gap-y-4 flex-[2] overflow-auto pt-4 pb-8">
            {items?.map((item) => (
              <ItemCardRate key={item.id} item={item} />
            ))}

            <div ref={endListRef}>
              <div className="flex items-center justify-center">
                <LoaderCircle className="text-primary animate-spin" />
              </div>
            </div>
          </div>
        </div>
      )}

      {!hasData && (
        <div className="overflow-x-auto hide-scroll w-full">
          <Card className="mb-0 p-3 flex items-center bg-gray-50 justify-center min-h-[80px]">
            <span className="text-blue-950 text-sm">
              Bạn chưa nắm bắt cơ hội nào
            </span>
          </Card>
        </div>
      )}
    </div>
  );
};

export default TabRateAccumulate;
