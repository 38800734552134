import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { driverApi, expenseApi } from "../apis";
import { toast } from "react-hot-toast";

export const getAllExpense = createAsyncThunk(
    "/expense/getAllExpense",
    async (query) => {
        try {
            const response = await expenseApi.getAllExpense(query);

            return response;
        } catch (error) {
            toast.error(error?.message);
            throw new Error(error);
        }
    }
);

export const getAllExpenseType = createAsyncThunk(
    "/expense/getAllExpenseType",
    async (query) => {
        try {
            const response = await expenseApi.getAllExpenseType(query);

            return response;
        } catch (error) {
            toast.error(error?.message);
            throw new Error(error);
        }
    }
);

export const expensePartnerSlice = createSlice({
    name: "expensePartner",
    initialState: {
        expenses: {
            list: [],
            total: 0,
            totalStatExpense: {}
        },

        expenseType: {
            list: [],
            total: 0
        },


    },

    reducers: {},

    extraReducers: (builder) => {
        builder.addCase(getAllExpense.fulfilled, (state, { payload }) => {
            state.expenses.list = payload?.data || [];
            state.expenses.total = payload?.total || 0;
            state.expenses.totalStatExpense = payload?.getStatExpense || {};
        });

        builder.addCase(
            getAllExpenseType.fulfilled,
            (state, { payload }) => {
                state.expenseType.list = payload?.data || [];
                state.expenseType.total = payload?.total || 0;
            }
        );
    },
});

export const { setDefaultCarList } = expensePartnerSlice.actions;

export default expensePartnerSlice.reducer;
