import { useAuth } from "components/containers/auth";
import ButtonSystem from "components/e-control/button-system";
import TooltipSystem from "components/e-control/tooltip-system";
import { web3Apis } from "modules/wallet-web3/apis";
import { getAllCrypto } from "modules/wallet-web3/stores";
import { useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { dispatch } from "root-stores";
import { formatNumberToAmountKeepEnableDecimal } from "utility/number-utils";
import { hiddenMiddleCharacter } from "utils";

const SendTransferScreen = ({ closePopup }) => {
  const { selectedCoin, sendData } = useSelector((state) => state.web3);
  const { user } = useAuth();
  const { web3Wallet } = user;

  const [isLoading, setIsLoading] = useState(false);

  const amountConvertUsd = sendData.amount * selectedCoin?.price;

  const totalConvertUsd = amountConvertUsd + selectedCoin?.feeConvertToUsdt;

  // const closePopup
  const onSend = async () => {
    const data = {
      amount: sendData.amount,
      address: sendData.address,
      typeCrypto: selectedCoin.symbol.toUpperCase(),
    };

    try {
      setIsLoading(true);
      await web3Apis.sendCrypto({
        ...data,
      });

      dispatch(getAllCrypto());
      toast.success("Transfer success");
      closePopup?.();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="text-center mt-4 mb-8">
        <h2 className="text-2xl font-bold text-black mb-2">
          -{sendData.amount} {selectedCoin?.symbol}
        </h2>
        <p className="text-gray-500 text-lg mb-4">
          &#8776; {formatNumberToAmountKeepEnableDecimal(amountConvertUsd, 2)}
        </p>
      </div>

      <div className="space-y-6">
        <div className="rounded-md p-4 bg-blue-50/50 space-y-6 shadow-sm">
          <div className="flex justify-between">
            <span className="text-gray-600">Asset</span>
            <div className="font-medium flex items-center gap-x-1">
              <img
                src={selectedCoin?.logo}
                className="w-6 h-6 object-cover"
                alt={selectedCoin?.symbol}
              />
              {`${selectedCoin.symbol} - BEP20`}
            </div>
          </div>

          <div className="flex justify-between">
            <span className="text-gray-600">Wallet(Main Wallet)</span>

            <span className="font-medium">
              <TooltipSystem title={web3Wallet?.address}>
                {hiddenMiddleCharacter(web3Wallet?.address ?? "")}
              </TooltipSystem>
            </span>
          </div>

          <div className="flex justify-between">
            <span className="text-gray-600">To</span>

            <span className="font-medium">
              <TooltipSystem title={sendData?.address}>
                {hiddenMiddleCharacter(sendData?.address ?? "")}
              </TooltipSystem>
            </span>
          </div>
        </div>

        <div className="rounded-md p-4 bg-blue-50/50 space-y-6 shadow-sm">
          <div className="flex justify-between">
            <span className="text-gray-600">Network fee</span>

            <div className="text-right">
              <span className="font-medium">
                {formatNumberToAmountKeepEnableDecimal(
                  selectedCoin?.networkFee,
                  0,
                  ""
                )}{" "}
                BNB
              </span>
              <p className="text-[#ccc]">
                &#8776;{" "}
                {formatNumberToAmountKeepEnableDecimal(
                  selectedCoin?.feeConvertToUsdt,
                  2
                )}
              </p>
            </div>
          </div>

          <div className="flex justify-between">
            <span className="text-gray-600">Max Total</span>
            <span className="font-medium">
              {formatNumberToAmountKeepEnableDecimal(totalConvertUsd, 4)}
            </span>
          </div>
        </div>
      </div>

      <div className="fixed bottom-6 left-0 right-0 px-4">
        <ButtonSystem
          loading={isLoading}
          className="w-full !h-12"
          color="primary"
          onClick={onSend}
        >
          Transfer
        </ButtonSystem>
      </div>
    </>
  );
};

export default SendTransferScreen;
