import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "components/containers/auth";

import ButtonSystem from "components/e-control/button-system";
import { Check } from "lucide-react";

const StepKyc = () => {
  const navigate = useNavigate();

  const { user } = useAuth();

  const isKyc = useMemo(() => {
    return user?.eKycStatus !== "NOT_YET_AUTH";
  }, [user]);

  const handleKyc = () => {
    navigate("/webview/kyc-user");
  };

  return (
    <div className="h-[230px]">
      {isKyc ? (
        <>
          <div className="flex justify-center mt-2">
            <div className="p-3  rounded-full inline-block bg-gradient-to-r from-primary to-primary-1">
              <Check size={30} color="#fff" />
            </div>
          </div>

          <h4 className="text-black text-center text-xl mt-5 uppercase">
            Bạn đã eKyc thành công
          </h4>

          <div className="flex justify-center mt-5">
            <div className="font-bold text-white px-5 py-2 inline-block rounded-xl bg-gradient-to-l from-primary to-primary-1">
              +50 $EGO
            </div>
          </div>
        </>
      ) : (
        <>
          <h4 className="text-black text-lg">
            Để nhận
            <span className="font-bold text-primary px-1">+50 $EGO</span>
            bạn cần tham gia eKYC ngay bây giờ.
          </h4>

          <div className="flex justify-center mt-3">
            <ButtonSystem onClick={handleKyc} className="mt-2" color="primary">
              eKyc bây giờ
            </ButtonSystem>
          </div>
        </>
      )}
    </div>
  );
};

export default StepKyc;
