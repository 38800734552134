import { useEffect, useMemo, useState } from "react";
import { StringeeCall, StringeeClient } from "stringee-chat-js-sdk";

import { useConfirmContext } from "components/containers/confirm-container";
import { ROLE } from "constants";
import ModalContentCall from "modules/ego-system/user/components/modal-content-call";
import moment from "moment";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { dispatch } from "root-stores";
import { formatPhoneNumberToShow } from "utils";
import { useHotlineCreateBooking } from "../hotline/context/create-booking-context";
import { setBookingFormAction } from "../hotline/store";
import { stringeeApi } from "./apis";
import CallClientConfirm from "./components/call-confirmation";
import { getStringeeToken, setUserStringee } from "./stores";

const StringClientComponent = () => {
  const [statusCall, setStatusCall] = useState("");
  const [isShowCall, setIsShowCall] = useState(false);
  const [valueCall, setValueCall] = useState({});

  const [startTime, setStartTime] = useState("");
  const [pickUpTime, setPickUpTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const { infoUserStringee, tokenStringee } = useSelector(
    (state) => state.stringee
  );

  const { userData } = useSelector((state) => state.auth);
  // const partnerId = userData?.id;

  const role = userData?.role;

  const { confirmDialog, setOpenConfirmation } = useConfirmContext();
  const { openHotlineBookingCreatorModal } = useHotlineCreateBooking();

  const isReceiveCall = useMemo(() => {
    return role === ROLE.SWITCHBOARD_OPERATOR;
  }, [role]);

  let call;

  const client = new StringeeClient();

  const loadTokenStringee = () => {
    dispatch(getStringeeToken({ userId: userData?.id }));
  };

  const clearInfoUserStringee = () => {
    dispatch(setUserStringee({}));
  };

  const createHistoryCall = async (info) => {
    console.log("create his call :<<", info);

    const customInfo = {
      data: {
        startTime: info?.startTime,
        pickUpTime: info?.pickUpTime,
        endTime: info?.endTime,
        from: info?.from,
        to: info?.to,
        status: info?.status,
        // partnerId: partnerId,
      },
    };

    try {
      const respone = await stringeeApi.createCallHistory(customInfo);

      if (respone?.id) {
        toast.success("Cuộc gọi đã được lưu trên hệ thống!");
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  // all sự kiện call trả về
  function settingCallEvent(call) {
    call.on("addremotestream", function (stream) {
      // Reset srcObject để khắc phục lỗi nhỏ trên Chrome và Edge.
      console.log("addremotestream");
      // setIsShowCall(true);

      // remoteVideo.srcObject = null;
      // remoteVideo.srcObject = stream;
    });

    call.on("addlocalstream", function (stream) {
      // Reset srcObject để khắc phục lỗi nhỏ trên Chrome và Edge.
      console.log("addlocalstream");
      // setIsShowCall(true);
      // localVideo.srcObject = null;
      // localVideo.srcObject = stream;
    });

    call.on("mediastate", function (state) {
      if (state?.code === 1) {
        console.log("mediastate connect:>>", state.reason);
        // setStatusCall("Đã kết nối");
        setStatusCall("Đã bắt máy");

        setOpenConfirmation(false);
        setIsShowCall(true);
      }

      if (state?.code === 2) {
        console.log("mediastate disconnect:>>", state.reason);
        setStatusCall("Ngắt kết nối");
      }

      console.log("mediastate", state);
    });

    call.on("info", function (info) {
      console.log("on info:" + JSON.stringify(info));
    });

    call.on("signalingstate", (state) => {
      // signalingstate
      console.log("signalingstate :>>", state);

      if (state.code === 1) {
        // console.log("User calling  :>>", state);

        setStatusCall("Đang gọi");
        setIsShowCall(true);
        setStartTime(moment());
      }

      if (state.code === 2) {
        // console.log("User ringing  :>>", state);
        setStatusCall("Đang reo chuông");
      }

      if (state.code === 3) {
        // console.log("User đã trả lời  :>>", state);
        setStatusCall("Đã bắt máy");
        setPickUpTime(moment());
      }

      if (state.code === 5) {
        // busy here
        // console.log("User busy :>>", state);
        setStatusCall("Người dùng bận");
        clearInfoUserStringee();
        createHistoryCall({
          startTime: startTime,
          endTime: moment(),
          from: "19005378",
          to: infoUserStringee?.phone,
          status: "REFUSE",
        });

        setTimeout(() => {
          setIsShowCall(false);
        }, [3000]);
      }

      if (state.code === 6) {
        // call ended
        // console.log("handle show screen kết thúc :>>", state);
        setStatusCall("Đã kết thúc");

        if (!isReceiveCall) {
          createHistoryCall({
            startTime: startTime,
            pickUpTime: endTime,
            endTime: moment(),
            from: "19005378",
            to: infoUserStringee?.phone,
            status: "ACCEPT",
          });
        }

        setTimeout(() => {
          setIsShowCall(false);
          openHotlineBookingCreatorModal(false);
          clearInfoUserStringee();
        }, [3000]);
      }
    });
  }

  const checkPhoneCall = async (phone) => {
    try {
      const result = await stringeeApi.checkUserByPhone({
        phoneNumber: phone,
      });

      if (result?.name === null) {
        dispatch(
          setBookingFormAction({
            phoneNumber: formatPhoneNumberToShow(result?.phone),
          })
        );
        openHotlineBookingCreatorModal(true);
      } else {
        dispatch(
          setBookingFormAction({
            clientName: result?.name,
            phoneNumber: formatPhoneNumberToShow(result?.phone),
          })
        );

        openHotlineBookingCreatorModal(true);
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  useEffect(() => {
    if (client && tokenStringee) {
      client.connect(tokenStringee);

      client.on("connect", function () {
        console.log("Đã kết nối", client?.userId);
      });

      // Lắng nghe sự kiện khi khách hàng được xác thực
      client.on("authen", function (res) {
        console.log("Đã xác thực", res);
        if (res?.message === "API_KEY_SID_NOT_FOUND") {
          loadTokenStringee();
        }
      });

      // Lắng nghe sự kiện khi khách hàng ngắt kết nối từ máy chủ Stringee
      client.on("disconnect", function () {
        console.log("Đã ngắt kết nối");
      });

      // Lắng nghe sự kiện khi access_token hết hạn
      client.on("requestnewtoken", function () {
        console.log(
          "Yêu cầu access_token mới; vui lòng lấy access_token mới từ YourServer và gọi client.connect(new_access_token)"
        );
        loadTokenStringee();
        // Vui lòng lấy access_token mới từ YourServer và gọi:
        // client.connect(new_access_token);
      });

      client.on("otherdeviceauthen", (data) => {
        // console.log("other deviceauthen: ", data);
      });

      client.on("otherdevice", (data) => {
        // console.log("otherdevice: ", data);
      });
    }
  }, [client, tokenStringee]);

  useEffect(() => {
    if (client && tokenStringee && isReceiveCall) {
      client.connect(tokenStringee);

      // Nhận cuộc gọi đến
      client.on("incomingcall", async (incomingcall) => {
        let answer = await confirmDialog({
          messages: [
            "Có cuộc gọi từ: " +
              incomingcall?.fromNumber?.replace("84", "0") +
              ", bạn có muốn trả lời không?",
          ],
          options: {
            okButtonProps: {
              color: "info",
              text: "Bắt máy",
            },

            cancelButtonProps: {
              color: "danger",
              text: "Huỷ bỏ",
            },
          },
        });

        console.log("incomingcall :>>", incomingcall);

        // dispatch(
        //   setUserStringee({
        //     phone: `+${incomingcall?.fromNumber}`,
        //   })
        // );

        call = incomingcall;
        settingCallEvent(incomingcall);
        setValueCall(incomingcall);

        if (answer && call) {
          call?.answer(function (res) {
            console.log("answer res", res);
            setOpenConfirmation(false);
            checkPhoneCall(`+${incomingcall?.fromNumber}`);
          });
        }

        if (!answer && call) {
          call?.reject(function (res) {
            console.log("reject res", res);
            setOpenConfirmation(false);
            clearInfoUserStringee();
          });
        }
      });
    }
  }, [tokenStringee, isReceiveCall]);

  function handleMakeCall() {
    let fromNumber = `19005378`; // Số điện thoại của người gọi (người dùng đã xác thực)
    // let toNumber = document.getElementById("phoneNumber")?.value; // Số điện thoại người nhận
    let toNumber = infoUserStringee?.phone?.replace("+", "");
    console.log("toNumber :>>", toNumber);

    // eslint-disable-next-line no-undef
    call = new StringeeCall(client, fromNumber, toNumber, false);
    console.log("call :>>", call);
    settingCallEvent(call);
    setValueCall(call);

    call?.makeCall(function (res) {
      console.log("make call callback: ", res);
      if (res?.message === "NOT_ENOUGH_MONEY") {
        toast.error("Tài khoản không đủ tiền để thực hiện cuộc gọi !");
        clearInfoUserStringee();
      }

      if (res?.message === "FROM_NUMBER_OR_TO_NUMBER_INVALID_FORMAT") {
        toast.error("Format số điện thoại không đúng !");
        clearInfoUserStringee();
      }

      if (res?.message === "FROM_NUMBER_NOT_FOUND") {
        toast.error("Số điện thoại không tồn tại !");
        clearInfoUserStringee();
      }
    });
  }

  function hangupCall() {
    if (valueCall) {
      valueCall?.hangup(function (res) {
        console.log("hangup res", res);
        setIsShowCall(false);
        setOpenConfirmation(false);
        clearInfoUserStringee();
      });
    }
  }

  const confirmCall = async () => {
    const result = await confirmDialog({
      Component: CallClientConfirm,
      options: {
        componentProps: {
          client: infoUserStringee,
        },

        okButtonProps: {
          color: "info",
          text: "Gọi",
        },

        cancelButtonProps: {
          color: "danger",
          text: "Huỷ bỏ",
        },
      },
    });

    if (result) {
      handleMakeCall();
    } else {
      clearInfoUserStringee();
    }
  };

  useEffect(() => {
    if (infoUserStringee?.phone) {
      confirmCall();
    }
  }, [infoUserStringee]);

  useEffect(() => {
    loadTokenStringee();
  }, []);

  return (
    <>
      {/* <span onClick={confirmCall}>
        <Phone color="green" />
      </span> */}

      <ModalContentCall
        clientInfo={infoUserStringee}
        phone={infoUserStringee?.phone}
        statusCall={statusCall}
        isOpen={isShowCall}
        hangupCall={hangupCall}
      />
    </>
  );
};

export default StringClientComponent;
