import MAP_OPTIONS from "modules/ego-system/gg-map/constants"

export class ExpressBookingModel {
    bookings = []

    static mapOneBookingFromApi(booking) {
        return { ...booking, type: MAP_OPTIONS.BOOKING_TYPE.EXPRESS }
    }

    static mapBookingsFromApi(bookings) {
        return bookings.map(booking => ({...booking, type: MAP_OPTIONS.BOOKING_TYPE.EXPRESS }))
    }
}