import { gql } from "@apollo/client";
import { mutate, query } from "configs/http-config";

const connectEgoShema = {
  getAllTripPriceForPartner: gql`
    query getAllTripPriceForPartner(
      $page: Int
      $limit: Int
      $filter: Mixed
      $order: Mixed
      $search: String
    ) {
      getAllTripPriceForPartner(
        q: {
          limit: $limit
          page: $page
          filter: $filter
          order: $order
          search: $search
        }
      ) {
        data {
          id
          pricePerKm

          workPlace {
            id
            name
          }
          carType {
            id
            name
            type
          }

          createdAt
          updatedAt
        }
        total
      }
    }
  `,

  getTripPriceForPartner: gql`
    query getTripPriceForPartner($workplaceId: ID!, $carTypeIds: [ID]!) {
      getTripPriceForPartner(
        workPlaceId: $workplaceId
        carTypeIds: $carTypeIds
      ) {
        carType {
          id
          name
        }

        pricePerKm
      }
    }
  `,

  getAllPriceChangeHistory: gql`
    query getAllPriceChangeHistory(
      $page: Int
      $limit: Int
      $filter: Mixed
      $order: Mixed
      $search: String
    ) {
      getAllPriceChangeHistory(
        q: {
          limit: $limit
          page: $page
          filter: $filter
          order: $order
          search: $search
        }
      ) {
        data {
          id
          workPlace {
            id
            name
          }
          carType {
            id
            name
            type
          }

          type
          oldPrice
          newPrice
          priceChange

          createdAt
          updatedAt
        }
        total
      }
    }
  `,

  createTripPriceForPartner: gql`
    mutation createTripPriceForPartner($data: CreateTripPriceInput!) {
      createTripPriceForPartner(data: $data) {
        id
      }
    }
  `,

  updateTripPrice: gql`
    mutation updateTripPrice($id: ID!, $data: UpdateTripPriceInput!) {
      updateTripPrice(id: $id, data: $data) {
        id
      }
    }
  `,

  deleteOneTripPrice: gql`
    mutation deleteOneTripPrice($id: ID!) {
      deleteOneTripPrice(id: $id) {
        id
      }
    }
  `,

  getAllRentCarPartnerCarWOPaging: gql`
    query getAllPartnerRentCarWOPaging(
      $category: String
      $carTypeId: ID
      $carCompany: String
    ) {
      getAllPartnerRentCarWOPaging(
        category: $category
        carTypeId: $carTypeId
        carCompany: $carCompany
      ) {
        id
        isConnectedEgo
        carCode
      }
    }
  `,

  getAllCarTypeWOPagingForPartner: gql`
    query getAllCarTypeWOPagingForPartner(
      $category: String
      $businessType: String
      $partnerId: ID!
    ) {
      getAllCarTypeWOPagingForPartner(
        category: $category
        businessType: $businessType
        partnerId: $partnerId
      ) {
        id
        type
        name
      }
    }
  `,

  getAllPartnerCarWOPaging: gql`
    query getAllPartnerCarWOPaging(
      $category: String
      $carTypeId: ID
      $carCompany: String
    ) {
      getAllPartnerCarWOPaging(
        category: $category
        carTypeId: $carTypeId
        carCompany: $carCompany
      ) {
        id
        businessType
        isConnectedEgo
        carCode
        carCompany
        carType {
          id
          isActived
          name
        }
        createdAt
        updatedAt
      }
    }
  `,

  getAllCarInfo: gql`
    query getAllCarInfo(
      $page: Int
      $limit: Int
      $filter: Mixed
      $order: Mixed
      $search: String
    ) {
      getAllCarInfo(
        q: {
          limit: $limit
          page: $page
          filter: $filter
          order: $order
          search: $search
        }
      ) {
        data {
          id
          carCompanies
          colors
          manufacturingYears
          type
        }
        total
      }
    }
  `,

  getAllPartnerCar: gql`
    query getAllPartnerCar(
      $page: Int
      $limit: Int
      $filter: Mixed
      $order: Mixed
      $search: String
      $partnerId: ID!
    ) {
      getAllPartnerCar(
        q: {
          limit: $limit
          page: $page
          filter: $filter
          order: $order
          search: $search
        }
        partnerId: $partnerId
      ) {
        data {
          id
          carCode
          licensePlateNumber
          images
          status
          carTypeId
          partnerId
          isConnectedEgo
          carCompany
          driverId
          color
          manufacturingYear
          businessTypes
          fuelType
          category
          price
          driver {
            id
            name
            phone
          }
          carType {
            id
            name
            type
          }
          partner {
            id
            phone
            email
            address
            avatar
          }

          workPlace {
            id
            name
          }
          createdAt
          updatedAt
        }
        total
      }
    }
  `,

  connectCarToEgo: gql`
    mutation connectCarToEgo($carId: ID!, $workPlaceId: ID!) {
      connectCarToEgo(carId: $carId, workPlaceId: $workPlaceId)
    }
  `,

  disConnectCarToEgo: gql`
    mutation disconnectCarToEgo($carId: ID!) {
      disconnectCarToEgo(carId: $carId)
    }
  `,
};

export const connectEgoPartnerApi = {
  getRentCarPartner: (payload) => {
    return query(
      "getAllPartnerRentCarWOPaging",
      connectEgoShema.getAllRentCarPartnerCarWOPaging,
      payload
    );
  },

  getAllPriceChangeHistory: (payload) => {
    return query(
      "getAllPriceChangeHistory",
      connectEgoShema.getAllPriceChangeHistory,
      payload
    );
  },

  getAllTripPriceForPartner: (payload) => {
    return query(
      "getAllTripPriceForPartner",
      connectEgoShema.getAllTripPriceForPartner,
      payload
    );
  },

  createTripPriceForPartner: (payload) => {
    return mutate(
      "createTripPriceForPartner",
      connectEgoShema.createTripPriceForPartner,
      payload
    );
  },

  updateTripPrice: (payload) => {
    return mutate("updateTripPrice", connectEgoShema.updateTripPrice, payload);
  },

  deleteOneTripPrice: (payload) => {
    return mutate(
      "deleteOneTripPrice",
      connectEgoShema.deleteOneTripPrice,
      payload
    );
  },

  getAllPartnerCarWOPaging: (payload) => {
    return query(
      "getAllPartnerCarWOPaging",
      connectEgoShema.getAllPartnerCarWOPaging,
      payload
    );
  },

  getAllCarTypeWOPagingForPartner: (payload) => {
    return query(
      "getAllCarTypeWOPagingForPartner",
      connectEgoShema.getAllCarTypeWOPagingForPartner,
      payload
    );
  },

  getAllCarInfo: (payload) => {
    return query("getAllCarInfo", connectEgoShema.getAllCarInfo, payload);
  },

  getAllPartnerCar: (payload) => {
    return query("getAllPartnerCar", connectEgoShema.getAllPartnerCar, payload);
  },

  connectEgoSystem: (payload) => {
    console.log("payload", payload);
    return mutate("connectCarToEgo", connectEgoShema.connectCarToEgo, payload);
  },

  disconnectEgoSystem: (payload) => {
    return mutate(
      "disConnectCarToEgo",
      connectEgoShema.disConnectCarToEgo,
      payload
    );
  },

  getPriceForPartner: (payload) => {
    return query(
      "getTripPriceForPartner",
      connectEgoShema.getTripPriceForPartner,
      payload
    );
  },
};
