/* eslint-disable multiline-ternary */
import React, { useEffect, useMemo, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import "./index.scss";
import { PhoneOff, User } from "react-feather";
import Avatar from "components/controls/avatar";
import { formatPhoneNumberToShow } from "utils";
import AvatarSystem from "components/controls/avatar-system";
import { useSelector } from "react-redux";

const ModalContentCall = ({ phone, statusCall = "", isOpen, hangupCall }) => {
  const [count, setCount] = useState(0);

  const { infoUserStringee } = useSelector((state) => state.stringee);


  useEffect(() => {
    if (statusCall === "Đã bắt máy") {
      let myInterval = setTimeout(() => {
        setCount((prevState) => prevState + 1);
        return;
      }, 1000);

      return () => clearTimeout(myInterval);
    } else {
      setCount(0);
      return;
    }
  }, [count, statusCall]);

  const isAnswered = useMemo(() => {
    return statusCall === "Đã bắt máy";
  }, [statusCall]);

  const handleHangUp = () => {
    hangupCall?.();
    setCount(0);
  };

  return (
    <Modal isOpen={isOpen} centered size={"sm"} className="custom-modal-call">
      <ModalBody>
        <div className="modal-content-call-user flex justify-center">
          {/* <User /> */}
          <AvatarSystem
            imgClass="avatar-content-call"
            srcImg={infoUserStringee?.avatar}
            name={infoUserStringee?.name}
          />
        </div>

        <div className="modal-content-call-phone">
          {infoUserStringee?.name || formatPhoneNumberToShow(phone)}
        </div>

        {isAnswered && (
          <div className="modal-content-call-title">
            {`${
              // eslint-disable-next-line multiline-ternary
              Math.floor(count / 60) < 10
                ? `0${Math.floor(count / 60)}`
                : Math.floor(count / 60)
            }`}
            :
            {`${
              Math.floor(count % 60) < 10
                ? `0${Math.floor(count % 60)}`
                : Math.floor(count % 60)
            }`}
          </div>
        )}

        {!isAnswered && (
          <div className="modal-content-call-title">{statusCall}...</div>
        )}

        <div className="text-center mt-3">
          <div
            className="inline-flex justify-center bg-[red] p-2 rounded-full"
            onClick={handleHangUp}
          >
            <span>
              <PhoneOff color="white" size={20} />
            </span>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalContentCall;
