import { useLayoutEffect, useRef } from 'react';
import './index.scss';

const WelcomePreLoading = () => {

    const containerRef = useRef();

    useLayoutEffect(() => {

    }, [])

    return (
        <div ref={containerRef} className="preloader">
            <div className="preloader-top bg-background fixed top-0 left-0 right-0" />
            <div className="preloader-bottom bg-background fixed left-0 right-0 bottom-0" />
        </div>
    )
}

export default WelcomePreLoading;