// ** React Imports
import { lazy } from 'react'

const Login = lazy(() => import('modules/authentication/components/login'))
const ForgotPassword = lazy(() => import('modules/authentication/components/forgot-password'))

export const AUTH_ROUTES = {
    LOGIN: '/login',
    FORGOT_PASSWORD: '/forgot-password'
}

const AuthenticationRoutes = [
  {
    path: AUTH_ROUTES.LOGIN,
    element: <Login />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },

  {
    path: AUTH_ROUTES.FORGOT_PASSWORD,
    element: <ForgotPassword />,
    layout: 'BlankLayout',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  }
]

export default AuthenticationRoutes
