import { gql } from "@apollo/client";
import { mutate, query } from "configs/http-config";

const action = {
  getStringeeToken: gql`
    query getStringeeToken($userId: ID!) {
      getStringeeToken(userId: $userId)
    }
  `,

  getAllCallHistory: gql`
    query getAllCallHistory(
      $page: Int
      $limit: Int
      $search: String
      $filter: Mixed
    ) {
      getAllCallHistory(
        q: {
          limit: $limit
          page: $page
          search: $search
          filter: $filter
          order: { createdAt: -1 }
        }
      ) {
        data {
          id
          from
          to
          startTime
          endTime
          pickUpTime
          status
          time
        }
        total
      }
    }
  `,

  checkUserByPhone: gql`
    mutation checkUserByPhone($phoneNumber: String!) {
      checkUserByPhone(phoneNumber: $phoneNumber) {
        id
        name
        phone
      }
    }
  `,

  createCallHistory: gql`
    mutation createCallHistory($data: CreateCallHistoryInput!) {
      createCallHistory(data: $data) {
        id
        time
        status
      }
    }
  `,
};

export const stringeeApi = {
  getStringeeToken: (payload) => {
    return query("getStringeeToken", action.getStringeeToken, payload);
  },

  getAllCallHistory: (payload) => {
    return query("getAllCallHistory", action.getAllCallHistory, payload);
  },

  checkUserByPhone: (payload) => {
    return mutate("checkUserByPhone", action.checkUserByPhone, payload);
  },

  createCallHistory: (payload) => {
    return mutate("createCallHistory", action.createCallHistory, payload);
  },
};
