import { gql } from "@apollo/client";

export const BookingFragment = gql`
    fragment BookingInfo on Trip {
        id
        code
        note
        status
        userId
        endPoint
        driverId
        distance
        waitTime
        couponIds
        finalCost
        createdAt
        updatedAt
        startPoint
        endAddress
        travelTime
        initialCost
        startAddress
        paymentMethod
        discountAmount
        driverPickupTime
        driverDropOffTime
    }
`

export const RequestFragment = gql`
    fragment BookingInfo on TaxiRequestBooking {
        id
        name
        note
        phone
        status
        tripId
        endPoint
        driverId
        createdAt
        updatedAt
        startPoint
        endAddress
        workPlaceId
        dispatchIds
        startAddress
        endFullAddress
        startFullAddress
        dispatchedDriverIds
    }
`

