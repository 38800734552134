import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import { stockApi } from "../apis";

export const getAllInventory = createAsyncThunk(
    "/product/getAllInventory",
    async (query) => {
        try {
            const response = await stockApi.getAllInventory(query);

            return response
        } catch (error) {
            toast.error(error?.message);
            throw new Error(error);
        }
    }
)

export const productSlice = createSlice({
    name: "stock",
    initialState: {
        stocks: {
            list: [],
            total: 0,
        }
    },

    extraReducers: (builder) => {
        builder.addCase(getAllInventory.fulfilled, (state, {payload}) => {
            state.stocks.list = payload?.data || [];
            state.stocks.total = payload?.total || 0
        })
    }
})

export default productSlice.reducer;
