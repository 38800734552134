import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DATE_TIME_FORMAT } from "constants";
import moment from "moment";
import { toast } from "react-hot-toast";
import { InvoiceApi } from "../apis";

export const defaultFilter = {
  dateRange: [
    moment().startOf("month").format(DATE_TIME_FORMAT.YMD_SNAKE),
    moment().format(DATE_TIME_FORMAT.YMD_SNAKE),
  ],
  sort: "",
  activeWorkPlace: "",
  businessTypes: "TAXI",
};

export const getStatAllBookings = createAsyncThunk(
  "/driver/getStatAllBookings",
  async (query) => {
    try {
      const response = await InvoiceApi.getStatAllBooking(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const InvoiceSlice = createSlice({
  name: "invoices",
  initialState: {
    list: [],
    total: 10,
    stats: {},
    filter: defaultFilter,
  },

  reducers: {
    setFilter: (state, { payload }) => {
      state.filter = payload;
    }
  },

  extraReducers: (builder) => {
    builder.addCase(getStatAllBookings.fulfilled, (state, { payload }) => {
      state.list = payload?.data || [];
      state.total = payload?.total || 0;
      state.stats = payload?.getStatRevenue || {};
    });
  },
});

export const { setFilter } = InvoiceSlice.actions;

export default InvoiceSlice.reducer;
