import { mutate, query } from "configs/http-config";
import { modifierActions, modifierGroupActions } from "./schema";

export const modifierApis = {
    // modifier group
    getAllModifierGroup: (params) => {
        return query('getAllShopOptionGroup', modifierGroupActions.loadModifierGroup, { q: params });
    },

    createModifierGroup: (payload) => {
        return mutate('createShopOptionGroup', modifierGroupActions.createModifierGroup, { data: payload });
    },

    updateModifierGroup: ({ id, ...payload }) => {
        return mutate('updateShopOptionGroup', modifierGroupActions.updateModifierGroup, { updateShopOptionGroupId: id, data: payload });
    },

    deleteModifierGroup: (id) => {
        return mutate('deleteOneShopOptionGroup', modifierGroupActions.deleteModifierGroup, { id });
    },

    // modifier
    getAllModifier: (params) => {
        return query('getAllShopOption', modifierActions.loadModifier, { q: params });
    },

    createModifier: (payload) => {
        return mutate('createShopOption', modifierActions.createModifier, { data: payload });
    },

    updateModifier: ({ id, ...payload }) => {
        return mutate('updateShopOption', modifierActions.updateModifier, { updateShopOptionId: id, data: payload });
    },

    deleteModifier: (id) => {
        return mutate('deleteOneShopOption', modifierActions.deleteModifier, { deleteOneShopOptionId: id });
    },
}