import { ArrowRight, BellRing } from "lucide-react";
import toast from "react-hot-toast";

const toastNotice = (args = {}) => {
    const { icon, title, description, callback, options = {} } = args

    return toast.custom((t) => (
        <div
            className={`${t.visible ? 'animate-enter' : 'animate-leave'} max-w-md w-full bg-background-accent shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
        >
            <div className="flex-1 w-0 p-4">
                <div className="flex items-start">
                    <div className="flex-shrink-0 pt-0.5">
                        <div
                            className="h-10 w-10 rounded-full bg-primary/30 flex items-center justify-center"
                        >
                            {icon || <BellRing className="w-5 h-5 text-primary" />}
                        </div>
                    </div>
                    <div className="ml-3 flex-1">
                        <p className="text-sm font-medium text-primary">
                            {title || 'Notice Title'}
                        </p>
                        <p className="mt-1 text-sm text-secondary">
                            {description || 'Notice description'}
                        </p>
                    </div>
                </div>
            </div>
            {callback && (<div className="flex border-l border-border-color">
                <button
                    onClick={() => {
                        callback?.()
                        toast.dismiss(t.id)
                    }}
                    className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                    <ArrowRight className="w-5 h-5 text-primary" />
                </button>
            </div>)}
        </div>
    ), {
        duration: 2500,
        ...options,
    })
}

export default toastNotice;