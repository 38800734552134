import { mutate, query } from "configs/http-config";
import { productAction } from "./schema";

export const productApi = {
  getAllShopProduct: (payload) => {
    return query("getAllShopProduct", productAction.getAllShopProduct, {
      q: payload,
    });
  },

  getAllShopOrder: (payload) => {
    return query("getAllShopOrder", productAction.getAllShopOrder, {
      q: payload,
    });
  },

  getOneShopOrder: (id) => {
    return query("getOneShopOrder", productAction.getOneShopOrder, { id: id });
  },

  getOneShopProduct: (payload) => {
    return query("getOneShopProduct", productAction.getOneShopProduct, {
      id: payload,
    });
  },

  createShopProduct: (payload) => {
    return mutate(
      "createShopProduct",
      productAction.createShopProduct,
      payload
    );
  },

  updateShopProduct: (payload) => {
    return mutate(
      "updateShopProduct",
      productAction.updateShopProduct,
      payload
    );
  },

  deleteOneShopProduct: (payload) => {
    return mutate(
      "deleteOneShopProduct",
      productAction.deleteOneShopProduct,
      payload
    );
  },
};
