import { createAsyncThunk } from "@reduxjs/toolkit"
import { productTypeApi } from "modules/shop/product-category/apis"

// products category
export const getAllShopProductType = createAsyncThunk(
    "/product/getAllShopProductType",
    async (query) => {
        try {
            const response = await productTypeApi.getAllShopProductType(query)
            return response
        } catch (error) {
            throw new Error(error)
        }
    })

export const getAllActiveProductType = createAsyncThunk(
    "/product/getAllActiveProductType",
    async () => {
        try {
            const response = await productTypeApi.getAllShopProductType({ page: 1, limit: 10000, filter: { isActived: true } })

            return response?.data || []
        } catch (error) {
            throw new Error(error)
        }
    })

export const getOneProductCategory = createAsyncThunk(
    '/product/getOneProductCategory',
    async (id) => {
        try {
            const response = await productTypeApi.getOneProductCategory(id);
            return response;
        } catch (error) {
            console.log(error);
        }
    }
)

export const productCategories = {
    list: [],
    total: 0,
    activeCategories: [],
    categoryAction: null,
}

export const categoryBuilder = (builder) => {
    builder.addCase(getAllShopProductType.fulfilled, (state, { payload }) => {
        state.categories.list = payload?.data || [];
        state.categories.total = payload?.total || 0;
    });

    builder.addCase(getAllActiveProductType.fulfilled, (state, { payload }) => {
        state.categories.activeCategories = payload;
    });

    builder.addCase(getOneProductCategory.fulfilled, (state, { payload }) => {
        state.categories.categoryAction = payload
    })
}