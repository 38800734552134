import BardSystem from "components/e-control/bard-system";
import TableSystem, { TableWrapperContext } from "components/e-control/table";
import { investApi } from "modules/invest/apis";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card } from "reactstrap";
import usePagination from "utility/hooks/usePagination";
import { formatNumberToAmount } from "utility/number-utils";
import InvestAccountTemplate from "./template";

const InvestAccountTableProject = ({ ...props }) => {
    const [investProject, setInvestProject] = useState([])
    const [totalStat, setTotalStat] = useState()
    const [total, setTotal] = useState(0);
    const params = useParams();
    const { id } = params;

    const { page, limit, pagination } = usePagination(total);

    const [loadingDetail, setLoadingDetail] = useState(false);

    const loadDetailInvestmentOrder = async () => {
        try {
            setLoadingDetail(true);
            const params = {
                page,
                limit,
                filter: {
                    userId: id,
                }
            };

            const res = await investApi.getInvestmentOrder(params);
            setInvestProject(res?.data)
            setTotalStat(res?.getStatInvestmentOrder)
            setTotal(res?.total)
        } catch (error) {
            return;
        } finally {
            setLoadingDetail(false);
        }
    };

    useEffect(() => {
        loadDetailInvestmentOrder()
    }, [id])

    const columns = [
        {
            dataIndex: 'code',
            key: 'code',
            title: 'Mã mua',
            fixed: 'left',
            className: 'bg-[#343d55]',
            render: (item) => <span className="whitespace-nowrap">{item}</span>
        },

        {
            dataIndex: 'name',
            key: 'name',
            title: 'Tên dự án',
            width: 300,
            render: (_, row) => <span className="line-clamp-1">{row?.investmentProject?.name}</span>
        },

        {
            dataIndex: 'investmentProject',
            key: 'investmentProject',
            title: 'Kênh đầu tư',
            render: (_, row) => row?.investmentProject?.investmentUnit?.name
        },

        {
            dataIndex: 'quantity',
            key: 'quantity',
            title: 'Số lượng',
            className: 'amount',
            render: (qty) => formatNumberToAmount(qty || 0)
        },

        {
            dataIndex: 'finalCost',
            key: 'finalCost',
            title: 'Giá',
            className: 'amount',
            render: (amount) => formatNumberToAmount(amount || 0)
        },

        {
            dataIndex: 'isPaid',
            key: 'isPaid',
            title: 'Thanh toán',
            className: 'amount',
            render: (isPaid) => isPaid ? <BardSystem type="primary">Hoàn thành</BardSystem> : <BardSystem type="danger">Chưa thanh toán</BardSystem>
        },
    ]

    return (
        <TableWrapperContext>
            <div className="space-y-4 flex flex-col w-full overflow-hidden">
                <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
                    <Card className="mb-0 p-3 space-y-2">
                        <span className="text-sm text-secondary-2">Số lượt đầu tư</span>
                        <span className="font-bold text-md">{totalStat?.totalOrder || 0}</span>
                    </Card>

                    <Card className="mb-0 p-3 space-y-2">
                        <span className="text-sm text-secondary-2">Kênh đầu tư</span>
                        <span className="font-bold text-md">{totalStat?.totalInvestmentUnit || 0}</span>
                    </Card>

                    <Card className="mb-0 p-3 space-y-2">
                        <span className="text-sm text-secondary-2">Tổng lượng cổ phần</span>
                        <span className="font-bold text-md">{totalStat?.totalBoughtStock || 0}</span>
                    </Card>

                    <Card className="mb-0 p-3 space-y-2">
                        <span className="text-sm text-secondary-2">Tổng tiền</span>
                        <span className="font-bold text-md">{formatNumberToAmount(totalStat?.totalInvestmentAmount) || 0}</span>
                    </Card>
                </div>

                <Card className="overflow-hidden space-y-3 mb-0 flex-[2] w-full bg-background-accent/5 md:bg-background-accent border-none">
                    <h3 className="text-primary pt-3 px-0 md:px-3 text-sm font-bold">Danh sách dự án đầu tư</h3>

                    <TableSystem
                        {...props}
                        data={investProject}
                        template={InvestAccountTemplate}
                        columns={columns}
                        pagination={pagination}
                        scroll={{ x: 992 }}
                        className="w-full"
                    />
                </Card>
            </div>

        </TableWrapperContext>

    )
}
export default InvestAccountTableProject;
