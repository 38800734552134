import { mutate, query } from "configs/http-config";
import {
  getAllCrypto,
  receiveCrypto,
  sendCrypto,
  getAllCryptoTransHistory,
} from "./schema";

export const web3Apis = {
  getAllCrypto: () => {
    return query("getAllCrypto", getAllCrypto);
  },

  getAllCryptoTransHistory: (params) => {
    return query("getAllCryptoTransHistory", getAllCryptoTransHistory, {
      q: params,
    });
  },

  sendCrypto: (data) => {
    return mutate("sendCrypto", sendCrypto, { ...data });
  },

  receiveCrypto: (typeCrypto) => {
    return mutate("receiveCrypto", receiveCrypto, { typeCrypto });
  },
};
