import { distance, lineString, point, pointToLineDistance } from "@turf/turf";

import MAP_OPTIONS from "modules/ego-system/gg-map/constants";

import endMarker from "modules/ego-system/gg-map/assets/end-marker.svg";
import startMarker from "modules/ego-system/gg-map/assets/start-marker.svg";

export default class BookingModel {
  constructor() {
    this.map = null;
    this.route = null;
    this.bounds = null;
    this.endPoint = null;
    this.startPoint = null;
    this.endMarker = null;
    this.startMarker = null;
    this.linePath = null;
    this.status = MAP_OPTIONS.BOOKING_STATUS.COMPLETED;
    this.route = null;
    this.coordinates = [];
    this.intiPosition = null;
  }

  get origin() {
    return { lng: this.startPoint?.[0], lat: this.startPoint?.[1] };
  }

  get originBound() {
    if (!this.startPoint) {
      return null;
    }

    return new window.google.maps.LatLng(
      this.startPoint[0],
      this.startPoint[1]
    );
  }

  get destination() {
    return { lng: this.endPoint?.[0], lat: this.endPoint?.[1] };
  }

  get destinationBound() {
    if (!this.endMarker) {
      return null;
    }

    return new window.google.maps.LatLng(this.endMarker[0], this.endMarker[1]);
  }

  get lineColor() {
    switch (this.status) {
      case MAP_OPTIONS.BOOKING_STATUS.CANCEL:
        return "#ea5455";
      case MAP_OPTIONS.BOOKING_STATUS.COMPLETED:
        return "#28c76f";
      case MAP_OPTIONS.BOOKING_STATUS.PROCESS:
        return "#00cfe8";
      case MAP_OPTIONS.BOOKING_STATUS.READY:
        return "#ff9f43";

      default:
        return "#dae1e7";
    }
  }

  get coordinatesArray() {
    if (!this.coordinates) {
      return [];
    }

    return this.coordinates.map((location) => [location.lng(), location.lat()]);
  }

  get isOnLineString() {
    console.log("this.coordinatesArray", this.coordinatesArray);
    if (this.coordinatesArray.length <= 1) {
      return false;
    }

    const lineStringRoute = lineString(this.coordinatesArray);

    const start = point(this.startPoint);
    return (
      pointToLineDistance(start, lineStringRoute, { units: "kilometers" }) <
      0.02
    );
  }

  get distanceFromInitial() {
    if (!this.intiPosition || !this.startPoint) {
      return 0;
    }

    const from = point(this.intiPosition);
    const to = point(this.startPoint);
    return distance(from, to, { units: "kilometers" });
  }

  init({ map, endPoint, startPoint, status }) {
    console.log({ map, endPoint, startPoint, status });
    this.map = map;

    this.status = status;
    this.endPoint = endPoint;
    this.startPoint = startPoint;
    this.intiPosition = startPoint;
    this.bounds = new window.google.maps.LatLngBounds();
  }

  updateInitialPosition(position) {
    this.intiPosition = position;
  }

  updateBookingStatus(status) {
    this.status = status;
  }

  setStartPosition(position) {
    this.startPoint = position;
  }

  setEndPosition(position) {
    this.endPoint = position;
  }

  setStartMarker() {
    this.startMarker = new window.google.maps.Marker({
      animation: 2,
      map: this.map,
      position: this.origin,
      icon: startMarker,
    });
  }

  setEndMarker() {
    this.endMarker = new window.google.maps.Marker({
      animation: 2,
      map: this.map,
      position: this.destination,
      icon: endMarker,
    });
  }

  fitBounds() {
    if (this.bounds) {
      this.bounds?.extend(this.origin);
      this.bounds?.extend(this.destination);
      this.map?.fitBounds(this.bounds);
    }
  }

  async createBookingRoute() {
    this.removeRoute.call(this);

    if (
      this.status !== MAP_OPTIONS.BOOKING_STATUS.READY &&
      this.status !== MAP_OPTIONS.BOOKING_STATUS.PROCESS &&
      this.status !== MAP_OPTIONS.BOOKING_STATUS.ARRIVED
    ) {
      this.setStartMarker.call(this);
    }

    await this.createRoute.call(this);

    if (!this.endMarker) {
      this.setEndMarker.call(this);
    }

    this.bounds?.extend(this.origin);
    this.bounds?.extend(this.destination);
    this.map?.fitBounds(this.bounds);
  }

  async createRoute() {
    const directionsService = new window.google.maps.DirectionsService();

    this.route = await directionsService.route({
      origin: this.origin,
      destination: this.destination,
      travelMode: "DRIVING",
    });

    if (this.route.status !== "OK") {
      return;
    }

    this.coordinates = this.route?.routes?.[0]?.overview_path;

    this.linePath = new window.google.maps.Polyline({
      path: this.coordinates,
      geodesic: true,
      strokeColor: this.lineColor,
      strokeWeight: 4,
    });

    this.linePath.setMap(this.map);
  }

  removeMarker(type) {
    if (type === "start") {
      this.startMarker?.setMap(null);
      this.startMarker = null;
    }

    if (type === "end") {
      this.endMarker?.setMap(null);
      this.endMarker = null;
    }
  }

  removeRoute() {
    this.linePath?.setMap(null);
    this.linePath = null;
  }

  removeAll() {
    this.removeMarker("start");
    this.removeMarker("end");
    this.removeRoute();
  }

  resetAll() {
    this.removeMarker("start");
    this.removeMarker("end");
    this.removeRoute();

    this.map = null;
    this.route = null;
    this.route = null;
    this.bounds = null;
    this.endPoint = null;
    this.coordinates = [];
    this.startPoint = null;
    this.status = MAP_OPTIONS.BOOKING_STATUS.COMPLETED;
  }
}
