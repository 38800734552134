import { gql } from "@apollo/client";
import { mutate, query } from "configs/http-config";

const BookingsCarRentalAction = {
  getAllRentCarTrip: gql`
    query getAllRentCarTrip(
      $q: QueryGetListInput
      $fromDate: DateTime
      $toDate: DateTime
    ) {
      getAllRentCarTrip(q: $q, fromDate: $fromDate, toDate: $toDate) {
        data {
          id
          createdAt
          startDate
          cancelDate
          endDate
          pickupAddress
          code
          isClientCancel
          discountAmount
          initialCost
          driverFee
          partnerId
          partner {
            id
            name
            transportationTypes
          }
          car {
            id
            color
            carCode
            category
            carCompany
            manufacturingYear
            licensePlateNumber
            price
            overtimeFee
            overlimitFee
            cleanFee
            deodoriFee
            images
            owner {
              id
              name
            }

            address

            garage {
              id
              name
              address
            }
          }
          paymentNote
          user {
            id
            name
            phone
            driverLicense
            avatar
            identityCard
          }
          isRentDriver
          isSelfPickup
          mortgage
          legalPaper
          note
          pickupPoint
          partnerCar {
            id
            carEgoId
            color
            carCode
            category
            carCompany
            manufacturingYear
            licensePlateNumber
            images
            description

            overtimeFee
            overlimitFee
            cleanFee
            deodoriFee

            owner {
              name
            }
            price

            address

            garage {
              id
              name
              address
            }
          }
          driver {
            username
          }
          deposit
          finalCost
          insuranceFee
          insuranceTripFee
          driverFee
          serviceFee
          VATFee
          cashOnDelivery
          surchargeFee

          costIncurred
          paidType
          status

          mortgage
          legalPaper
          user {
            id
            name
            phone
          }
          type
          paidType
          paymentMethod
          overLimitKm
          overLimitTime
          reasonCancel
          refundCost
          refundMethod

          taxCode
          companyName
          companyAddress
          companyEmail
        }

        getStatRentCarTrip
        total
      }
    }
  `,

  getAllRentCarByLocation: gql`
    query getAllRentCarByLocation(
      $page: Int
      $limit: Int
      $filter: Mixed
      $order: Mixed
      $search: String
      $timeSort: Int
      $timestamp: DateTime
      $pickupPoint: [Float]!
    ) {
      getAllRentCarByLocation(
        q: {
          limit: $limit
          page: $page
          filter: $filter
          order: $order
          search: $search
          timeSort: $timeSort
          timestamp: $timestamp
        }
        pickupPoint: $pickupPoint
      ) {
        data {
          id
          carCode
        }
        total
      }
    }
  `,

  createRentCarTripForAdmin: gql`
    mutation createRentCarTripForAdmin($data: CreateRentCarTripInputForAdmin!) {
      createRentCarTripForAdmin(data: $data) {
        id
      }
    }
  `,

  updateRentCarTrip: gql`
    mutation updateRentCarTrip($id: ID!, $data: UpdateRentCarTripInput!) {
      updateRentCarTrip(id: $id, data: $data) {
        id
      }
    }
  `,

  deleteOneRentCarTrip: gql`
    mutation deleteOneRentCarTrip($id: ID!) {
      deleteOneRentCarTrip(id: $id) {
        id
      }
    }
  `,

  cancelRentCarTrip: gql`
    mutation cancelRentCarTrip($rentCarTripId: ID!, $reasonCancel: String!) {
      cancelRentCarTrip(
        rentCarTripId: $rentCarTripId
        reasonCancel: $reasonCancel
      ) {
        id
      }
    }
  `,

  acceptRentCarTrip: gql`
    mutation acceptRentCarTrip($rentCarTripId: ID!) {
      acceptRentCarTrip(rentCarTripId: $rentCarTripId) {
        id
      }
    }
  `,

  changePartnerForRentCarTrip: gql`
    mutation changePartnerForRentCarTrip($rentCarTripId: ID!, $partnerId: ID!) {
      changePartnerForRentCarTrip(
        rentCarTripId: $rentCarTripId
        partnerId: $partnerId
      ) {
        id
      }
    }
  `,
};

export const bookingCarRentalApi = {
  getAllRentCarTrip: (payload) => {
    return query(
      "getAllRentCarTrip",
      BookingsCarRentalAction.getAllRentCarTrip,
      payload
    );
  },

  getAllRentCarByLocation: (payload) => {
    return query(
      "getAllRentCarByLocation",
      BookingsCarRentalAction.getAllRentCarByLocation,
      payload
    );
  },

  createRentCarTripForAdmin: (payload) => {
    return mutate(
      "createRentCarTripForAdmin",
      BookingsCarRentalAction.createRentCarTripForAdmin,
      payload
    );
  },

  updateRentCarTrip: (payload) => {
    return mutate(
      "updateRentCarTrip",
      BookingsCarRentalAction.updateRentCarTrip,
      payload
    );
  },

  deleteOneRentCarTrip: (payload) => {
    return mutate(
      "deleteOneRentCarTrip",
      BookingsCarRentalAction.deleteOneRentCarTrip,
      payload
    );
  },

  cancelRentCarTrip: (payload) => {
    return mutate(
      "cancelRentCarTrip",
      BookingsCarRentalAction.cancelRentCarTrip,
      payload
    );
  },

  changePartnerForRentCarTrip: (payload) => {
    return mutate(
      "changePartnerForRentCarTrip",
      BookingsCarRentalAction.changePartnerForRentCarTrip,
      payload
    );
  },

  acceptRentCarTrip: (payload) => {
    return mutate(
      "acceptRentCarTrip",
      BookingsCarRentalAction.acceptRentCarTrip,
      payload
    );
  },
};
