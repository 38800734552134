import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notificationApi } from "../apis";

export const getAllNotifications = createAsyncThunk(
    "/notice/getAllNotifications",
    async (params) => {
        try {

            const response = await notificationApi.getAllNotifications({
                page: params?.page || 1,
                limit: params?.limit,
                search: params?.search || undefined,
                filter: params?.filter || {},
            });

            return { response, page: params?.page || 1, onPage: params.onPage || false };

        } catch (error) {
            throw error
        }
    });

export const NoticeSlice = createSlice({
    name: "notice",
    initialState: {
        list: [],
        total: 0,
        flatListTotal: 0,
        hasNew: false,
        numberNewCount: 0,
        topLastestByIds: [],
        flatList: [],
    },

    reducers: {
        setHasNew: (state, { payload = false }) => {
            state.hasNew = payload;
            state.numberNewCount = 0;
        },

        resetNotice: (state) => {
            state.list = [];
            state.numberNewCount = 0;
        }
    },

    extraReducers: (builder) => {
        builder.addCase(getAllNotifications.fulfilled, (state, { payload }) => {
            if (payload.onPage) {
                state.flatList = payload.response.data || [];
                state.flatListTotal = payload.response.total || [];
                return;
            }

            state.total = payload.response.total;
            const page = payload.page;
            if (payload.response.data) {
                if (state.list.length < page) {
                    state.list.push(payload.response.data);
                } else {
                    state.list[page - 1] = payload.response.data
                }
            }
        })
    },
});

export const { setHasNew, resetNotice } = NoticeSlice.actions;

export default NoticeSlice.reducer;
