/* eslint-disable multiline-ternary */
import classNames from "classnames";
import { Clock, Tags } from "lucide-react";
import { formatNumberToAmount } from "utility/number-utils";
import Utils from "utility/time";
import TooltipSystem from "components/e-control/tooltip-system";
import { useTableWrapperContext } from "components/e-control/table";
import { Card } from "reactstrap";
import DropdownSystem from "components/e-control/forms/form-elements/dropdown-system";
import MoreDropdown from "components/views/more-dropdown";
import AvatarSystem from "components/controls/avatar-system";
import { formatPhoneNumberToShow } from "utils";

const TemplateItemOrderInvest = ({ item }) => {
  const { menuDropdown, isShowAction } = useTableWrapperContext();

  return (
    <>
      <Card className="mb-2 p-3 space-y-2 relative">
        <div className="space-y-2">
          <div className="flex items-center justify-between gap-x-2">
            <div className="flex items-center gap-x-2">
              <span className="flex items-center gap-x-2 text-sm ">
                <Tags className="w-4 h-4" />
              </span>

              <span className="text-primary">{item?.code}</span>
            </div>

            {isShowAction && (
              <div className="absolute top-0 right-0">
                <DropdownSystem
                  options={menuDropdown(
                    item?.isPaid,
                    item?.paymentMethod,
                    item?.status === "DELETED"
                  )}
                  onMenuSelect={(callback) => callback?.(item)}
                >
                  <MoreDropdown />
                </DropdownSystem>
              </div>
            )}
          </div>

          <div className="flex justify-between items-center text-sm gap-2">
            <div className="flex items-center gap-x-2 whitespace-nowrap">
              <Clock color="#29ab68" size={14} />
              <span className="text-sm">
                {Utils.formatDateOnlyNumber(item?.createdAt)}
              </span>
            </div>

            <TooltipSystem
              title={`${item?.user?.name} / ${formatPhoneNumberToShow(
                item?.user?.phone
              )}`}
            >
              <div className="line-clamp-1">
                <div className="flex gap-x-1 whitespace-nowrap">
                  <p>{item?.user?.name}</p>
                  <span>/</span>
                  <p>{formatPhoneNumberToShow(item?.user?.phone)}</p>
                </div>
              </div>
            </TooltipSystem>
          </div>

          <div className="flex justify-between items-center">
            <div className="flex items-center gap-x-2">
              <span className="text-sm">Số lượng:</span>
              <span className="text-sm">
                {formatNumberToAmount(item?.quantity)}
              </span>
            </div>

            <div className="flex items-center gap-x-2">
              <span className="text-sm ">Tổng giá:</span>
              <span className="text-sm">
                {formatNumberToAmount(item?.finalCost)}
              </span>
            </div>
          </div>

          <div className="flex items-center justify-between gap-2">
            <TooltipSystem title={item?.investmentProject?.name}>
              <h3 className="text-sm line-clamp-1">
                {item?.investmentProject?.name}
              </h3>
            </TooltipSystem>

            <div className="flex justify-between items-center">
              <div
                className={classNames(
                  "flex items-center gap-x-2 py-1 px-2 rounded-md group text-xs flex-shrink-0 whitespace-nowrap",
                  item?.isPaid && "text-primary bg-primary/30",
                  !item?.isPaid && "text-red-500 bg-red-500/30"
                )}
              >
                {/* {item?.isPaid ? "Đã thanh toán" : "Chưa thanh toán"}` */}

                {item?.status === "DELETED"
                  ? "Đã xoá"
                  : item?.isPaid
                  ? "Đã thanh toán"
                  : "Chưa thanh toán"}
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default TemplateItemOrderInvest;
