// ** Reducers Imports

import layout from "layouts/store";
import partner from "modules/ego-system/account/stores";
import bookings from "modules/ego-system/bookings/stores";
import car from "modules/ego-system/car/stores";
import coupon from "modules/ego-system/coupon/stores";
import driver from "modules/ego-system/drivers/stores";
import map from "modules/ego-system/gg-map/stores";
import hotline from "modules/ego-system/hotline/store";
import invoices from "modules/ego-system/invoices/stores";
import location from "modules/ego-system/location/stores";
import news from "modules/ego-system/news/stores";
import price from "modules/ego-system/price/stores";
import staff from "modules/ego-system/staff/stores";
import user from "modules/ego-system/user/stores";
import navbar from "./navbar";

import auth from "modules/authentication/store";
import dashboard from "modules/ego-system/dashboard/stores";
import notification from "modules/ego-system/notification/stores";
import dashboardPartner from "modules/partner/dashboard/stores";
import bookingsPartner from "modules/transportation-system/modules/booking-management/components/bookings/stores";
import invoicesPartner from "modules/transportation-system/modules/booking-management/components/taxi-invoices/stores";
import driverPartner from "modules/transportation-system/modules/car-driver/components/drivers/stores";
import carPartner from "modules/transportation-system/modules/car-driver/components/vehicle/stores";
import eCar from "modules/transportation-system/modules/connect-ego/components/ecar-list/stores";
import configPricePartner from "modules/transportation-system/modules/connect-ego/stores";
import departmentPartner from "modules/transportation-system/modules/settings/components/department/stores";
import incurredPartner from "modules/transportation-system/modules/settings/components/incurred-fee/stores";
import workPlace from "modules/transportation-system/modules/settings/components/work-place/stores";

import bookingsCarRental from "modules/ego-system/booking-car-rental/stores";

import calendar from "apps/calendar/store";
import stringee from "modules/ego-system/call-stringee/stores";
import notice from "modules/ego-system/notice/store";
import stock from "modules/shop/stock/stores";
import carRental from "modules/transportation-system/modules/booking-management/components/car-rental-booking/stores";
import partnerSettingStore from "modules/transportation-system/modules/settings/stores";

import egoTaxiBooking from "modules/transportation-system/modules/connect-ego/components/bookings/stores";
import accountPermission from "./account-permission";

import reportPartner from "modules/partner/reports/stores";
import garage from "modules/transportation-system/modules/car-driver/components/garage/stores";

import accumulate from "modules/ego-system/accumulate/stores";
import delivery from "modules/ego-system/delivery-booking/stores";
import invest from "modules/invest/stores";

import expensePartner from "modules/partner/expenditure/stores";
import device from "./device";

import shop from "modules/shop/stores";

import web3 from 'modules/wallet-web3/stores';

const rootReducer = {
  map,
  car,
  auth,
  user,
  eCar,
  staff,
  price,
  navbar,
  layout,
  driver,
  coupon,
  carRental,
  hotline,
  partner,
  location,
  news,
  bookings,
  invoices,
  stringee,
  workPlace,
  dashboard,
  notification,
  carPartner,
  driverPartner,
  dashboardPartner,
  invoicesPartner,
  bookingsPartner,
  departmentPartner,
  bookingsCarRental,
  configPricePartner,
  calendar,
  notice,
  partnerSetting: partnerSettingStore,
  stock,
  incurredPartner,

  accountPermission,

  egoTaxiBooking,

  garage,
  reportPartner,
  device,

  expensePartner,
  delivery,
  accumulate,

  // investment
  invest,

  // shopping
  shop,

  /// web 3
  web3
};

export default rootReducer;
