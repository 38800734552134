import withGGMap, { useGGMap } from "apps/maps/hocs/with-map";
import AutoCompleteInput from "modules/ego-system/gg-map/components/autocomplete-input";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Col, Row } from "reactstrap";

const CreateBookingForm = ({ create }) => {
    const [location, setLocation] = useState([]);

    const { control, resetField, handleSubmit } = useForm({});

    const createNewBooking = async (formData) => {
        console.log('createNewBooking', { ...formData, endFullAddress: formData.endAddress, startFullAddress: formData.startAddress });
        if (!formData.startPoint || !formData.endPoint) {
            return;
        }

        const startPoint = formData.startPoint.split(',').map(point => parseFloat(point))
        const endPoint = formData.endPoint.split(',').map(point => parseFloat(point))

        create?.({ ...formData, startPoint: startPoint, endPoint: endPoint, endFullAddress: formData.endAddress, startFullAddress: formData.startAddress });
    };

    const { map } = useGGMap();

    return (
        <Row>
            <Col>
                <AutoCompleteInput
                    onEmit={(address) => setLocation([address.location.lng(), address.location.lat(),])}
                />

                <p>{location.join(',')}</p>
            </Col>
        </Row>
    )
};

export default withGGMap(CreateBookingForm);