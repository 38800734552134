/* eslint-disable multiline-ternary */
import { Card } from "reactstrap";

import { setUserStringee } from "modules/ego-system/call-stringee/stores";
import { dispatch } from "root-stores";
import Utils from "utility/time";
import DocumentUnit from "../document";

const CardInvestUnitDetail = ({ detailUnit }) => {
    console.log("detailUnit", detailUnit)

    const listBody = [
        {
            text: "Tên",
            content: detailUnit?.name,
            show: detailUnit?.name,
        },
        {
            text: "Mã số thuế",
            content: detailUnit?.taxCode,
            show: detailUnit?.taxCode,
        },
        {
            text: "Người đại diện",
            content: detailUnit?.nameAgent,
            show: detailUnit?.nameAgent,
        },
        {
            text: "Địa chỉ",
            content: detailUnit?.address,
            show: detailUnit?.address,
        },
    ];

    const handleCall = (row) => {
        dispatch(setUserStringee(row));
    };


    return (
        <>
            <div className="space-y-4">
                <Card className="mb-0 rounded-md p-4 space-y-4 relative">
                    <div className="flex flex-col items-center gap-2">
                        <img
                            src={detailUnit?.logo}
                            className="size-[80px] rounded-md object-cover"
                        />

                        <h1 className="text-primary uppercase text-lg font-bold">
                            {detailUnit?.name}
                        </h1>
                    </div>

                    <div className="h-[1px] w-full bg-border-color" />

                    <div className="">
                        <div className="space-y-2">
                            {listBody
                                ?.filter((item) => item?.show)
                                ?.map((item, index) => (
                                    <div
                                        key={index}
                                        className="flex items-center gap-x-2 align-middle"
                                    >
                                        <div className="text-sm text-secondary-2">
                                            {item?.text} :{" "}
                                        </div>
                                        <div className="flex items-center gap-x-2">
                                            <span className="text-sm">{item?.content}</span>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </Card>

                <Card className="mb-0 rounded-md p-4 space-y-2">
                    <div className="flex items-center justify-between">
                        <div className="text-sm text-secondary-2">Ngày đăng ký: </div>
                        <div className="flex items-center gap-x-2 text-sm">
                            {Utils.formatDateUtc(detailUnit?.createdAt)}
                        </div>
                    </div>

                    <div className="flex items-center justify-between">
                        <div className="text-sm text-secondary-2">Ngày cập nhật: </div>
                        <div className="flex items-center gap-x-2 text-sm">
                            {Utils.formatDateUtc(detailUnit?.updatedAt)}
                        </div>
                    </div>
                </Card>

                <Card className="mb-0 rounded-md p-4 space-y-2">
                    <div>
                        <div className="text-sm text-secondary-2">Mô tả ngắn:</div>
                        <div className="flex items-center gap-x-2 text-sm">
                            {detailUnit?.description}
                        </div>
                    </div>
                </Card>

                <Card className="mb-0 rounded-md p-4 space-y-2">
                    <div >
                        <div className="text-sm text-secondary-2 pb-2">Tài liệu: </div>
                        <div className="flex items-center gap-x-2 text-sm">
                            <DocumentUnit detailUnit={detailUnit} />
                        </div>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default CardInvestUnitDetail;
