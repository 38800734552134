import BardSystem from "components/e-control/bard-system"
import { Tags } from "lucide-react"
import { Card } from "reactstrap"
import { formatNumberToAmount } from "utility/number-utils"

const InvestAccountTemplate = ({ item }) => {
    return (
        <Card className="mb-4 p-3 space-y-2">
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-x-2 text-sm">
                    <Tags className="size-4 text-secondary-2" />
                    <span>{item?.code}</span>
                </div>

                {item?.isPaid ? <BardSystem type="primary">Hoàn thành</BardSystem> : <BardSystem type="danger">Chưa hoàn thành</BardSystem>}
            </div>

            <div className="flex items-center gap-x-2 text-sm">
                <span>{item?.investmentProject?.name}</span>
            </div>

            <div className="flex items-center gap-x-2 text-sm">
                <img src={item?.investmentProject?.investmentUnit?.logo} className="size-5 rounded-md object-cover" />
                <span>{item?.investmentProject?.investmentUnit?.name}</span>
            </div>  

            <div className="flex items-center justify-between">
                <div className="flex items-center gap-x-2 text-sm">
                    <span className="text-secondary-2 text-sm">Số lượng</span>
                    <span className="text-sm">{formatNumberToAmount(item?.quantity)}</span>
                </div>

                <div className="flex items-center gap-x-2 text-sm">
                    <span className="text-secondary-2 text-sm">Tổng giá</span>
                    <span className="text-sm">{formatNumberToAmount(item?.finalCost)}</span>
                </div>

            </div>
        </Card >
    )
}

export default InvestAccountTemplate
