import { getAllCrypto } from "modules/wallet-web3/stores";
import { useEffect } from "react";
import { dispatch } from "root-stores";

export const withCryptoWrapper = (Wrapper) => {
    return (props) => {
        useEffect(() => {
            const loadCrypto = () => {
                dispatch(getAllCrypto());
            }
    
            loadCrypto();
    
            const timmer = setInterval(() => {
                loadCrypto()
            }, 5000)
    
    
            return () => {
                clearInterval(timmer)
            }
        }, [])

        return <Wrapper  {...props}/>
    } 
}