import SpinnerComponent from "components/controls/spinner/fallback-spinner";
import WelcomePreLoading from "components/views/welcome";
import { ACCOUNT_BUSINESS_TYPE, ROLE } from "constants";
import { getAccountInfo, handleLogout, setToken } from "modules/authentication/store";
import { getAllWorkPlace } from "modules/ego-system/gg-map/stores";
import { createContext, useContext, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { dispatch } from "root-stores";

const AuthContext = createContext();

const Auth = ({ children }) => {
  const [searchParams] = useSearchParams();
  const tokenParam = searchParams.get("token");

  const [loading, setLoading] = useState(true);

  // ** Store
  const { userData, token } = useSelector((state) => state.auth);

  const isPartnerSupportRole = useMemo(() => {
    return userData.role === ROLE.PARTNER_SUPPORT;
  }, [userData]);

  const isPartnerRole = useMemo(() => {
    return userData.role === ROLE.PARTNER;
  }, [userData]);

  const isAdminRole = useMemo(() => {
    return userData.role === ROLE.ADMIN;
  }, [userData]);

  const isSupperAdminRole = useMemo(() => {
    return userData.role === ROLE.SUPER_ADMIN;
  }, [userData]);

  const isGOVERNMENTRole = useMemo(() => {
    return userData.role === ROLE.GOVERNMENT;
  }, [userData]);

  const isShoppingPartnerType = useMemo(() => {
    if (!userData?.partnerTypes?.length) {
      return false;
    }

    return userData?.partnerTypes?.includes(ACCOUNT_BUSINESS_TYPE.SHOPPING.VALUE)
  }, [userData])

  useEffect(() => {
    if (token) {
      dispatch(getAccountInfo());
    } else {
      setLoading(false);
    }
  }, [token]);

  useLayoutEffect(() => {
    if (tokenParam) {
      dispatch(setToken(tokenParam));
    }
  }, [tokenParam]);

  useEffect(() => {
    if (userData) {
      setLoading(false);
    } else {
      dispatch(handleLogout())
    }

    if (userData?.role === ROLE.ADMIN || userData?.role === ROLE.SUPER_ADMIN) {
      dispatch(getAllWorkPlace());
    }
  }, [userData]);

  useEffect(() => {
    // handle implement logout by cmd in browser console tab
    window.__FUNC__logout__ = () => {
      dispatch(handleLogout())
    }
  }, [])

  if (loading) {
    return <SpinnerComponent />
  }

  return (
    <AuthContext.Provider
      value={{
        isAdminRole,
        isPartnerRole,
        isSupperAdminRole,
        isPartnerSupportRole,
        isGOVERNMENTRole,
        isShoppingPartnerType,

        user: userData,
      }}
    >
      <WelcomePreLoading />
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default Auth;
