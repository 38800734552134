import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { toast } from "react-hot-toast";
import { dashboardPartnerApi } from "../apis";

export const getStatDashboardForPartner = createAsyncThunk(
  "/dashboard/getStatDashboardForPartner",
  async () => {
    try {
      const response = await dashboardPartnerApi.getStatDashboardForPartner();

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const dashboardPartnerSlice = createSlice({
  name: "dashboardPartner",
  initialState: {
    totalDashboarPartner: {},
  },

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(
      getStatDashboardForPartner.fulfilled,
      (state, { payload }) => {
        state.totalDashboarPartner = payload || {};
      }
    );
  },
});

export default dashboardPartnerSlice.reducer;
