import { gql } from "@apollo/client";
import { mutate, query } from "configs/http-config";

const carAction = {
  getAllCar: gql`
    query getAllCar($q: QueryGetListInput) {
      getAllCar(q: $q) {
        data {
          id
          carCode
          quantity
          carType {
            id
            name
            isActived
          }
          licensePlateNumber
          images
          verifyStatus
          driverId
          status
          workPlaceId
          address
          price
          fuelType
          businessType
          carCompany
          carTypeId
          color
          manufacturingYear
          description
          category
          location
          isConnectedEgo
          workPlace {
            id
            name
            location
            landMarkIds
            landMarks {
              id
              name
            }
          }
          tagIds
          carFeatureIds
          driver {
            id
            phone
            name
            username
          }
          createdAt
          updatedAt
          transmisstionType
          deliveryKm
          deliveryCostPerKm
          freeDeliveryKm
          discountByWeek
          discountByMonth
          partnerId
          partner {
            name
            phone
            id
          }
          district {
            id
            name
            workPlaceId
          }
          rentingQuantity
        }
        getStatCar
        total
      }
    }
  `,

  getAllCarType: gql`
    query getAllCarType($q: QueryGetListInput) {
      getAllCarType(q: $q) {
        data {
          id
          name
          workPlaceIds
          workPlaces {
            id
            name
          }
          isActived
          createdAt
          updatedAt
        }
        total
      }
    }
  `,

  getOneCarType: gql`
    query getOneCarType($id: ID!) {
      getOneCarType(id: $id) {
        id
        name
        createdAt
        updatedAt
      }
    }
  `,

  getOneCar: gql`
    query GetOneCar($id: ID!) {
      getOneCar(id: $id) {
        id
        carCode
        licensePlateNumber
        images
        carTypeId
        address
        rate
        price
        description
        location
        businessType
        businessTypes
        category
        carCompany
        color
        fuelType
        manufacturingYear
        status
        partnerId
        driverId
        isConnectedEgo
        workPlaceId
        rentCarStatus
        tranportationType
        ownerId
        isConnectedDriver
        carFeatureIds
        tagIds
        countTrip
        avgRate
        avgPoint
        deliveryKm
        deliveryCostPerKm
        freeDeliveryKm
        discountByWeek
        discountByMonth
        transmisstionType
        isSaved
        isAvailable
        districtId
        listRentedDate
        mortgage
        overtimeFee
        overlimitFee
        cleanFee
        deodoriFee
        countryId
        createdAt
        updatedAt

        reasonRefuse
        verifyStatus

        partner {
          name
          id
          phone
        }

        carFeatures {
          id
          icon
          name
          createdAt
          updatedAt
        }

        driver {
          name
          id
          phone
        }

        tags {
          id
          name
          type
          createdAt
          updatedAt
        }

        workPlace {
          id
          name
          location
          landMarkIds
          isAutoPickDriver
          countryId
          createdAt
          updatedAt
          getStatWorkPlace
        }
      }
    }
  `,

  getAllCarOwner: gql`
    query getAllCarOwner(
      $page: Int
      $limit: Int
      $filter: Mixed
      $order: Mixed
      $fromDate: DateTime
      $toDate: DateTime
      $search: String
      $partnerId: ID!
    ) {
      getAllCarOwner(
        q: {
          limit: $limit
          page: $page
          filter: $filter
          order: $order
          search: $search
        }
        fromDate: $fromDate
        toDate: $toDate
        partnerId: $partnerId
      ) {
        data {
          avatar
          address
          createdAt
          id
          name
          phone
          status
          businessRules
          identityCard
          countCar
          workPlace {
            id
            name
          }
          workPlaceId
          workPlaces {
            id
            name
          }
          workPlaceIds
          note
        }
        getStatUser
        total
      }
    }
  `,

  createCar: gql`
    mutation createCar($data: CreateCarInput!) {
      createCar(data: $data) {
        id
      }
    }
  `,

  createCarType: gql`
    mutation createCarType($data: CreateCarTypeInput!) {
      createCarType(data: $data) {
        id
        name
      }
    }
  `,

  deleteOneCar: gql`
    mutation deleteOneCar($id: ID!) {
      deleteOneCar(id: $id) {
        id
        carCode
        driver {
          id
          name
          username
          phone
        }
      }
    }
  `,

  deleteOneCarType: gql`
    mutation deleteOneCarType($id: ID!) {
      deleteOneCarType(id: $id) {
        id
        name
      }
    }
  `,

  updateCar: gql`
    mutation updateCar($id: ID!, $data: UpdateCarInput!) {
      updateCar(id: $id, data: $data) {
        id
        workPlace {
          id
          name
          location
          landMarkIds
          landMarks {
            id
            name
          }
        }
        carCode
        images
      }
    }
  `,

  updateCarType: gql`
    mutation updateCarType($id: ID!, $data: UpdateCarTypeInput!) {
      updateCarType(id: $id, data: $data) {
        id
        name
        isActived
      }
    }
  `,

  getAllWorkPlaceWOPaging: gql`
    query getAllWorkPlaceWOPaging {
      getAllWorkPlaceWOPaging {
        id
        name
        location
        landMarkIds
        landMarks {
          id
          name
        }
      }
    }
  `,

  getAllCarTypeByStartPoint: gql`
    query getAllCarTypeWOPagingByStartPoint($startPoint: [Float]!) {
      getAllCarTypeWOPagingByStartPoint(startPoint: $startPoint) {
        id
        name
      }
    }
  `,

  getAllCarTypeWOPagingByWorkPlace: gql`
    query getAllCarTypeWOPagingByWorkPlace($workPlaceId: ID!) {
      getAllCarTypeWOPagingByWorkPlace(workPlaceId: $workPlaceId) {
        id
        name
        isActived
        createdAt
        updatedAt
      }
    }
  `,

  activateCarType: gql`
    mutation activateCarType($carTypeId: ID!, $workPlaceId: ID!) {
      activateCarType(carTypeId: $carTypeId, workPlaceId: $workPlaceId)
    }
  `,

  deactivateCarType: gql`
    mutation deactivateCarType($carTypeId: ID!, $workPlaceId: ID!) {
      deactivateCarType(carTypeId: $carTypeId, workPlaceId: $workPlaceId)
    }
  `,

  disconnectCarForAdmin: gql`
    mutation disconnectCarForAdmin($carId: ID!) {
      disconnectCarForAdmin(carId: $carId)
    }
  `,
};

export const carApi = {
  getAllCar: (payload) => {
    console.log("car", payload);
    return query("getAllCar", carAction.getAllCar, payload);
  },

  getAllCarType: (payload) => {
    return query("getAllCarType", carAction.getAllCarType, payload);
  },

  getAllCarOwner: (payload) => {
    return query("getAllCarOwner", carAction.getAllCarOwner, payload);
  },

  getOneCarType: (payload) => {
    return query("getOneCarType", carAction.getOneCarType, payload);
  },

  createCar: (payload) => {
    return mutate("createCar", carAction.createCar, payload);
  },

  createCarType: (payload) => {
    return mutate("createCarType", carAction.createCarType, payload);
  },

  deleteOneCar: (payload) => {
    return mutate("deleteOneCar", carAction.deleteOneCar, payload);
  },

  deleteOneCarType: (payload) => {
    return mutate("deleteOneCarType", carAction.deleteOneCarType, payload);
  },

  updateCar: (payload) => {
    return mutate("updateCar", carAction.updateCar, payload);
  },

  updateCarType: (payload) => {
    return mutate("updateCarType", carAction.updateCarType, payload);
  },

  getAllWorkPlaceWOPaging: () => {
    return query("getAllWorkPlaceWOPaging", carAction.getAllWorkPlaceWOPaging);
  },

  getAllCarTypeByStartPoint: (payload) => {
    return query(
      "getAllCarTypeWOPagingByStartPoint",
      carAction.getAllCarTypeByStartPoint,
      payload
    );
  },

  getAllCarTypeWOPagingByWorkPlace: (payload) => {
    return query(
      "getAllCarTypeWOPagingByWorkPlace",
      carAction.getAllCarTypeWOPagingByWorkPlace,
      payload
    );
  },

  activateCarType: (payload) => {
    return query("activateCarType", carAction.activateCarType, payload);
  },

  deactivateCarType: (payload) => {
    return query("deactivateCarType", carAction.deactivateCarType, payload);
  },

  disconnectCarForAdmin: (payload) => {
    return query(
      "disconnectCarForAdmin",
      carAction.disconnectCarForAdmin,
      payload
    );
  },

  getOneCar: (id) => {
    return query("getOneCar", carAction.getOneCar, id);
  },
};
