/* eslint-disable multiline-ternary */
import ButtonSystem from "components/e-control/button-system";
import { ChevronRight, Clock } from "lucide-react";
import { useState } from "react";
import { generateDateTime } from "../../utils";
import BuyForm from "../buy-form";
import Popup from "../popup";

import { investApi } from "modules/invest/apis";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { INVEST_ROUTES } from "router/invest-routes";
import { formatNumberToAmount } from "utility/number-utils";
import FormInfoQrCode from "../form-info-qr";
import LoadingCardWebview from "../loading-card";
import "./index.scss";

const CampaignInvestItem = ({
  item,
  onClick,
  isInvestDetail,
  isClient = false,
}) => {
  const navigate = useNavigate();

  const [orderId, setOrderId] = useState("");

  const renderStatus = (item, callback) => {
    const { diffByStartDate, diffByEndDate } = generateDateTime(
      item.startDate,
      item.endDate
    );

    if (diffByStartDate < 0) {
      return (
        <div className="space-y-4">
          <div className="flex items-center gap-x-2 py-1 px-2 w-fit rounded-md text-sm  text-blue-700 bg-blue-700/30">
            <Clock className="size-4" />
            <span className="text-xs">Sắp mở bán</span>
          </div>

          {/* <ButtonSystem
            color="primary"
            className="w-full rounded-[16px]"
            onClick={callback}
          >
            Săn ngay
          </ButtonSystem> */}
        </div>
      );
    }

    if (diffByEndDate < 0) {
      return (
        <div className="space-y-4">
          <div className="flex items-center gap-x-2 py-1 px-2 w-fit rounded-md text-sm text-primary bg-primary/30">
            <Clock className="size-4" />
            <span className="text-xs">
              Hết hạn trong {Math.abs(diffByEndDate)} ngày
            </span>
          </div>

          <ButtonSystem
            color="primary"
            className="w-full rounded-[16px]"
            onClick={callback}
          >
            Săn ngay
          </ButtonSystem>
        </div>
      );
    }

    if (diffByEndDate > 0) {
      return (
        <div className="space-y-4">
          <div className="flex items-center gap-x-2 py-1 px-2 w-fit rounded-md text-sm text-yellow-600 bg-yellow-600/30">
            <Clock className="size-4" />
            <span className="text-xs">Đã hết hạn</span>
          </div>

          <ButtonSystem className="w-full rounded-[16px]">
            Săn ngay
          </ButtonSystem>
        </div>
      );
    }
  };

  // order
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectInvestment, setSelectInvestment] = useState(null);

  const [isPopupOpenPayment, setIsPopupOpenPayment] = useState(false);

  const [infoQrCode, setInfoQrCode] = useState({});

  const handleConfirmPayment = async () => {
    try {
      const response = await investApi.sendConfirmPaymentInvestmentOrder(
        orderId
      );
      if (response === "success") {
        toast.success("Đã xác thực chuyển tiền thành công");
        setTimeout(() => {
          setIsPopupOpenPayment(false);
        }, [1000]);
      }
    } catch (e) {
      toast.error(e?.message);
    }
  };

  const handleOrderButtonClick = (event, item) => {
    event.stopPropagation();
    event.preventDefault();
    setSelectInvestment(item);
    setIsPopupOpen(true);
  };

  const handleSetInfoQrCode = (item) => {
    setIsPopupOpenPayment(true);
    setIsPopupOpen(false);
    setInfoQrCode(item);
  };

  if (!item) {
    return (
      <div className="mb-0 bg-transparent w-full h-full overflow-hidden flex items-center justify-center relative">
        {/* <p className="text-blue-950 relative z-10 text-sm">Đang tải...</p>
         */}
        <LoadingCardWebview />
      </div>
    );
  }

  return (
    <>
      <div
        className="mb-0 h-full bg-gray-50 w-full overflow-hidden rounded-[12px] campaign-card-item border border-[#d5d5d5]"
        onClick={onClick}
      >
        <img
          alt={item?.name}
          src={item?.images?.[0] || item?.investmentUnit?.logo}
          className="w-full aspect-[14/6] object-cover"
        />

        <div className="p-3 space-y-2">
          <h3 className="text-primary font-bold line-clamp-2">{item?.name}</h3>

          <div
            className="flex items-center justify-between"
            onClick={
              !isInvestDetail
                ? () =>
                    // eslint-disable-next-line implicit-arrow-linebreak
                    navigate(
                      INVEST_ROUTES.INVEST_OWNER_DETAIL.replace(
                        ":id",
                        item?.investmentUnit?.id
                      )
                    )
                : () => null
            }
          >
            <div className="flex items-center gap-x-2">
              <img
                src={item?.investmentUnit?.logo}
                className="w-6 aspect-square rounded-md border-1 object-cover"
              />
              <span className="text-blue-950 text-sm line-clamp-1">
                {item?.investmentUnit?.name}
              </span>
            </div>

            {!isInvestDetail && (
              <ChevronRight className="size-4 text-blue-950" />
            )}
          </div>

          <div className="flex justify-between">
            <div className="flex flex-col justify-center">
              <span className="text-sm text-blue-950">Đơn giá</span>
              <span className="text-black">
                {formatNumberToAmount(String(item?.pricePerStock || 0))}đ
              </span>
            </div>

            <div className="flex flex-col items-end justify-center">
              <span className="text-sm text-blue-950">Tổng cổ phần</span>
              <span className="text-black">
                {formatNumberToAmount(String(item?.totalStock || 0))}
              </span>
            </div>
          </div>

          <div className="w-full flex items-stretch rounded-full overflow-hidden h-4 border border-primary/30">
            <div
              style={{
                width: (item?.totalSoldTock / item?.totalStock) * 100 + "%",
              }}
              className={"bg-primary"}
            />
            <div className={"bg-white flex-[2]"} />
          </div>

          <div className="flex justify-between">
            <div className="flex flex-col justify-center">
              <span className="text-blue-950 text-sm">Tổng bán</span>
              <span className="text-[#db5a5a]">
                {formatNumberToAmount(item?.totalSoldTock)}
              </span>
            </div>

            <div className="flex flex-col items-end justify-center">
              <span className="text-blue-950 text-sm">Còn lại</span>
              <span className="text-primary">
                {formatNumberToAmount(item?.totalStock - item?.totalSoldTock)}
              </span>
            </div>
          </div>
          <p className="text-blue-950 line-clamp-2">{item.description}</p>

          {!isClient &&
            renderStatus(item, (event) => handleOrderButtonClick(event, item))}
        </div>
      </div>

      <Popup
        header="Đầu tư"
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
      >
        <BuyForm
          campaign={selectInvestment}
          onCancel={() => setIsPopupOpen(false)}
          handleSetInfoQrCode={handleSetInfoQrCode}
          setOrderId={setOrderId}
        />
      </Popup>

      <Popup
        header="Thông tin thanh toán"
        isOpen={isPopupOpenPayment}
        onClose={() => setIsPopupOpenPayment(false)}
      >
        <FormInfoQrCode
          handleConfirmPayment={handleConfirmPayment}
          info={infoQrCode}
        />
      </Popup>
    </>
  );
};

export default CampaignInvestItem;
