import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import { partenrApi } from "../apis";

export const getAllPartners = createAsyncThunk(
  "/partner/getAllPartner",
  async (query) => {
    try {
      const response = await partenrApi.getAllPartner({ q: query });
      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const createPartner = async (payload) => {
  try {
    await partenrApi.createPartnerForAdmin(payload);
  } catch (error) {
    throw new Error(error);
  }
};

export const getAllCarTypeWOPaging = createAsyncThunk(
  "/partner/getAllCarTypeWOPaging",
  async (query) => {
    try {
      const response = await partenrApi.getAllCarTypeWOPaging(query);
      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getAllWorkPlaceWOPaging = createAsyncThunk(
  "/partner/getAllWorkPlaceWOPaging",
  async (query) => {
    try {
      const response = await partenrApi.getAllWorkPlaceWOPaging(query);
      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const deleteOneUser = createAsyncThunk(
  "/partner/deteleOneUser",
  async (query) => {
    try {
      const response = await partenrApi.deleteOneUser(query);
      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const PartnerSlice = createSlice({
  name: "partner",
  initialState: {
    partners: {
      list: [],
      total: 0,
    },

    carTypes: [],

    workPlaces: [],
  },

  extraReducers: (builder) => {
    builder.addCase(getAllPartners.fulfilled, (state, { payload }) => {
      state.partners.list = payload?.data || [];
      state.partners.total = payload?.total || 0;
    });

    builder.addCase(getAllCarTypeWOPaging.fulfilled, (state, { payload }) => {
      state.carTypes = payload || [];
    });

    builder.addCase(getAllWorkPlaceWOPaging.fulfilled, (state, { payload }) => {
      console.log("pay", payload);
      state.workPlaces = payload || [];
    });
  },
});

export default PartnerSlice.reducer;
