const { useEffect } = require("react");

const MAX = 128;
const k = 0.4;
function appr(x) {
    return MAX * (1 - Math.exp((-k * x) / MAX));
}

function addPullIndicator(el) {
    const indicator = el.querySelector(".pull-indicator");

    if (indicator) {
        return
    }

    const pullIndicator = document.createElement("div");
    pullIndicator.className = "pull-indicator";
    pullIndicator.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-loader-circle"><path d="M21 12a9 9 0 1 1-6.219-8.56"/></svg>';

    el.appendChild(pullIndicator);
}

function removePullIndicator(el) {
    const pullIndicator = el.querySelector(".pull-indicator");

    if (pullIndicator) {
        pullIndicator.remove();
    }
}

const usePullRefresh = (ref, onTrigger, depedencies = []) => {
    useEffect(() => {
        const el = ref.current;
        if (!el) return;


        function handleTouchStart(startEvent) {
            const appContent = document.querySelector('.app-content');
            if (!appContent || appContent?.scrollTop) {
                return;
            }
            
            const el = ref.current;
            if (!el) return;
            
            // get the initial Y position
            const initialY = startEvent.touches[0].clientY;

            function handleTouchMove(moveEvent) {
                const el = ref.current;
                if (!el) return;

                // get the current Y position
                const currentY = moveEvent.touches[0].clientY;

                // get the difference
                const dy = currentY - initialY;

                const parentEl = el.parentNode
                if (dy > 100) {
                    addPullIndicator(parentEl);
                }

                if (dy < 0) return;

                // now we are using the `appr` function
                el.style.transform = `translateY(${appr(dy)}px)`;
            }

            function onTransitionEnd() {
                const el = ref.current;
                if (!el) return;

                // remove transition
                el.style.transition = "";

                // cleanup
                el.removeEventListener("transitionend", onTransitionEnd);
            }

            function handleTouchEnd(endEvent) {
                const el = ref.current;
                if (!el) return;

                // return the element to its initial position
                el.style.transform = "translateY(0)";

                // add transition
                el.style.transition = "transform 0.2s";

                const y = endEvent.changedTouches[0].clientY;
                const dy = y - initialY;

                console.log(dy);

                const parentEl = el.parentNode
                if (dy > 100) {
                    removePullIndicator(parentEl);
                }

                if (dy > 300) {
                    onTrigger?.();
                }

                el.addEventListener("transitionend", onTransitionEnd);

                el.removeEventListener("touchmove", handleTouchMove);
                el.removeEventListener("touchend", handleTouchEnd);
            }

            el.addEventListener("touchmove", handleTouchMove);
            el.addEventListener("touchend", handleTouchEnd);

        }

        el.addEventListener("touchstart", handleTouchStart);

        return () => {
            // don't forget to cleanup
            el.removeEventListener("touchstart", handleTouchStart);
        };
    }, [ref.current, ...depedencies]);
};

export default usePullRefresh;