import { FilePlus } from "lucide-react";
import React from "react";
import { useSelector } from "react-redux";

const Document = () => {
  const { unit } = useSelector((state) => state.invest);

  const handleDownloadDocument = (url, fileName = "document") => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = (event) => {
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(xhr.response);
      a.download = fileName;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      const blob = xhr.response;
    };
    xhr.open("GET", url);
    xhr.send();

    // const xhr = new XMLHttpRequest();
    // xhr.open("GET", url, true);
    // xhr.responseType = "blob";

    // xhr.onload = function () {
    //   if (xhr.status === 200) {
    //     const blob = xhr.response;
    //     const downloadUrl = URL.createObjectURL(blob);

    //     const a = document.createElement("a");
    //     a.href = downloadUrl;
    //     a.download = fileName;
    //     document.body.appendChild(a);
    //     a.click();
    //     document.body.removeChild(a);

    //     URL.revokeObjectURL(downloadUrl);
    //   } else {
    //     console.error("Failed to download file:", xhr.statusText);
    //   }
    // };

    // xhr.onerror = function () {
    //   console.error("Network error while downloading file.");
    // };

    // xhr.send();
  };

  return (
    <div className="flex flex-col gap-3">
      {unit?.documents.map((item, index) => {
        return (
          <div
            key={index}
            className="flex items-center gap-2 text-black p-2 bg-[#b6dfc5] rounded-md cursor-pointer"
            onClick={() => handleDownloadDocument(item?.url, item?.name)}
          >
            <FilePlus />
            <span>{item?.name}</span>
          </div>
        );
      })}
    </div>
  );
};

export default Document;
