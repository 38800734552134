import ButtonSystem from "components/e-control/button-system";
import InputSystem from "components/e-control/forms/form-elements/input-system";
import React, { useEffect, useState } from "react";
import { Controller, Form, useForm } from "react-hook-form";
import { FormGroup, Label } from "reactstrap";

import "./index.scss";
import toast from "react-hot-toast";
import { web3Api } from "modules/ego-system/web3/apis";
import { useAuth } from "components/containers/auth";

const FormInputRef = ({ isTypeReferralCode, handleCompleteSubmit }) => {
  const { user } = useAuth();

  const form = useForm({
    mode: "onChange",
    defaultValues: {
      // content: user?.referralCode,
    },
  });

  const [loading, setLoading] = useState(false);

  const { control, formState, watch } = form;

  const { content } = watch();

  const { errors } = formState;

  const submitOrder = async (data) => {
    try {
      setLoading(true);
      await web3Api.submitInviteCode({
        inviteCode: data?.content,
      });

      toast.success("Nhập mã giới thiệu thành công");
      handleCompleteSubmit?.();
    } catch (e) {
      toast.error(e?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isTypeReferralCode) {
      form.setValue("content", user?.referralCode);
      form.setValue("name", user?.referralUser?.name);
    }
  }, [isTypeReferralCode]);

  return (
    <Form
      control={control}
      className="form-input-ref pb-2"
      onSubmit={form.handleSubmit(submitOrder)}
    >
      <div className="flex flex-wrap gap-2">
        <FormGroup className="!mb-0 flex-1">
          <Label className="text-primary">Mã giới thiệu</Label>
          <Controller
            control={control}
            name="content"
            render={({ field }) => (
              <InputSystem
                {...field}
                className="[&>div>input]:bg-white [&>div>input]:text-[16px] text-black"
                placeholder="Mã giới thiệu"
                disabled={isTypeReferralCode}
              />
            )}
            rules={{
              required: "Vui lòng nhập mã giới thiệu",
            }}
          />

          <div className="text-[red] text-xs">{errors?.content?.message}</div>
        </FormGroup>
      </div>

      {isTypeReferralCode && (
        <div className="flex flex-wrap gap-2">
          <FormGroup className="!mb-0 flex-1">
            <Label className="text-primary">Tên người giới thiệu</Label>
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <InputSystem
                  {...field}
                  className="[&>div>input]:bg-white [&>div>input]:text-[16px] text-black"
                  placeholder="Tên người giới thiệu giới thiệu"
                  disabled={isTypeReferralCode}
                />
              )}
            />
          </FormGroup>
        </div>
      )}

      {!isTypeReferralCode && (
        <div className="flex justify-center">
          <ButtonSystem
            disabled={!content}
            type="submit"
            color="primary"
            className="flex-2 w-full"
            loading={loading}
          >
            Xác nhận
          </ButtonSystem>
        </div>
      )}
    </Form>
  );
};

export default FormInputRef;
