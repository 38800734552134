import { gql } from "@apollo/client";
import { mutate, query } from "configs/http-config";

const driverAction = {
  getAllDriver: gql`
    query getAllDriver(
      $page: Int
      $limit: Int
      $search: String
      $filter: Mixed
      $fromDate: DateTime
      $toDate: DateTime
    ) {
      getAllDriver(
        q: {
          limit: $limit
          page: $page
          search: $search
          filter: $filter
          order: { createdAt: -1 }
        }
        fromDate: $fromDate
        toDate: $toDate
      ) {
        data {
          id
          name
          username
          phone
          email
          password
          isBlock
          key
          role
          identityCard
          avatar
          address
          location
          signInProvider
          isActived
          createdAt
          updatedAt
          driverLicense
          tripIds
          status
          trips {
            id
            status
          }
          workPlaceId
          salary
          workPlace {
            name
          }

          car {
            id
            carCode
            images
            driverId
            status
            category
            color
            carCompany
            tranportationType
            transmisstionType
            carType {
              category
              id
              name
            }
            fuelType
            licensePlateNumber
            manufacturingYear
            mortgage
            createdAt
            updatedAt
          }
          driverLicenseTypes
          carType {
            id
            businessTypes
            category
            name
            type
          }
          driverLicense
          district {
            id
            name
            workPlaceId
          }
          districtId
        }
        getStatDriver
        total
      }
    }
  `,

  getAllDriverLicenseTypeWOPaging: gql`
    query getAllDriverLicenseTypeWOPaging($type: String) {
      getAllDriverLicenseTypeWOPaging(type: $type) {
        id
        name
        type
      }
    }
  `,

  getAllCurriculumVitae: gql`
    query getAllCurriculumVitae($q: QueryGetListInput) {
      getAllCurriculumVitae(q: $q) {
        data {
          carType {
            id
            name
            icon
            businessTypes
            type
          }

          id
          name
          phone
          workPlaceId
          gender
          birthday
          email
          carCategory
          carCompany
          color
          fuelType
          manufacturingYear
          licensePlateNumber
          carTypeId
          status
          identityCard
          driverLicense
          cardImages
          reasonCancel
          districtId
          countryId

          workPlace {
            name
            location
            id
          }
          workPlaceId
        }

        total
      }
    }
  `,

  getDriverByWorkplaceId: gql`
    query getAllDriverByWorkPlace($workPlaceId: ID!) {
      getAllDriverByWorkPlace(workPlaceId: $workPlaceId) {
        id
        name
        username
        phone
        email
        role
        identityCard
        avatar
        address
        location
        signInProvider
        isActived
        createdAt
        updatedAt
        status
        tripIds
        trips {
          id
          status
        }
        workPlaceId

        workPlace {
          name
        }

        car {
          id
          carCode
          images
          driverId
          status
          createdAt
          updatedAt
        }
      }
    }
  `,

  getAllBooking: gql`
    query getAllBooking(
      $page: Int
      $limit: Int
      $filter: Mixed
      $order: Mixed
      $fromDate: DateTime
      $toDate: DateTime
    ) {
      getAllBooking(
        q: { limit: $limit, page: $page, filter: $filter, order: $order }
        fromDate: $fromDate
        toDate: $toDate
      ) {
        data {
          id
          startPoint
          endPoint
          startAddress
          endAddress
          couponIds
          status
          paymentMethod
          code
          driverId
          distance
          waitTime
          travelTime
          initialCost
          discountAmount
          finalCost
          userId
          driverPickupTime
          driverDropOffTime
          createdAt
          updatedAt

          user {
            name
            username
          }
        }

        total
      }
    }
  `,

  getStatBookingByDriver: gql`
    query getStatBookingByDriver(
      $page: Int
      $limit: Int
      $filter: Mixed
      $order: Mixed
      $fromDate: DateTime
      $toDate: DateTime
    ) {
      getStatBookingByDriver(
        q: { limit: $limit, page: $page, filter: $filter, order: $order }
        fromDate: $fromDate
        toDate: $toDate
      )
    }
  `,

  getOneUser: gql`
    query getOneUser($id: ID!) {
      getOneUser(id: $id) {
        id
        name
        username
        phone
        email
        password
        isBlock
        driverLicense
        key
        role
        isTaxiCar
        identityCard
        avatar
        address
        signInProvider
        isActived
        createdAt
        updatedAt

        status

        workPlaceId

        workPlace {
          name
        }

        driverLicenseInfos {
          id
          driverLicense
          driverLicenseType {
            id
            name
            type
          }
          type
        }

        car {
          id
          carCode
          images
          driverId
          status
          category
          color
          carCompany
          tranportationType
          transmisstionType

          carType {
            category
            id
            name
            businessTypes
          }
          fuelType
          licensePlateNumber
          manufacturingYear
          mortgage
          createdAt
          updatedAt
        }
        driverLicenseTypes
        carType {
          id
          businessTypes
          category
          name
          type
        }
        carTypeId
      }
    }
  `,

  getAllCarWOPaging: gql`
    query getAllCarWOPaging(
      $workPlaceId: ID
      $carTypeId: ID!
      $districtId: ID
    ) {
      getAllCarWOPaging(
        workPlaceId: $workPlaceId
        carTypeId: $carTypeId
        districtId: $districtId
      ) {
        id
        carCode
        licensePlateNumber
        images
        driverId
        status
        carTypeId
        carType {
          id
          isActived
          name
        }
        createdAt
        updatedAt
      }
    }
  `,

  createUserForDriver: gql`
    mutation createUserForDriver(
      $email: String
      $phoneNumber: String!
      $identityCard: String
      $address: String!
      $avatar: String
      $name: String!
      $workPlaceId: String
      $driverLicense: String
      $carCode: String
      $licensePlateNumber: String
      $category: String
      $carTypeId: ID
      $carCompany: String
      $color: String
      $manufacturingYear: String
      $fuelType: String
      $transmisstionType: String
      $images: [String]
      $driverLicenseTypes: [String]
      $password: String
      $driverLicenseTypeIds: [ID]
      $driverLicenseMoto: String
      $driverLicenseOto: String
    ) {
      createUserForDriver(
        email: $email
        phoneNumber: $phoneNumber
        identityCard: $identityCard
        address: $address
        avatar: $avatar
        name: $name
        workPlaceId: $workPlaceId
        driverLicense: $driverLicense

        carCode: $carCode
        licensePlateNumber: $licensePlateNumber
        category: $category
        carTypeId: $carTypeId
        carCompany: $carCompany
        color: $color
        manufacturingYear: $manufacturingYear
        fuelType: $fuelType
        transmisstionType: $transmisstionType
        images: $images
        driverLicenseTypes: $driverLicenseTypes
        password: $password

        driverLicenseTypeIds: $driverLicenseTypeIds
        driverLicenseMoto: $driverLicenseMoto
        driverLicenseOto: $driverLicenseOto
      ) {
        id
      }
    }
  `,

  deleteOneUser: gql`
    mutation deleteOneUser($id: ID!) {
      deleteOneUser(id: $id) {
        id
        phone
      }
    }
  `,

  updateUser: gql`
    mutation updateUser($id: ID!, $data: UpdateUserInput!) {
      updateUser(id: $id, data: $data) {
        id
        phone
      }
    }
  `,

  addCarForDriver: gql`
    mutation addCarForDriver($driverId: String!, $carId: String!) {
      addCarForDriver(driverId: $driverId, carId: $carId)
    }
  `,

  changeCarForDriver: gql`
    mutation changeCarForDriver($driverId: String!, $carId: String!) {
      changeCarForDriver(driverId: $driverId, carId: $carId)
    }
  `,

  removeCarForDriver: gql`
    mutation removeCarForDriver($driverId: String!, $carId: String!) {
      removeCarForDriver(driverId: $driverId, carId: $carId)
    }
  `,

  resetPasswordForAdmin: gql`
    mutation resetPasswordForAdmin($userId: ID!, $password: String!) {
      resetPasswordForAdmin(userId: $userId, password: $password)
    }
  `,

  getDriversNearBooking: gql`
    query getDriversNearBooking(
      $tripId: ID!
      $filter: Mixed
      $lat: Float!
      $lng: Float!
      $type: String
    ) {
      getDriversNearBooking(
        tripId: $tripId
        type: $type
        q: { filter: $filter }
      ) {
        data {
          id
          name
          username
          phone
          email
          role
          identityCard
          avatar
          address
          location
          signInProvider
          isActived
          createdAt
          updatedAt
          status
          tripIds
          revenueToday
          distance(latitude: $lat, longitude: $lng)
          distanceCalculated
          trips {
            id
            status
          }
          workPlaceId

          workPlace {
            name
          }

          car {
            id
            carCode
            images
            driverId
            status
            createdAt
            updatedAt
          }
        }
      }
    }
  `,

  approveCurriculumVitae: gql`
    mutation approveCurriculumVitae($id: ID!) {
      approveCurriculumVitae(id: $id)
    }
  `,

  refuseCurriculumVitae: gql`
    mutation refuseCurriculumVitae($id: ID!, $reasonCancel: String!) {
      refuseCurriculumVitae(id: $id, reasonCancel: $reasonCancel)
    }
  `,

  getOneCurriculumVitae: gql`
    query getOneCurriculumVitae($id: ID!) {
      getOneCurriculumVitae(id: $id) {
        birthday
        carCategory
        carCompany
        carType {
          id
          name
        }
        carTypeId
        cardImages
        color
        createdAt
        driverLicense
        email
        fuelType
        gender
        id
        identityCard
        licensePlateNumber
        name
        manufacturingYear
        phone
        reasonCancel
        status
        updatedAt
        workPlace {
          id
          name
        }
        workPlaceId
      }
    }
  `,

  getAllCarForDriverEgo: gql`
    query getAllCarForDriverEgo($q: QueryGetListInput, $driverId: ID) {
      getAllCarForDriverEgo(q: $q, driverId: $driverId) {
        data {
          id
          address
          businessTypes
          color
          images
          carCode
          carCompany
          category
          licensePlateNumber
          manufacturingYear
          createdAt
          fuelType
          verifyStatus
          reasonRefuse
          price
          tranportationType
          transmisstionType
          carTypeId
          businessType
        }
        total
      }
    }
  `,
};

export const driverApi = {
  getAllCurriculumVitae: (payload) => {
    return query(
      "getAllCurriculumVitae",
      driverAction.getAllCurriculumVitae,
      payload
    );
  },

  getAllDriver: (payload) => {
    return query("getAllDriver", driverAction.getAllDriver, payload);
  },

  getAllDriverByWorkplace: (payload) => {
    return query(
      "getAllDriverByWorkPlace",
      driverAction.getDriverByWorkplaceId,
      payload
    );
  },

  getAllBooking: (payload) => {
    return query("getAllBooking", driverAction.getAllBooking, payload);
  },

  getStatBookingByDriver: (payload) => {
    return query(
      "getStatBookingByDriver",
      driverAction.getStatBookingByDriver,
      payload
    );
  },

  getOneUser: (payload) => {
    return mutate("getOneUser", driverAction.getOneUser, payload);
  },

  getOneCurriculumVitae: (payload) => {
    return mutate(
      "getOneCurriculumVitae",
      driverAction.getOneCurriculumVitae,
      payload
    );
  },

  getAllCarWOPaging: (payload) => {
    return query("getAllCarWOPaging", driverAction.getAllCarWOPaging, payload);
  },

  createUserForDriver: (payload) => {
    return mutate(
      "createUserForDriver",
      driverAction.createUserForDriver,
      payload
    );
  },

  deleteOneUser: (payload) => {
    return mutate("deleteOneUser", driverAction.deleteOneUser, payload);
  },

  updateUser: (payload) => {
    return mutate("updateUser", driverAction.updateUser, payload);
  },

  addCarForDriver: (payload) => {
    return mutate("addCarForDriver", driverAction.addCarForDriver, payload);
  },

  changeCarForDriver: (payload) => {
    return mutate(
      "changeCarForDriver",
      driverAction.changeCarForDriver,
      payload
    );
  },

  removeCarForDriver: (payload) => {
    return mutate(
      "removeCarForDriver",
      driverAction.removeCarForDriver,
      payload
    );
  },

  resetPasswordForAdmin: (payload) => {
    return mutate(
      "resetPasswordForAdmin",
      driverAction.resetPasswordForAdmin,
      payload
    );
  },

  getDriversNearBooking: ({
    tripId,
    status,
    startPoint,
    isActived,
    filter,
    type,
  }) => {
    return query("getDriversNearBooking", driverAction.getDriversNearBooking, {
      tripId: tripId,
      lng: startPoint?.[0],
      lat: startPoint?.[1],
      filter: { status, isActived, ...filter },
      type: type,
    });
  },

  approveCurriculumVitae: (payload) => {
    return mutate(
      "approveCurriculumVitae",
      driverAction.approveCurriculumVitae,
      payload
    );
  },

  refuseCurriculumVitae: (payload) => {
    return mutate(
      "refuseCurriculumVitae",
      driverAction.refuseCurriculumVitae,
      payload
    );
  },

  getAllCarForDriverEgo: (payload) => {
    return query(
      "getAllCarForDriverEgo",
      driverAction.getAllCarForDriverEgo,
      payload
    );
  },

  getAllDriverLicenseTypeWOPaging: (payload) => {
    return query(
      "getAllDriverLicenseTypeWOPaging",
      driverAction.getAllDriverLicenseTypeWOPaging,
      payload
    );
  },
};
