import classNames from "classnames";
import ViewCondition from "components/utils/view-condition";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

// ** Style Import
import "./index.scss";

const ModalSystem = (props) => {
  const {
    children,
    bodyClassName = "",
    className = "",
    header = null,
    footer = null,
    close = null,
    headerClass = "",
    ...restProps
  } = props;

  return (
    <Modal className={classNames(["modal-system", className])} {...restProps}>
      <ModalHeader
        className={classNames("modal-system__header", headerClass)}
        toggle={restProps.onClose}
        onClose={close}
      >
        {header}
      </ModalHeader>

      <ModalBody className={classNames(["modal-system__body overflow-hidden", bodyClassName])}>
        {children}
      </ModalBody>

      <ViewCondition rIf={footer}>
        <ModalFooter className="modal-system__footer">{footer}</ModalFooter>
      </ViewCondition>
    </Modal>
  );
};

export default ModalSystem;
