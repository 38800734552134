import classNames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { ChevronLeft } from "lucide-react";
import "./index.scss";

const DrawerFilterSystem = (props) => {
  const {
    onClose,
    header = null,

    contentClassName,
    wrapperClassName,
    className,
    size = "sm",
    headerClassName,
    children,
    form,
    onSubmit,
    ...rest
  } = props;

  return (
    <Modal
      toggle={onClose}
      contentClassName="pb-4 overflow-hidden drawer-system"
      modalClassName={classNames("modal-slide-in", {
        [wrapperClassName]: wrapperClassName,
      })}
      className={classNames({
        [className]: className,
        "sidebar-xl": size === "xl",
        "sidebar-lg": size === "lg",
        "sidebar-sm": size === "sm",
        "sidebar-full": size === "full",
      })}
      {...rest}
    >
      <ModalHeader
        className={classNames({
          [headerClassName]: headerClassName,
        })}
        close={null}
        toggle={onClose}
      >
        <div className="flex flex-wrap justify-between items-center w-full gap-x-4">
          <div className="truncate ... flex items-center justify-start gap-x-2">
            <div onClick={onClose} className="md:hidden visible block">
              <ChevronLeft className="w-6 h-6 text-primary" />
            </div>

            {header}
          </div>
        </div>
      </ModalHeader>

      <PerfectScrollbar options={{ wheelPropagation: false }} className="pb-4">
        <ModalBody className="h-full">{children}</ModalBody>
      </PerfectScrollbar>
    </Modal>
  );
};

export default DrawerFilterSystem;
