import { getAllInvestCampaigns } from "modules/invest/stores";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { dispatch } from "root-stores";
import CampaignInvestItem from "../../campaign-card-item";

const TabInvest = ({ unitId }) => {
  const { campaigns, orders } = useSelector((state) => state.invest);
  const { records, total } = campaigns;

  useEffect(() => {
    if (unitId) {
      dispatch(
        getAllInvestCampaigns({
          page: 1,
          limit: 100,
          filter: { investmentUnitId: unitId },
          // order: { startDate: -1 },
        })
      );
    }
  }, [unitId]);

  return (
    <div className="w-full flex flex-col gap-2">
      {records.map((item) => (
        <div className="w-full" key={item.id}>
          <CampaignInvestItem isInvestDetail={true} item={item} />
        </div>
      ))}
    </div>
  );
};

export default TabInvest;
