import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { dispatch } from "root-stores";


import PageWrapper from "components/containers/page/page-wrapper";
import ButtonSystem from "components/e-control/button-system";
import ViewDevice from "components/utils/view-device";
import { getUserDetail } from "modules/ego-system/user/stores";
import { useSelector } from "react-redux";
import { INVEST_ROUTES } from "router/invest-routes";
import CardInvestDetail from "./components/card-invest-detail";
import InvestAccountTableProject from "./components/table-invest-project";

const AccountInvestDetailPage = () => {
    const params = useParams();
    const { id } = params;
    const navigate = useNavigate();

    const [loadingDetail, setLoadingDetail] = useState(false);

    const { userDetail } = useSelector((state) => state.user);

    const loadDetailUser = async () => {
        try {
            setLoadingDetail(true);
            const params = {
                id: id,
            };

            await dispatch(getUserDetail(params));
        } catch (error) {
            return;
        } finally {
            setLoadingDetail(false);
        }
    };

    useEffect(() => {
        loadDetailUser()
    }, [id]);

    return (
        <PageWrapper
            className="detail-user"
            title="Chi tiết nhà đầu tư"
            breadcrumbs={[
                { title: "Danh sách nhà đầu tư", link: INVEST_ROUTES.ACCOUNT },
                { title: userDetail?.name },
            ]}
            actions={
                <ViewDevice>
                    <ButtonSystem
                        color="primary"
                        onClick={() => navigate(INVEST_ROUTES.ACCOUNT)}
                    >
                        Quay lại
                    </ButtonSystem>
                </ViewDevice>
            }
            depedencies={[id]}
            reload={loadDetailUser}
            onBack={() => navigate(INVEST_ROUTES.ACCOUNT)}
        >
            <div className="grid grid-cols-1 md:grid-cols-[320px_auto] gap-x-4 gap-4">
                <CardInvestDetail
                    loading={loadingDetail}
                />
                    
                <InvestAccountTableProject />
            </div>
        </PageWrapper>
    );
};

export default AccountInvestDetailPage;
