import React from "react";
import { useNavigate } from "react-router-dom";

import air from "../../../../assets/images/icons/ic_menu_insurance.svg";
import stake from "../../../../assets/images/icons/ic_menu_staking.svg";
import invest from "../../../../assets/images/icons/ic_menu_invest.svg";
import accumulate from "../../../../assets/images/icons/ic_menu_saving.svg";

import { INVEST_ROUTES } from "router/invest-routes";

const DAppTab = () => {
  const navigate = useNavigate();
  const LIST_APP = [
    {
      icon: invest,
      text: "Invest",
      route: INVEST_ROUTES.INVEST_WEBVIEW,
    },

    {
      icon: air,
      text: "Airdrop",
      route: INVEST_ROUTES.WEB3_EGO,
    },

    {
      icon: accumulate,
      text: "Accumulate",
      route: INVEST_ROUTES.ACCUMULATE,
    },

    {
      icon: stake,
      text: "Staking USDT",
      route: INVEST_ROUTES.STAKING_EGO,
    },
  ];
  return (
    <div className="mt-8 px-0">
      <div className="flex items-center gap-8">
        {LIST_APP.map((item, index) => (
          <div
            key={index}
            className="flex flex-col gap-1 items-center justify-center cursor-pointer"
            onClick={() => navigate(item?.route)}
          >
            <img className="w-10 h-10" src={item?.icon} alt={item?.text} />

            <span className="text-sm font-medium">{item?.text}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DAppTab;
