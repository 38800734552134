import { gql } from "@apollo/client";
import { mutate, query } from "configs/http-config";

const driversAction = {
  getAllPartnerDriver: gql`
    query getAllPartnerDriver(
      $page: Int
      $limit: Int
      $order: Mixed
      $filter: Mixed
      $search: String
      $partnerId: ID!
    ) {
      getAllPartnerDriver(
        q: {
          limit: $limit
          page: $page
          filter: $filter
          order: $order
          search: $search
        }
        partnerId: $partnerId
      ) {
        data {
          id
          name
          password
          phone
          email
          address
          isTaxiCar
          identityCard
          driverLicenseTypes
          driverLicenseInfos {
            id
            driverLicense
            driverLicenseType {
              id
              name
              type
            }
            type
          }
          avatar
          carId
          carTypeId
          workPlaceId
          status
          partnerId
          isActived
          location
          isConnectedEgo
          countTrip
          car {
            id
            carCode
            carTypeId
            status
          }
          carType {
            id
            name
            isActived
            type
            workPlaceIds
          }
          workPlace {
            id
            name
          }
          partner {
            id
            name
            username
            phone
            email
            password
            isBlock
            key
            role
            location
            identityCard
            avatar
            address
            signInProvider
            isActived
            carId
            workPlaceId
            checkPointIds
            tripIds
            distanceCalculated
            status
            usedCouponIds
            salary
            bonus
            walletId
            revenueToday
            carTypeId
            point
            rank
            workPlaceIds
            carTypeIds
          }
          driverLicense
          createdAt
          updatedAt
        }
        total
      }
    }
  `,

  createPartnerDriver: gql`
    mutation createPartnerDriver($data: createPartnerDriverInput!) {
      createPartnerDriver(data: $data) {
        id
        name
      }
    }
  `,

  updatePartnerDriver: gql`
    mutation updateUser($id: ID!, $data: UpdateUserInput!) {
      updateUser(id: $id, data: $data) {
        id
        phone
      }
    }
  `,

  deleteOnePartnerDriver: gql`
    mutation deleteOneUser($id: ID!) {
      deleteOneUser(id: $id) {
        id
      }
    }
  `,
};

export const driverPartnerApi = {
  getAllPartnerDriver: (payload) => {
    return query("getAllPartnerDriver", driversAction.getAllPartnerDriver, {
      ...payload,
    });
  },

  createPartnerDriver: (payload) => {
    return mutate(
      "createPartnerDriver",
      driversAction.createPartnerDriver,
      payload
    );
  },

  updatePartnerDriver: (payload) => {
    return mutate(
      "updatePartnerDriver",
      driversAction.updatePartnerDriver,
      payload
    );
  },

  deleteOnePartnerDriver: (payload) => {
    return mutate(
      "deleteOnePartnerDriver",
      driversAction.deleteOnePartnerDriver,
      payload
    );
  },
};
