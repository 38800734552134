import { gql } from "@apollo/client";
import { mutate, query } from "configs/http-config";

const garageAction = {
  getAllGarage: gql`
    query getAllGarage($partnerId: ID!, $q: QueryGetListInput) {
      getAllGarage(q: $q, partnerId: $partnerId) {
        data {
          id
          name
          location
          country {
            id
            name
          }
          address
          countryId
          partnerId
          workPlaceId
          workPlace {
            id
            name
          }
        }
        total
      }
    }
  `,

  getAllGarageForAdmin: gql`
    query getAllGarageForAdmin($q: QueryGetListInput) {
      getAllGarageForAdmin(q: $q) {
        data {
          id
          name
          location
          country {
            id
            name
          }
          address
          countryId
          partnerId
          workPlaceId
          workPlace {
            id
            name
          }
        }
        total
      }
    }
  `,

  createGarage: gql`
    mutation createGarage($data: CreateGarageInput!) {
      createGarage(data: $data) {
        address
        countryId
        id
        location
        name
        partnerId
        workPlace {
          id
          name
          location
        }
        workPlaceId
        country {
          id
          name
        }
      }
    }
  `,

  getOneGarage: gql`
    query getOneGarage($id: ID!) {
      getOneGarage(id: $id) {
        address
        country {
          id
          name
        }
        countryId
        id
        location
        name
        partner {
          id
          name
        }
        partnerId
        workPlace {
          id
          location
          name
        }
        workPlaceId
      }
    }
  `,

  deleteOneGarage: gql`
    mutation deleteOneGarage($id: ID!) {
      deleteOneGarage(id: $id) {
        id
      }
    }
  `,

  updateGarage: gql`
    mutation updateGarage($id: ID!, $data: UpdateGarageInput!) {
      updateGarage(id: $id, data: $data) {
        id
      }
    }
  `,
};

export const garageApi = {
  getAllGarage: (payload) => {
    return query("getAllGarage", garageAction.getAllGarage, payload);
  },

  getAllGarageForAdmin: (payload) => {
    return query(
      "getAllGarageForAdmin",
      garageAction.getAllGarageForAdmin,
      payload
    );
  },

  getOneGarage: (payload) => {
    return mutate("getOneGarage", garageAction.getOneGarage, payload);
  },

  createGarage: (payload) => {
    return mutate("createGarage", garageAction.createGarage, payload);
  },

  deleteOneGarage: (payload) => {
    return mutate("deleteOneGarage", garageAction.deleteOneGarage, payload);
  },

  updateGarage: (payload) => {
    return mutate("updateGarage", garageAction.updateGarage, payload);
  },
};
