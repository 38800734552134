import { createSlice } from "@reduxjs/toolkit";
import { combo, comboBuilder } from "./combo";
import { modifier, modifierBuilder } from "./modifier";
import { categoryBuilder, productCategories } from "./product-type";
import { productBuilder, products } from "./products";
import { stockBuilder, stocks } from "./stock";
import { unit, unitBuilder } from "./unit-type";
import { warehouse, warehouseBuilder } from "./ware-house";
import { supplier, supplierBuilder } from "./suppiler";

export const shopSlice = createSlice({
  name: "shop",
  initialState: {
    products: products,
    categories: productCategories,
    units: unit,
    stocks: stocks,
    modifier: modifier,
    combo: combo,
    warehouse: warehouse,
    supplier: supplier,
  },

  reducers: {
    setModifierGroupAction: (state, { payload }) => {
      state.modifier.modifierGroup.modifierGroupAction = payload;
    },

    setModifierAction: (state, { payload }) => {
      state.modifier.modifiers.modifierAction = payload;
    },

    setComboAction: (state, { payload }) => {
      state.combo.comboAction = payload;
    },
  },

  extraReducers: (builder) => {
    productBuilder(builder);
    categoryBuilder(builder);
    unitBuilder(builder);
    stockBuilder(builder);
    modifierBuilder(builder);
    comboBuilder(builder);
    warehouseBuilder(builder);
    supplierBuilder(builder);
  },
});

export const { setModifierGroupAction, setModifierAction, setComboAction } =
  shopSlice.actions;

export default shopSlice.reducer;
