import ComponentSpinner from "components/controls/spinner/Loading-spinner";
import MAP_OPTIONS from "modules/ego-system/gg-map/constants";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import BookingItem from "../booking-item";

import { bookingApi } from "modules/ego-system/bookings/apis";
import {
    getDriverNearBooking,
    removeDriverIds,
} from "modules/ego-system/hotline/store";
import toast from "react-hot-toast";
import { dispatch } from "root-stores";
import "./index.scss";

const HotlineBookingDetail = ({ handleCompleteCancelBooking }) => {
  // ** Store
  const { bookingActions } = useSelector((state) => state.hotline);

  const bookingStatus = useMemo(() => {
    return bookingActions.status;
  }, [bookingActions]);

  const pendingBooking = useMemo(() => {
    return bookingStatus === MAP_OPTIONS.BOOKING_STATUS.PENDING;
  }, [bookingStatus]);

  const loadDrivers = async () => {
    try {
      const payload = {
        tripId: bookingActions?.id,
        startPoint: bookingActions?.startPoint,
      };

      await dispatch(getDriverNearBooking(payload)).unwrap();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const cancelBooking = async () => {
    try {
      const payload = {
        tripId: bookingActions?.id,
        reasonCancel: "",
      };

      await bookingApi.cancelBookingByHotline(payload);

      handleCompleteCancelBooking();
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (pendingBooking) {
      loadDrivers();
    }
  }, [bookingActions, pendingBooking]);

  useEffect(() => {
    return () => {
      dispatch(removeDriverIds());
    };
  }, []);

  return (
    <div className="hotline-booking-detail">
      <div className="hotline-booking-info">
        <BookingItem booking={bookingActions} cancelBooking={cancelBooking} />
      </div>

      <div className="hotline-booking-detail__content">
        {pendingBooking && (
          <div className="hotline-booking-detail__pending">
            <ComponentSpinner />
            <p className="hotline-booking-detail__pending-alert">
              Yêu cầu tài xế. Xin vui long chờ...
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default HotlineBookingDetail;
