import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toastNotice from "components/utils/toast-notice";
import { investApi } from "../apis";
import { SampleData } from "../client/sample-data";


export const getAllInvestAccounts = createAsyncThunk(
    "/product/getAllInvestAccounts",
    async (params) => {
        try {
            const response = await investApi.getInvestmentAccounts(params);

            return response
        } catch (error) {
            toastNotice({
                title: 'Lỗi',
                description: error.message,
            })
        }
    }
)

export const getAllInvestOrder = createAsyncThunk(
    "/product/getAllInvestOrder",
    async (params) => {
        try {
            console.log('params', params)
            const response = await investApi.getInvestmentOrder(params);

            return {response, page: params?.page}
        } catch (error) {
            toastNotice({
                title: 'Lỗi',
                descriptions: error.message,
            })
        }
    }
)

export const getOneInvestmentById = createAsyncThunk(
    "/product/getOneInvestmentById",
    async (id) => {
        try {
            const response = await investApi.getOneInvestmentById(id);

            return response
        } catch (error) {
            toastNotice({
                title: 'Lỗi',
                descriptions: error.message,
            })
        }
    }
)

export const getAllInvestCampaigns = createAsyncThunk(
    "/product/getAllInvestCampaigns",
    async (params) => {
        try {
            const response = await investApi.getInvestmentCampaigns(params);

            return response
        } catch (error) {
            toastNotice({
                title: 'Lỗi',
                descriptions: error.message,
            })
        }
    }
)

export const getAllInvestUnit = createAsyncThunk(
    "/product/getAllInvestUnit",
    async (params) => {
        try {
            const response = await investApi.getInvestmentUnit(params);

            return response
        } catch (error) {
            toastNotice({
                title: 'Lỗi',
                description: error.message,
            })
        }
    }
)

export const getOneInvestUnit = createAsyncThunk(
    "/product/getOneInvestUnit",
    async (id) => {
        try {
            const response = await investApi.getOneInvestmentUnit(id);

            return response
        } catch (error) {
            toastNotice({
                title: 'Lỗi',
                description: error.message,
            })
        }
    }
)

export const investSlice = createSlice({
    name: "invest",
    initialState: {
        accounts: {
            records: [],
            total: 0,
        },

        account: null,

        campaigns: {
            records: [],
            total: 0,
        },
        campaign: null,

        units: {
            records: [],
            total: 0,
        },
        unit: null,

        webview: {
            list: SampleData,
            detail: SampleData[1],
        },

        orders: {
            clientRecords: [],
            records: [],
            total: 0,
        },
    },

    reducers: {
        setCampaign: (state, { payload }) => {
            state.campaign = payload
        },

        resetCampaigns: (state) => {
            state.campaigns = {
                records: [],
                total: 0,
            }
        },

        resetOrderHistory: (state) => {
            state.campaigns = {
                records: [],
                total: 0,
            }
        },

        setUnit: (state, { payload }) => {
            state.unit = payload;
        }
    },

    extraReducers: (builder) => {
        builder.addCase(getAllInvestCampaigns.fulfilled, (state, { payload }) => {
            state.campaigns.records = payload.data
            state.campaigns.total = payload.total
        });

        builder.addCase(getAllInvestOrder.fulfilled, (state, { payload }) => {
            state.orders.records = payload?.response?.data
            state.orders.total = payload?.response?.total

            if (payload?.page === 1) {
                state.orders.clientRecords = [];
            }
            
            state.orders.clientRecords.push(payload?.response?.data)
        });

        builder.addCase(getOneInvestmentById.fulfilled, (state, { payload }) => {
            state.campaignDetail = payload;
        });

        builder.addCase(getAllInvestAccounts.fulfilled, (state, { payload }) => {
            state.accounts.records = payload?.data || [];
            state.accounts.total = payload?.total || 0;
        });

        builder.addCase(getAllInvestUnit.fulfilled, (state, { payload }) => {
            state.units.records = payload?.data || [];
            state.units.total = payload?.total || 0;
        });

        builder.addCase(getOneInvestUnit.fulfilled, (state, { payload}) => {
            state.unit = payload;
        });
    }
})

export const { setCampaign, resetCampaigns, resetOrderHistory, setUnit } = investSlice.actions


export default investSlice.reducer;
