import { toast } from "react-hot-toast";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { connectEgoPartnerApi } from "modules/transportation-system/modules/connect-ego/apis";

export const getAllCarWOPaging = createAsyncThunk(
  "/ecar/getAllCarWOPaging",
  async (query) => {
    try {
      const response = await connectEgoPartnerApi.getAllPartnerCarWOPaging(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getAllECarInfo = createAsyncThunk(
  "/ecar/getAllCarInfo",
  async (query) => {
    try {
      const response = await connectEgoPartnerApi.getAllCarInfo(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getAllPartnerCar = createAsyncThunk(
  "/ecar/getAllPartnerCar",
  async (query) => {
    try {
      const response = await connectEgoPartnerApi.getAllPartnerCar(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const eCarSlice = createSlice({
  name: "eCar",
  initialState: {
    eCar: {
      list: [],
      total: 0,
    },

    eCarInfor: {
      list: [],
    },

    cars: {
      list: [],
      total: 0,
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllCarWOPaging.fulfilled, (state, { payload }) => {
      state.eCar.list = payload || [];
    });

    builder.addCase(getAllECarInfo.fulfilled, (state, { payload }) => {
      state.eCarInfor.list = payload?.data[0]?.carCompanies || [];
    });

    builder.addCase(getAllPartnerCar.fulfilled, (state, { payload }) => {
      state.cars.list = payload?.data || [];
      state.cars.total = payload?.total || 0
    });
  },
});

export default eCarSlice.reducer;
