import { mutate, query } from "configs/http-config";
import {
  confirmPaymentInvestmentOrder,
  createInvestmentCampaign,
  createInvestmentOrder,
  createInvestmentUnit,
  deleteOneInvestmentProject,
  deleteOneInvestmentUnit,
  getAllInvestmentOrder,
  getAllInvestmentProject,
  getInvestStats,
  getOneInvestmentOrder,
  getOneInvestmentOrderById,
  getOneInvestmentUnit,
  investmentUnit,
  investorAccount,
  oneInvestmentUnit,
  paymentInvestmentOrder,
  sendConfirmPaymentInvestmentOrder,
  updateInvestmentOrder,
  updateInvestmentProject,
  updateInvestmentUnit,
} from "./schema";

export const investApi = {
  getInvestmentAccounts: (params) => {
    return query("getAllUser", investorAccount, {
      q: { ...params, filter: { isInvestor: true, role: "CLIENT" } },
    });
  },

  getInvestmentCampaigns: (params) => {
    return query("getAllInvestmentProject", getAllInvestmentProject, {
      q: params,
    });
  },

  creatInvestmentCampaigns: (payload) => {
    return mutate("createInvestmentProject", createInvestmentCampaign, {
      data: payload,
    });
  },

  getInvestmentOrder: (params) => {
    return query("getAllInvestmentOrder", getAllInvestmentOrder, { q: params });
  },

  getOneInvestmentById: (id) => {
    return query("getOneInvestmentOrder", getOneInvestmentOrder, {
      getOneInvestmentOrderId: id,
    });
  },

  getOneInvestmentOrderById: (id) => {
    return query("getOneInvestmentOrderById", getOneInvestmentOrderById, {
      id: id,
    });
  },

  createInvestmentOrder: (payload) => {
    return mutate("createInvestmentOrder", createInvestmentOrder, {
      data: payload,
    });
  },

  paymentInvestmentOrder: (payload) => {
    return mutate("paymentInvestmentOrder", paymentInvestmentOrder, payload);
  },

  getInvestmentUnit: (params) => {
    return query("getAllInvestmentUnit", investmentUnit, { q: params });
  },

  getOneInvestmentUnit: (id) => {
    return query("getOneInvestmentUnit", oneInvestmentUnit, {
      getOneInvestmentUnitId: id,
    });
  },

  getOneInvestmentUnitId: (id) => {
    return query("getOneInvestmentUnit", getOneInvestmentUnit, id);
  },

  createInvestmentUnit: (payload) => {
    return query("createInvestmentUnit", createInvestmentUnit, {
      data: payload,
    });
  },

  updateInvestmentUnit: (payload) => {
    return query("updateInvestmentUnit", updateInvestmentUnit, payload);
  },

  deleteOneInvestmentUnit: (payload) => {
    return query("deleteOneInvestmentUnit", deleteOneInvestmentUnit, {
      id: payload,
    });
  },

  updateInvestmentProject: (payload) => {
    return query("updateInvestmentProject", updateInvestmentProject, {
      id: payload?.id,
      data: payload?.data,
    });
  },

  deleteOneInvestmentProject: (payload) => {
    console.log("delete", payload);
    return query("deleteOneInvestmentProject", deleteOneInvestmentProject, {
      id: payload,
    });
  },

  sendConfirmPaymentInvestmentOrder: (payload) => {
    return query(
      "sendConfirmPaymentInvestmentOrder",
      sendConfirmPaymentInvestmentOrder,
      { orderId: payload }
    );
  },

  confirmPaymentInvestmentOrder: (payload) => {
    return query(
      "confirmPaymentInvestmentOrder",
      confirmPaymentInvestmentOrder,
      { orderId: payload }
    );
  },

  updateInvestmentOrder: (payload) => {
    return query("updateInvestmentOrder", updateInvestmentOrder, payload);
  },

  getInvestStats: (id) => {
    return query("getInvestStats", getInvestStats, id);
  },
};
