import { ROLE } from "constants";
import { lazy } from "react";
import { KEYS } from "utility/permission/key-config";

const HashtagCardsPage = lazy(() => import("modules/ego-system/tag"));

export const HASHTAG_ROUTES = {
  HAGTAG: "/hash-tag",
};

const HashTagRoutes = [
  {
    path: HASHTAG_ROUTES.HAGTAG,
    element: <HashtagCardsPage />,
    title: 'Thiết lập hashtag',
    meta: {
      permissionKey: KEYS.SUPPER_ADMIN.HASHTAG,
    },
  },
];

export default HashTagRoutes;
