import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";

import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";

import { ApolloClient, HttpLink, InMemoryCache, split } from "@apollo/client";

import toastNotice from "components/utils/toast-notice";
import { CONSTANTS } from "constants";
import { store } from "root-stores";
import localUtils from "utility/local-utils";
import LocalUtils from "utility/storage";
import { handleLogout } from "modules/authentication/store";

const authLink = setContext((_, { headers }) => {
  const token = store.getState().auth.token || "";

  return {
    headers: {
      "x-token": token ?? "",
      ...headers,
    },
  };
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_WSURL,
    connectionParams: {
      ["x-token"]: LocalUtils.get(CONSTANTS.LOCAL_STORAGE.TOKEN),
    },
  })
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, extensions }) => {
      if (extensions?.exception?.info?.status === 401) {
        store.dispatch(handleLogout())
        return
      }
      return message;
    });
  }

  if (networkError) {
    if (networkError.response.status === 401 || networkError.response.status === 500) {
      toastNotice({
        title: 'Lỗi kết nối',
        description: 'Hệ thống đang bảo trì. Mời bạn quay lại sau!'
      })
      store.dispatch(handleLogout())
    }
  }
});

const httpLink = new HttpLink({ uri: process.env.REACT_APP_BASENAME });

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  errorLink.concat(authLink.concat(httpLink))
);

const service = new ApolloClient({
  cache: new InMemoryCache(),
  link: link,
});

export const mutate = async (type, action, payload, options = {}) => {
  const response = await service.mutate({
    mutation: action,
    variables: { ...payload },
    fetchPolicy: "no-cache",
    errorPolicy: "all",
    ...options,
  });

  const { data, errors } = response;

  if (errors?.length) {

    const errorMessage = errors.map((error) => error.message);
    throw new Error(errorMessage);
  }

  if (type) {
    return data?.[type];
  }

  return response;
};

export const query = async (type, action, payload, options = {}) => {
  const account = JSON.parse(localUtils.get(CONSTANTS.LOCAL_STORAGE.ORG_ACCOUNT)) || null

  const variables = account ? { ...payload, [account.key]: account.id } : { ...payload }

  const response = await service.query({
    query: action,
    variables: variables,
    fetchPolicy: "no-cache",
    errorPolicy: "all",
    ...options,
  });

  const { data, errors } = response;

  if (errors?.length) {
    const errorMessage = errors.map((error) => error.message);
    throw new Error(errorMessage);
  }

  if (type) {
    return data?.[type] ?? data;
  }

  return response;
};

export default service;
