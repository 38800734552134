import classNames from "classnames";
import DrawerSystem from "components/e-control/drawer-system";
import InputSystem from "components/e-control/forms/form-elements/input-system";
import { getAllWorkPlaceWOPaging } from "modules/ego-system/account/stores";
import { selectWorkplace } from "modules/ego-system/location/stores";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import { DropdownItem } from "reactstrap";
import { dispatch } from "root-stores";
import useResponsive, { DEVICE_DETECT } from "utility/hooks/useResponsive";

const WorkplaceContainerContext = createContext();

const GlobalOption = {
    id: '',
    name: 'Toàn cục'
}

const WorkplaceContainer = ({ children }) => {

    const { allworkplaces, selectedWorkplace } = useSelector(state => state.location);

    const { view } = useResponsive();
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState("");

    const filterWorkplaces = useMemo(() => {
        const allOptions = [GlobalOption, ...allworkplaces]

        if (!search) {
            return allOptions
        }

        return allOptions.filter(workplace => workplace.name.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1);
    }, [allworkplaces, search])

    const handleOpenDrawerList = () => {
        setIsOpen(true);
    }

    const handleSelectWorkplace = (selected) => {
        dispatch(selectWorkplace(selected));
        window.location.reload();
    };

    // #region get partner accounts
    const getAllWorkplace = async () => {
        try {
            dispatch(getAllWorkPlaceWOPaging());
        } catch (error) {
            //
        }
    };

    useEffect(() => {
        if (!selectedWorkplace) {
            dispatch(selectWorkplace(GlobalOption))
        }
    }, [selectedWorkplace])

    useEffect(() => {
        getAllWorkplace();
    }, []);

    return (
        <WorkplaceContainerContext.Provider
            value={{
                allworkplaces,
                selectedWorkplace,

                openWorkplaces: handleOpenDrawerList,
                selectWorkplace: handleSelectWorkplace,
            }}
        >
            {children}

            <DrawerSystem
                isOpen={isOpen}
                wrapperClassName={classNames(
                    view === DEVICE_DETECT.MOBILE && "modal-slide-up"
                )}
                header="Chọn khu vực"
                onClose={() => {
                    setIsOpen(false);
                }}
            >
                <div
                    className="overflow-x-hidden overflow-hidden h-full -mx-[12px] flex flex-col gap-y-4"
                >
                    <div className="dropdown-menu-header p-2">
                        <div className="d-flex p-0">
                            <InputSystem
                                value={search}
                                className="text-sm"
                                placeholder="Tìm tên"
                                onKeyDown={(event) => {
                                    console.log(event);
                                    if (event.key === 'Escape') {
                                        event.preventDefault();
                                    }
                                }}
                                onChange={(event) => setSearch(event.target.value)}
                            />
                        </div>
                    </div>

                    <div className="min-h-full p-0">
                        {!filterWorkplaces?.length && (
                            <div className="text-foreground text-center text-sm h-full flex justify-center flex-col">
                                Không có dữ liệu
                            </div>
                        )}

                        {!!filterWorkplaces?.length && (
                            <PerfectScrollbar
                                options={{ wheelPropagation: true }}
                                className="scrollable-container"
                            >
                                <div
                                    id="dropdown-select-list"
                                    className="overflow-y-auto hide-scrollbar"

                                    onScroll={event => event.stopPropagation()}
                                >
                                    {filterWorkplaces?.map((workplace, index) => (
                                        <DropdownItem
                                            tag="li"
                                            key={index}
                                            className={classNames(
                                                "d-flex align-items-center p-2",
                                                workplace.id === selectedWorkplace?.id &&
                                                "bg-primary-1 item-activated"
                                            )}
                                            onClick={() => handleSelectWorkplace(workplace)}
                                        >
                                            <div className="flex flex-col ms-2">
                                                <span className="text-sm">{`${workplace.name}`}</span>
                                            </div>
                                        </DropdownItem>
                                    ))}
                                </div>
                            </PerfectScrollbar>
                        )}
                    </div>
                </div>
            </DrawerSystem>
        </WorkplaceContainerContext.Provider>
    )
}

export const useWorkplaceContext = () => useContext(WorkplaceContainerContext);

export default WorkplaceContainer;