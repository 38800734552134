import { gql } from "@apollo/client";
import { mutate, query } from "configs/http-config";

const InvoiceAction = {
  getStatAllBooking: gql`
    query getStatAllBooking(
      $page: Int
      $limit: Int
      $filter: Mixed
      $order: Mixed
      $fromDate: DateTime
      $toDate: DateTime
      $search: String
      $type: String
    ) {
      getStatAllBooking(
        q: {
          limit: $limit
          page: $page
          filter: $filter
          order: $order
          search: $search
        }
        fromDate: $fromDate
        toDate: $toDate
        type: $type
      )
    }
  `,

  getStatInvoiceRentCarTripForAdmin: gql`
    query getStatInvoiceRentCarTripForAdmin(
      $page: Int
      $limit: Int
      $filter: Mixed
      $order: Mixed
      $fromDate: DateTime
      $toDate: DateTime
      $search: String
    ) {
      getStatInvoiceRentCarTripForAdmin(
        q: {
          limit: $limit
          page: $page
          filter: $filter
          order: $order
          search: $search
        }
        fromDate: $fromDate
        toDate: $toDate
      )
    }
  `,

  getStatInvoiceExpressForAdmin: gql`
    query getStatInvoiceExpressForAdmin(
      $page: Int
      $limit: Int
      $filter: Mixed
      $order: Mixed
      $fromDate: DateTime
      $toDate: DateTime
      $search: String
    ) {
      getStatInvoiceExpressForAdmin(
        q: {
          limit: $limit
          page: $page
          filter: $filter
          order: $order
          search: $search
        }
        fromDate: $fromDate
        toDate: $toDate
      )
    }
  `,
};

export const InvoiceApi = {
  getStatAllBooking: (payload) => {
    return query("getStatAllBooking", InvoiceAction.getStatAllBooking, payload);
  },

  getStatInvoiceRentCarTripForAdmin: (payload) => {
    return query(
      "getStatInvoiceRentCarTripForAdmin",
      InvoiceAction.getStatInvoiceRentCarTripForAdmin,
      payload
    );
  },

  getStatInvoiceExpressForAdmin: (payload) => {
    return query(
      "getStatInvoiceExpressForAdmin",
      InvoiceAction.getStatInvoiceExpressForAdmin,
      payload
    );
  },
};
