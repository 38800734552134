import { gql } from "@apollo/client";

export const StockSheama = gql`
  fragment Stock on ShopProductWarehouse {
    id
    name
    quantity
    sellPrice
    soldQuantity
    shopProductTypeId
    shopProductId
    shopProduct {
      id
      name
      unit
      shopProductType {
        name
        id
      }
    }
    importPrice
    code
    partnerId
    warehouseDate
    shopProductUnit {
      id
      name
    }

    shopWarehouseLocation {
      id
      name
    }
  }
`;

export const stockAction = {
  getAllShopProductWarehouse: gql`
    ${StockSheama}
    query getAllShopProductWarehouse($q: QueryGetListInput) {
      getAllShopProductWarehouse(q: $q) {
        data {
          ...Stock
          createdAt
          updatedAt
        }
        pagination {
          total
          page
        }
        total
      }
    }
  `,

  getOneShopProductWarehouse: gql`
    ${StockSheama}
    query getOneShopProductWarehouse($id: ID!) {
      getOneShopProductWarehouse(id: $id) {
        ...Stock
        createdAt
        updatedAt
      }
    }
  `,

  createShopProductWarehouse: gql`
    mutation createShopProductWarehouse(
      $data: CreateShopProductWarehouseInput!
    ) {
      createShopProductWarehouse(data: $data)
    }
  `,

  updateShopProductWarehouse: gql`
    mutation updateShopProductWarehouse(
      $id: ID!
      $data: UpdateShopProductWarehouseInput!
    ) {
      updateShopProductWarehouse(id: $id, data: $data) {
        id
      }
    }
  `,

  deleteOneShopProductWarehouse: gql`
    mutation deleteOneShopProductWarehouse($id: ID!) {
      deleteOneShopProductWarehouse(id: $id) {
        id
      }
    }
  `,
};
