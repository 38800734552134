import { gql } from "@apollo/client";
import { mutate, query } from "configs/http-config";

const supplierAction = {
  getAllShopSupplier: gql`
    query getAllShopSupplier($q: QueryGetListInput) {
      getAllShopSupplier(q: $q) {
        data {
          address
          code
          createdAt
          email
          id
          name
          phone
          workPlace {
            id
            name
          }
        }
        total
      }
    }
  `,

  createShopSupplier: gql`
    mutation createShopSupplier($data: CreateShopSupplierInput!) {
      createShopSupplier(data: $data) {
        id
      }
    }
  `,

  deleteOneShopSupplier: gql`
    mutation deleteOneShopSupplier($id: ID!) {
      deleteOneShopSupplier(id: $id) {
        id
      }
    }
  `,

  updateShopSupplier: gql`
    mutation updateShopSupplier($id: ID!, $data: UpdateShopSupplierInput!) {
      updateShopSupplier(id: $id, data: $data) {
        id
      }
    }
  `,
};

export const supplierApi = {
  getAllShopSupplier: (payload) => {
    return query(
      "getAllShopSupplier",
      supplierAction.getAllShopSupplier,
      payload
    );
  },

  createShopSupplier: (payload) => {
    return mutate(
      "createShopSupplier",
      supplierAction.createShopSupplier,
      payload
    );
  },

  deleteOneShopSupplier: (payload) => {
    return mutate(
      "deleteOneShopSupplier",
      supplierAction.deleteOneShopSupplier,
      payload
    );
  },

  updateShopSupplier: (payload) => {
    return mutate(
      "updateShopSupplier",
      supplierAction.updateShopSupplier,
      payload
    );
  },
};
