import React from "react";
import { useAuth } from "components/containers/auth";

import "./index.scss";

const StepClaimStaking = () => {
  const { user } = useAuth();

  return (
    <>
      <div className="mt-10">
        <h4 className="text-black text-lg font-bold">
          Bắt đầu kiếm tiền chỉ với 3 bước
        </h4>

        <div className="container-step-web3">
          <div className="step pt-0 items-start">
            <div className="step-number -translate-y-3 -translate-x-[16px] rotate-45 ">
              <span className="rotate-[320deg]">1</span>
            </div>

            <div className="step-content -translate-y-3">
              <div className="text-base  mb-0 flex items-center justify-between gap-x-3">
                <p className="flex-1 text-black font-semibold">Staking USDT</p>
              </div>

              <p className="mt-1 text-gray-400">
                Stake USDT , nhận WBUSDT dựa trên tỉ lệ chuyển đổi hàng ngày.
              </p>
            </div>
          </div>

          <div className="step pt-0 items-start">
            <div className="step-number -translate-y-3 -translate-x-[16px] rotate-45">
              <span className="rotate-[320deg]">2</span>
            </div>

            <div className="step-content -translate-y-3">
              <div className="text-base  mb-0 flex items-center justify-between gap-x-3">
                <p className="flex-1 text-black font-semibold">
                  Nhận phần thưởng hàng ngày
                </p>
              </div>

              <p className="mt-1 text-gray-400">
                Kiếm lợi suất bằng cách nắm giữ WBUSDT hoặc sử dụng WBUSDT của
                bạn cho nhiều trường hợp sử dụng khác nhau, Khi phần thưởng
                stake USDT tích luỹ, 1 WBUSDT dần vượt quá 1 USDT về giá trị ~
                hiện ở mức:{" "}
              </p>

              <p className="mt-2 text-sm text-black bg-gray-200 rounded p-1 inline-block">
                1WBUSDT = 1.0489093USDT
              </p>
            </div>
          </div>

          <div className="step pt-0 items-start border-l-0">
            <div className="step-number -translate-y-3 -translate-x-[14px] rotate-45">
              <span className="rotate-[320deg]">3</span>
            </div>

            <div className="step-content -translate-y-3">
              <div className="text-base  mb-0 flex items-center justify-between gap-x-3">
                <p className="flex-1 text-black font-semibold">
                  Linh hoạt đổi USDT
                </p>
              </div>

              <p className="mt-1 text-gray-400">Đổi WBUSDT lấy USDT</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepClaimStaking;
