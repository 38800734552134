import { useAuth } from "components/containers/auth";
import { useWorkplaceContext } from "components/containers/workplace-wrapper";
import ViewDevice from "components/utils/view-device";
import { ChevronLeft, ChevronRight, Info } from "lucide-react";
import { useEffect, useRef } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import usePullRefresh from "utility/hooks/use-pull-refresh";
import { getHomeRouteForLoggedInUser } from "utils";

const PageWrapper = ({ title, breadcrumbs, actions, onBack, isGlobal, children, reload, depedencies = [] }) => {
  const { user, isAdminRole } = useAuth();

  const { selectedWorkplace } = useWorkplaceContext();

  const ref = useRef();

  usePullRefresh(ref, reload, depedencies);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const contentRect = ref.current.getBoundingClientRect();
    if (contentRect) {
      ref.current.style.setProperty('--content-top', contentRect.top + 'px');
      ref.current.style.setProperty('--content-height', contentRect.height - contentRect.top + 'px');
    }
  }, [])

  return (
    <div className="gap-4 flex flex-col h-[var(--height)] overflow-hidden">

      <ViewDevice>
        <div className="flex items-center justify-between gap-x-4">
          <div className="flex flex-col space-y-1 w-full">
            <h3 className="text-secondary flex items-center">{title}
              {(isAdminRole && isGlobal) && (<span className="flex items-center ml-1 gap-x-2 text-sm text-secondary-2 pl-2"><Info className="w-4 h-4" /> (Toàn cục)</span>)}
            </h3>

            <div className="flex items-center space-x-2">
              <div className="flex items-center space-x-1">
                <Link to={getHomeRouteForLoggedInUser(user)}>
                  <small className="">
                    <span>Trang chủ</span>
                  </small>
                </Link>

                <ChevronRight className="w-4 h-4" />
              </div>

              {breadcrumbs?.map((item, index) => {
                if (item.link) {
                  return (
                    <Link
                      key={index}
                      to={item.link}
                      className="flex items-center space-x-2"
                    >
                      <small className="">{item.title}</small>

                      {index !== breadcrumbs.length - 1 && (
                        <ChevronRight className="w-4 h-4" />
                      )}
                    </Link>
                  );
                }

                return (
                  <div className="flex items-center space-x-2" key={index}>
                    <small className="">{item.title}</small>

                    {index !== breadcrumbs.length - 1 && (
                      <ChevronRight className="w-4 h-4" />
                    )}
                  </div>
                );
              })}
            </div>
          </div>

          <div className="">
            {actions}
          </div>
        </div>
      </ViewDevice>

      <ViewDevice is={['mobile', 'tablet']}>
        <div className="z-[99] fixed top-0 left-0 w-full h-[50px] shadow-lg">
          <div className="flex items-center bg-background-accent justify-center w-full h-full px-2 relative">
            {onBack && (
              <div
                className="absolute top-0 left-4 h-full w-fit flex items-center"
                onClick={onBack}
              >
                <ChevronLeft className="w-6 h-6 text-primary" />
              </div>
            )}

            <h3 className="text-secondary max-w-[80%] whitespace-wrap text-center flex flex-col items-center">
              {title}

              {isAdminRole && (
                <>
                  {isGlobal && (
                    <p className="flex items-center ml-1 gap-x-2 text-sm text-secondary-2">
                      <span>Toàn cục</span>
                      <Info className="w-4 h-4" />
                    </p>
                  )}

                  {!isGlobal && (
                    <span className="flex items-center ml-1 gap-x-2 text-sm text-secondary-2 pl-2">
                      {`(${selectedWorkplace?.name})`}
                    </span>
                  )}
                </>
              )}
            </h3>

            {actions && (
              <div className="absolute top-0 right-4 h-full w-fit flex items-center">
                {actions}
              </div>
            )}
          </div>
        </div>
      </ViewDevice>
      
      <div ref={ref} className="pt-[50px] md:pt-0 flex-[2] overflow-y-auto overflow-x-hidden">
        <PerfectScrollbar className="-mx-3 px-3 overflow-x-hidden">
          {children}
        </PerfectScrollbar>
      </div>
    </div>
  );
};

export default PageWrapper;
