import React from "react";
import { useSelector } from "react-redux";
import { Activity, CalendarRange, Clock, DollarSign, Plus } from "lucide-react";
import { formatNumberToAmount } from "utility/number-utils";

const CardFeature = () => {
  const { details } = useSelector((state) => state.accumulate);

  const LIST_FEATURE = [
    {
      title: "Lãi suất",
      value: `${details?.ratePYear * 100}%/năm`,
      icon: <Activity size={12} color="#29ab68" />,
    },

    {
      title: "Lãi suất rút trước",
      value: `${details?.earlyWithdrawRate * 100}%/năm`,
      icon: <Clock size={12} color="#29ab68" />,
    },

    {
      title: "Hạn mức của bạn",
      value: `${formatNumberToAmount(details?.max)}đ`,
      icon: <DollarSign size={12} color="#29ab68" />,
    },

    {
      title: "Tích luỹ tối thiểu",
      value: `${formatNumberToAmount(details?.min)}đ`,
      icon: <Plus size={12} color="#29ab68" />,
    },

    {
      title: "Thời hạn",
      value: `${details?.savingsTerm} tháng`,
      icon: <CalendarRange size={12} color="#29ab68" />,
    },
  ];
  return (
    <div className="p-4 rounded-lg bg-white shadow-sm mt-4">
      <h3 className="text-lg font-bold text-black">Đặc điểm </h3>

      <div className="mt-3 flex flex-col gap-2">
        {LIST_FEATURE?.map((item, index) => (
          <div
            key={index}
            className="flex items-center justify-between gap-x-2"
          >
            <div className="flex items-center gap-x-1">
              {item?.icon}

              <p className="text-base font-medium">{item?.title}</p>
            </div>

            <div className="text-base font-semibold">{item?.value}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardFeature;
