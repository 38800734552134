import { Card } from "reactstrap"
import { formatNumberToAmount } from "utility/number-utils"

const InvestUnitTemplate = ({ item }) => {
    return (
        <Card className="mb-3 p-3 space-y-2">
            <div className="flex items-center gap-x-2 text-sm">
                <span>{item?.name}</span>
            </div>

            <div className="flex items-center justify-between">
                <div className="flex items-center gap-x-2 text-sm">
                    <span className="text-secondary-2 text-sm">Số lượng phát hành:</span>
                    <span className="text-sm">{formatNumberToAmount(item?.totalStock)}</span>

                </div>

                <div className="flex items-center gap-x-2 text-sm">
                    <span className="text-secondary-2 text-sm">Mệnh giá:</span>
                    <span className="text-sm">{formatNumberToAmount(item?.pricePerStock)}</span>
                </div>
            </div>


            <div className="flex items-center gap-x-2 text-sm">
                <span className="text-secondary-2 text-sm">Số lượng đã bán ra:</span>
                <span className="text-sm">{formatNumberToAmount(item?.totalSoldTock)}</span>
            </div>
        </Card >
    )
}

export default InvestUnitTemplate
