/* eslint-disable no-unsafe-optional-chaining */
// ** React Imports

// ** Third Party Components
import { Controller, useForm } from "react-hook-form";
import { Form, FormGroup, Label } from "reactstrap";

// ** Styles
import InputSystem from "components/e-control/forms/form-elements/input-system";
import SelectSystem from "components/e-control/forms/form-elements/select-system";

import { useEffect } from "react";
import styled from "styled-components";
import useDebounce from "utility/hooks/useDebounce";
import "./index.scss";

const StyleSelect = styled(SelectSystem)`
  .select__single-value {
    color: rgb(23 37 84 / var(--tw-text-opacity)) !important;
  }
`;

const HistoryOrderFilter = ({ filter, handleFilter }) => {
  const { handleSubmit, control, watch } = useForm({
    defaultValues: {
      ...filter,
    },
  });

  const { search, isPaid } = watch();

  useDebounce(
    () => {
      handleFilter({
        search,
      });
    },
    [search],
    400
  );

  useEffect(() => {
    handleFilter({
      isPaid,
    });
  }, [isPaid]);

  const onSubmit = (data) => {
    const { search, isPaid } = data;

    handleFilter({
      ...filter,
      isPaid: isPaid,
      search: search,
    });
  };

  return (
    <Form className="invest-filter px-3" onSubmit={handleSubmit(onSubmit)}>
      <FormGroup className="booking-filter__group">
        <Label className="booking-filter__group-label text-primary" sm={12}>
          Tìm kiếm
        </Label>
        <Controller
          name="search"
          control={control}
          render={({ field }) => (
            <InputSystem
              id="search"
              name="search"
              placeholder="Tìm theo mã đặt chỗ"
              type="text"
              className="custom-input-search text-blue-950 bg-white"
              {...field}
            />
          )}
        />
      </FormGroup>

      <FormGroup className="booking-filter__group">
        <Label className="booking-filter__group-label text-primary" sm={12}>
          Trạng thái
        </Label>
        <Controller
          name="isPaid"
          control={control}
          render={({ field }) => (
            <StyleSelect
              {...field}
              data={[
                {
                  value: false,
                  label: "Chưa thanh toán",
                },

                {
                  value: true,
                  label: "Đã thanh toán",
                },
              ]}
              classNamePrefix="select"
              placeholder="Chọn trạng thái"
            />
          )}
        />
      </FormGroup>

      {/* <div className="flex items-center gap-x-2">
        <ButtonSystem
          className="add-new-user px-3"
          color="danger"
          type="submit"
          onClick={resetField}
        >
          <X className="w-4 h-4" />
        </ButtonSystem>

        <ButtonSystem
          className="add-new-user px-3"
          color="primary"
          type="submit"
        >
          <Filter className="w-4 h-4" />
        </ButtonSystem>
      </div> */}
    </Form>
  );
};

export default HistoryOrderFilter;
