import { Wrapper } from "@googlemaps/react-wrapper";
import { createContext, useContext, useState } from "react";
import GGMapLayout from "..";

const GgMapContext = createContext({});

const withGGMap = (Component) => {
  return (props) => {
    const { ...componentProps } = props;

    const [map, setMap] = useState();

    return (
      <Wrapper
        apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
        verson="4.4.2"
        libraries={[
          "marker",
          "places",
          "routes",
          "drawing",
          "geometry",
          "directions",
          "maps",
          "streetView",
        ]}
      >
        <GgMapContext.Provider value={{ map: map }}>
          <Component {...componentProps} map={map}>
            <GGMapLayout setMap={setMap} />
          </Component>
        </GgMapContext.Provider>
      </Wrapper>
    );
  };
};

export const useGGMap = () => useContext(GgMapContext);

export default withGGMap;
