import classNames from "classnames";
import { forwardRef, useEffect, useRef, useState } from "react";

import './index.scss'

const LazyImage = forwardRef((props, ref) => {
    // ** Props

    // ** Refs
    const imageRef = useRef(null);

    // ** state
    const [progressing, setProgressing] = useState(0);
    const [imgLoaded, setImgLoaded] = useState(true);

    const handleImageLoaded = () => {
        setImgLoaded(false);
    }

    return (
        <div className={classNames([
            'progressing-image',
            { 'loading': imgLoaded }
        ])}>
                <img
                    ref={ref}
                    {...props}

                    onLoad={handleImageLoaded}
                />

                {
                    imgLoaded && (
                        <span>
                            Đang tải...
                        </span>
                    )
                }
        </div>
    )
});

export default LazyImage;