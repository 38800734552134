import { CONSTANTS } from "constants";

const LocalUtils = {
    get(key) {
        if (typeof localStorage !== "undefined") {
            return localStorage.getItem(key);
        }
        return null;
    },

    set(key, value) {
        localStorage.setItem(key, value);
    },

    remove(key) {
        localStorage.removeItem(key);
    },

    clear() {
        localStorage.clear();
        sessionStorage.clear();
    },
};

export default LocalUtils;
