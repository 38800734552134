import { useAuth } from "components/containers/auth";
import React from "react";

const FormDashBoardRef = () => {
  const { user } = useAuth();

  return (
    <div>
      <h3 className="text-black">
        Có{" "}
        <span className="text-xl text-primary font-bold">
          {user?.userConnect || 0} {""}
        </span>
        tài khoản nhập mã giới thiệu
      </h3>
    </div>
  );
};

export default FormDashBoardRef;
