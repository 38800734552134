import { gql } from "@apollo/client";

export const ComboFragment = gql`
  fragment Combo on ShopCombo {
    id
    image
    name
    price
    status
    details {
      quantity
      shopProduct {
        id
        name
        image
        quantity
        price
      }
      shopProductId
    }
    description
  }
`;

export const comboActions = {
  getAllShopCombo: gql`
    ${ComboFragment}
    query getAllShopCombo($q: QueryGetListInput) {
      getAllShopCombo(q: $q) {
        data {
          ...Combo
          createdAt
          updatedAt
        }
        total
      }
    }
  `,

  getOneShopCombo: gql`
    ${ComboFragment}
    query getOneShopCombo($id: ID!) {
      getOneShopCombo(id: $id) {
        ...Combo
        createdAt
        updatedAt
      }
    }
  `,

  createShopCombo: gql`
    mutation createShopCombo($data: CreateShopComboInput!) {
      createShopCombo(data: $data) {
        id
      }
    }
  `,

  updateShopCombo: gql`
    mutation updateShopCombo($id: ID!, $data: UpdateShopComboInput!) {
      updateShopCombo(id: $id, data: $data) {
        id
      }
    }
  `,

  deleteOneShopCombo: gql`
    mutation deleteOneShopCombo($id: ID!) {
      deleteOneShopCombo(id: $id) {
        id
      }
    }
  `,
};
