import { gql } from "@apollo/client";
import { mutate, query } from "configs/http-config";

const clientsPartnerAction = {
  getAllPartnerClient: gql`
    query getAllPartnerClient(
      $page: Int
      $limit: Int
      $filter: Mixed
      $order: Mixed
      $search: String
      $partnerId: ID!
    ) {
      getAllPartnerClient(
        q: {
          limit: $limit
          page: $page
          filter: $filter
          order: $order
          search: $search
        }
        partnerId: $partnerId
      ) {
        data {
          id
          address
          name
          phone
          note
          avatar
          gender
          birthday
          identityCard
          driverLicense
        }

        total
      }
    }
  `,

  getOneUser: gql`
    query getOneUser($id: ID!) {
      getOneUser(id: $id) {
        id
        address
        name
        phone
        note
        avatar
        identityCard
        driverLicense
      }
    }
  `,

  getAllBookingForPartnerClient: gql`
    query getAllBookingForPartnerClient(
      $page: Int
      $limit: Int
      $filter: Mixed
      $order: Mixed
      $search: String
      $phoneClient: String!
    ) {
      getAllBookingForPartnerClient(
        phoneClient: $phoneClient
        q: {
          limit: $limit
          page: $page
          filter: $filter
          order: $order
          search: $search
        }
      ) {
        data {
          id
          finalCost
          endFullAddress
          endAddress
          distance
          discountAmount
          businessType
          initialCost
          nameClient
          note
          phoneClient
          startAddress
          startFullAddress
          status
          type
          updatedAt
          workPlace {
            id
            name
          }
          workPlaceId
          createdAt
        }
        total
      }
    }
  `,

  getAllCallHistoryByPhone: gql`
    query getAllCallHistoryByPhone(
      $page: Int
      $limit: Int
      $filter: Mixed
      $order: Mixed
      $search: String
      $phone: String!
    ) {
      getAllCallHistoryByPhone(
        phone: $phone
        q: {
          limit: $limit
          page: $page
          filter: $filter
          order: $order
          search: $search
        }
      ) {
        data {
          id
          from
          to
          startTime
          endTime
          pickUpTime
          status
          time
        }
        total
      }
    }
  `,
  createPartnerClient: gql`
    mutation createPartnerClient(
      $name: String!
      $phoneNumber: String!
      $note: String
      $avatar: String
      $birthday: String
      $gender: String
      $address: String
      $driverLicense: String
      $identityCard: String
    ) {
      createPartnerClient(
        name: $name
        phoneNumber: $phoneNumber
        note: $note
        avatar: $avatar
        birthday: $birthday
        gender: $gender
        address: $address
        driverLicense: $driverLicense
        identityCard: $identityCard
      ) {
        id
      }
    }
  `,

  UpdateUser: gql`
    mutation updateUser($id: ID!, $data: UpdateUserInput!) {
      updateUser(id: $id, data: $data) {
        id
      }
    }
  `,

  deleteOnePartnerUser: gql`
    mutation deleteOneUser($id: ID!) {
      deleteOneUser(id: $id) {
        id
      }
    }
  `,
};

export const clientPartnerApi = {
  getAllPartnerClient: (payload) => {
    return query(
      "getAllPartnerClient",
      clientsPartnerAction.getAllPartnerClient,
      payload
    );
  },

  getOneUser: (payload) => {
    return query("getOneUser", clientsPartnerAction.getOneUser, payload);
  },

  getAllBookingForPartnerClient: (payload) => {
    return query(
      "getAllBookingForPartnerClient",
      clientsPartnerAction.getAllBookingForPartnerClient,
      payload
    );
  },

  getAllCallHistoryByPhone: (payload) => {
    return query(
      "getAllCallHistoryByPhone",
      clientsPartnerAction.getAllCallHistoryByPhone,
      payload
    );
  },

  createPartnerClient: (payload) => {
    return mutate(
      "createPartnerClient",
      clientsPartnerAction.createPartnerClient,
      payload
    );
  },

  UpdateUser: (payload) => {
    return mutate("UpdateUser", clientsPartnerAction.UpdateUser, payload);
  },

  deleteOnePartnerUser: (payload) => {
    return mutate(
      "deleteOnePartnerUser",
      clientsPartnerAction.deleteOnePartnerUser,
      payload
    );
  },
};
