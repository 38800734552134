/* eslint-disable multiline-ternary */
/* eslint-disable implicit-arrow-linebreak */
import { useMemo } from "react";

import TemplateItemOrderInvest from "./template";
import BardSystem from "components/e-control/bard-system";
import MoreDropdown from "components/views/more-dropdown";
import AvatarSystem from "components/controls/avatar-system";
import TooltipSystem from "components/e-control/tooltip-system";
import TableSystem, { TableWrapperContext } from "components/e-control/table";
import DropdownSystem from "components/e-control/forms/form-elements/dropdown-system";

import Utils from "utility/time";
import { formatNumberToAmount } from "utility/number-utils";
import { formatPhoneNumberToShow } from "utils";

const OrderHistoryAccumulateTable = ({
  data,
  loading,
  pagination,
  editAmount,
  approvePayment,
  deletePayment,
  filter,
  viewDetailOrder,
}) => {
  const isShowAction = useMemo(() => {
    return true;
  }, [filter]);

  const menuOptions = [
    // {
    //   label: "Chỉnh sửa",
    //   value: editAmount,
    //   status: true,
    // },

    {
      label: "Xác nhận đã thanh toán",
      value: approvePayment,
      status: true,
    },
  ];

  const menuOptionsUnPaid = [
    {
      label: "Xoá",
      value: deletePayment,
      status: true,
    },
  ];

  const menuOptionsDetail = [
    {
      label: "Chi tiết",
      value: viewDetailOrder,
      status: true,
    },
  ];

  const optionsByStatus = (status, payment, isDeleted) => {
    if (!status && payment === "COMPANY" && !isDeleted) {
      return [...menuOptionsDetail, ...menuOptions, ...menuOptionsUnPaid];
    } else if (!status && !isDeleted) {
      return [...menuOptionsDetail, ...menuOptionsUnPaid];
    } else {
      return [...menuOptionsDetail];
    }
  };

  const columns = [
    {
      title: "Khách hàng",
      dataIndex: "user",
      key: "user",
      minWidth: "150px",
      render: (_, row) => (
        <div className="flex items-center gap-2">
          <div className="hidden md:block visible">
            <AvatarSystem name={row?.user?.name} srcImg={row?.user?.avatar} />
          </div>

          <div className="flex gap-x-2 md:block flex-wrap">
            <p>{row?.user?.name}</p>
            <span className="block md:hidden">/</span>
            <p>{formatPhoneNumberToShow(row?.user?.phone)}</p>
          </div>
        </div>
      ),
      show: true,
    },
    {
      title: "Ngày tích luỹ",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (time, row) => Utils.formatDateOnlyNumber(time),
      minWidth: "150px",
      show: true,
    },

    {
      title: "Mã đơn hàng",
      dataIndex: "code",
      key: "code",
      minWidth: "150px",
      show: true,
    },

    {
      title: "Tên chương trình tích luỹ",
      dataIndex: "name",
      key: "name",
      render: (_, row) => (
        <TooltipSystem title={row?.savingsPlan?.name}>
          <span className="line-clamp-1">{row?.savingsPlan?.name}</span>
        </TooltipSystem>
      ),
      minWidth: "150px",
      show: true,
    },

    {
      title: "Số tiền",
      dataIndex: "amount",
      key: "amount",
      render: (_, row) => formatNumberToAmount(row?.amount),
      className: "amount",
      minWidth: "150px",
      show: true,
    },

    {
      title: "Kì hạn(tháng)",
      dataIndex: "savingsTerm",
      key: "savingsTerm",
      render: (value, _) => formatNumberToAmount(value),
      className: "amount",
      minWidth: "150px",
      show: true,
    },

    {
      title: "Trạng thái",
      dataIndex: "isPaid",
      key: "isPaid",
      render: (_, row) => {
        return (
          <BardSystem type={row?.isPaid ? "success" : "danger"}>
            {row?.status === "DELETED"
              ? "Đã xoá"
              : row?.isPaid
              ? "Đã thanh toán"
              : "Chưa thanh toán"}
          </BardSystem>
        );
      },
      minWidth: "150px",
      show: true,
    },

    {
      isDropdown: true,
      ignoreRowClick: true,
      allowOverflow: true,
      dataIndex: "action",
      key: "action",
      button: true,
      fixed: "right",
      width: "40px",
      render: (_, row) => {
        return (
          <DropdownSystem
            options={optionsByStatus(
              row?.isPaid,
              row?.paymentMethod,
              row?.status === "DELETED"
            )}
            onMenuSelect={(callback) => callback(row)}
          >
            <MoreDropdown />
          </DropdownSystem>
        );
      },
      show: isShowAction,
    },
  ].filter((item) => item?.show);

  return (
    <TableWrapperContext
      value={{
        menuDropdown: optionsByStatus,
        isShowAction: isShowAction,
      }}
    >
      <TableSystem
        template={TemplateItemOrderInvest}
        data={data}
        columns={columns}
        loading={loading}
        pagination={pagination}
        containerClassName="car-table"
        scroll={{ x: 768 }}
      />
    </TableWrapperContext>
  );
};

export default OrderHistoryAccumulateTable;
