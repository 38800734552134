import { gql } from "@apollo/client";
import { mutate } from "configs/http-config";

const actions = {
    loginUser: gql`
    mutation LoginUser($username: String!, $password: String!, $type: String!) {
      loginUser(username: $username, password: $password, type: $type) {
        token
      }
    }
  `,

    activeApp: gql`
        mutation {
            activateDriver
        }
    `,

    deactivateApp: gql`
        mutation {
            deactivateDriver
        }
    `,

    location: gql`
        mutation updateLocation($point: [Float]!) {
            updateLocation(point: $point) {
                id
            }
        }
    `,

    acceptTrip: gql`
        mutation acceptTripDispatch($tripDispatchId: ID!) {
            acceptTripDispatch(tripDispatchId: $tripDispatchId) {
                id
            }
        }
    `,

    refuseTrip: gql`
        mutation refuseTripDispatch($tripDispatchId: ID!, $reasonForRefusal: String!) {
            refuseTripDispatch(tripDispatchId: $tripDispatchId, reasonForRefusal: $reasonForRefusal) {
                id
            }
        }
    `,

    startTrip: gql`
        mutation startTrip($tripId: ID!) {
            startTrip(tripId: $tripId) {
                id
            }
        }
    `,

    finishTrip: gql`
        mutation finishTrip($tripId: ID!, $location: [Float]!) {
            finishTrip(tripId: $tripId, location: $location) {
                id
            }
        }
    `,

    checkArrived: gql`
        mutation checkArrived($tripId: ID!) {
            checkArrived(tripId: $tripId) {
                id
            }
        }
    `,

    createBooking: gql`
        mutation CreateTripInput($endAddress: String!, $endFullAddress: String!, $startFullAddress: String!, $startAddress: String!, $endPoint: [Float]!, $startPoint: [Float]!) {
            bookingTrip(data: {
                endAddress: $endAddress, endFullAddress: $endFullAddress, startFullAddress: $startFullAddress, startAddress: $startAddress, endPoint: $endPoint, startPoint: $startPoint
            }) {
                id
            }
        } 
    `,

    cancelBooking: gql`
        mutation cancelTrip($tripId: ID!, $reasonCancel: String!) {
            cancelTrip(tripId: $tripId, reasonCancel: $reasonCancel) {
                code
            }
        }
    `,

    requestPayment: gql`
        mutation RequestPaymentTrip($tripId: String!, $location: [Float]!, $paymentMethod: String) {
            requestPaymentTrip(tripId: $tripId, location: $location, paymentMethod: $paymentMethod)
        }
    `
}

const testDriverApis = {
    login: (payload) => {
        return mutate("loginUser", actions.loginUser, { ...payload, password: '123123' });
    },

    openApp: (token) => {
        return mutate('activateDriver', actions.activeApp, {}, {
            context: {
                headers: {
                    "x-token": token,
                },
            },
        })
    },

    offApp: (token) => {
        return mutate('deactivateDriver', actions.deactivateApp, {}, {
            context: {
                headers: {
                    "x-token": token,
                },
            },
        })
    },

    createBooking: (payload, token) => {
        return mutate('bookingTrip', actions.createBooking, payload, {
            context: {
                headers: {
                    "x-token": token,
                },
            },
        })
    },

    updateLocation: (payload, token) => {
        return mutate('updateLocation', actions.location, payload, {
            context: {
                headers: {
                    "x-token": token,
                },
            },
        })
    },

    acceptBooking: (payload, token) => {
        return mutate('acceptTripDispatch', actions.acceptTrip, payload, {
            context: {
                headers: {
                    "x-token": token,
                },
            },
        })
    },

    refuseBooking: (payload, token) => {
        return mutate('refuseTripDispatch', actions.refuseTrip, { ...payload, reasonForRefusal: "Ok" }, {
            context: {
                headers: {
                    "x-token": token,
                },
            },
        })
    },

    startBooking: (payload, token) => {
        return mutate('startTrip', actions.startTrip, payload, {
            context: {
                headers: {
                    "x-token": token,
                },
            },
        })
    },

    requestPaid: (payload, token) => {
        return mutate('requestPaymentTrip', actions.requestPayment, payload, {
            context: {
                headers: {
                    "x-token": token,
                },
            },
        })
    },

    finishBooking: (payload, token) => {
        return mutate('finishTrip', actions.finishTrip, payload, {
            context: {
                headers: {
                    "x-token": token,
                },
            },
        })
    },

    arrivedBooking: (payload, token) => {
        return mutate('checkArrived', actions.checkArrived, payload, {
            context: {
                headers: {
                    "x-token": token,
                },
            },
        })
    },

    cancelTrip: (payload, token) => {
        const params = { ...payload, reasonCancel: 'For fun!' }
        return mutate('cancelBooking', actions.cancelBooking, params, {
            context: {
                headers: {
                    "x-token": token,
                },
            },
        })
    }
}

export default testDriverApis;