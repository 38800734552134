/* eslint-disable multiline-ternary */
// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { CONSTANTS, ROLE } from "constants";
import { authApi } from "modules/authentication/apis";
import { toast } from "react-hot-toast";
import { AUTH_ROUTES } from "router/ego-admin-routes/auth";
import localUtils from "utility/local-utils";
import LocalUtils from "utility/storage";

const initialUser = () => {
  const item = LocalUtils.get(CONSTANTS.LOCAL_STORAGE.USER_DATA);
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {};
};

const initialToken = () => {
  return LocalUtils.get(CONSTANTS.LOCAL_STORAGE.TOKEN) || "";
};

export const login = createAsyncThunk("/auth/login", async (query) => {
  try {
    const payload = {
      password: query.password,
      username: query.loginEmail,
      type: "ADMIN",
    };

    const response = await authApi.login(payload);

    return response;
  } catch (error) {
    toast.error(error?.message);
  }
});

export const getAccountInfo = createAsyncThunk(
  "/auth/getAccountInfo",
  async () => {
    try {
      const response = await authApi.getAccountInfo();

      return response;
    } catch (error) {
      throw new Error(error.message);
    }
  }
);

export const authSlice = createSlice({
  name: "authentication",
  initialState: {
    userData: initialUser(),
    token: initialToken(),
    workPlaces: [],
  },
  reducers: {
    handleLogout: (state) => {
      state.token = "";
      state.userData = {};
      LocalUtils.clear();
      // window.location.replace(AUTH_ROUTES.LOGIN);
    },

    setToken: (state, { payload }) => {
      state.token = payload;
      LocalUtils.set(CONSTANTS.LOCAL_STORAGE.TOKEN, payload);
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, { payload }) => {
        state.userData = payload?.user;
        state.token = payload?.token;
        state.workPlaces = payload.user?.workPlaces;

        LocalUtils.set(CONSTANTS.LOCAL_STORAGE.TOKEN, payload?.token);
        LocalUtils.set(
          CONSTANTS.LOCAL_STORAGE.USER_DATA,
          JSON.stringify(payload?.user)
        );

        const key = {
          ADMIN: "adminId",
          SUPER_ADMIN: "adminId",
          PARTNER: "partnerId",
          SUPPORT_PARTNER: "partnerId",
          PARTNER_STAFF: "partnerId",
        };

        const idLocal =
          payload?.user?.role === ROLE.PARTNER_STAFF
            ? payload?.user?.partnerId
            : payload?.user?.id;

        localUtils.set(
          CONSTANTS.LOCAL_STORAGE.ORG_ACCOUNT,
          JSON.stringify({
            id: idLocal,
            name: payload?.user?.name,
            key: key[payload?.user?.role],
          })
        );
      })

      .addCase(getAccountInfo.fulfilled, (state, { payload }) => {
        state.userData = payload;
        LocalUtils.set(
          CONSTANTS.LOCAL_STORAGE.USER_DATA,
          JSON.stringify(payload)
        );

        // const key = {
        //   ADMIN: "adminId",
        //   SUPER_ADMIN: "adminId",
        //   PARTNER: "partnerId",
        //   SUPPORT_PARTNER: "partnerId",
        // };

        // localUtils.set(CONSTANTS.LOCAL_STORAGE.ORG_ACCOUNT, JSON.stringify({ id: payload.id, name: payload.name, key: key[payload.role]}));
      });
  },
});

export const { handleLogin, handleLogout, setToken } = authSlice.actions;

export default authSlice.reducer;
