import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { toast } from "react-hot-toast";
import { dashboardPartnerApi, reportPartnerApi } from "../apis";

export const getStatReportForPartner = createAsyncThunk(
  "/report/getStatReportForPartner",
  async () => {
    try {
      const response = await reportPartnerApi.getStatReportForPartner();

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getStatReportRevenue = createAsyncThunk(
  "/report/getStatReportRevenue",
  async (payload) => {
    try {
      const response = await reportPartnerApi.getStatReportRevenue(payload);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const reportPartnerSlice = createSlice({
  name: "reportPartner",
  initialState: {
    totalReportPartner: {},

    totalReportRevenue: {}
  },

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getStatReportForPartner.fulfilled, (state, { payload }) => {
      state.totalReportPartner = payload || {};
    });

    builder.addCase(getStatReportRevenue.fulfilled, (state, { payload }) => {
      state.totalReportRevenue = payload || {};
    });
  },
});

export default reportPartnerSlice.reducer;
