import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { priceApi } from "../apis";
import { toast } from "react-hot-toast";

export const getAllTripPrice = createAsyncThunk(
  "/price/getAllTripPrice",
  async (query) => {
    try {
      const response = await priceApi.getAllTripPrice(query);
      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const createTripPrice = async (query) => {
  try {
    const payload = {
      data: query,
    };

    await priceApi.createTripPrice(payload);
  } catch (error) {
    throw new Error(error);
  }
};

export const updateTripPrice = async (query) => {
  try {
    const payload = {
      data: query?.pricePerKm,
      id: query?.id,
    };

    await priceApi.updateTripPrice(query);
  } catch (error) {
    throw new Error(error);
  }
};

export const priceSlice = createSlice({
  name: "price",
  initialState: {
    prices: {
      list: [],
      total: 0,
    },
  },

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getAllTripPrice.fulfilled, (state, { payload }) => {
      state.prices.list = payload?.data || [];
      state.prices.total = payload?.total || 0;
    });
  },
});

export default priceSlice.reducer;
