import { useEffect, useMemo, useState } from "react";
import { ArrowDown, ArrowUp } from "lucide-react";

import DetailHistory from "./detail";
import Popup from "modules/invest/client/components/popup";
import toast from "react-hot-toast";
import { web3Apis } from "modules/wallet-web3/apis";
import moment from "moment";
import Utils from "utility/time";
import TooltipSystem from "components/e-control/tooltip-system";
import { hiddenMiddleCharacter } from "utils";

const transactions = [
  {
    date: "Sep 1 2024",
    type: "Transfer",
    direction: "In",
    address: "0x58a01...4561940",
    amount: "+4",
    currency: "USDT",
  },
  {
    date: "Sep 1 2024",
    type: "Transfer",
    direction: "In",
    address: "0x58a01...4561940",
    amount: "+9",
    currency: "USDT",
  },
  {
    date: "Aug 26 2024",
    type: "Transfer",
    direction: "In",
    address: "0x11A81...83028f2",
    amount: "+10",
    currency: "USDT",
  },
  {
    date: "Aug 15 2024",
    type: "Transfer",
    direction: "Out",
    address: "0x666fE...3Cd5Fdd",
    amount: "-0.067",
    currency: "BNB",
  },
  {
    date: "Aug 15 2024",
    type: "Transfer",
    direction: "Out",
    address: "0x666fE...3Cd5Fdd",
    amount: "-13.315",
    currency: "USDT",
  },
  {
    date: "Jul 18 2024",
    type: "Transfer",
    direction: "Out",
    address: "0x67777...c571e3F",
    amount: "-11",
    currency: "USDT",
  },
  {
    date: "Jul 18 2024",
    type: "Transfer",
    direction: "In",
    address: "0x82709...6D27a00",
    amount: "+6",
    currency: "USDT",
  },
  {
    date: "Jul 18 2024",
    type: "Transfer",
    direction: "In",
    address: "0x82709...6D27a00",
    amount: "+5",
    currency: "USDT",
  },
  {
    date: "Jul 11 2024",
    type: "Transfer",
    direction: "In",
    address: "0x87CF...84d1U8",
    amount: "+13.305",
    currency: "USDT",
  },
];

const HistoryWalletPage = () => {
  const [isDetailPopupOpen, setIsDetailPopupOpen] = useState(false);

  const [dataDetail, setDataDetail] = useState({});

  const [listHistory, setListHistory] = useState([]);

  const data = useMemo(() => {
    return transactions?.reduce((acc, item) => {
      const { date } = item;

      if (!acc[date]) {
        acc[date] = [];
      }

      acc[date].push(item);

      return acc;
    }, {});
  }, []);

  const getListHistory = async () => {
    try {
      const result = await web3Apis.getAllCryptoTransHistory({
        page: 1,
        limit: 1000,
      });

      const customData = result?.data
        ?.map((item, index) => {
          return {
            ...item,
            date: Utils.formatOnyDate(moment()),
          };
        })
        ?.reduce((acc, item) => {
          const { date } = item;

          if (!acc[date]) {
            acc[date] = [];
          }

          acc[date].push(item);

          return acc;
        }, {});

      setListHistory(customData);
    } catch (e) {
      toast.error(e.message);
    }
  };

  useEffect(() => {
    getListHistory();
  }, []);

  return (
    <>
      <div className="p-4 space-y-3">
        {Object.keys(listHistory).map((key, index) => {
          const histories = listHistory[key];

          return (
            <div key={index} className="space-y-2 ">
              <p className="text-gray-400">{key}</p>

              {histories?.map((tx, index) => (
                <div
                  key={index}
                  className="flex justify-between items-center cursor-pointer pb-4"
                  onClick={() => {
                    setDataDetail(tx);
                    setIsDetailPopupOpen(true);
                  }}
                >
                  <>
                    <div className="flex items-center">
                      <div className="bg-gray-200 rounded-full p-2">
                        {tx.type !== "TRANSFER" ? (
                          <ArrowUp className="size-4" />
                        ) : (
                          <ArrowDown className="size-4" />
                        )}
                      </div>
                      <div className="ml-3">
                        <p className="font-semibold">{tx.type}</p>
                        <p className="text-sm text-gray-400 flex items-center gap-x-1">
                          {tx.type !== "TRANSFER" ? "From: " : "To: "}
                          <TooltipSystem title={tx.to}>
                            {hiddenMiddleCharacter(tx?.to ?? "")}
                          </TooltipSystem>
                        </p>
                      </div>
                    </div>
                    <p
                      className={`font-bold ${
                        tx.type !== "TRANSFER" ? "text-green-500" : "text-black"
                      }`}
                    >
                      {tx.amount} {tx.typeCrypto}
                    </p>
                  </>
                </div>
              ))}
            </div>
          );
        })}
      </div>

      <Popup
        isOpen={isDetailPopupOpen}
        onClose={() => {
          setIsDetailPopupOpen(false);
        }}
        header="Detail History"
      >
        <div className="h-[75vh]">
          <DetailHistory item={dataDetail} />
        </div>
      </Popup>
    </>
  );
};

export default HistoryWalletPage;
