import { useGGMap } from "apps/maps/hocs/with-map";
import BookingModel from "apps/maps/model/booking-model";
import { resetBookingActions } from "modules/ego-system/hotline/store";
import { memo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { dispatch } from "root-stores";
import MapPin from "../map-pin";

const MapBookingDetail = ({ children, onOpenBookingForm }) => {

    const [mapZoom, setMapZoom] = useState(12);
    const booking = useRef(new BookingModel());

    const { map } = useGGMap();

    // ** Store
    const { workplaces } = useSelector((state) => state.map)
    const { bookingActions } = useSelector(state => state.hotline);

    const handleMapPinClick = (event) => {
        map.setZoom(10)
        map.setCenter(event.latLng)
        onOpenBookingForm?.()
    }

    useEffect(() => {
        if (!map) {
            return
        }

        if (!bookingActions) {
            if (booking.current.map) {
                booking.current.resetAll();
                booking.current = new BookingModel();
            }

            return;
        }

        if (!booking.current.map) {
            booking.current.init({
                map,
                endPoint: bookingActions.endPoint,
                startPoint: bookingActions.startPoint,
                status: bookingActions.status
            });

            booking.current.createBookingRoute();
        }

    }, [map, booking, bookingActions]);

    useEffect(() => {
        return () => {
            if (booking.current.map) {
                booking.current.resetAll();
                booking.current = new BookingModel();
                dispatch(resetBookingActions())
            }
        };
    }, [booking]);

    useEffect(() => {
        if (!map) {
            return
        }

        const handleMapZoom = () => {
            const currentZoom = map.getZoom();

            setMapZoom(currentZoom)
        }

        handleMapZoom()

        map.addListener('zoom_changed', handleMapZoom)
    }, [map])

    return (
        <>
            {children}

            {workplaces?.map(workplace => (
                <MapPin
                    map={map}
                    mapZoom={mapZoom}
                    key={workplace.id}
                    workplace={workplace}

                    onClick={handleMapPinClick}
                />
            ))}
        </>
    )
};

export default memo(MapBookingDetail);