import { gql } from "@apollo/client";
import { mutate, query } from "configs/http-config";
import { BookingFragment, RequestFragment } from "./fragment";

const BookingsAction = {
  getAllPartnerTrip: gql`
    query getAllPartnerTrip(
      $page: Int
      $limit: Int
      $filter: Mixed
      $order: Mixed
      $search: String
      $fromDate: DateTime
      $toDate: DateTime
      $partnerId: ID!
    ) {
      getAllPartnerTrip(
        q: {
          limit: $limit
          page: $page
          filter: $filter
          order: $order
          search: $search
        }
        fromDate: $fromDate
        toDate: $toDate
        partnerId: $partnerId
      ) {
        data {
          id
          distance
          driverId
          type
          driver {
            id
            name
            phone
            car {
              carCode
            }
          }
          workPlace {
            id
            name
          }
          finalCost
          startFullAddress
          endFullAddress
          reasonCancel
          initialCost
          nameClient
          note
          partner {
            id
            name
          }
          nameClient
          phoneClient
          status
          businessType
          code
          createdAt
        }
        getStatBooking
        total
      }
    }
  `,

  getBookingToday: gql`
    query getAllBookingToDay(
      $page: Int
      $limit: Int
      $filter: Mixed
      $order: Mixed
    ) {
      getAllBookingToDay(
        q: { page: $page, limit: $limit, filter: $filter, order: $order }
      ) {
        data {
          id
          createdAt
          status
          driver {
            id
            username
            avatar
            name
            car {
              carCode
              id
            }
            carId
            status
          }
          dispatchId
          distance
          driverId
          finalCost
          endAddress
          endPoint
          endFullAddress
          startAddress
          startPoint
          startFullAddress
          user {
            name
            phone
            username
          }
        }

        total
      }
    }
  `,

  getAllBookingByHotline: gql`
    ${BookingFragment}
    query getAllBookingCreatedByHotline(
      $page: Int
      $limit: Int
      $filter: Mixed
      $order: Mixed
      $fromDate: DateTime
      $toDate: DateTime
      $search: String
    ) {
      getAllBookingCreatedByHotline(
        q: {
          limit: $limit
          page: $page
          filter: $filter
          order: $order
          search: $search
        }
        fromDate: $fromDate
        toDate: $toDate
      ) {
        data {
          ...BookingInfo

          driver {
            name

            car {
              carCode
            }
          }

          user {
            name
            username
          }
        }
        total
      }
    }
  `,

  getAllTaxiRequestBooking: gql`
    ${RequestFragment}
    query getAllTaxiRequestBooking(
      $page: Int
      $limit: Int
      $filter: Mixed
      $order: Mixed
      $fromDate: DateTime
      $toDate: DateTime
      $search: String
    ) {
      getAllTaxiRequestBooking(
        q: {
          limit: $limit
          page: $page
          filter: $filter
          order: $order
          search: $search
        }
        fromDate: $fromDate
        toDate: $toDate
      ) {
        data {
          ...BookingInfo

          driver {
            name

            car {
              carCode
            }
          }
        }

        total
      }
    }
  `,

  getAllPartnerDriver: gql`
  query getAllPartnerDriver(
    $page: Int
    $limit: Int
    $filter: Mixed
    $order: Mixed
    $fromDate: DateTime
    $toDate: DateTime
    $search: String
    $partnerId: ID!
  ) {
    getAllPartnerDriver(
      q: {
        limit: $limit
        page: $page
        filter: $filter
        order: $order
        search: $search
      }
      fromDate: $fromDate
      toDate: $toDate
      partnerId: $partnerId
    ) {
      data {
        carId
        car {
          id
          carCode
        }
        id
        isTaxiCar
        name
        status
      }

      total
    }
  }
`,


  cancelBookingByHotline: gql`
    mutation cancelTrip($tripId: ID!, $reasonCancel: String) {
      cancelTrip(tripId: $tripId, reasonCancel: $reasonCancel) {
        id
      }
    }
  `,

  cancelRequest: gql`
    mutation cancelTaxiRequestBooking($taxiRequestId: ID!) {
      cancelTaxiRequestBooking(taxiRequestId: $taxiRequestId) {
        id
      }
    }
  `,

  getOneTrip: gql`
    query getOneTrip($id: ID!) {
      getOneTrip(id: $id) {
        code
        couponIds
        coupons {
          code
        }
        createdAt
        discountAmount
        dispatch {
          status
        }
        dispatchId
        distance
        driver {
          name
          phone
          car {
            carCode
            licensePlateNumber
          }
        }
        driverDropOffTime
        driverId
        driverPickupTime
        endAddress
        endFullAddress
        endPoint
        finalCost
        id
        initialCost
        paymentMethod
        startAddress
        startFullAddress
        startPoint
        status
        travelTime
        updatedAt
        user {
          name
          phone
        }
        userId
        waitTime
        workPlace {
          id
          name
        }
        workPlaceId
      }
    }
  `,

  getAllActivityHistory: gql`
    query getAllActivityHistory($filter: Mixed) {
      getAllActivityHistory(q: { filter: $filter }) {
        data {
          user {
            id
            role
            name
          }

          tripId
          type
          createdAt
        }
      }
    }
  `,

  getConfigBookingDistance: gql`
    query getConfigBookingDistance {
      getConfigBookingDistance
    }
  `,

  createTripForSwitchboard: gql`
    mutation createTripForSwitchboard($data: CreateTripInputForSwitchboard) {
      createTripForSwitchboard(data: $data) {
        id
        code
      }
    }
  `,

  createTaxiRequestBooking: gql`
    mutation createTaxiRequestBooking($data: CreateTaxiRequestBookingInput!) {
      createTaxiRequestBooking(data: $data) {
        id
        name
      }
    }
  `,

  createPartnerTrip: gql`
    mutation createPartnerTrip($data: CreatePartnerTripInput!) {
      createPartnerTrip(data: $data) {
        id
      }
    }
  `,

  createTripForPartner: gql`
  mutation createTripForPartner($data: CreateTripPartnerInput!) {
    createTripForPartner(data: $data) {
      id
    }
  }
`,

  updateTrip: gql`
  mutation updateTrip($id: ID!, $data: UpdateTripInput!) {
  updateTrip(id: $id, data: $data) {
    id
  } 
}
`,

  updatePartnerTrip: gql`
    mutation updatePartnerTrip($id: ID!, $data: UpdatePartnerTripInput!) {
      updatePartnerTrip(id: $id, data: $data) {
        id
      }
    }
  `,

  deleteOnePartnerTrip: gql`
    mutation deleteOnePartnerTrip($id: ID!) {
      deleteOnePartnerTrip(id: $id) {
        id
      }
    }
  `,

  validateCoordinate: gql`
    mutation validateCoordinate(
      $long: Float!
      $lat: Float!
      $maxDistance: Float!
    ) {
      validateCoordinate(long: $long, lat: $lat, maxDistance: $maxDistance)
    }
  `,

  checkCarSolution: gql`
    mutation checkCarSolution($startPoint: [Float]!, $endPoint: [Float]!) {
      checkCarSolution(startPoint: $startPoint, endPoint: $endPoint) {
        _id
        name
      }
    }
  `,
};

export const bookingPartnerApi = {
  getAllPartnerTrip: (payload) => {
    return query(
      "getAllPartnerTrip",
      BookingsAction.getAllPartnerTrip,
      payload
    );
  },

  getBookingToday: (payload) => {
    return query("getAllBookingToDay", BookingsAction.getBookingToday, payload);
  },

  getAllBookingByHotline: (payload) => {
    return query(
      "getAllBookingCreatedByHotline",
      BookingsAction.getAllBookingByHotline,
      payload
    );
  },

  getAllRequestByHotline: (payload) => {
    return query(
      "getAllTaxiRequestBooking",
      BookingsAction.getAllTaxiRequestBooking,
      payload
    );
  },

  getAllPartnerDriver: (payload) => {
    return query(
      "getAllPartnerDriver",
      BookingsAction.getAllPartnerDriver,
      { partnerId: payload?.id, q: { filter: { status: payload?.status } } }
    );
  },

  getOneBooking: (payload) => {
    return query("getOneTrip", BookingsAction.getOneTrip, payload);
  },

  getAllActivityHistory: (payload) => {
    return query(
      "getAllActivityHistory",
      BookingsAction.getAllActivityHistory,
      payload
    );
  },

  getConfigBookingDistance: () => {
    return query(
      "getConfigBookingDistance",
      BookingsAction.getConfigBookingDistance
    );
  },

  createTripForSwitchboard: (payload) => {
    return mutate(
      "createTripForSwitchboard",
      BookingsAction.createTripForSwitchboard,
      payload
    );
  },

  createTaxiRequestBooking: (payload) => {
    return mutate(
      "createTaxiRequestBooking",
      BookingsAction.createTaxiRequestBooking,
      payload
    );
  },

  createTripForPartner: (payload) => {
    return mutate(
      "createTripForPartner",
      BookingsAction.createTripForPartner,
      payload
    );
  },

  updateTrip: (payload) => {
    return mutate(
      "updateTrip",
      BookingsAction.updateTrip,
      payload
    );
  },


  createPartnerTrip: (payload) => {
    return mutate(
      "createPartnerTrip",
      BookingsAction.createPartnerTrip,
      payload
    );
  },

  updatePartnerTrip: (payload) => {
    return mutate(
      "updatePartnerTrip",
      BookingsAction.updatePartnerTrip,
      payload
    );
  },

  deleteOnePartnerTrip: (payload) => {
    return mutate(
      "deleteOnePartnerTrip",
      BookingsAction.deleteOnePartnerTrip,
      payload
    );
  },

  cancelBookingByHotline: (payload) => {
    return mutate("cancelTrip", BookingsAction.cancelBookingByHotline, payload);
  },

  cancelRequest: (payload) => {
    return mutate(
      "cancelTaxiRequestBooking",
      BookingsAction.cancelRequest,
      payload
    );
  },

  validateCoordinate: (payload) => {
    return mutate(
      "validateCoordinate",
      BookingsAction.validateCoordinate,
      payload
    );
  },

  checkCarSolution: (payload) => {
    return mutate("checkCarSolution", BookingsAction.checkCarSolution, payload);
  },
};
