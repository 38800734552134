import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { web3Apis } from '../apis';

export const getAllCrypto = createAsyncThunk(
    "/product/getAllCrypto",
    async () => {
        try {
            const response = await web3Apis.getAllCrypto();

            return response
        } catch (error) {
            console.log(error);
        }
    }
)

const web3Strore = createSlice({
    name: 'web3Strore',
    initialState: {
        cryptos: [],
        selectedCoin: null,
        sendData: null,
    },
    reducers: {
        selectCrypto: (state, { payload = null }) => {
            state.selectedCoin = payload
        },
        setFormData: (state, { payload = null }) => {
            state.sendData = {...payload, fee: 0.02,  total: payload.amount + 0.02}
        },
    },

    extraReducers: (builder) => {
        builder.addCase(getAllCrypto.fulfilled, (state, { payload }) => {
            state.cryptos = payload.data || []
        });
    }
});

export const {
    actions: {
        selectCrypto,
        setFormData,
    },
    reducer,
} = web3Strore;

export default reducer;