import { Loader } from "lucide-react";
import React from "react";
import { Card } from "reactstrap";

import "./index.scss";

const LoadingCardWebview = () => {
  return (
    <Card className="mb-0 bg-transparent w-full h-full overflow-hidden flex items-center justify-center relative">
      <div className="p-4 spinner-webview-card">
        <Loader color="#29ab68" />
      </div>
    </Card>
  );
};

export default LoadingCardWebview;
