const { gql } = require("@apollo/client");
const { query } = require("configs/http-config");

const deliveryAction = {
  getBooking: gql`
    query getAllEgoExpress(
      $q: QueryGetListInput
      $fromDate: DateTime
      $toDate: DateTime
    ) {
      getAllEgoExpress(q: $q, fromDate: $fromDate, toDate: $toDate) {
        data {
          id
          code
          userId
          fromName
          startAddress
          startFullAddress
          startPoint
          fromPhone
          toName
          toPhone
          endAddress
          endFullAddress
          endPoint
          descriptionFrom
          descriptionTo
          note
          cargoVolumn
          typeCargos
          costCOD
          initialCost
          finalCost
          paymentMethod
          originalImages
          lastImages
          isCOD
          status
          distance
          reasonCancel
          driverId
          workPlaceId
          countryId
          partnerId
          dispatchId
          dispatchedDriverIds
          isPaid
          driverAction
          clientAction
          clientImages
          startImages
          finishImages
          couponIds
          typeDelivery
          dispatchedDrivers {
            avatar
          }
          user {
            avatar
            name
          }
          driver {
            avatar
            name
            phone
          }
          workPlace {
            name
            id
          }
          partner {
            id
            name
          }
          createdAt
          updatedAt
        }
        total
      }
    }
  `,

  getOneEgoExpress: gql`
    query getOneEgoExpress($id: ID!) {
      getOneEgoExpress(id: $id) {
        id

        code
        discountAmount
        fromName
        fromPhone
        toName
        toPhone
        startAddress
        startFullAddress
        endAddress
        endFullAddress
        status
        egoDiscount
        netAmount
        egoDiscountPercent
        finalCost
        initialCost

        user {
          id
          name
          phone
        }
        driverId
        driver {
          id
          name
          phone
          address

          car {
            id
            carCode
            licensePlateNumber
          }
        }
        distance
        endPoint
        startPoint

        isPaid

        cargoVolumn
        typeCargos

        isCOD
        costCOD
        paymentMethod

        reasonCancel

        clientImages
        startImages
        finishImages
        lastImages
        originalImages
        VATFee
        tipAmount

        isInvoice

        companyInfo {
          email
          name
          address
          taxCode
        }
        note

        createdAt
        updatedAt

        netAmount
        egoDiscount
      }
    }
  `,

  getExpressBookingToday: gql`
    query getAllEgoExpressToday($q: QueryGetListInput) {
      getAllEgoExpressToday(q: $q) {
        data {
          id
          code
          userId
          fromName
          startAddress
          startFullAddress
          startPoint
          fromPhone
          toName
          toPhone
          endAddress
          endFullAddress
          endPoint
          descriptionFrom
          descriptionTo
          note
          cargoVolumn
          typeCargos
          costCOD
          initialCost
          finalCost
          paymentMethod
          originalImages
          lastImages
          isCOD
          status
          distance
          reasonCancel
          driverId
          workPlaceId
          countryId
          partnerId
          dispatchId
          dispatchedDriverIds
          isPaid
          driverAction
          clientAction
          clientImages
          startImages
          finishImages
          couponIds
          typeDelivery
          dispatchedDrivers {
            avatar
          }
          user {
            avatar
            name
          }
          driver {
            avatar
            name
            phone
          }
          workPlace {
            name
            id
          }
          partner {
            id
            name
          }
          createdAt
          updatedAt
        }
        total
      }
    }
  `,
};

export const deliveryApis = {
  getBooking: (payload) => {
    console.log("payload :>>", payload);
    return query("getAllEgoExpress", deliveryAction.getBooking, {
      q: {
        ...payload.params,
        order: { createdAt: -1 },
      },
      fromDate: payload.fromDate,
      toDate: payload.toDate,
    });
  },

  getOneEgoExpress: (payload) => {
    return query("getOneEgoExpress", deliveryAction.getOneEgoExpress, payload);
  },

  getExpressBookingToday: (payload) => {
    return query(
      "getAllEgoExpressToday",
      deliveryAction.getExpressBookingToday,
      { q: { ...payload, order: { createdAt: -1 } } }
    );
  },
};
