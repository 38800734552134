import { gql } from "@apollo/client";

export const savingPlanFragment = gql`
  fragment savingsPlanInfo on SavingsPlan {
    bannerImage
    createdAt
    description
    earlyWithdrawRate
    id
    image
    max
    min
    name
    ratePYear
    savingsTerm
    updatedAt
  }
`;

export const getAllSavingsPlan = gql`
  ${savingPlanFragment}
  query getAllSavingsPlan($q: QueryGetListInput) {
    getAllSavingsPlan(q: $q) {
      data {
        ...savingsPlanInfo
      }
      total
    }
  }
`;

export const getAllSavingsPackage = gql`
  query getAllSavingsPackage($q: QueryGetListInput) {
    getAllSavingsPackage(q: $q) {
      data {
        amount
        code
        createdAt
        id
        isPaid
        paymentMethod

        user {
          avatar
          id
          name
          phone
        }

        savingsPlan {
          id
          ratePYear
          savingsTerm
          image
          max
          min
          name
          earlyWithdrawRate
          bannerImage
          description
          createdAt
          updatedAt
        }

        savingsTerm
      }
      total
    }
  }
`;

export const getOneSavingsPackage = gql`
  query getOneSavingsPackage($id: ID!) {
    getOneSavingsPackage(id: $id) {
      amount
      code
      createdAt
      id
      isPaid
      paymentMethod
      savingsTerm

      user {
        avatar
        id
        name
        phone
      }

      savingsPlan {
        id
        ratePYear
        savingsTerm
        image
        max
        min
        name
        earlyWithdrawRate
        bannerImage
        description
        createdAt
        updatedAt
      }
    }
  }
`;

export const getAllEgoTransaction = gql`
  query getAllEgoTransaction($q: QueryGetListInput) {
    getAllEgoTransaction(q: $q) {
      data {
        id
        balance
        code
        interestAmount
        createdAt
        status
        type

        savingsPackage {
          id
          code
          amount
          isPaid
          paymentMethod

          savingsPlan {
            id
            name
            ratePYear
            savingsTerm
          }
        }
      }
      total
    }
  }
`;

export const getOneSavingsPlan = gql`
  ${savingPlanFragment}
  query getOneSavingsPlan($id: ID!) {
    getOneSavingsPlan(id: $id) {
      ...savingsPlanInfo
    }
  }
`;

export const createSavingsPackage = gql`
  mutation createSavingsPackage($data: CreateSavingsPackageInput!) {
    createSavingsPackage(data: $data) {
      id
      paymentMethod
    }
  }
`;

export const createSavingsPlan = gql`
  mutation createSavingsPlan($data: CreateSavingsPlanInput!) {
    createSavingsPlan(data: $data) {
      id
    }
  }
`;

export const updateSavingPlan = gql`
  mutation updateSavingsPlan($data: UpdateSavingsPlanInput!, $id: ID!) {
    updateSavingsPlan(data: $data, id: $id) {
      id
    }
  }
`;

export const deleteOneSavingsPlan = gql`
  mutation deleteOneSavingsPlan($id: ID!) {
    deleteOneSavingsPlan(id: $id) {
      id
    }
  }
`;

export const paymentSavingsPackage = gql`
  mutation paymentSavingsPackage(
    $savingsPackageId: ID!
    $paymentMethod: String!
  ) {
    paymentSavingsPackage(
      savingsPackageId: $savingsPackageId
      paymentMethod: $paymentMethod
    )
  }
`;

export const sendConfirmPaymentSavingsPackage = gql`
  mutation sendConfirmPaymentSavingsPackage($savingsPackageId: ID!) {
    sendConfirmPaymentSavingsPackage(savingsPackageId: $savingsPackageId) {
      id
    }
  }
`;

export const confirmPaymentSavingsPackage = gql`
  mutation confirmPaymentSavingsPackage($savingsPackageId: ID!) {
    confirmPaymentSavingsPackage(savingsPackageId: $savingsPackageId) {
      id
    }
  }
`;
