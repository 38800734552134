import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { garageApi } from "../apis";
import { toast } from "react-hot-toast";

export const getAllGarage = createAsyncThunk(
  "/garage/getAllGarage",
  async (query) => {
    try {
      const response = await garageApi.getAllGarage(query);
      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getAllGarageForAdmin = createAsyncThunk(
  "/garage/getAllGarageForAdmin",
  async (query) => {
    try {
      const response = await garageApi.getAllGarageForAdmin(query);
      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getOneGarage = createAsyncThunk(
  "/garage/getOneGarage",
  async (query) => {
    try {
      const response = await garageApi.getOneGarage(query);
      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const garageSlice = createSlice({
  name: "garage",
  initialState: {
    garages: {
      list: [],
      total: 0,
    },
    garagesAdmin: {
      list: [],
      total: 0,
    },
  },

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getAllGarage.fulfilled, (state, { payload }) => {
      state.garages.list = payload?.data || [];
      state.garages.total = payload?.total || 0;
    });

    builder.addCase(getAllGarageForAdmin.fulfilled, (state, { payload }) => {
      state.garagesAdmin.list = payload?.data || [];
      state.garagesAdmin.total = payload?.total || 0;
    });
  },
});

export default garageSlice.reducer;
