import classNames from "classnames";
import ButtonSystem from "components/e-control/button-system";
import { useEffect } from "react";

const { default: DrawerSystem } = require("components/e-control/drawer-system");
const { Form } = require("reactstrap");

const FormDrawer = (props) => {
  const {
    form,
    children,
    onClose,
    loading,
    actions,
    okButtonProps,
    okBtnText,
    cancelBtnText,
    cancleButtonProps,
    bodyClassName,
    ...drawerProps
  } = props;

  useEffect(() => {
    if (!drawerProps?.isOpen) {
      form?.reset();
    }
  }, [drawerProps?.isOpen]);

  return (
    <DrawerSystem
      bodyClassName={bodyClassName}
      {...drawerProps}
      form={form}
      actions={
        actions || (
          <div
            className={classNames(
              "flex justify-center items-center space-x-2",
              {
                "max-w-[300px] lg:m-auto m-0": drawerProps.size === "full",
              },
              "max-w-[350px] lg:m-auto m-0"
            )}
          >
            <ButtonSystem
              type="button"
              color="danger"
              className="flex-[2] md:block visible hidden"
              {...cancleButtonProps}
              onClick={onClose}
            >
              {cancelBtnText || "Trở về"}
            </ButtonSystem>

            <ButtonSystem
              loading={loading}
              color="primary"
              type="submit"
              className="flex-[2]"
              {...okButtonProps}
            >
              {okBtnText || "Lưu"}
            </ButtonSystem>
          </div>
        )
      }
      onClose={onClose}
    >
      {drawerProps.isOpen && children}
    </DrawerSystem>
  );
};

export default FormDrawer;

export const withFormWrapper = (WappedComponent) => {
  return (props) => {
    return (
      <FormDrawer {...props}>
        <WappedComponent {...props} />
      </FormDrawer>
    );
  };
};
