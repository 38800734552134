import { useEffect, useMemo } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import ReactPaginate from 'react-paginate';

// ** Third Party Components
import PropTypes from 'prop-types';

import './index.scss';

const PaginationSystem = (props) => {
    /**
     * Props
     */
    const { page, size, total, onPageChange } = props;

    const currentPage = useMemo(() => {
        return page - 1;
    }, [page]);

    const totalPage = useMemo(() => {
        return Math.ceil(total / size);
    }, [total, size]);

    const handlePageChanged = (page) => {
        const changedPage = page?.selected || 0

        onPageChange?.(changedPage + 1);
    };

    useEffect(() => {
        if (!currentPage || !totalPage) {
            return
        }

        if (currentPage > totalPage - 1) {
            onPageChange?.(totalPage);
        }
    }, [totalPage, currentPage]);

    if (!totalPage || totalPage < 2) {
        return null;
    }

    return (
        <ReactPaginate
            {...props}
            nextLabel={<ChevronRight size={16} />}
            pageCount={totalPage}
            forcePage={currentPage}
            breakLabel='...'
            previousLabel={<ChevronLeft size={16} />}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            activeClassName='pagination-system__active'
            pageClassName='pagination-system__page-item'
            breakClassName='pagination-system__page-item'
            pageLinkClassName='pagination-system__page-link'
            nextLinkClassName='pagination-system__page-link'
            breakLinkClassName='pagination-system__page-link'
            previousLinkClassName='pagination-system__page-link'
            nextClassName='pagination-system__page-item pagination-system__next-item'
            previousClassName='pagination-system__page-item pagination-system__prev-item'
            containerClassName='pagination-system react-paginate'

            onPageChange={handlePageChanged}
        />
    )
};

PaginationSystem.propTypes = {
    page: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
};

PaginationSystem.defaultProps = {
    page: 1,
    total: 0,
    size: 10,

    onPageChange: () => null,
}

export default PaginationSystem