import { yupResolver } from "@hookform/resolvers/yup";
import "cleave.js/dist/addons/cleave-phone.vi";
import { Controller, useForm } from "react-hook-form";
import { Button, Form, FormFeedback, Input, Label } from "reactstrap";
import * as yup from "yup";

import { useGGMap } from "apps/maps/hocs/with-map";
import BookingModel from "apps/maps/model/booking-model";
import classNames from "classnames";
import ButtonSystem from "components/e-control/button-system";
import InputSystem from "components/e-control/forms/form-elements/input-system";
import PhoneInputSystem from "components/e-control/forms/form-elements/phone-input-system";
import SelectSystem from "components/e-control/forms/form-elements/select-system";
import ViewDevice from "components/utils/view-device";
import { TYPE_CAR } from "constants";
import { bookingApi } from "modules/ego-system/bookings/apis";
import { carApi } from "modules/ego-system/car/apis";
import { getAllCouponByStartPoints } from "modules/ego-system/coupon/stores";
import AutoCompleteInput from "modules/ego-system/gg-map/components/autocomplete-input";
import { HOTLINE_OPTIONS } from "modules/ego-system/hotline/constants";
import {
  setBookingFormAction,
  setType,
} from "modules/ego-system/hotline/store";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { dispatch } from "root-stores";
import useDebounce from "utility/hooks/useDebounce";
import { selectThemeColors } from "utils";
import "./index.scss";

const AddBookingForm = ({
  onAddSuccess,
  isCreateModal,
  handleCompleteCancelBooking,
}) => {
  const { map } = useGGMap();
  const [loading, setLoading] = useState(false);

  const bookingModel = useRef(new BookingModel());
  const [carCategories, setCarCategories] = useState([]);
  const [type, setTypeOption] = useState(
    HOTLINE_OPTIONS.BOOKING_CREATE_TYPE.BOOKING
  );

  // ** Store
  const { couponByStartPoint } = useSelector((state) => state.coupon);
  const { bookingFormAction } = useSelector((state) => state.hotline);

  const SignupSchema = useMemo(() => {
    const schemaFields = {
      name: yup.string().required("Vui lòng nhập tên khách hàng."),
      carType: yup.string().required("Vui lòng chọn loại xe."),
      phone: yup
        .string()
        .required("Vui lòng nhập số điện thoại của khách hàng."),
      endAddress: yup.string().required("Vui lòng nhập địa chỉ cuối."),
      startAddress: yup.string().required("Vui lòng nhập địa chỉ bắt đầu."),
    };

    if (type === HOTLINE_OPTIONS.BOOKING_CREATE_TYPE.REQUEST) {
      delete schemaFields.endAddress;
      delete schemaFields.carType;
    }

    return yup.object().shape(schemaFields);
  }, [type]);

  // ** Hooks
  const {
    reset,
    watch,
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(SignupSchema) });

  const phoneValue = watch("phone");
  const endPoint = watch("endPoint");
  const startPoint = watch("startPoint");
  const watchStartAddress = watch("startAddress");

  const startPoints = useMemo(() => {
    if (!startPoint) {
      return null;
    }

    return [startPoint.lng(), startPoint.lat()];
  }, [startPoint]);

  const endPoints = useMemo(() => {
    if (!endPoint) {
      return null;
    }

    return [endPoint.lng(), endPoint.lat()];
  }, [endPoint]);

  const carTypeOptions = useMemo(() => {
    return carCategories.map((type) => ({
      value: type.id,
      label: TYPE_CAR?.[type.name]?.LABEL || type?.name,
    }));
  }, [carCategories]);

  const couponsOptions = useMemo(() => {
    return couponByStartPoint.list?.map((coupon) => ({
      value: coupon.id,
      label: coupon.title,
    }));
  }, [couponByStartPoint.list]);

  const isBookingType = useMemo(() => {
    return type === HOTLINE_OPTIONS.BOOKING_CREATE_TYPE.BOOKING;
  }, [type]);

  const mapingPayload = useCallback(
    (data) => {
      const couponIds = (() => {
        if (data?.coupon?.value) {
          return [data?.coupon?.value];
        }

        return [];
      })();

      const phoneNumber = (() => {
        if (data?.phone) {
          if (data.phone.indexOf("+84") !== -1) {
            return data.phone;
          }

          return data.phone.replace("0", "+84");
        }

        return "";
      })();

      const basicPayload = {
        name: data?.name,
        note: data?.note,
        startPoint: startPoints,
        carTypeId: data?.carType,
        startAddress: data?.startAddress,
        startFullAddress: data?.startAddress,
      };

      if (isBookingType) {
        return {
          ...basicPayload,
          endPoint: endPoints,
          endAddress: data?.endAddress,
          endFullAddress: data?.endAddress,

          couponIds: couponIds,
          phoneCustomer: phoneNumber,
          carTypeId: data?.carType,
        };
      }

      return {
        ...basicPayload,
        phone: phoneNumber,
      };
    },
    [type, startPoints, endPoints, isBookingType]
  );

  const onSubmit = async (formData) => {
    try {
      const payload = mapingPayload(formData);

      const response = await (async () => {
        if (isBookingType) {
          try {
            setLoading(true);
            const result = await bookingApi.createTripForSwitchboard({
              data: payload,
            });

            return result;
          } catch (error) {
            return { error: error };
          } finally {
            setLoading(false);
          }
        }

        try {
          setLoading(true);

          const result = await bookingApi.createTaxiRequestBooking({
            data: payload,
          });

          return result;
        } catch (error) {
          return { error };
        } finally {
          setLoading(false);
        }
      })();

      if (response?.error) {
        throw response?.error;
      }

      if (isBookingType) {
        toast.success("Tạo chuyến xe thành công");
        dispatch(setType(HOTLINE_OPTIONS.BOOKING_CREATE_TYPE.BOOKING));
        // handleCompleteCancelBooking();
      } else {
        toast.success("Tạo yêu cầu chuyến xe thành công");
        dispatch(setType(HOTLINE_OPTIONS.BOOKING_CREATE_TYPE.REQUEST));
        // handleCompleteCancelBooking();
      }

      onAddSuccess?.();
      if (isCreateModal) {
        dispatch(setBookingFormAction());
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleReset = () => {
    reset({
      name: "",
      phone: "",
      carType: "",
      endAddress: "",
      startAddress: "",
    });

    setTypeOption(HOTLINE_OPTIONS.BOOKING_CREATE_TYPE.BOOKING);
  };

  const loadCouponByStartPoint = async (points, phone) => {
    await dispatch(
      getAllCouponByStartPoints({
        page: 1,
        limit: 10,
        phone: phone,
        startPoint: points,
        filter: {
          serviceTypes: { $in: "TAXI" },
        },
      })
    );
  };

  const loadCarTypeByStartPoint = async (points) => {
    try {
      const reponse = await carApi.getAllCarTypeByStartPoint({
        startPoint: points,
      });

      setCarCategories(reponse);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleShowBooingOnMap = () => {
    if (!map) {
      return;
    }

    if (!bookingModel.current.map) {
      bookingModel.current.init({ map });
    }

    if (startPoint) {
      if (!bookingModel.current.startMarker) {
        const startPoints = [startPoint.lng(), startPoint.lat()];
        bookingModel.current.setStartPosition(startPoints);
        bookingModel.current.setStartMarker();
        bookingModel.current.map.setCenter(startPoint);
        bookingModel.current.map.setZoom(18);
      }
    }

    if (endPoint) {
      if (!bookingModel.current.endMarker) {
        const endPoints = [endPoint.lng(), endPoint.lat()];
        bookingModel.current.setEndPosition(endPoints);
        bookingModel.current.setEndMarker();

        bookingModel.current.map.setCenter(endPoint);
        bookingModel.current.map.setZoom(18);
      }
    }

    if (endPoint && startPoint) {
      bookingModel.current.createRoute();
      bookingModel.current.fitBounds();
    }

    if (!endPoint || !startPoint) {
      if (!endPoint && bookingModel.current.endMarker) {
        bookingModel.current.removeMarker("end");
      }

      if (!startPoint && bookingModel.current.startMarker) {
        bookingModel.current.removeMarker("start");
      }

      if (bookingModel.current.linePath) {
        bookingModel.current.removeRoute();
      }
    }
  };

  useEffect(() => {
    if (type === HOTLINE_OPTIONS.BOOKING_CREATE_TYPE.REQUEST) {
      reset({
        endAddress: "",
        endPoint: null,
      });
    }
  }, [type]);

  useEffect(() => {
    handleShowBooingOnMap();
  }, [map, endPoint, startPoint, bookingModel]);

  useEffect(() => {
    if (isCreateModal && bookingFormAction) {
      setValue("name", bookingFormAction.clientName);
      setValue("phone", bookingFormAction.phoneNumber);
    }
  }, [bookingFormAction, isCreateModal, type]);

  useDebounce(
    () => {
      if (!startPoint || !phoneValue) {
        return;
      }

      const startPoints = [startPoint.lng(), startPoint.lat()];
      loadCouponByStartPoint(startPoints, phoneValue);
      loadCarTypeByStartPoint(startPoints);
    },
    [startPoint, phoneValue],
    400
  );

  useEffect(() => {
    return () => {
      if (bookingModel.current.map) {
        bookingModel.current.resetAll();
        bookingModel.current = new BookingModel();
      }
    };
  }, [bookingModel]);

  return (
    <Form
      className="create-booking-form-wrapper"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="hotline-form-content">
        <ViewDevice is={["mobile", "tablet"]}>
          <div className="d-flex form-button-group mb-4">
            <ButtonSystem
              className="me-1"
              color="primary"
              type="submit"
              loading={loading}
            >
              Tạo
            </ButtonSystem>
            <Button
              outline
              color="secondary"
              type="reset"
              onClick={handleReset}
            >
              Đặt lại
            </Button>
          </div>
        </ViewDevice>

        <div className="form-content__client-info">
          <div className="mb-2 hotline-form-content__input">
            <Label className="form-label" for="client-name">
              Tên khách hàng
            </Label>
            <Controller
              id="client-name"
              name="name"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <InputSystem 
                  {...field}
                  placeholder="Tên khách hàng"
                  invalid={errors.name && true}
                />
              )}
            />
            {errors.name && <FormFeedback>{errors.name.message}</FormFeedback>}
          </div>

          <div className="mb-2 hotline-form-content__input">
            <Label className="form-label" for="client-name">
              Số điện thoại khách hàngs
            </Label>
            <Controller
              id="client-phone"
              name="phone"
              control={control}
              render={({ field }) => (
                <PhoneInputSystem
                  {...field}
                  className={classNames("form-control", {
                    "is-invalid": errors.phone && true,
                  })}
                  placeholder="Số điện thoại khách hàng"
                />
              )}
            />
            {errors.phone && (
              <FormFeedback>{errors.phone.message}</FormFeedback>
            )}
          </div>
        </div>

        <div className="mb-2 form-content__booking-type">
          <Label className="form-label" for="booking-type">
            Loại chuyến xe
          </Label>

          <div className="form-radio-group">
            <div className="form-check form-check-primary">
              <Input
                type="radio"
                name="type"
                checked={isBookingType}
                onChange={() =>
                  // eslint-disable-next-line implicit-arrow-linebreak
                  setTypeOption(HOTLINE_OPTIONS.BOOKING_CREATE_TYPE.BOOKING)
                }
              />
              <Label className="form-check-label" for="type-active">
                Chuyến xe
              </Label>
            </div>
            <div className="form-check form-check-primary">
              <Input
                type="radio"
                name="type"
                checked={!isBookingType}
                onChange={() =>
                  // eslint-disable-next-line implicit-arrow-linebreak
                  setTypeOption(HOTLINE_OPTIONS.BOOKING_CREATE_TYPE.REQUEST)
                }
              />
              <Label className="form-check-label" for="type-inactive">
                Gửi yêu cầu
              </Label>
            </div>
          </div>
        </div>

        <div className="mb-2">
          <Label className="form-label" for="start-address">
            Địa chỉ đi
          </Label>
          <Controller
            id="start-address"
            name="startAddress"
            defaultValue=""
            control={control}
            render={({ field }) => {
              const handleAutoCompleteSelect = (place) => {
                field.onChange(place.address);
                setValue("startPoint", place.location);
              };

              return (
                <AutoCompleteInput
                  map={map}
                  placeholder="Địa chỉ đi"
                  className={classNames("autocomplete-input", {
                    "is-invalid": errors.startAddress && true,
                  })}
                  onEmit={handleAutoCompleteSelect}
                />
              );
            }}
          />
          {errors.startAddress && (
            <FormFeedback>{errors.startAddress.message}</FormFeedback>
          )}
        </div>

        {isBookingType && (
          <div className="mb-2">
            <Label className="form-label" for="end-address">
              Địa chỉ đến
            </Label>
            <Controller
              id="end-address"
              name="endAddress"
              defaultValue=""
              control={control}
              render={({ field }) => {
                const handleAutoCompleteSelect = (place) => {
                  field.onChange(place.address);
                  setValue("endPoint", place.location);
                };

                return (
                  <AutoCompleteInput
                    map={map}
                    placeholder="Địa chỉ đến"
                    className={classNames("autocomplete-input", {
                      "is-invalid": errors.endAddress && true,
                    })}
                    onEmit={handleAutoCompleteSelect}
                  />
                );
              }}
            />
            {errors.endAddress && (
              <FormFeedback>{errors.endAddress.message}</FormFeedback>
            )}
          </div>
        )}

        <div className="mb-2">
          <Label className="form-label" for="car-type">
            Loại xe
          </Label>
          <Controller
            id="car-type"
            name="carType"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <SelectSystem
                menuPlacement="top"
                data={carTypeOptions}
                classNamePrefix="select"
                placeholder="Chọn loại xe"
                theme={selectThemeColors}
                className={classNames("react-select create-booking__select", {
                  "is-invalid": errors.carType && true,
                })}
                {...field}
              />
            )}
          />
          {errors.carType && (
            <FormFeedback>{errors.carType.message}</FormFeedback>
          )}
        </div>

        {isBookingType && (
          <div className="mb-2">
            <Label className="form-label" for="coupon-id">
              Khuyến mãi
            </Label>
            <Controller
              id="coupon-id"
              name="coupon"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <SelectSystem
                  menuPlacement="top"
                  options={couponsOptions}
                  classNamePrefix="select"
                  placeholder="Chọn khuyến mãi"
                  theme={selectThemeColors}
                  className={classNames("react-select create-booking__select", {
                    "is-invalid": errors.coupon && true,
                  })}
                  {...field}
                />
              )}
            />
            {errors.coupon && (
              <FormFeedback>{errors.coupon.message}</FormFeedback>
            )}
          </div>
        )}

        <div className="mb-2">
          <Label className="form-label" for="car-type">
            Ghi chú
          </Label>
          <Controller
            id="car-type"
            name="note"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <InputSystem 
                type="textarea"
                menuPlacement="top"
                classNamePrefix="select"
                placeholder="Thêm ghi chú khách hàng"
                className={classNames("react-select create-booking__select", {
                  "is-invalid": errors.note && true,
                })}
                {...field}
              />
            )}
          />
        </div>
      </div>

      <ViewDevice>
        <div className="d-flex form-button-group">
          <ButtonSystem
            className="me-1"
            color="primary"
            type="submit"
            loading={loading}
          >
            Tạo
          </ButtonSystem>
          <Button outline color="secondary" type="reset" onClick={handleReset}>
            Đặt lại
          </Button>
        </div>
      </ViewDevice>
    </Form>
  );
};

export default AddBookingForm;
