import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import { newApi } from "../apis";

export const getAllNews = createAsyncThunk("/new/getAllNews", async (query) => {
  try {
    const response = await newApi.getAllNews({ q: query });
    return response;
  } catch (error) {
    toast.error(error?.message);
    throw new Error(error);
  }
});

// export const createPartner = async (payload) => {
//   try {
//     await partenrApi.createPartnerForAdmin(payload);
//   } catch (error) {
//     throw new Error(error);
//   }
// };

// export const deleteOneUser = createAsyncThunk(
//   "/partner/deteleOneUser",
//   async (query) => {
//     try {
//       const response = await partenrApi.deleteOneUser(query);
//       return response;
//     } catch (error) {
//       toast.error(error?.message);
//       throw new Error(error);
//     }
//   }
// );

export const NewSlice = createSlice({
  name: "news",
  initialState: {
    news: {
      list: [],
      total: 0,
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllNews.fulfilled, (state, { payload }) => {
      console.log("payload :>>", payload);
      state.news.list = payload?.data || [];
      state.news.total = payload?.total || 0;
    });

    // builder.addCase(getAllCarTypeWOPaging.fulfilled, (state, { payload }) => {
    //   state.carTypes = payload || [];
    // });

    // builder.addCase(getAllWorkPlaceWOPaging.fulfilled, (state, { payload }) => {
    //   console.log("pay", payload);
    //   state.workPlaces = payload || [];
    // });
  },
});

export default NewSlice.reducer;
