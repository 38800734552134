import classNames from "classnames";
import "./index.scss";
import { formatTimeToLocalTime } from "utility/time-utils";
import { DATE_TIME_FORMAT } from "constants";
import MAP_OPTIONS from "modules/ego-system/gg-map/constants";
import { Button } from "reactstrap";
import { useMemo } from "react";
import { formatPhoneNumberToShow } from "utils";
import ButtonSystem from "components/e-control/button-system";

const RequestItem = ({
  booking,
  className,
  onBookingClick,
  onCancelRequest,
}) => {
  const isShowCancelBoooking = useMemo(() => {
    return (
      booking.status === MAP_OPTIONS.BOOKING_STATUS.PENDING ||
      booking.status === MAP_OPTIONS.BOOKING_STATUS.READY
    );
  }, [booking.status]);

  const handleBookingClick = () => {
    onBookingClick?.();
  };

  const handleCancelRequest = (event) => {
    event.stopPropagation();

    onCancelRequest?.();
  };

  return (
    <div
      className={classNames([className, "hotline-request-item__card"])}
      onClick={handleBookingClick}
    >
      <div className="hotline-request-item__content">
        <p className="hotline-request-item__content-client">
          <span>{booking?.name}</span>
          <span>{formatPhoneNumberToShow(booking?.phone)}</span>
        </p>

        <p className="hotline-request-item__content-booking-date">
          <span>{formatTimeToLocalTime(booking?.createdAt)}</span>
          <span>
            {formatTimeToLocalTime(booking?.createdAt, DATE_TIME_FORMAT.Hm)}
          </span>
        </p>

        <p className="hotline-request-item__content-address">
          <span>Từ:</span>
          <span>{booking?.startAddress}</span>
        </p>
      </div>

      <div className="hotline-request-item__footer">
        {isShowCancelBoooking && (
          <ButtonSystem color="danger" onClick={handleCancelRequest}>
            Huỷ
          </ButtonSystem>
        )}
      </div>
    </div>
  );
};

export default RequestItem;
