import { gql } from "@apollo/client";
import { mutate, query } from "configs/http-config";

const newAction = {
  getAllNewsForAdmin: gql`
    query getAllNewsForAdmin($q: QueryGetListInput) {
      getAllNewsForAdmin(q: $q) {
        data {
          id
          content
          country {
            id
            name
          }
          createdAt
          description
          images
          title
          type
        }
        total
      }
    }
  `,

  createNews: gql`
    mutation createNews($data: CreateNewsInput!) {
      createNews(data: $data) {
        id
      }
    }
  `,

  updateNews: gql`
    mutation updateNews($id: ID!, $data: UpdateNewsInput!) {
      updateNews(id: $id, data: $data) {
        id
        title
      }
    }
  `,

  deleteOneNews: gql`
    mutation deleteOneNews($id: ID!) {
      deleteOneNews(id: $id) {
        id
      }
    }
  `,
};

export const newApi = {
  getAllNews: (payload) => {
    return query("getAllNewsForAdmin", newAction.getAllNewsForAdmin, payload);
  },

  createNews: (payload) => {
    return mutate("createNews", newAction.createNews, payload);
  },

  updateNews: (payload) => {
    return mutate("updateNews", newAction.updateNews, payload);
  },

  deleteOneNews: (payload) => {
    return mutate("deleteOneNews", newAction.deleteOneNews, payload);
  },
};
