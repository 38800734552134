import { useViewDetected } from "components/containers/device";
import useResponsive, { DEVICE_DETECT } from "utility/hooks/useResponsive";

export const getDeviceType = (userDetected) => {
  if (userDetected.tablet()) {
    return "tablet";
  }

  if (userDetected.mobile()) {
    return "mobile";
  }

  return "desktop";
};

const ViewDevice = ({
  is = 'desktop',
  children,
}) => {
  const { view } = useResponsive();
  const { deviceType } = useViewDetected();

  if ((deviceType === DEVICE_DETECT.DESKTOP && (Array.isArray(is) && is.includes(view) || is === view)) || (deviceType !== DEVICE_DETECT.DESKTOP && (is.includes(deviceType) || deviceType === is))) {
    return children
  }

  return null
}

export default ViewDevice;