import { DATE_TIME_FORMAT } from "constants"
import moment from "moment"

export const SampleData = [
    {
        title: 'DIVR',
        id: '123',
        description: 'lorem ipsum dolor sit amet, consectet. lorem ipsum dolor lorem ipsum lorem. Cum sociis natoque penatibus et justo',
        startDate: moment().add(5, 'day').format(DATE_TIME_FORMAT.YMD_SNAKE),
        endDate: moment().add(15, 'day').format(DATE_TIME_FORMAT.YMD_SNAKE),
        quantity: 1000000,
        price: 14000,
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGCu0HNBE7D9oht1Wat8e3BwoJJY5-rOxVZg&s'
    },

    {
        title: 'EVAFE',
        id: '123',
        description: 'lorem ipsum dolor sit amet, consectet. lorem ipsum dolor lorem ipsum lorem. Cum sociis natoque penatibus et justo',
        startDate: moment().subtract(2, 'day').format(DATE_TIME_FORMAT.YMD_SNAKE),
        endDate: moment().add(10, 'day').format(DATE_TIME_FORMAT.YMD_SNAKE),
        price: 10000,
        quantity: 1000000,
        image: 'https://media.licdn.com/dms/image/C4E12AQHJ8BmJANl8BQ/article-cover_image-shrink_720_1280/0/1623565059834?e=2147483647&v=beta&t=9Nl-O-y0UEqY3aXVM9jKqFgv7BqinwQnMGgvHQ27pgo'
    },

    {
        title: 'LEVIS',
        id: '123',
        description: 'lorem ipsum dolor sit amet, consectet. lorem ipsum dolor lorem ipsum lorem. Cum sociis natoque penatibus et justo',
        startDate: moment().subtract(10, 'day').format(DATE_TIME_FORMAT.YMD_SNAKE),
        endDate: moment().subtract(3, 'day').format(DATE_TIME_FORMAT.YMD_SNAKE),
        price: 5000,
        quantity: 2000000,
        image: 'https://www.eqs.com/assets/2020/03/Investor-Targeting-Basics_featured-768x432.jpg'
    }
]