import classNames from "classnames";
import { HOTLINE_OPTIONS } from "modules/ego-system/hotline/constants";
import { useImperativeHandle, useMemo, useRef, useState } from "react";
import HotlineBookings from "../bookings";

import { DATE_TIME_FORMAT } from "constants";
import MAP_OPTIONS from "modules/ego-system/gg-map/constants";
import {
    getAllBookingCreatedByHotlineToday,
    resetBookingActions,
} from "modules/ego-system/hotline/store";
import moment from "moment";
import { ArrowLeft } from "react-feather";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { dispatch } from "root-stores";
import AddBookingForm from "../add-booking-form";
import HotlineBookingDetail from "../booking-detail";
import "./index.scss";

const HotlineBookingWrapper = ({
  hotlineBookingRef,
  isCreateModal = false,
  closeCreateModal,
}) => {
  const [type, setType] = useState(HOTLINE_OPTIONS.VIEW.BOOKING_LIST);
  const [page, setPage] = useState(1);

  const { type: typeFilter } = useSelector((state) => state.hotline);

  // console.log("typeFilter :>>", typeFilter);

  const hotlineRef = useRef(null);

  const addFormView = useMemo(() => {
    return type === HOTLINE_OPTIONS.VIEW.ADD_FORM;
  }, [type]);

  const bookingListView = useMemo(() => {
    return type === HOTLINE_OPTIONS.VIEW.BOOKING_LIST;
  }, [type]);

  const bookingDetaiView = useMemo(() => {
    return type === HOTLINE_OPTIONS.VIEW.BOOKING_DETAIL;
  }, [type]);

  const contentClass = useMemo(() => {
    return classNames([
      "hotline-booking__content",
      {
        "hotline-booking__content-form": addFormView || isCreateModal,
        "hotline-booking__content-booking-list":
          bookingListView && !isCreateModal,
        "hotline-booking__content-booking-detail":
          bookingDetaiView && !isCreateModal,
      },
    ]);
  }, [addFormView, bookingListView, bookingDetaiView]);

  const handleAddBooking = () => {
    setType(HOTLINE_OPTIONS.VIEW.ADD_FORM);
  };

  const handleGoBack = () => {
    dispatch(resetBookingActions());
    setType(HOTLINE_OPTIONS.VIEW.BOOKING_LIST);
  };

  const handleAddSuccess = () => {
    if (!isCreateModal) {
      handleGoBack();
    }

    if (isCreateModal) {
      closeCreateModal?.();
    }
  };

  const handleBookingDetailView = () => {
    setType(HOTLINE_OPTIONS.VIEW.BOOKING_DETAIL);
  };

  const loadBookingByFilter = async (filter = {}) => {
    try {
      const payload = {
        page: page,
        fromDate: moment()
          .subtract(30, "days")
          .format(DATE_TIME_FORMAT.YMD_SNAKE),
        toDate: moment().format(DATE_TIME_FORMAT.YMD_SNAKE),

        status: [
          MAP_OPTIONS.BOOKING_STATUS.PENDING,
          MAP_OPTIONS.BOOKING_STATUS.REFUSED,
        ],
        search: filter?.search,
        type: filter?.type || typeFilter,
      };

      await dispatch(getAllBookingCreatedByHotlineToday(payload));
    } catch (error) {
      toast.error(error);
    }
  };

  const handleCompleteCancelBooking = () => {
    loadBookingByFilter();
    setType(HOTLINE_OPTIONS.VIEW.BOOKING_LIST);
  };

  useImperativeHandle(hotlineBookingRef, () => ({
    handleAddBooking,
    ...hotlineRef.current,
  }));

  return (
    <div className="hotline-booking-wrapper">
      <div className="hotline-booking__header">
        <div
          className={classNames([
            "hotline-booking__header-back",
            { shown: (addFormView || bookingDetaiView) && !isCreateModal },
          ])}
          onClick={handleGoBack}
        >
          <ArrowLeft />
          <p className="hotline-booking__header-back-text">Trở về</p>
        </div>

        {bookingDetaiView ? "Chi tiết chuyến xe" : "Tạo chuyến xe"}
      </div>

      <div className="hotline-booking__body">
        <div className={contentClass}>
          <div className="hotline-booking__page">
            {bookingListView && !isCreateModal && (
              <HotlineBookings
                bookingListRef={hotlineRef}
                onAddBooking={handleAddBooking}
                onSelectBooking={handleBookingDetailView}
                page={page}
                setPage={setPage}
                loadBookingByFilter={loadBookingByFilter}
              />
            )}
          </div>

          <div className="hotline-booking__page hotline-booking__form">
            {(addFormView || isCreateModal) && (
              <AddBookingForm
                onAddSuccess={handleAddSuccess}
                isCreateModal={isCreateModal}
                handleCompleteCancelBooking={handleCompleteCancelBooking}
              />
            )}
          </div>

          <div className="hotline-booking__page hotline-booking__form">
            {bookingDetaiView && !isCreateModal && (
              <HotlineBookingDetail
                handleCompleteCancelBooking={handleCompleteCancelBooking}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotlineBookingWrapper;
