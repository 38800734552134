import classNames from "classnames";
import TabPageContent from "components/containers/tab-page-content";
import { useCallback, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Document from "./document";
import TabInvest from "./invest";
import TabDescription from "./description";

const TabOwnerDetail = ({ unitId }) => {
  const listTab = useMemo(
    () => [
      {
        title: "Đầu tư",
        tabId: 0,
        content: <TabInvest unitId={unitId} />,
      },

      {
        title: "Thông tin công ty",
        tabId: 1,
        content: <TabDescription />,
      },

      {
        title: "Tài liệu",
        tabId: 2,
        content: <Document />,
      },
    ],
    [unitId]
  );

  const [current, setCurrent] = useState();

  const [searchParams, setSearchParams] = useSearchParams();

  const currentTab = useMemo(() => {
    return searchParams.get("tabId") || 0;
  }, [searchParams]);

  const Components = useCallback(() => {
    if (current) {
      return listTab?.[current]?.content;
      // || <TableBookingUser />;
    }

    return listTab?.[currentTab]?.content || "Đầu tuw";
  }, [listTab, currentTab, current]);

  const tabLabel = useMemo(() => {
    return listTab?.[current]?.title;
  }, [current]);

  const handleTabSelect = (tabId) => {
    searchParams.set("tabId", tabId);
    setSearchParams(searchParams);
  };
  return (
    <div>
      <TabPageContent
        contentClass="overflow-x-auto"
        tabs={
          <>
            {listTab?.map((tab) => (
              <div
                key={tab.tabId}
                className={classNames(
                  "transition-all rounded-md py-2 px-4 cursor-pointer text-[12px] whitespace-nowrap w-fit",
                  Number(currentTab) === tab.tabId && "bg-primary text-white"
                )}
                onClick={() => {
                  handleTabSelect?.(tab?.tabId);
                }}
              >
                {tab.title}
              </div>
            ))}
          </>
        }
      >
        <div className="py-4 mb-0">
          <Components />
        </div>
      </TabPageContent>
    </div>
  );
};

export default TabOwnerDetail;
