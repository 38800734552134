import { createAsyncThunk } from "@reduxjs/toolkit";
import { comboApis } from "modules/shop/combo/api";

// products
export const getAllCombo = createAsyncThunk(
  "/product/getAllCombo",
  async (query) => {
    try {
      const response = await comboApis.getAllShopCombo(query);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getOneShopCombo = createAsyncThunk(
  "/product/getOneShopCombo",
  async (id) => {
    try {
      const response = await comboApis.getOneShopCombo(id);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const combo = {
  records: [],
  total: 0,
  comboAction: null,
  comboDetails: {},
};

export const comboBuilder = (builder) => {
  builder.addCase(getAllCombo.fulfilled, (state, { payload }) => {
    state.combo.records = payload?.data || [];
    state.combo.total = payload?.total || 0;
  });

  builder.addCase(getOneShopCombo.fulfilled, (state, { payload }) => {
    state.comboDetails = payload || {};
  });
};
