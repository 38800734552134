import { useEffect, useRef } from "react";

import locationMapPin from "assets/images/map/location-workplace.svg";

const MapPin = ({ workplace, map, mapZoom, onClick }) => {
  const marker = useRef(null);

  useEffect(() => {
    if (!marker.current) {
      return;
    }

    // const isVisible = marker.current.getVisible();

    marker.current.setVisible(true);

    // if (mapZoom > 8 && isVisible) {
    //   marker.current.setVisible(false);
    // }

    // if (mapZoom < 8 && !isVisible) {
    //   marker.current.setVisible(true);
    // }

    marker.current.addListener("click", onClick);
  }, [mapZoom, marker.current]);

  useEffect(() => {
    if (!map) {
      return;
    }

    if (!marker.current) {
      marker.current = new window.google.maps.Marker({
        animation: 2,
        map: map,
        position: {
          lng: workplace?.location?.[0],
          lat: workplace?.location?.[1],
        },
        icon: locationMapPin,
      });
    }

    // if (!workplace?.location && marker.current) {
    //     marker.current.setMap(null)
    //     marker.current = null
    // }
  }, [workplace, map, marker.current]);

  useEffect(() => {
    return () => {
      if (!marker.current) {
        return;
      }
      marker.current.setMap(null);
      marker.current = null;
    };
  }, []);
};

export default MapPin;
