import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import "./index.scss";

import { useConfirmContext } from "components/containers/confirm-container";
import PageWrapper from "components/containers/page/page-wrapper";
import TablePageContent, {
  TableFilter,
} from "components/containers/table-page-content";
import { useSearchParams } from "react-router-dom";
import { INVEST_ROUTES } from "router/invest-routes";
import { investApi } from "../apis";
import { PAYMENT_METHODS } from "../client/components/buy-form";
import HistoryOrderFilterAdmin from "./filter";
import OrderHistoryTable from "./table";
import UpdateOrderForm from "./update-order-form";
import ModalDetailOrder from "./modal-detail-order";

const OrderHistoryPageAdmin = () => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [listOrder, setListOrder] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [dataDetail, setDataDetail] = useState({});
  const [isOpenDetail, setIsOpenDetail] = useState(false);

  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const paramId = searchParams.get("id");

  const [filter, setFilter] = useState({
    isPaid: false,
    search: "",
    paymentMethod: PAYMENT_METHODS.COMPANY,
  });

  const { setOpenConfirmation, confirmDialog } = useConfirmContext();

  const handleCompleteParamId = () => {
    if (paramId) {
      setSearchParams("");
    }
  };

  const handleFilter = (value) => {
    setFilter({
      ...filter,
      ...value,
    });
    setPage(1);
  };

  const pagination = useMemo(() => {
    return {
      page,
      total: listOrder?.total || 0,
      onPageChange: (page) => setPage(page),
    };
  }, [page, listOrder?.total]);

  // Handle get list payment
  const loadListPayment = async () => {
    const isDeleteStatus = filter?.isPaid === "DELETED";

    try {
      setLoading(true);

      const params = {
        page: page,
        limit: 10,
        filter: {
          ...(!isDeleteStatus && {
            isPaid: filter?.isPaid,
            status: { $ne: "DELETED" },
          }),

          ...(isDeleteStatus && {
            status: filter?.isPaid,
          }),
          paymentMethod: filter?.paymentMethod,
        },

        search: filter?.search,
        order: { updatedAt: -1 },
      };

      const response = await investApi.getInvestmentOrder(params);

      setListOrder(response);
    } catch (error) {
      toast.error(error?.message);
      return;
    } finally {
      setLoading(false);
    }
  };

  const approvePayment = async (item) => {
    const isConfirm = await confirmDialog({
      messages: ["Bạn có chắc đơn hàng này đã thanh toán không?"],
    });

    if (!isConfirm) {
      return;
    }

    try {
      await investApi.confirmPaymentInvestmentOrder(item?.id);

      toast.success("Xác thực thanh toán thành công!");
      loadListPayment();
      setIsOpenDetail(false);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const deletePayment = async (item) => {
    const isConfirm = await confirmDialog({
      messages: ["Bạn có chắc cập nhật trạng thái xoá đơn hàng này không?"],
    });

    if (!isConfirm) {
      return;
    }

    const payload = {
      id: item?.id,
      data: {
        status: "DELETED",
      },
    };

    try {
      // await investApi.confirmPaymentInvestmentOrder(item?.id);
      await investApi.updateInvestmentOrder(payload);

      toast.success("Cập nhật trạng thái đơn hàng thành công!");
      loadListPayment();
      setIsOpenDetail(false);
    } catch (error) {
      toast.error(error.message);
    }
  };

  // update order
  const [formOpen, setFormOpen] = useState(false);
  const [order, setOrder] = useState(null);

  const closeFormUpdate = () => {
    setFormOpen(false);
    setOrder(null);
    loadListPayment();
  };

  const editAmount = (order) => {
    setOrder(order);
    setFormOpen(true);
    setIsOpenDetail(false);
  };

  const loadDetailPayments = async (id) => {
    try {
      const response = await investApi.getOneInvestmentOrderById(id);

      setDataDetail(response?.getOneInvestmentOrder);
      setIsOpenDetail(true);
    } catch (error) {
      toast.error(error?.message);
      return;
    }
  };

  const handleToggleModal = () => {
    setIsOpenDetail(!isOpenDetail);
    setDataDetail({});
  };

  const viewDetailOrder = (item) => {
    loadDetailPayments(item?.id);
  };

  const updateOrder = async (formData) => {
    const payload = {
      id: order?.id,
      data: {
        paymentMethod: order?.paymentMethod,
        investmentProjectId: order?.investmentProjectId,
        quantity: formData?.quantity,
        finalCost: formData?.amount,
      },
    };

    try {
      setLoadingUpdate(true);
      await investApi.updateInvestmentOrder(payload);

      toast.success("Cập nhật số cổ phiếu thành công");
      closeFormUpdate();
    } catch (e) {
      toast.error(e?.message);
    } finally {
      setLoadingUpdate(false);
    }
  };

  useEffect(() => {
    loadListPayment();
  }, [filter, page]);

  useEffect(() => {
    if (paramId) {
      window.history.replaceState(
        null,
        "",
        INVEST_ROUTES.INVEST_ORDER_HISTORY_ADMIN
      );

      if (paramId) {
        loadDetailPayments(paramId);
      }
    }
  }, [paramId]);

  return (
    <>
      <PageWrapper
        title="Lịch sử đầu tư"
        breadcrumbs={[
          {
            title: "Lịch sử đầu tư",
          },
        ]}
      >
        <TablePageContent>
          <div className="flex items-end justify-between mb-3">
            <span className="text-sm">Tổng: {listOrder?.total || 0}</span>

            <TableFilter>
              <HistoryOrderFilterAdmin
                filter={filter}
                handleFilter={handleFilter}
              />
            </TableFilter>
          </div>

          <OrderHistoryTable
            data={listOrder?.data}
            loading={loading}
            pagination={pagination}
            filter={filter}
            editAmount={editAmount}
            approvePayment={approvePayment}
            deletePayment={deletePayment}
            viewDetailOrder={viewDetailOrder}
          />
        </TablePageContent>
      </PageWrapper>

      <UpdateOrderForm
        isOpen={formOpen}
        order={order}
        initQty={order?.quantity || 0}
        header="Chỉnh sửa đầu tư"
        onClose={closeFormUpdate}
        onSubmit={updateOrder}
        loading={loadingUpdate}
      />

      <ModalDetailOrder
        isOpen={isOpenDetail}
        data={dataDetail}
        toggle={handleToggleModal}
        approvePayment={approvePayment}
        editAmount={editAmount}
      />
    </>
  );
};

export default OrderHistoryPageAdmin;
