import classNames from "classnames";
import { Activity, Captions, Clock, Send, Tags } from "lucide-react";
import { useState } from "react";
import { formatNumberToAmount } from "utility/number-utils";
import Utils from "utility/time";
import TooltipSystem from "components/e-control/tooltip-system";
import toast from "react-hot-toast";
import BuyFormAccumulate from "../buy-form-accumulate";
import { accumulateApi } from "../../apis";
import Popup from "modules/invest/client/components/popup";
import FormInfoQrCode from "modules/invest/client/components/form-info-qr";
import ViewCondition from "components/utils/view-condition";

const OrderItemAccumulate = ({ item }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupOpenPaymentAgain, setIsPopupOpenPaymentAgain] = useState(false);

  const [selectInvestment, setSelectInvestment] = useState(null);

  const [isPopupOpenPayment, setIsPopupOpenPayment] = useState(false);

  const [infoQrCode, setInfoQrCode] = useState({});

  const [orderId, setOrderId] = useState("");

  const handleConfirmPayment = async () => {
    try {
      await accumulateApi.sendConfirmPaymentSavingsPackage(orderId);

      toast.success("Đã xác thực chuyển tiền thành công");
      setTimeout(() => {
        setIsPopupOpenPayment(false);
      }, [1000]);
    } catch (e) {
      toast.error(e?.message);
    }
  };

  const handleSetInfoQrCode = (item) => {
    setIsPopupOpenPayment(true);
    setIsPopupOpenPaymentAgain(false);
    setInfoQrCode(item);
  };

  const handleRePayment = () => {
    setSelectInvestment({
      ...item?.savingsPlan,
      amount: item?.amount,
      orderId: item?.id,
    });
    setIsPopupOpenPaymentAgain(true);
  };

  return (
    <>
      <div className="mb-0 p-3 bg-gray-50 w-full border border-[#d5d5d5]">
        <div className="space-y-2">
          <div className="flex items-center justify-between gap-x-2">
            <div className="flex items-center gap-x-2">
              <span className="flex items-center gap-x-2 text-sm text-secondary-2">
                <Tags className="w-4 h-4" />
              </span>

              <span className="text-primary">{item?.code}</span>
            </div>

            <div className="flex justify-between items-center">
              <div className="flex items-center gap-x-2">
                <Clock color="#29ab68" className="w-4 h-4" />

                <span className="text-blue-950 text-sm">
                  {Utils.formatDateOnlyNumber(item?.createdAt)}
                </span>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center gap-x-2">
            <div className="flex items-center gap-x-2">
              <Captions className="w-4 h-4" color="#29ab68" />

              <TooltipSystem title={item?.savingsPlan?.name}>
                <span className="text-blue-950 text-sm line-clamp-1">
                  {item?.savingsPlan?.name}
                </span>
              </TooltipSystem>
            </div>

            <div className="flex items-center gap-x-2">
              <Activity className="w-4 h-4" color="#29ab68" />
              <span className="text-blue-950 text-sm">
                {item?.savingsPlan?.ratePYear * 100}%
              </span>
            </div>
          </div>

          <div className="flex justify-between items-center gap-x-2">
            <div className="flex items-center gap-x-2">
              <span className="text-sm text-blue-950">Số tiền tích luỹ:</span>
              <span className="text-blue-950 text-sm">
                {formatNumberToAmount(item?.amount)}
              </span>
            </div>

            <div className="flex items-center gap-x-2">
              <span className="text-sm text-blue-950">Thời hạn:</span>
              <span className="text-blue-950 text-sm">
                {item?.savingsTerm} tháng
              </span>
            </div>
          </div>

          <div className="flex justify-end items-center gap-x-2">
            <div
              className={classNames(
                "flex items-center gap-x-2 py-1 px-2 rounded-md group text-xs",
                item?.isPaid && "text-primary bg-primary/30",
                !item?.isPaid && "text-red-500 bg-red-500/30"
              )}
            >
              {item?.isPaid ? "Đã thanh toán" : "Chưa thanh toán"}
            </div>

            <ViewCondition rIf={!item?.isPaid}>
              <div className="flex justify-end gap-x-2">
                {!item?.isPaid && (
                  <div
                    className="flex items-center gap-x-2 bg-blue-500/30 text-blue-500 py-1 px-2 rounded-md group"
                    onClick={handleRePayment}
                  >
                    <Send className="size-4 group-hover:rotate-45 transition-all" />
                    <span className="text-xs">Thanh toán</span>
                  </div>
                )}
              </div>
            </ViewCondition>
          </div>
        </div>
      </div>

      <Popup
        header="Thanh toán lại"
        isOpen={isPopupOpenPaymentAgain}
        onClose={() => setIsPopupOpenPaymentAgain(false)}
      >
        <BuyFormAccumulate
          campaign={selectInvestment}
          onCancel={() => setIsPopupOpen(false)}
          handleSetInfoQrCode={handleSetInfoQrCode}
          isPaymentAgain={true}
          setOrderId={setOrderId}
        />
      </Popup>

      <Popup
        header="Thông tin thanh toán"
        isOpen={isPopupOpenPayment}
        onClose={() => setIsPopupOpenPayment(false)}
      >
        <FormInfoQrCode
          handleConfirmPayment={handleConfirmPayment}
          info={infoQrCode}
        />
      </Popup>
    </>
  );
};

export default OrderItemAccumulate;
