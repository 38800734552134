import classNames from "classnames";
import { Activity, Captions, Clock, Send, Tags } from "lucide-react";
import { useState } from "react";
import { formatNumberToAmount } from "utility/number-utils";
import Utils from "utility/time";
import TooltipSystem from "components/e-control/tooltip-system";

const ItemCardRate = ({ item }) => {
  return (
    <>
      <div className="mb-0 p-3 bg-gray-50 w-full border border-[#d5d5d5]">
        <div className="space-y-2">
          <div className="flex items-center justify-between gap-x-2">
            <div className="flex items-center gap-x-2">
              <span className="flex items-center gap-x-2 text-sm text-secondary-2">
                <Tags className="w-4 h-4" />
              </span>

              <span className="text-primary">{item?.code}</span>
            </div>

            <div className="flex justify-between items-center">
              <div className="flex items-center gap-x-2">
                <Clock color="#29ab68" className="w-4 h-4" />

                <span className="text-blue-950 text-sm">
                  {Utils.formatDateOnlyNumber(item?.createdAt)}
                </span>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center gap-x-2">
            <div className="flex items-center gap-x-2">
              <Captions className="w-4 h-4" color="#29ab68" />

              <TooltipSystem title={item?.savingsPackage?.savingsPlan?.name}>
                <span className="text-blue-950 text-sm line-clamp-1">
                  {item?.savingsPackage?.savingsPlan?.name}
                </span>
              </TooltipSystem>
            </div>

            <div className="flex items-center gap-x-2">
              <Activity className="w-4 h-4" color="#29ab68" />
              <span className="text-blue-950 text-sm">
                {item?.savingsPackage?.savingsPlan?.ratePYear * 100}%
              </span>
            </div>
          </div>

          <div className="flex justify-between items-center gap-x-2">
            <div className="flex items-center gap-x-2">
              <span className="text-sm text-blue-950">Số tiền tích luỹ:</span>
              <span className="text-blue-950 text-sm">
                {formatNumberToAmount(item?.savingsPackage?.amount)}
              </span>
            </div>

            <div className="flex items-center gap-x-2">
              <span className="text-sm text-blue-950">Thời hạn:</span>
              <span className="text-blue-950 text-sm">
                {item?.savingsPackage?.savingsPlan?.savingsTerm} tháng
              </span>
            </div>
          </div>

          <div className="flex justify-between items-center gap-x-2">
            <div className="flex items-center gap-x-2">
              <span className="text-sm text-blue-950">Tiền lãi:</span>
              <span className="text-blue-950 text-sm">
                {formatNumberToAmount(item?.interestAmount)}
              </span>
            </div>

            <div
              className={classNames(
                "flex items-center gap-x-2 py-1 px-2 rounded-md group text-xs",
                item?.isPaid && "text-primary bg-primary/30",
                !item?.isPaid && "text-red-500 bg-red-500/30"
              )}
            >
              {item?.isPaid ? "Đã thanh toán" : "Chưa thanh toán"}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemCardRate;
