import { mutate, query } from "configs/http-config";
import { categoryAction } from "./schema";

export const productTypeApi = {
    getAllShopProductType: (payload) => {
        return query(
            "getAllShopProductType",
            categoryAction.getAllShopProductType,
            { q: payload }
        )
    },

    getOneProductCategory: (id) => {
        return query(
            'getOneShopProductType',
            categoryAction.getOneShopCategory,
            { getOneShopProductTypeId: id }
        )
    },

    createShopProductType: (payload) => {
        return mutate(
            "createShopProductType",
            categoryAction.createShopProductType,
            payload
        )
    },

    updateShopProductType: (payload) => {
        return mutate(
            "updateShopProductType",
            categoryAction.updateShopProductType,
            payload
        )
    },

    deleteOneShopProductType: (payload) => {
        return mutate(
            "deleteOneShopProductType",
            categoryAction.deleteOneShopProductType,
            payload
        )
    }
}