/* eslint-disable multiline-ternary */
/* eslint-disable implicit-arrow-linebreak */
import TableSystem, { TableWrapperContext } from "components/e-control/table";

import { useConfirmContext } from "components/containers/confirm-container";
import DropdownSystem from "components/e-control/forms/form-elements/dropdown-system";
import MoreDropdown from "components/views/more-dropdown";
// import "./index.scss";
// import KycUserItemTemplate from "./template";

const AuthPaymentTable = ({
  partners,
  loading,
  pagination,
  refuseDriverCar,
  approveDriverCar,
}) => {
  const { confirmDialog } = useConfirmContext();

  const menuOptions = [
    {
      label: "Xác nhận",
      value: approveDriverCar,
      status: ["PENDING"],
    },
    {
      label: "Từ chối",
      value: refuseDriverCar,
      status: ["PENDING"],
    },
    // {
    //   label: "Chi tiết",
    //   value: handleViewDetail,
    //   status: ["PENDING", "APPROVE"],
    // },
  ];

  const optionsByStatus = (status) => {
    return menuOptions.filter((item) => {
      if (!item.status) {
        return true;
      }

      return item.status && item?.status?.includes(status);
    });
  };

  const columns = [
    {
      title: "Mã đơn hàng",
      dataIndex: "name",
      key: "name",
      render: (_, row) => row?.name,
    },
    {
      title: "Chương trình đầu tư",
      dataIndex: "name",
      key: "name",
      render: (_, row) => row?.name,
    },
    {
      title: "Tên người dùng",
      dataIndex: "name",
      key: "name",
      render: (_, row) => row?.name,
    },

    {
      title: "Số điện thoại",
      dataIndex: "phone",
      key: "phone",
      render: (_, row) => row?.phone?.replace("+84", "0"),
    },

    {
      title: "Trạng thái",
      dataIndex: "eKycStatus",
      key: "eKycStatus",
      render: (_, row) =>
        row?.eKycStatus === "PENDING"
          ? "Chờ duyệt"
          : row?.eKycStatus === "APPROVE"
          ? "Chấp nhận"
          : "Huỷ",
    },

    {
      title: "",
      key: "action",
      dataIndex: "action",
      className: "actions",
      width: "80px",
      render: (_, row) => {
        return (
          <DropdownSystem
            options={optionsByStatus(row?.eKycStatus)}
            onMenuSelect={(callback) => callback(row)}
          >
            <MoreDropdown />
          </DropdownSystem>
        );
      },
    },
  ];

  return (
    <TableWrapperContext
      value={{
        options: menuOptions,
      }}
    >
      <TableSystem
        // template={KycUserItemTemplate}
        data={partners}
        columns={columns}
        loading={loading}
        pagination={pagination}
        containerClassName="car-table"
        scroll={{ x: 768 }}
      />
    </TableWrapperContext>
  );
};

export default AuthPaymentTable;
