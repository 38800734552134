
const { ChevronDown, MoreVerticalIcon, Equal, MoreHorizontalIcon } = require("lucide-react")

const MoreDropdown = () => {
    return (
        <>
            <MoreVerticalIcon className="hidden w-4 h-4 md:block visible" />

            <MoreHorizontalIcon className="block w-4 h-4 md:hidden visible" />
            
            {/* <div className="block md:hidden visible text-primary flex flex-col gap-y-1 items-center gap-x-1 text-sm active:bg-transparent">
                <p className="h-0.5 w-[20px] bg-primary rounded-md"/>
                <p className="h-0.5 w-[20px] bg-primary rounded-md"/>
            </div> */}
        </>
    )
}

export default MoreDropdown;