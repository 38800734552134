import React from "react";

const CardDescription = () => {
  return (
    <div className="p-4 rounded-lg bg-white shadow-sm mt-4">
      <p className="text-base">
        Sản phẩm được cấu thành từ: chứng chỉ tiền gửi , tiền gửi và công cụ nợ
        tại các công ty tài chính tiêu dùng, sản phẩm sinh lời cố định của các
        công ty chứng khoán
      </p>
    </div>
  );
};

export default CardDescription;
