import { gql } from "@apollo/client";
import { mutate, query } from "configs/http-config";

const BookingsAction = {
  getAllRentCarTripForPartner: gql`
    query getAllRentCarTripForPartner(
      $page: Int
      $limit: Int
      $filter: Mixed
      $order: Mixed
      $search: String
      $fromDate: DateTime
      $toDate: DateTime
      $partnerId: ID!
    ) {
      getAllRentCarTripForPartner(
        q: {
          limit: $limit
          page: $page
          filter: $filter
          order: $order
          search: $search
        }
        fromDate: $fromDate
        toDate: $toDate
        partnerId: $partnerId
      ) {
        data {
          id
          createdAt
          startDate
          cancelDate
          endDate
          pickupAddress
          code
          isClientCancel
          discountAmount
          initialCost
          driverFee
          car {
            address
            id
            color
            carCode
            category
            carCompany
            manufacturingYear
            licensePlateNumber
            price
            overtimeFee
            overlimitFee
            cleanFee
            deodoriFee

            garage {
              id
              name
            }
          }
          paymentNote
          user {
            id
            name
            phone
            driverLicense
            avatar
            identityCard
            address
          }
          isRentDriver
          isSelfPickup
          mortgage
          legalPaper
          note
          pickupPoint
          partnerCar {
            id
            address
            carEgoId
            color
            carCode
            category
            carCompany
            manufacturingYear
            licensePlateNumber
            images
            description
            price

            overtimeFee
            overlimitFee
            cleanFee
            deodoriFee

            owner {
              name
            }
            price
            garage {
              id
              name
            }
          }
          driver {
            username
          }
          deposit
          finalCost
          insuranceFee
          insuranceTripFee
          driverFee
          serviceFee
          VATFee
          cashOnDelivery
          surchargeFee

          costIncurred
          paidType
          status

          mortgage
          legalPaper
          user {
            id
            name
            phone
          }
          type
          paidType
          paymentMethod
          overLimitKm
          overLimitTime
          reasonCancel
          refundCost
          refundMethod

          taxCode
          companyName
          companyAddress
          companyEmail

          partnerId

          deliveryNote
          egoDiscount
          egoDiscountPercent
        }

        getStatRentCarTrip
        total
      }
    }
  `,

  getAllPriceChangeHistory: gql`
    query getAllPriceChangeHistory(
      $page: Int
      $limit: Int
      $filter: Mixed
      $order: Mixed
      $search: String
    ) {
      getAllPriceChangeHistory(
        q: {
          limit: $limit
          page: $page
          filter: $filter
          order: $order
          search: $search
        }
      ) {
        data {
          id
          oldPrice
          newPrice
          priceChange
          reasonUpdate
          updatedAt
          createdAt
        }

        total
      }
    }
  `,

  getAllReasonIncurredWOPaging: gql`
    query getAllReasonIncurredWOPaging($type: String) {
      getAllReasonIncurredWOPaging(type: $type) {
        id
        name
        cost
        description
        type
      }
    }
  `,

  getAllActivityHistory: gql`
    query getAllActivityHistory($q: QueryGetListInput) {
      getAllActivityHistory(q: $q) {
        data {
          createdAt
          type

          newPartner {
            id
            name
          }

          oldPartner {
            id
            name
          }

          rentCarTrip {
            paymentNote
            receiveNote
            receiveImages

            userApproved {
              id
              name
            }

            user {
              id
              name
            }

            userCancel {
              id
              name
            }

            userRefuse {
              id
              name
            }

            partner {
              id
              name
            }
          }
        }
      }
    }
  `,

  cancelBookingByHotline: gql`
    mutation cancelTrip($tripId: ID!, $reasonCancel: String) {
      cancelTrip(tripId: $tripId, reasonCancel: $reasonCancel) {
        id
      }
    }
  `,

  cancelRequest: gql`
    mutation cancelTaxiRequestBooking($taxiRequestId: ID!) {
      cancelTaxiRequestBooking(taxiRequestId: $taxiRequestId) {
        id
      }
    }
  `,

  getOneTrip: gql`
    query getOneTrip($id: ID!) {
      getOneTrip(id: $id) {
        code
        couponIds
        coupons {
          code
        }
        createdAt
        discountAmount
        dispatch {
          status
        }
        dispatchId
        distance
        driver {
          name
          phone
          car {
            carCode
            licensePlateNumber
          }
        }
        driverDropOffTime
        driverId
        driverPickupTime
        endAddress
        endFullAddress
        endPoint
        finalCost
        id
        initialCost
        paymentMethod
        startAddress
        startFullAddress
        startPoint
        status
        travelTime
        updatedAt
        user {
          name
          phone
        }
        userId
        waitTime
        workPlace {
          id
          name
        }
        workPlaceId
      }
    }
  `,

  getOneRentCarTrip: gql`
    query getOneRentCarTrip($id: ID!) {
      getOneRentCarTrip(id: $id) {
        id
        createdAt
        startDate
        cancelDate
        endDate
        pickupAddress
        code
        isClientCancel
        discountAmount
        initialCost
        driverFee
        car {
          address
          id
          color
          carCode
          category
          carCompany
          manufacturingYear
          licensePlateNumber
          price
          overtimeFee
          overlimitFee
          cleanFee
          deodoriFee
          deliveryKm

          garage {
            id
            name
          }
          images
        }
        paymentNote
        user {
          id
          name
          phone
          driverLicense
          avatar
          identityCard
          address
          partnerId
          partner {
            id
          }
        }
        isRentDriver
        isSelfPickup
        mortgage
        legalPaper
        note
        pickupPoint
        partnerCar {
          id
          address
          carEgoId
          color
          carCode
          category
          carCompany
          manufacturingYear
          licensePlateNumber
          images
          description
          price

          overtimeFee
          overlimitFee
          cleanFee
          deodoriFee
          deliveryKm

          owner {
            name
          }
          price
          garage {
            id
            name
          }
        }
        driver {
          username
        }
        deposit
        finalCost
        insuranceFee
        insuranceTripFee
        driverFee
        serviceFee
        VATFee
        cashOnDelivery
        surchargeFee

        costIncurred
        paidType
        status

        mortgage
        legalPaper
        user {
          id
          name
          phone
        }
        type
        paidType
        paymentMethod
        overLimitKm
        overLimitTime
        reasonCancel
        refundCost
        refundMethod

        taxCode
        companyName
        companyAddress
        companyEmail

        partnerId

        deliveryNote
        egoDiscount
        egoDiscountPercent
      }
    }
  `,

  createRentCarTripForPartner: gql`
    mutation createRentCarTripForPartner(
      $data: CreateRentCarTripInputForAdmin!
    ) {
      createRentCarTripForPartner(data: $data) {
        id
      }
    }
  `,

  updateRentCarTrip: gql`
    mutation updateRentCarTrip($id: ID!, $data: UpdateRentCarTripInput!) {
      updateRentCarTrip(id: $id, data: $data) {
        id
      }
    }
  `,

  deleteOneRentCarTrip: gql`
    mutation deleteOneRentCarTrip($id: ID!) {
      deleteOneRentCarTrip(id: $id) {
        id
      }
    }
  `,

  cancelRentCarTrip: gql`
    mutation cancelRentCarTrip(
      $rentCarTripId: ID!
      $reasonCancel: String!
      $refundCost: Float
      $refundMethod: String
      $cancelDate: DateTime
      $isClientCancel: Boolean
    ) {
      cancelRentCarTrip(
        rentCarTripId: $rentCarTripId
        reasonCancel: $reasonCancel
        refundCost: $refundCost
        refundMethod: $refundMethod
        cancelDate: $cancelDate
        isClientCancel: $isClientCancel
      ) {
        id
      }
    }
  `,

  acceptRentCarTrip: gql`
    mutation acceptRentCarTrip($rentCarTripId: ID!) {
      acceptRentCarTrip(rentCarTripId: $rentCarTripId) {
        id
      }
    }
  `,

  confirmReceiptCar: gql`
    mutation confirmReceiptCar($rentCarTripId: ID!, $receiveNote: String) {
      confirmReceiptCar(
        rentCarTripId: $rentCarTripId
        receiveNote: $receiveNote
      ) {
        id
      }
    }
  `,

  receiptCar: gql`
    mutation receiptCar($rentCarTripId: ID!, $deliveryNote: String) {
      receiptCar(rentCarTripId: $rentCarTripId, deliveryNote: $deliveryNote) {
        id
      }
    }
  `,

  refuseRentCarTrip: gql`
    mutation refuseRentCarTrip($rentCarTripId: ID!, $reasonRefuse: String) {
      refuseRentCarTrip(
        rentCarTripId: $rentCarTripId
        reasonRefuse: $reasonRefuse
      ) {
        id
      }
    }
  `,

  finishRentCarTrip: gql`
    mutation finishRentCarTrip(
      $rentCarTripId: ID!
      $paymentMethod: String!
      $costIncurred: Float
      $reasonIncurredIds: [ID]
      $overLimitTime: Int
      $overLimitKm: Float
      $paymentNote: String
    ) {
      finishRentCarTrip(
        rentCarTripId: $rentCarTripId
        paymentMethod: $paymentMethod
        costIncurred: $costIncurred
        reasonIncurredIds: $reasonIncurredIds
        overLimitTime: $overLimitTime
        overLimitKm: $overLimitKm
        paymentNote: $paymentNote
      ) {
        id
      }
    }
  `,

  confirmPayRentCarTripForAdmin: gql`
    mutation confirmPayRentCarTripForAdmin(
      $rentCarTripId: ID!
      $paymentMethod: String!
      $costIncurred: Float
      $reasonIncurred: String
    ) {
      confirmPayRentCarTripForAdmin(
        rentCarTripId: $rentCarTripId
        paymentMethod: $paymentMethod
        costIncurred: $costIncurred
        reasonIncurred: $reasonIncurred
      ) {
        id
      }
    }
  `,

  updateCostIncurred: gql`
    mutation updateCostIncurred(
      $rentCarTripId: ID!
      $costIncurred: Float
      $reasonIncurred: String
    ) {
      updateCostIncurred(
        rentCarTripId: $rentCarTripId
        costIncurred: $costIncurred
        reasonIncurred: $reasonIncurred
      ) {
        id
      }
    }
  `,
  calcDistance: gql`
    mutation calcDistance($point1: [Float]!, $point2: [Float]!) {
      calcDistance(point1: $point1, point2: $point2)
    }
  `,

  getConfigHoliday: gql`
    query getConfigHoliday {
      getConfigHoliday
    }
  `,
};

export const bookingRentPartnerApi = {
  getAllRentCarTripForPartner: (payload) => {
    return query(
      "getAllRentCarTripForPartner",
      BookingsAction.getAllRentCarTripForPartner,
      payload
    );
  },

  getAllPriceChangeHistory: (payload) => {
    return query(
      "getAllPriceChangeHistory",
      BookingsAction.getAllPriceChangeHistory,
      payload
    );
  },

  getAllReasonIncurredWOPaging: (payload) => {
    return query(
      "getAllReasonIncurredWOPaging",
      BookingsAction.getAllReasonIncurredWOPaging,
      payload
    );
  },

  getOneBooking: (payload) => {
    return query(
      "getOneRentCarTrip",
      BookingsAction.getOneRentCarTrip,
      payload
    );
  },

  getAllActivityHistory: (payload) => {
    return query(
      "getAllActivityHistory",
      BookingsAction.getAllActivityHistory,
      payload
    );
  },

  getConfigHoliday: () => {
    return query("getConfigHoliday", BookingsAction.getConfigHoliday);
  },

  createRentCarTripForPartner: (payload) => {
    return mutate(
      "createRentCarTripForPartner",
      BookingsAction.createRentCarTripForPartner,
      payload
    );
  },

  updateRentCarTrip: (payload) => {
    return mutate(
      "updateRentCarTrip",
      BookingsAction.updateRentCarTrip,
      payload
    );
  },

  deleteOneRentCarTrip: (payload) => {
    return mutate(
      "deleteOneRentCarTrip",
      BookingsAction.deleteOneRentCarTrip,
      payload
    );
  },

  cancelBookingByHotline: (payload) => {
    return mutate("cancelTrip", BookingsAction.cancelBookingByHotline, payload);
  },

  cancelRequest: (payload) => {
    return mutate(
      "cancelTaxiRequestBooking",
      BookingsAction.cancelRequest,
      payload
    );
  },

  acceptRentCarTrip: (payload) => {
    return mutate(
      "acceptRentCarTrip",
      BookingsAction.acceptRentCarTrip,
      payload
    );
  },

  cancelRentCarTrip: (payload) => {
    return mutate(
      "cancelRentCarTrip",
      BookingsAction.cancelRentCarTrip,
      payload
    );
  },

  confirmReceiptCar: (payload) => {
    return mutate(
      "confirmReceiptCar",
      BookingsAction.confirmReceiptCar,
      payload
    );
  },

  refuseRentCarTrip: (payload) => {
    return mutate(
      "refuseRentCarTrip",
      BookingsAction.refuseRentCarTrip,
      payload
    );
  },

  finishRentCarTrip: (payload) => {
    return mutate(
      "finishRentCarTrip",
      BookingsAction.finishRentCarTrip,
      payload
    );
  },

  confirmPayRentCarTripForAdmin: (payload) => {
    return mutate(
      "confirmPayRentCarTripForAdmin",
      BookingsAction.confirmPayRentCarTripForAdmin,
      payload
    );
  },

  updateCostIncurred: (payload) => {
    return mutate(
      "updateCostIncurred",
      BookingsAction.updateCostIncurred,
      payload
    );
  },

  calcDistance: (payload) => {
    return mutate("calcDistance", BookingsAction.calcDistance, payload);
  },

  receiptCar: (payload) => {
    return mutate("receiptCar", BookingsAction.receiptCar, payload);
  },
};
