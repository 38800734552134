import { mutate, query } from "configs/http-config";
import { stockAction } from "./schema";

export const stockApi = {
    getAllShopProductWarehouse: (payload) => {
        return query(
            "getAllShopProductWarehouse",
            stockAction.getAllShopProductWarehouse,
            { q: payload }
        )
    },

    getOneShopProductWarehouse: (id) => {
        return query(
            'getOneShopProductWarehouse',
            stockAction.getOneShopProductWarehouse,
            id
        )
    },

    createShopProductWarehouse: (payload) => {
        return mutate(
            "createShopProductWarehouse",
            stockAction.createShopProductWarehouse,
            payload
        )
    },

    updateShopProductWarehouse: (payload) => {
        return mutate(
            "updateShopProductWarehouse",
            stockAction.updateShopProductWarehouse,
            payload
        )
    },

    deleteOneShopProductWarehouse: (payload) => {
        return mutate(
            "deleteOneShopProductWarehouse",
            stockAction.deleteOneShopProductWarehouse,
            payload
        )
    }
}