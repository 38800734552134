import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { InvoiceApi } from "../apis";
import { toast } from "react-hot-toast";

export const getStatInvoiceForPartner = createAsyncThunk(
  "/driver/getStatInvoiceForPartner",
  async (query) => {
    try {
      const response = await InvoiceApi.getStatInvoiceForPartner(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getStatInvoiceRentCarTrip = createAsyncThunk(
  "/driver/getStatInvoiceRentCarTrip",
  async (query) => {
    try {
      const response = await InvoiceApi.getStatInvoiceRentCarTrip(query);
      console.log("respone :>>", response);
      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const InvoiceSlice = createSlice({
  name: "invoices",
  initialState: {
    list: [],
    total: 0,
    stats: {},
    statsRent: {},
  },

  extraReducers: (builder) => {
    builder.addCase(
      getStatInvoiceForPartner.fulfilled,
      (state, { payload }) => {
        state.list = payload?.data || [];
        state.total = payload?.total || 0;
        state.stats = payload?.getStatTotal || {};
      }
    );

    builder.addCase(
      getStatInvoiceRentCarTrip.fulfilled,
      (state, { payload }) => {
        state.list = payload?.data || {};
        state.total = payload?.total || 0;
        state.stats = payload?.getStatTotal || {};
      }
    );
  },
});

// export const {} = driverSlice.actions;

export default InvoiceSlice.reducer;
