import { Tooltip } from "bootstrap";
import React, { useEffect, useRef } from "react";

import "./index.scss";
import { renderToStaticMarkup } from "react-dom/server";

const withTooltip = (WrappedComponent) => {
  return ({ children, ...props }) => {
    const { placement = "top", title = "", html = true } = props;

    const tooltipRef = useRef();

    useEffect(() => {
      let tooltip;

      const handleElementHover = (event) => {
        const contentElement = renderToStaticMarkup(title);

        tooltip = new Tooltip(event, {
          html,
          title: contentElement,
          placement,
          trigger: "hover",
        });

        return tooltip;
      };

      handleElementHover(tooltipRef.current);

      return () => {
        tooltip = null;
      };
    }, [title]);

    return (
      <div ref={tooltipRef} className="tooltip-system">
        <WrappedComponent>{children}</WrappedComponent>
      </div>
    );
  };
};

const TooltipSystem = ({ children }) => {
  return children;
};

export default withTooltip(TooltipSystem);
