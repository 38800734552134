import PaginationSystem from "components/e-control/pagination";
import { Calendar } from "react-feather";

import "./index.scss";
import BookingItem from "../booking-item";
import { useMemo, useRef } from "react";
import { HOTLINE_OPTIONS } from "modules/ego-system/hotline/constants";
import RequestItem from "../request-item";

const HotlineBookingList = ({
  list,
  pagination,
  bookingType,
  selectBooking,
  cancelBooking,
  cancelRequest,
}) => {
  const { onPageChange, ...paginationOptions } = pagination;

  const listRef = useRef();

  const isBookingType = useMemo(() => {
    return bookingType === HOTLINE_OPTIONS.BOOKING_CREATE_TYPE.BOOKING;
  }, [bookingType]);

  const handlePaginationChange = (page) => {
    listRef.current.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    onPageChange?.(page);
  };

  return (
    <div className="hotline-booking__list">
      <div ref={listRef} className="hotline-booking__list-wrapper">
        {!!list.length &&
          list.map((booking) => {
            if (isBookingType) {
              return (
                <BookingItem
                  key={booking.id}
                  booking={booking}
                  cancelBooking={() => cancelBooking(booking.id)}
                  onBookingClick={() => selectBooking(booking.id)}
                />
              );
            }

            return (
              <RequestItem
                key={booking.id}
                booking={booking}
                onCancelRequest={() => cancelRequest?.(booking.id)}
              />
            );
          })}

        {!list.length && (
          <div className="no-booking">
            <Calendar />
            <p>Không có chuyến xe</p>
          </div>
        )}
      </div>

      <div className="hotline-booking__pagination">
        <PaginationSystem
          {...paginationOptions}
          onPageChange={handlePaginationChange}
        />
      </div>
    </div>
  );
};

export default HotlineBookingList;
