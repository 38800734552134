import TableSystem, { TableWrapperContext } from "components/e-control/table";
import { investApi } from "modules/invest/apis";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card } from "reactstrap";
import { formatNumberToAmount } from "utility/number-utils";
import InvestUnitTemplate from "./template";

const InvestAccountTableProject = ({ detailUnit, ...props }) => {

    const [total, setTotal] = useState(0);
    const [investProject, setInvestProject] = useState([])
    const params = useParams();
    const { id } = params;
    const [loadingDetail, setLoadingDetail] = useState(false);

    const loadDetailInvestmentOrder = async () => {
        try {
            setLoadingDetail(true);
            const params = {
                filter: {
                    investmentUnitId: id,
                }
            };

            const res = await investApi.getInvestmentCampaigns(params);
            setInvestProject(res?.data)
            setTotal(res?.total || 0)
        } catch (error) {
            return;
        } finally {
            setLoadingDetail(false);
        }
    };

    useEffect(() => {
        loadDetailInvestmentOrder()
    }, [id])

    const columns = [
        {
            dataIndex: 'name',
            key: 'name',
            title: 'Tên dự án',
            render: (_, row) => <span className="line-clamp-1">{row?.name}</span>
        },

        {
            dataIndex: 'investAmount',
            key: 'investAmount',
            title: 'Số lượng phát hành',
            className: 'amount whitespace-nowrap',
            render: (_, row) => formatNumberToAmount(row?.totalStock || 0)
        },

        {
            dataIndex: 'pricePerStock',
            key: 'pricePerStock',
            title: 'Giá mỗi phần',
            className: 'amount whitespace-nowrap',
            render: (_, row) => formatNumberToAmount(row?.pricePerStock || 0)
        },

        {
            dataIndex: 'totalSoldTock',
            key: 'totalSoldTock',
            title: 'Đã bán',
            className: 'amount',
            render: (_, row) => row?.totalSoldTock || 0
        },
    ]

    return (
        <TableWrapperContext>
            <div className="flex flex-col space-y-4">
                <Card className="overflow-hidden space-y-3 mb-0 flex-[2] w-full bg-background-accent/5 md:bg-background-accent border-none">

                    <div className="flex items-end justify-between pt-3 p-0 md:px-3">
                        <h3 className="font-bold text-primary ">Danh sách dự án</h3>
                        <span className="text-sm">
                            Tổng: {total}
                        </span>
                    </div>

                    <TableSystem
                        {...props}
                        data={investProject}
                        template={InvestUnitTemplate}
                        columns={columns}
                        pagination={investProject.length}
                    />
                </Card>

                <Card className="mb-0 rounded-md p-4 space-y-2">
                    <div className="text-sm text-secondary-2 pb-2">Mô tả: </div>
                    <div className="flex items-center gap-x-2 text-sm">
                        <div
                            className="companyInfo-tab"
                            dangerouslySetInnerHTML={{ __html: detailUnit?.companyInfo }}
                        />
                    </div>
                </Card>
            </div>
        </TableWrapperContext>
    )
}
export default InvestAccountTableProject;
