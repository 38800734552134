import { gql } from "@apollo/client";
import { mutate, query } from "configs/http-config";

const NotificationAction = {
  adminSendNoti: gql`
    mutation AdminSendNoti(
      $type: String!
      $couponId: String
      $title: String!
      $body: String!
      $content: String!
      $workPlaceId: ID
      $userId: ID
      $option: String!
      $images: [String]!
    ) {
      adminSendNoti(
        type: $type
        couponId: $couponId
        title: $title
        body: $body
        content: $content
        workPlaceId: $workPlaceId
        userId: $userId
        option: $option
        images: $images
      )
    }
  `,

  getAllNotification: gql`
    query getAllNotification(
      $page: Int
      $limit: Int
      $filter: Mixed
      $order: Mixed
      $timeSort: Int
      $timestamp: DateTime
    ) {
      getAllNotification(
        q: { limit: $limit, page: $page, filter: $filter, order: $order,timeSort: $timeSort, timestamp: $timestamp }
      ) {
        data {
          id
          tag
          title
          body
          html
          seen
          seenAt
          data
          image
          toUserId
          fromUserId
          tripId
          taxiRequestId
          content
          fromUser {
            id
          }
          toUser {
            id
          }
          trip {
            id
          }
          taxiRequest {
            id
            name
          }
          createdAt
          updatedAt
        }
        total
      }
    }
  `,

  deleteOneNotification: gql`
  mutation deleteOneNotification($id: ID!) {
    deleteOneNotification(id: $id) {
      id
    }
  }
`,
};

export const notificationApi = {
  adminSendNoti: (payload) => {
    return mutate("adminSendNoti", NotificationAction.adminSendNoti, payload);
  },

  getAllNotification: (payload) => {
    return mutate("getAllNotification", NotificationAction.getAllNotification, payload);
  },

  deleteOneNotification: (payload) => {
    return mutate("deleteOneNotification", NotificationAction.deleteOneNotification, payload);
  },
};
