import { useEffect, useState } from "react";

export const MAX_LIMIT = 10000;

const usePagination = (dataTotal) => {
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [limit, setLimit] = useState(10);

    useEffect(() => {
        if (dataTotal || dataTotal === 0) {
            setTotal(dataTotal)
        }
    }, [dataTotal])

    return {
        page,
        limit,
        total,

        pagination: {
            page,
            total,
            limit: 10,
            onPageChange: (page) => setPage(page), 
        },

        setLimit,
        setPage,
        setTotal,
    }
}

export default usePagination;