import TooltipSystem from "components/e-control/tooltip-system";

import { formatNumberToAmountKeepEnableDecimal } from "utility/number-utils";
import { hiddenMiddleCharacter } from "utils";

const DetailHistory = ({ item }) => {
  return (
    <>
      <div className="text-center mt-4 mb-8">
        <h2 className="text-2xl font-bold text-black mb-2">
          {item.amount} {item?.typeCrypto}
        </h2>
        {/* <p className="text-gray-500 text-lg mb-4">
          &#8776;{" "}
          {formatNumberToAmountKeepEnableDecimal(item?.amount * 1000, 2)}
        </p> */}
      </div>

      <div className="space-y-6">
        <div className="rounded-md p-4 bg-blue-50/50 space-y-6 shadow-sm">
          <div className="flex justify-between">
            <span className="text-gray-600">Date</span>
            <div className="font-medium flex items-center gap-x-1">
              {item?.date}
            </div>
          </div>

          <div className="flex justify-between">
            <span className="text-gray-600">Status</span>

            <span className="font-medium">{item?.status}</span>
          </div>

          <div className="flex justify-between">
            <span className="text-gray-600">
              {item.type === "TRANSFER" ? "Received" : "From"}
            </span>

            <span className="font-medium">
              <TooltipSystem
                title={item.type === "TRANSFER" ? item?.to : item?.from}
              >
                {hiddenMiddleCharacter(
                  item.type === "TRANSFER" ? item?.to : item?.from ?? ""
                )}
              </TooltipSystem>
            </span>
          </div>
        </div>

        <div className="rounded-md p-4 bg-blue-50/50 space-y-6 shadow-sm">
          <div className="flex justify-between">
            <span className="text-gray-600">Network fee</span>

            <div className="text-right">
              <span className="font-medium">
                {formatNumberToAmountKeepEnableDecimal(
                  item?.feeBNB || 0.018,
                  0,
                  ""
                )}{" "}
                BNB
              </span>
              <p className="text-[#ccc]">
                &#8776;{" "}
                {formatNumberToAmountKeepEnableDecimal(item?.feeUSDT || 0.2, 2)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailHistory;
