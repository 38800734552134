import toast from "react-hot-toast";
import { Plus } from "lucide-react";
import { dispatch } from "root-stores";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useDebounce from "utility/hooks/useDebounce";
import usePagination from "utility/hooks/usePagination";

import TablePageContent, {
  Slot,
} from "components/containers/table-page-content";
import AccumulateProgramTable from "../../components/table";
import ButtonSystem from "components/e-control/button-system";
import PageWrapper from "components/containers/page/page-wrapper";
import AccumulateActionModal from "../../components/accumulate-actions";
import InputSystem from "components/e-control/forms/form-elements/input-system";

import { getAllSavingsPlan } from "modules/ego-system/accumulate/stores";
import { accumulateApi } from "modules/ego-system/accumulate/apis";

const ProgramAccumulate = () => {
  const [search, setSearch] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [dataEdit, setDataEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormActionShow, setIsFormActionShow] = useState(false);

  const { listAccumulate } = useSelector((state) => state.accumulate);

  const { total, records } = listAccumulate;

  const { page, limit, pagination, setPage } = usePagination(total);

  const closeModal = () => {
    setDataEdit(null);
    setIsEdit(false);
    setIsFormActionShow(false);
  };

  const loadListAccumulate = async () => {
    const params = {
      page,
      limit,
      search,
    };

    await dispatch(getAllSavingsPlan(params));
  };

  const handleEditCampaign = (campaign) => {
    setDataEdit(campaign);
    setIsEdit(true);
    setIsFormActionShow(true);
  };

  const submitForm = async (data) => {
    try {
      setIsLoading(true);

      const customData = {
        ...data,
        ratePYear: data?.ratePYear / 100,
        earlyWithdrawRate: data?.earlyWithdrawRate / 100,
      };

      if (isEdit) {
        await accumulateApi.updateSavingPlan({
          data: customData,
          id: dataEdit?.id,
        });

        toast.success("Cập nhật chương trình tích luỹ thành công");
      } else {
        await accumulateApi.createSavingsPlan({
          data: customData,
        });

        toast.success("Thêm chương trình tích luỹ thành công");
      }

      setPage(1);
      closeModal();
      loadListAccumulate();
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteCampaign = async (item) => {
    try {
      setIsLoading(true);

      await accumulateApi.deleteOneSavingsPlan({
        id: item?.id,
      });

      toast.success("Xoá chương trình tích luỹ thành công!");

      setPage(1);
      closeModal();
      loadListAccumulate();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useDebounce(loadListAccumulate, [search], 400);

  useEffect(() => {
    loadListAccumulate();
  }, [page]);

  return (
    <>
      <PageWrapper
        title="Chương trình tích luỹ"
        breadcrumbs={[{ title: "Chương trình tích luỹ" }]}
        actions={
          <ButtonSystem
            color="primary"
            className="flex items-center gap-x-2"
            onClick={() => setIsFormActionShow(true)}
          >
            <Plus className="size-4" />
            <span>Mới</span>
          </ButtonSystem>
        }
      >
        <TablePageContent>
          <Slot name="header">
            <div className="flex items-center justify-between">
              <span className="text-sm">Tổng: {total}</span>

              <InputSystem
                className="max-w-[200px] text-sm"
                value={search}
                placeholder="Tìm theo tên chương trình..."
                onChange={(event) => {
                  setSearch(event.target.value);
                }}
              />
            </div>
          </Slot>

          <AccumulateProgramTable
            data={records}
            pagination={pagination}
            onEdit={handleEditCampaign}
            onDelete={handleDeleteCampaign}
          />
        </TablePageContent>
      </PageWrapper>

      <AccumulateActionModal
        isEdit={isEdit}
        dataEdit={dataEdit}
        isLoading={isLoading}
        isOpen={isFormActionShow}
        submit={submitForm}
        onClose={closeModal}
      />
    </>
  );
};

export default ProgramAccumulate;
