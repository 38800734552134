import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { connectEgoPartnerApi } from "../apis";

export const getAllTripPriceForPartner = createAsyncThunk(
  "/car/getAllTripPriceForPartner",
  async (query) => {
    try {
      const response = await connectEgoPartnerApi.getAllTripPriceForPartner(
        query
      );

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getAllPriceChangeHistory = createAsyncThunk(
  "/car/getAllPriceChangeHistory",
  async (query) => {
    try {
      const response = await connectEgoPartnerApi.getAllPriceChangeHistory(
        query
      );

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

// export const createPartnerCar = async (query) => {
//   try {
//     const payload = {
//       data: {
//         carCode: query?.carCode,
//         licensePlateNumber: query?.licensePlate,
//         carTypeId: query?.carType,
//         images: query?.images,
//         workPlaceId: query?.workPlaceId,
//       },
//     };

//     await carPartnerApi.createPartnerCar(payload);
//   } catch (error) {
//     throw new Error(error);
//   }
// };

export const carSlice = createSlice({
  name: "configPricePartner",
  initialState: {
    configPriceTaxi: {
      list: [],
      total: 0,
    },

    priceChangeHistory: {
      list: [],
      total: 0,
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      getAllTripPriceForPartner.fulfilled,
      (state, { payload }) => {
        state.configPriceTaxi.list = payload?.data || [];
        state.configPriceTaxi.total = payload?.total || 0;
      }
    );

    builder.addCase(
      getAllPriceChangeHistory.fulfilled,
      (state, { payload }) => {
        state.priceChangeHistory.list = payload?.data || [];
        state.priceChangeHistory.total = payload?.total || 0;
      }
    );
  },
});

export default carSlice.reducer;
