import { gql } from "@apollo/client";
import { mutate, query } from "configs/http-config";

const staffAction = {
  getAllManager: gql`
    query getAllManager(
      $page: Int
      $limit: Int
      $search: String
      $filter: Mixed
      
    ) {
      getAllManager(
        q: {
          limit: $limit
          page: $page
          search: $search
          filter: $filter
          order: { createdAt: -1 }
        }
        
      ) {
        data {
          id
          name
          username
          phone
          email
          password
          isBlock
          key
          role
          identityCard
          avatar
          address
          signInProvider
          isActived
          createdAt
          updatedAt

          workPlace {
            id
            name
          }
          workPlaceId
          location

          car {
            id
            carCode
            images
            driverId
            status
            createdAt
            updatedAt
          }
        }
        total
        getStatManager
      }
    }
  `,

  getOneUser: gql`
    query getOneUser($id: ID!) {
      getOneUser(id: $id) {
        id
        name
        username
        phone
        email
        password
        isBlock
        key
        role
        identityCard
        avatar
        address
        signInProvider
        isActived
        createdAt
        updatedAt
        location

        workPlace {
          id
          landMarkIds
          landMarks {
            address
            id
            image
            location
            name
            description
          }
        }
        workPlaceId

        car {
          id
          carCode
          images
          driverId
          status
          category
          color
          carCompany
          tranportationType
          transmisstionType
          carType {
            category
            id
            name
          }
          fuelType
          licensePlateNumber
          manufacturingYear
          mortgage
          createdAt
          updatedAt
        }

        carType {
          id
          businessTypes
          category
          name
          type
        }
      }
    }
  `,

  createManagerForAdmin: gql`
    mutation createManagerForAdmin(
      $name: String!
      $email: String
      $phoneNumber: String!
      $address: String
      $avatar: String
      $workPlaceId: String
      $role: String!
      $password: String!
    ) {
      createManagerForAdmin(
        name: $name
        email: $email
        phoneNumber: $phoneNumber
        address: $address
        avatar: $avatar
        workPlaceId: $workPlaceId
        role: $role
        password: $password
      ) {
        id
      }
    }
  `,

  deleteOneUser: gql`
    mutation deleteOneUser($id: ID!) {
      deleteOneUser(id: $id) {
        id
        phone
      }
    }
  `,

  updateUser: gql`
    mutation updateUser($id: ID!, $data: UpdateUserInput!) {
      updateUser(id: $id, data: $data) {
        id
        phone
      }
    }
  `,

  getAllWorkPlaceWOPaging: gql`
    query getAllWorkPlaceWOPaging {
      getAllWorkPlaceWOPaging {
        id
        name
        location
        landMarkIds
        landMarks {
          id
          name
        }
      }
    }
  `,
};

export const staffApi = {
  getAllManager: (payload) => {
    return query("getAllManager", staffAction.getAllManager, payload);
  },

  getOneUser: (payload) => {
    return mutate("getOneUser", staffAction.getOneUser, payload);
  },

  createManagerForAdmin: (payload) => {
    return mutate(
      "createManagerForAdmin",
      staffAction.createManagerForAdmin,
      payload
    );
  },

  deleteOneUser: (payload) => {
    return mutate("deleteOneUser", staffAction.deleteOneUser, payload);
  },

  updateUser: (payload) => {
    return mutate("updateUser", staffAction.updateUser, payload);
  },

  getAllWorkPlaceWOPaging: () => {
    return query(
      "getAllWorkPlaceWOPaging",
      staffAction.getAllWorkPlaceWOPaging
    );
  },
};
