import classNames from "classnames";
import InputNumber from "rc-input-number";
import {
  formatAmountToNumber,
  formatNumberToAmount,
} from "utility/number-utils";

import { useEffect, useRef } from "react";
import withKeyboardDetectFocusInput from "utility/hocs/with-keyboard-detect";
import "./input-system.scss";

const InputNumberSystem = ({
  className,
  focusIn,
  focusOut,
  disable = false,
  value,
  ...props
}) => {
  const ref = useRef();

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    ref.current.addEventListener("focus", focusIn);
    ref.current.addEventListener("blur", focusOut);

    return () => {
      if (ref.current) {
        ref.current.removeEventListener("focus", focusIn);
        ref.current.removeEventListener("focus", focusOut);
      }
    };
  }, []);

  const handleOnWheel = (e) => {
    e.preventDefault();
    ref.current.blur();
  };

  return (
    <InputNumber
      ref={ref}
      value={value}
      parser={formatAmountToNumber}
      formatter={formatNumberToAmount}
      className={classNames([className, "input-number-system"])}
      disabled={disable}
      onWheel={handleOnWheel}
      wheel={false}
      {...props}
    />
  );
};

export default withKeyboardDetectFocusInput(InputNumberSystem);
