import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import cloneDeep from "clone-deep";
import { clientPartnerApi } from "modules/partner/clients/apis";
import toast from "react-hot-toast";
import { bookingRentPartnerApi } from "../apis";

export const getRentCarBookings = createAsyncThunk(
  "/partner/getRentCarBookings",
  async (query) => {
    const params = {
      page: 1,
      limit: 1000,
      order: {
        createdAt: -1,
      },
      filter: query.filter,
      fromDate: query?.fromDate,
      toDate: query?.toDate,
      partnerId: query?.partnerId,
    };

    const response = await bookingRentPartnerApi.getAllRentCarTripForPartner(
      params
    );

    return response;
  }
);

export const getAllPartnerClient = createAsyncThunk(
  "/staff/getAllPartnerClient",
  async (query) => {
    try {
      const response = await clientPartnerApi.getAllPartnerClient(query);
      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getOnePartnerTrip = createAsyncThunk(
  "/staff/getOnePartnerTrip",
  async (query) => {
    try {
      const response = await bookingRentPartnerApi.getOneBooking(query);
      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const CarRentalSlice = createSlice({
  name: "car-rental",
  initialState: {
    bookings: [],
    stats: {},
    bookingIds: {},
    bookingTotals: 0,
    bookingAction: null,
    bookingByDate: {},
    dropCarId: null,

    client: {
      list: [],
      total: 0,
    },
  },

  reducers: {
    setBookings: (state, { payload }) => {
      state.bookings = payload;
    },

    setStats: (state, { payload }) => {
      state.stats = payload;
    },

    setBookingAction: (state, { payload = null }) => {
      state.bookingAction = payload;
    },

    setDropCarId: (state, { payload = null }) => {
      state.dropCarId = payload;
    },

    updateBookings: (state, { payload }) => {
      const cloneBookings = cloneDeep(state.bookings);
      const updatedBookingIndex = state.bookingIds[payload.id].index;
      const updatedBooking = {
        ...state.bookingIds[payload.id].booking,
        ...payload,
      };
      cloneBookings.splice(updatedBookingIndex, 1, updatedBooking);
      state.bookings = cloneBookings;
      state.bookingIds = cloneBookings.map((booking, index) => ({
        [booking.id]: { index, booking: { ...booking } },
      }));
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getRentCarBookings.fulfilled, (state, { payload }) => {
      state.bookings = payload?.data || [];
      state.bookingTotals = payload?.total || 0;
      state.bookingIds = payload?.data?.reduce(
        (bookingIds, booking, index) => ({
          ...bookingIds,
          [booking.id]: { index, booking: { ...booking } },
        }),
        {}
      );
    });

    builder.addCase(getAllPartnerClient.fulfilled, (state, { payload }) => {
      state.client.list = payload?.data || [];
      state.client.total = payload?.total || 0;
    });

    builder.addCase(getOnePartnerTrip.fulfilled, (state, { payload }) => {
      state.bookingAction = payload || {};
    });
  },
});

export const {
  setBookings,
  setStats,
  setDropCarId,
  updateBookings,
  setBookingAction,
} = CarRentalSlice.actions;

export default CarRentalSlice.reducer;
