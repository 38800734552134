import { gql } from "@apollo/client";

export const ModifierGroupFragment = gql`
    fragment ModifierGroup on ShopOptionGroup {
        id
        name
        min
        max
    }
`

export const ModifierFragment = gql`
    ${ModifierGroupFragment}
    fragment Modifier on ShopOption {
        id
        name
        image
        price
        optionGroupIds
        shopProductIds
        status
        isCountable
        optionGroups {
            ...ModifierGroup
        }

    }
`

export const modifierActions = {
    loadModifier: gql`
        ${ModifierFragment}
        query GetAllShopOption($q: QueryGetListInput) {
            getAllShopOption(q: $q) {
                data {
                    ...Modifier
                    createdAt
                    updatedAt
                }
                total
            }
        }
    `,

    createModifier: gql`
        mutation CreateShopOption($data: CreateShopOptionInput!) {
            createShopOption(data: $data) {
                id
            }
        }
    `,

    updateModifier: gql`
        mutation UpdateShopOption($updateShopOptionId: ID!, $data: UpdateShopOptionInput!) {
            updateShopOption(id: $updateShopOptionId, data: $data) {
                id
            }
        }
    `,

    deleteModifier: gql`
        mutation DeleteOneShopOption($deleteOneShopOptionId: ID!) {
            deleteOneShopOption(id: $deleteOneShopOptionId) {
                id
            }
        }
    `,
}

export const modifierGroupActions = {
    loadModifierGroup: gql`
        ${ModifierGroupFragment}
        query GetAllShopOptionGroup($q: QueryGetListInput) {
            getAllShopOptionGroup(q: $q) {
                data {
                    ...ModifierGroup
                    createdAt
                    updatedAt
                }
                total
            }
        }
    `,

    createModifierGroup: gql`
        mutation CreateShopOptionGroup($data: CreateShopOptionGroupInput!) {
            createShopOptionGroup(data: $data) {
                id
            }
        }
    `,

    updateModifierGroup: gql`
        mutation UpdateShopOptionGroup($updateShopOptionGroupId: ID!, $data: UpdateShopOptionGroupInput!) {
            updateShopOptionGroup(id: $updateShopOptionGroupId, data: $data) {
                id
            }
        }
    `,

    deleteModifierGroup: gql`
        mutation DeleteOneShopOptionGroup($deleteOneShopOptionGroupId: ID!) {
            deleteOneShopOptionGroup(id: $deleteOneShopOptionGroupId) {
                id
            }
        }
    `,
}