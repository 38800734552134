import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { deliveryApis } from '../apis';

export const getDeliveryBooking = createAsyncThunk("/booking/delivery", async (params) => {
    try {
        const response = await deliveryApis.getBooking(params);

        return response;
    } catch (error) {
        throw new Error(error);
    }
});

const deliverySlice = createSlice({
    name: "delivery",
    initialState: {
        bookings: [],
        total: [],
    },

    reducers: {},

    extraReducers: (builder) => {
        builder.addCase(getDeliveryBooking.fulfilled, (state, { payload }) => {
            state.total = payload?.total || 0;
            state.bookings = payload?.data || [];
        });
    }
});

export default deliverySlice.reducer;