import { gql } from "@apollo/client";
import { mutate, query } from "configs/http-config";

const wareHouseAction = {
  getAllShopWarehouseLocation: gql`
    query getAllShopWarehouseLocation($q: QueryGetListInput) {
      getAllShopWarehouseLocation(q: $q) {
        data {
          address
          code
          createdAt
          id
          location
          name
          workPlace {
            name
            id
          }
        }
        total
      }
    }
  `,

  createShopWarehouseLocation: gql`
    mutation createShopWarehouseLocation(
      $data: CreateShopWarehouseLocationInput!
    ) {
      createShopWarehouseLocation(data: $data) {
        id
      }
    }
  `,

  deleteOneShopWarehouseLocation: gql`
    mutation deleteOneShopWarehouseLocation($id: ID!) {
      deleteOneShopWarehouseLocation(id: $id) {
        id
      }
    }
  `,

  updateShopWarehouseLocation: gql`
    mutation updateShopWarehouseLocation(
      $id: ID!
      $data: UpdateShopWarehouseLocationInput!
    ) {
      updateShopWarehouseLocation(id: $id, data: $data) {
        id
      }
    }
  `,
};

export const wareHouseApi = {
  getAllShopWarehouseLocation: (payload) => {
    return query(
      "getAllShopWarehouseLocation",
      wareHouseAction.getAllShopWarehouseLocation,
      payload
    );
  },

  createShopWarehouseLocation: (payload) => {
    return mutate(
      "createShopWarehouseLocation",
      wareHouseAction.createShopWarehouseLocation,
      payload
    );
  },

  deleteOneShopWarehouseLocation: (payload) => {
    return mutate(
      "deleteOneShopWarehouseLocation",
      wareHouseAction.deleteOneShopWarehouseLocation,
      payload
    );
  },

  updateShopWarehouseLocation: (payload) => {
    return mutate(
      "updateShopWarehouseLocation",
      wareHouseAction.updateShopWarehouseLocation,
      payload
    );
  },
};
