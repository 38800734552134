import { createAsyncThunk } from "@reduxjs/toolkit"
import { modifierApis } from "modules/shop/modifiers/api"

// products
export const getAllModifierGroup = createAsyncThunk(
    "/product/getAllModifierGroup",
    async (query) => {
        try {
            const response = await modifierApis.getAllModifierGroup(query)
            return response
        } catch (error) {
            throw new Error(error)
        }
    })

export const getAllModifier = createAsyncThunk(
    "/product/getAllModifier",
    async (query) => {
        try {
            const response = await modifierApis.getAllModifier(query)
            return response
        } catch (error) {
            throw new Error(error)
        }
    })

export const modifier = {
    modifierGroup: {
        records: [],
        total: 0,
        modifierGroupAction: null,
    },

    modifiers: {
        records: [],
        total: 0,
        modifierAction: null,
    }
}

export const modifierBuilder = (builder) => {
    builder.addCase(getAllModifierGroup.fulfilled, (state, { payload }) => {
        state.modifier.modifierGroup.records = payload?.data || [];
        state.modifier.modifierGroup.total = payload?.total || 0;
    });

    builder.addCase(getAllModifier.fulfilled, (state, { payload }) => {
        state.modifier.modifiers.records = payload?.data || [];
        state.modifier.modifiers.total = payload?.total || 0;
    });
}