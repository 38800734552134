import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CONSTANTS } from "constants";
import { workPlacePartnerApi } from "modules/transportation-system/modules/settings/components/work-place/apis";
import { toast } from "react-hot-toast";
import localUtils from "utility/local-utils";
import { LocationApi } from "../apis";

export const getCountry = createAsyncThunk("/location/getCountry", async () => {
  try {
    const response = await LocationApi.getCountry();

    return response;
  } catch (error) {
    toast.error(error?.message);
    throw new Error(error);
  }
});

export const getListProvince = createAsyncThunk(
  "/location/getProvince",
  async (query) => {
    try {
      const response = await LocationApi.getProvince(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getListDistrict = createAsyncThunk(
  "/workPlace/getDistrict",
  async (query) => {
    try {
      const response = await workPlacePartnerApi.getDistrict(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getAllWorkPlace = createAsyncThunk(
  "/location/getAllWorkPlace",
  async (query) => {
    try {
      const response = await LocationApi.getAllWorkPlace(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getAllDistrict = createAsyncThunk(
  "/location/getAllDistrict",
  async (query) => {
    try {
      const response = await LocationApi.getAllDistrict({ workPlaceId: query });

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getAllLandMark = createAsyncThunk(
  "/location/getAllLandMark",
  async (query) => {
    try {
      const response = await LocationApi.getAllLandMark(query);
      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getAllTag = createAsyncThunk(
  "/location/getAllTag",
  async (query) => {
    try {
      const response = await LocationApi.getAllTag(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getAllCarFeatureWOPaging = createAsyncThunk(
  "/location/getAllCarFeatureWOPaging",
  async () => {
    try {
      const response = await LocationApi.getAllCarFeatureWOPaging();

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getAllWorkPlaceWOPaging = createAsyncThunk(
  "/location/getAllWorkPlaceWOPaging",
  async () => {
    try {
      const response = await LocationApi.getAllWorkPlaceWOPaging();

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getAllTagWOPaging = createAsyncThunk(
  "/location/getAllTagWOPaging",
  async (query) => {
    try {
      const response = await LocationApi.getAllTagWOPaging(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getOneLandMark = createAsyncThunk(
  "/driver/getOneLandMark",
  async (query) => {
    try {
      const response = await LocationApi.getOneLandMark(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getOneWorkPlace = createAsyncThunk(
  "/driver/getOneWorkPlace",
  async (query) => {
    try {
      const response = await LocationApi.getOneWorkPlace(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getSelectedWorkplace = () => {
  try {
    const workplaceId = JSON.parse(localUtils.get(CONSTANTS.LOCAL_STORAGE.WORKPLACE_ID));

    return workplaceId || null;
  } catch (error) {
    return null;
  }
}

export const locationSlice = createSlice({
  name: "location",
  initialState: {
    countries: [],

    province: {
      list: [],
    },

    allworkplaces: [],
    selectedWorkplace: getSelectedWorkplace(),

    workplaces: {
      list: [],
      total: 0,
    },

    landMark: {
      list: [],
      total: 0,
    },

    tags: {
      list: [],
      total: 0,
    },

    workplaceSelect: [],

    tagSelect: [],

    locationDetails: {},

    getStatWorkPlace: {},

    workplaceDetail: {},

    carFeature: [],

    district: [],
  },

  reducers: {
    selectWorkplace: (state, { payload }) => {
      state.selectedWorkplace = payload
      localUtils.set(
        CONSTANTS.LOCAL_STORAGE.WORKPLACE_ID,
        JSON.stringify(payload)
      );
    }
  },

  extraReducers: (builder) => {
    builder.addCase(getListProvince.fulfilled, (state, { payload }) => {
      state.province.list = payload || [];
    });

    builder.addCase(getListDistrict.fulfilled, (state, { payload }) => {
      state.district = payload || [];
    });

    builder.addCase(getAllWorkPlace.fulfilled, (state, { payload }) => {
      state.workplaces.list = payload?.data || [];
      state.workplaces.total = payload?.total || 0;
    });

    builder.addCase(getAllDistrict.fulfilled, (state, { payload }) => {
      state.district = payload || [];
    });

    builder.addCase(getAllLandMark.fulfilled, (state, { payload }) => {
      state.landMark.list = payload?.data || [];
      state.landMark.total = payload?.total || 0;
    });

    builder.addCase(getAllTag.fulfilled, (state, { payload }) => {
      state.tags.list = payload?.data || [];
      state.tags.total = payload?.total || 0;
    });

    builder.addCase(
      getAllCarFeatureWOPaging.fulfilled,
      (state, { payload }) => {
        state.carFeature = payload || [];
      }
    );

    builder.addCase(getAllWorkPlaceWOPaging.fulfilled, (state, { payload }) => {
      state.workplaceSelect = payload || [];
      state.allworkplaces = payload || [];
    });

    builder.addCase(getAllTagWOPaging.fulfilled, (state, { payload }) => {
      state.tagSelect = payload || [];
    });

    builder.addCase(getOneLandMark.fulfilled, (state, { payload }) => {
      state.locationDetails = payload || {};
    });

    builder.addCase(getCountry.fulfilled, (state, { payload }) => {
      state.countries = payload.data || {};
    });

    builder.addCase(getOneWorkPlace.fulfilled, (state, { payload }) => {
      state.getStatWorkPlace = payload || {};
      state.workplaceDetail = payload || {};
    });
  },
});

export const { selectWorkplace } = locationSlice.actions;

export default locationSlice.reducer;
