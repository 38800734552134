import classNames from "classnames";
import { useSelector } from "react-redux";
import { formatNumberToAmountKeepEnableDecimal } from "utility/number-utils";

const CryptoList = ({ onSelect }) => {
  const { cryptos } = useSelector((state) => state.web3);

  return (
    <div className="flex flex-col gap-5 mt-8">
      {cryptos?.map((item, index) => (
        <div
          key={index}
          className="flex justify-between cursor-pointer"
          onClick={() => onSelect?.(item)}
        >
          <div className="flex items-center gap-x-2">
            <img
              src={item?.logo}
              className="w-10 h-10 object-cover"
              alt={item?.symbol}
            />

            <div className="flex flex-col gap-1">
              <h4 className="text-[#000] font-bold flex items-center gap-x-1">
                <span>{item?.symbol}</span>

                <span className="p-1 bg-slate-200 rounded-md text-xs text-[#313131]">
                  {item?.network}
                </span>
              </h4>
              <p className="font-medium text-[#868585] flex items-center gap-x-2">
                <span>
                  {formatNumberToAmountKeepEnableDecimal(item?.price, 2)}
                </span>

                <span
                  className={classNames(" font-semibold", {
                    "text-primary": item?.exchangeRate > 0,
                    "text-red-error": item?.exchangeRate < 0,
                  })}
                >
                  {`${formatNumberToAmountKeepEnableDecimal(
                    item?.exchangeRate,
                    2
                  )}%`}
                </span>
              </p>
            </div>
          </div>

          <div className="flex flex-col items-end gap-1">
            <span className="text-[#000] font-bold">
              {formatNumberToAmountKeepEnableDecimal(item?.myBalance, 2, "")}
            </span>
            <span className="font-medium text-[#868585]">
              {formatNumberToAmountKeepEnableDecimal(
                item?.myBalance * item?.price,
                2
              )}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CryptoList;
