import classNames from "classnames";
import { Input } from "reactstrap";
import withKeyboardDetectFocusInput from "utility/hocs/with-keyboard-detect";

const InputSystem = ({ focusIn, focusOut, className, ...props }) => {
  return (
    <Input
      {...props}
      className={classNames("text-sm", className)}
      onFocus={(event) => {
        focusIn?.(event);
        props.onFocus?.(event);
      }}
      onBlur={(event) => {
        focusOut?.(event);
        props.onBlur?.(event);
      }}
    />
  );
};

export default withKeyboardDetectFocusInput(InputSystem);
