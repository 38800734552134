import { ArrowLeft } from "lucide-react";
import Popup from "modules/invest/client/components/popup";
import { selectCrypto, setFormData } from "modules/wallet-web3/stores";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { dispatch } from "root-stores";
import CryptoList from "../crypto-list";
import SendForm from "./form";
import SendTransferScreen from "./transfer";

const SendScreen = (props) => {

  const [step, setStep] = useState(0);
  const [coin, setCoin] = useState(null);

  const { selectedCoin } = useSelector(state => state.web3);

  const SendCoinHeader = (
    <div className="flex items-center gap-x-12">
      <div className=""
        onClick={() => {
          setStep(prev => prev - 1);
        }}
      >
        <ArrowLeft className="size-4" />
      </div>
      <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">{`Send ${selectedCoin?.symbol}`}</span>
    </div>
  )

  const TransferStep = (
    <div className="flex items-center gap-x-12">
      <div className=""
        onClick={() => {
          setStep(prev => prev - 1);
        }}
      >
        <ArrowLeft className="size-4" />
      </div>
      <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">{`Transfer`}</span>
    </div>
  )

  const defaultHeader = <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">{`Send`}</span>

  const title = useMemo(() => {
    switch (step) {
      case 1: return SendCoinHeader;
      case 2: return TransferStep;

      default: return defaultHeader
    }
  }, [step, coin])

  const handleInputValue = (formData) => {
    dispatch(setFormData(formData))
    setStep(prev => prev + 1)
  }

  return (

    <Popup
      header={title}
      {...props}
    >
      <div className="h-[75vh]">
        {step === 0 && (

          <CryptoList onSelect={(coin) => {
            dispatch(selectCrypto(coin))
            setStep((prev) => prev + 1)
          }} />
        )}

        {step === 1 && (
          <SendForm onSubmit={handleInputValue} />
        )}

        {step === 2 && (
          <SendTransferScreen closePopup={props.onClose} />
        )}
      </div>
    </Popup>
  )
};

export default SendScreen;
