import React from "react";
import { useNavigate } from "react-router-dom";

import { INVEST_ROUTES } from "router/invest-routes";
import { formatRoundNumber } from "utility/number-utils";

import TooltipSystem from "components/e-control/tooltip-system";

const CardItemAccumulate = ({ item }) => {
  const navigate = useNavigate();

  const handleDetail = () => {
    navigate(`${INVEST_ROUTES.ACCUMULATE_DETAIL}?id=${item?.id}`);
  };

  return (
    <div
      onClick={handleDetail}
      className="p-4 rounded-xl bg-white shadow-sm cursor-pointer"
    >
      <div className="flex items-center gap-2 justify-between">
        <div className="flex items-center gap-x-3">
          <img
            className="w-8 h-8 object-cover rounded-lg"
            alt="accumulate"
            src={item?.image}
          />

          <p className="text-sm text-black font-bold line-clamp-1">
            <TooltipSystem title={item?.name || "Đang cập nhật"}>
              {item?.name || "Đang cập nhật"}
            </TooltipSystem>
          </p>
        </div>
        <div className="text-sm text-primary font-medium">
          {item?.ratePYear * 100}%/năm
        </div>
      </div>

      {/* <div className="w-full flex items-stretch rounded-full overflow-hidden h-2 border border-primary/30 mt-3">
        <div
          style={{
            width: (30 / 100) * 100 + "%",
          }}
          className={"bg-primary"}
        />
        <div className={"bg-white flex-[2]"} />
      </div> */}

      <div className="mt-5">
        <div className="flex items-center gap-x-1">
          <span className="bg-primary w-3 h-3 rounded-full" />

          <p>Phù hợp với nhu cầu tích luỹ ngắn hạn</p>
        </div>

        <div className="flex items-center gap-x-1 mt-1">
          <span className="bg-primary w-3 h-3 rounded-full" />

          <p>
            Lãi xuất thực nhận là {formatRoundNumber(item?.ratePYear * 95, 2)}
            %/năm (sau thuế)
          </p>
        </div>
      </div>

      <div className="mt-4 flex items-center gap-x-2">
        <span className="px-2 py-1 rounded-md border border-primary text-sm text-primary">
          Có kỳ hạn
        </span>

        <span className="px-2 py-1 rounded-md border border-primary text-sm text-primary">
          {item?.savingsTerm} tháng
        </span>
      </div>
    </div>
  );
};

export default CardItemAccumulate;
