import classNames from "classnames";
import "./index.scss";
import { formatRoundNumber } from "utility/number-utils.js";
import { formatTimeToLocalTime } from "utility/time-utils";
import { DATE_TIME_FORMAT } from "constants";
import MAP_OPTIONS from "modules/ego-system/gg-map/constants";
import { Button } from "reactstrap";
import ButtonSystem from "components/e-control/button-system";
import { formatPhoneNumberToShow } from "utils";
import { useConfirmContext } from "components/containers/confirm-container";

const BookingItem = ({ booking, className, cancelBooking, onBookingClick }) => {
  const { confirmDialog } = useConfirmContext();

  const handleBookingClick = () => {
    onBookingClick?.();
  };

  const handleCancelButtonClick = async (event) => {
    event.stopPropagation();
    const result = await confirmDialog({
      messages: ["Bạn có muốn huỷ booking này?"],
      options: {},
    });

    console.log("result :>>", result);
    if (!result) {
      return;
    }

    cancelBooking?.();
  };

  return (
    <div
      className={classNames([className, "hotline-booking-item__card"])}
      onClick={handleBookingClick}
    >
      <div className="hotline-booking-item__content">
        <p className="hotline-booking-item__content-client">
          <span>{booking?.user?.name}</span>
          <span>{formatPhoneNumberToShow(booking?.user?.phone)}</span>
        </p>

        <p className="hotline-booking-item__content-booking-date">
          <span>{formatTimeToLocalTime(booking?.createdAt)}</span>
          <span>
            {formatTimeToLocalTime(booking?.createdAt, DATE_TIME_FORMAT.Hm)}
          </span>
        </p>

        <p className="hotline-booking-item__content-address">
          <span>Từd:</span>
          <span>{booking?.startAddress}</span>
        </p>

        <p className="hotline-booking-item__content-address">
          <span>Đến:</span>
          <span>{booking?.endAddress}</span>
        </p>

        <p className="hotline-booking-item__content-address">
          <span>Ghi chú:</span>
          <span>{booking?.note}</span>
        </p>
      </div>

      <div className="hotline-booking-item__footer">
        <p className="hotline-booking-item__content-booking-code">
          Mã: {booking?.code}
        </p>

        <p className="hotline-booking-item__content-booking-info">
          <span>{formatRoundNumber(booking?.distance, 1)} km</span>
          <span>{formatRoundNumber(booking?.finalCost)} VNĐ</span>
        </p>

        <ButtonSystem color="danger" onClick={handleCancelButtonClick}>
          Huỷ
        </ButtonSystem>
      </div>
    </div>
  );
};

export default BookingItem;
