// ** React Import
import { useCallback, useEffect, useMemo } from "react";

// ** Third Party Components
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

// ** Hooks
import { useAuth } from "components/containers/auth";
import useSocket from "modules/ego-system/maps/hooks/useSocket";

// ** Apis
import { mapActions } from "modules/ego-system/maps/apis";

// ** Constants
import { ROLE } from "constants";
import MAP_OPTIONS from "modules/ego-system/gg-map/constants";

// ** Store & actions
import toastNotice from "components/utils/toast-notice";
import { useNewExpressBooking } from "modules/ego-system/gg-map/components/driver-map/hooks/use-express-hook";
import {
  getAllDriverByWorkplace,
  setNewBooking
} from "modules/ego-system/gg-map/stores";
import { getAllNotifications } from "modules/ego-system/notice/store";
import { dispatch } from "root-stores";
import { MAP_ROUTES } from "router/ego-admin-routes/maps";
import { formatNumberToAmount } from "utility/number-utils";

const BookingNoticeUtils = () => {
  // ** Route
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  // ** Auth Hook
  const { user } = useAuth();

  // ** Store
  const { workplaceIds } = useSelector(
    (state) => state.map
  );

  // ** Memo
  const workplaceIdsByRoles = useMemo(() => {
    if (user.role === ROLE.ADMIN) {
      return workplaceIds;
    }

    if (user.role === ROLE.BOOKING_MANAGER) {
      return [user.workPlaceId];
    }

    return [];
  }, [user, workplaceIds]);

  const isMapPage = useMemo(() => {
    return pathname === MAP_ROUTES.MAIN;
  }, [pathname]);

  // ** New booking subscription
  const newBooking = useSocket(
    mapActions.subBooking,
    { workplaceIds: [...workplaceIdsByRoles] },
    "newBookingTrip"
  );

  useNewExpressBooking(workplaceIdsByRoles);

  /**
   * @description Click to booking notice
   */
  const handleConfirmClick = useCallback(() => {
      navigate(MAP_ROUTES.MAIN);
  }, []);

  useEffect(() => {
    if (newBooking) {
      toastNotice({
        title: 'Đơn đặt xe mới',
        description: `${newBooking.distance}km - ${formatNumberToAmount(newBooking.finalCost)}`,
        callback: handleConfirmClick,
        options: {
          duration: 4000,
        }
      })
      dispatch(getAllNotifications({ page: 1, limit: 10, onPage: false }));
      dispatch(setNewBooking({ ...newBooking, type: MAP_OPTIONS.BOOKING_TYPE.TAXI }));
    }
  }, [newBooking]);

  useEffect(() => {
    if (user.role === ROLE.ADMIN || user.role === ROLE.BOOKING_MANAGER) {
      dispatch(getAllDriverByWorkplace());
    }
  }, [user]);
};

export default BookingNoticeUtils;
