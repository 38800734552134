import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userApi } from "../apis";
import { toast } from "react-hot-toast";

export const getAllUser = createAsyncThunk(
  "/user/getAllUser",
  async (query) => {
    try {
      const response = await userApi.getAllUser(query);
      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getListWorkPlace = createAsyncThunk(
  "/user/getOneUser",
  async (query) => {
    try {
      const response = await userApi.getOneUser(query);
      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getAllUserNoPagination = createAsyncThunk(
  "/user/getAllUserNoPagination",
  async () => {
    try {
      const response = await userApi.getAllUser({
        limit: 1000,
      });
      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getUserDetail = createAsyncThunk(
  "/user/getOneUsers",
  async (query) => {
    try {
      const response = await userApi.getOneUser(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getAllBooking = createAsyncThunk(
  "/user/getAllBooking",
  async (query) => {
    try {
      const response = await userApi.getAllBooking(query);
      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const createNewUser = async (query) => {
  try {
    const payload = {
      name: query?.name,
      email: query?.email,
      phoneNumber: query?.phoneNumber?.replace(query?.phoneNumber[0], "+84"),
      identityCard: query?.identityCard,
      address: query?.address,
      avatar: query?.avatar,
    };

    await userApi.createUser(payload);
  } catch (error) {
    throw new Error(error);
  }
};

// export const getStringeeToken = createAsyncThunk(
//   "/user/getStringeeToken",
//   async (query) => {
//     try {
//       const response = await userApi.getStringeeToken(query);

//       return response;
//     } catch (error) {
//       toast.error(error?.message);
//       throw new Error(error);
//     }
//   }
// );

export const userSlice = createSlice({
  name: "user",
  initialState: {
    users: {
      list: [],
      total: 0,
      getStatManager: {},
      totalAllUser: 0,
      totalActiveAccount: 0,
      totalBlockedAccount: 0,
    },

    userDetail: {},

    bookings: {
      list: [],
      total: 0,
      getStatBookingOfClient: [],
    },

    workPlacePartner: [],
    carTypeIdsPartner: [],
    carTypesPartner: [],

    // tokenStringee: "",
  },

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getAllUser.fulfilled, (state, { payload }) => {
      state.users.list = payload?.data || [];
      state.users.total = payload?.total || 0;
      state.users.getStatManager = payload?.getStatManager || {};
      // state.users.totalAllUser = payload?.getStatUser[0]?.totalAllUser || 0;
      // state.users.totalActiveAccount =
      //   payload?.getStatUser[0]?.totalActiveAccount || 0;
      // state.users.totalBlockedAccount =
      //   payload?.getStatUser[0]?.totalBlockedAccount || 0;
    });

    builder.addCase(getAllBooking.fulfilled, (state, { payload }) => {
      state.bookings.list = payload?.data || [];
      state.bookings.total = payload?.total || 0;
      state.bookings.getStatBookingOfClient =
        payload?.getStatBookingOfClient[0] || 0;
    });

    builder.addCase(getAllUserNoPagination.fulfilled, (state, { payload }) => {
      state.users.list = payload?.data || [];
      state.users.total = payload?.total || 0;
    });

    builder.addCase(getUserDetail.fulfilled, (state, { payload }) => {
      state.userDetail = payload || {};
    });

    builder.addCase(getListWorkPlace.fulfilled, (state, { payload }) => {
      state.workPlacePartner = payload?.workPlaces || [];
      state.carTypeIdsPartner = payload?.carTypeIds || [];
      state.carTypesPartner = payload?.carTypes || [];
    });

    // builder.addCase(getStringeeToken.fulfilled, (state, { payload }) => {
    //   state.tokenStringee = payload || {};
    // });
  },
});

export default userSlice.reducer;
