import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { accumulateApi } from "../apis";
import toastNotice from "components/utils/toast-notice";

export const getAllSavingsPlan = createAsyncThunk(
  "/invest/getAllSavingsPlan",
  async (params) => {
    try {
      const response = await accumulateApi.getAllSavingsPlan(params);

      return response;
    } catch (error) {
      toastNotice({
        title: "Lỗi",
        description: error?.message,
      });
    }
  }
);

export const getAllSavingsPackage = createAsyncThunk(
  "/invest/getAllSavingsPackage",
  async (params) => {
    try {
      const response = await accumulateApi.getAllSavingsPackage(params);

      return response;
    } catch (error) {
      toastNotice({
        title: "Lỗi",
        description: error?.message,
      });
    }
  }
);

export const getAllInvestOrderApp = createAsyncThunk(
  "/invest/getAllInvestOrderApp",
  async (params) => {
    try {
      const response = await accumulateApi.getAllSavingsPackage(params);

      return { response, page: params?.page };
    } catch (error) {
      toastNotice({
        title: "Lỗi",
        descriptions: error.message,
      });
    }
  }
);

export const getAllEgoTransaction = createAsyncThunk(
  "/invest/getAllEgoTransaction",
  async (params) => {
    try {
      const response = await accumulateApi.getAllEgoTransaction(params);

      return { response, page: params?.page };
    } catch (error) {
      toastNotice({
        title: "Lỗi",
        descriptions: error.message,
      });
    }
  }
);

export const getOneSavingsPlan = createAsyncThunk(
  "/invest/getOneSavingsPlan",
  async (id) => {
    try {
      const response = await accumulateApi.getOneSavingsPlan(id);

      return response;
    } catch (error) {
      toastNotice({
        title: "Lỗi",
        descriptions: error?.message,
      });
    }
  }
);

export const accumulateSlice = createSlice({
  name: "accumulate",
  initialState: {
    listAccumulate: {
      records: [],
      total: 0,
    },

    savingAccumulate: {
      records: [],
      total: 0,
    },

    orders: {
      clientRecords: [],
      records: [],
      total: 0,
    },

    rate: {
      clientRecords: [],
      records: [],
      total: 0,
    },

    details: {},
  },

  reducers: {
    resetOrderHistory: (state) => {
      state.campaigns = {
        records: [],
        total: 0,
      };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllSavingsPlan.fulfilled, (state, { payload }) => {
      state.listAccumulate.records = payload.data;
      state.listAccumulate.total = payload.total;
    });

    builder.addCase(getAllSavingsPackage.fulfilled, (state, { payload }) => {
      state.savingAccumulate.records = payload.data;
      state.savingAccumulate.total = payload.total;
    });

    builder.addCase(getOneSavingsPlan.fulfilled, (state, { payload }) => {
      state.details = payload;
    });

    builder.addCase(getAllInvestOrderApp.fulfilled, (state, { payload }) => {
      state.orders.records = payload?.response?.data;
      state.orders.total = payload?.response?.total;

      if (payload?.page === 1) {
        state.orders.clientRecords = [];
      }

      state.orders.clientRecords.push(payload?.response?.data);
    });

    builder.addCase(getAllEgoTransaction.fulfilled, (state, { payload }) => {
      state.rate.records = payload?.response?.data;
      state.rate.total = payload?.response?.total;

      if (payload?.page === 1) {
        state.rate.clientRecords = [];
      }

      state.rate.clientRecords.push(payload?.response?.data);
    });
  },
});

export default accumulateSlice.reducer;
