import { createAsyncThunk } from "@reduxjs/toolkit";
import { UnitTypeApi } from "modules/shop/unit/apis";

// products category
export const getAllShopProductUnit = createAsyncThunk(
  "/product/getAllShopProductUnit",
  async (query) => {
    try {
      const response = await UnitTypeApi.getAllShopProductUnit(query);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getAllProductUnitWP = createAsyncThunk(
  "/product/getAllProductUnitWP",
  async () => {
    try {
      const response = await UnitTypeApi.getAllShopProductUnit({
        page: 1,
        limit: 10000,
      });

      return response;

    } catch (error) {
      throw new Error(error);
    }
  }
);

export const unit = {
  list: [],
  total: 0,
  activeUnits: [],
};

export const unitBuilder = (builder) => {
  builder.addCase(getAllShopProductUnit.fulfilled, (state, { payload }) => {
    state.units.list = payload?.data || [];
    state.units.total = payload?.total || 0;
  });

  builder.addCase(getAllProductUnitWP.fulfilled, (state,  { payload }) => {
    state.units.activeUnits = payload?.data || []
  })
};
