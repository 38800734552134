import React, { useEffect, useState } from "react";
import { Award, Gift, Proportions } from "lucide-react";

import "./index.scss";
import toast from "react-hot-toast";
import { investApi } from "modules/invest/apis";
import { formatNumberToAmount } from "utility/number-utils";

const TabLinkAccumulate = () => {
  const [infoLink, setInfoLink] = useState({});

  const loadDashBoard = async () => {
    // try {
    //   const response = await investApi.getInvestStats();
    //   setInfoLink(response);
    // } catch (e) {
    //   toast.error(e?.message);
    // }
  };

  useEffect(() => {
    loadDashBoard();
  }, []);

  return (
    <div className="bg-white flex flex-col gap-y-4 tab-link-invest mt-4">
      <div className="flex gap-3 items-center bg-primary p-4 rounded-lg">
        <Gift size={40} color="#39805b" />

        <div className="flex flex-col gap-y-2 justify-center text-white">
          <h3 className="text-white text-base">
            Tổng lượt liên kết thành công
          </h3>

          <p className="flex items-center gap-1 font-bold">
            <span className="text-3xl">{infoLink?.userConnect || 0}</span>
            lượt
          </p>
        </div>
      </div>

      <div className="flex gap-3 items-center bg-primary p-4 rounded-lg">
        <Proportions size={40} color="#39805b" />

        <div className="flex flex-col gap-y-2 justify-center text-white">
          <h3 className="text-white text-base">Tổng tích luỹ của liên kết</h3>

          <p className="flex items-center gap-1 font-bold">
            <span className="text-3xl">
              {formatNumberToAmount(infoLink?.connectInvestAmount || 0)}
            </span>
            đ
          </p>
        </div>
      </div>

      <div className="flex gap-3 items-center bg-primary p-4 rounded-lg">
        <Award size={40} color="#39805b" />

        <div className="flex flex-col gap-y-2 justify-center text-white">
          <h3 className="text-white text-base">Tổng hoa hồng liên kết</h3>

          <p className="flex items-center gap-1 font-bold">
            <span className="text-3xl">
              {formatNumberToAmount(infoLink?.commissionConnect || 0)}
            </span>
            đ
          </p>
        </div>
      </div>
    </div>
  );
};

export default TabLinkAccumulate;
