import { gql } from "@apollo/client";

export const CategorySheama = gql`
    fragment Categories on ShopProductType {
        id
        name
        code
        displayIndex
        description
        isActived
    }
`

export const categoryAction = {
    getAllShopProductType: gql`
        ${CategorySheama}
        query getAllShopProductType($q: QueryGetListInput) {
            getAllShopProductType(q: $q) {
                data {
                    ...Categories
                    createdAt
                    updatedAt
                }
                pagination {
                    total
                    page
                }
                total
            }
        }
    `,

    getOneShopCategory: gql`
        ${CategorySheama}
        query GetOneShopProductType($getOneShopProductTypeId: ID!) {
            getOneShopProductType(id: $getOneShopProductTypeId) {
                ...Categories
                createdAt
                updatedAt
            }
        }
    `,

    createShopProductType: gql`
        mutation createShopProductType($data: CreateShopProductTypeInput!){
            createShopProductType(data: $data) {
                id
            }
        }
    `,

    updateShopProductType: gql`
        mutation updateShopProductType($id: ID!, $data: UpdateShopProductTypeInput!){
            updateShopProductType(id: $id, data: $data) {
                id
            }
        }
    `,

    deleteOneShopProductType: gql`
        mutation deleteOneShopProductType($id: ID!){
            deleteOneShopProductType(id: $id) {
                id
            }
        }
    `
}