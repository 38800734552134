import { gql } from "@apollo/client";
import { mutate, query } from "configs/http-config";

const actions = {
  getOneVehicleSettingByType: gql`
    query getOneCarInfoByType($type: String!) {
      getOneCarInfoByType(type: $type) {
        type
        colors
        fuelTypes
        carCompanies
        manufacturingYears
      }
    }
  `,

  getAllPartnerDepartmentWOPaging: gql`
    query getAllPartnerDepartmentWOPaging($partnerId: ID!) {
      getAllPartnerDepartmentWOPaging(partnerId: $partnerId) {
        id
        name
      }
    }
  `,

  getAllConfigForPartner: gql`
    query getAllConfigForPartner($q: QueryGetListInput, $partnerId: ID!) {
      getAllConfigForPartner(partnerId: $partnerId, q: $q) {
        data {
          data
          id
          key
          partnerId
        }
      }
    }
  `,

  getConfigPriceHoliday: gql`
    query getConfigPriceHoliday($partnerId: ID!) {
      getConfigPriceHoliday(partnerId: $partnerId) {
        data
        id
        key
        partnerId
      }
    }
  `,

  getAllPartnerWorkPlaceWOPaging: gql`
    query getAllPartnerWorkPlaceWOPaging {
      getAllPartnerWorkPlaceWOPaging {
        address
        id
        name
        province
        provinceId
        ward
        wardId
        district
        districtId
      }
    }
  `,

  changePassword: gql`
    mutation changePassword($oldPassword: String!, $newPassword: String!) {
      changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
        id
      }
    }
  `,

  updateMe: gql`
    mutation updateMe($data: UpdateUserClientInput!) {
      updateMe(data: $data) {
        id
      }
    }
  `,

  updateConfigPriceCarForPartner: gql`
    mutation updateConfigPriceCarForPartner(
      $data: UpdateConfigPriceCarInput!
      $id: ID!
    ) {
      updateConfigPriceCarForPartner(data: $data, id: $id) {
        id
        key
      }
    }
  `,

  updateConfig: gql`
    mutation updateConfig($data: UpdateConfigInput!, $id: ID!) {
      updateConfig(data: $data, id: $id) {
        id
        key
      }
    }
  `,

  getAllCarTypeWOPaging: gql`
    query getAllCarTypeWOPaging($businessTypes: [String], $category: String) {
      getAllCarTypeWOPaging(
        businessTypes: $businessTypes
        category: $category
      ) {
        id
        name
        category
        createdAt
        updatedAt
        businessTypes
      }
    }
  `,
};

export const settingApis = {
  getAllCarInfoSetting: (payload) => {
    return query(
      "getOneCarInfoByType",
      actions.getOneVehicleSettingByType,
      payload
    );
  },

  getAllPartnerDepartmentWOPaging: (payload) => {
    return query(
      "getAllPartnerDepartmentWOPaging",
      actions.getAllPartnerDepartmentWOPaging,
      payload
    );
  },

  getAllPartnerWorkPlaceWOPaging: (payload) => {
    return query(
      "getAllPartnerWorkPlaceWOPaging",
      actions.getAllPartnerWorkPlaceWOPaging,
      payload
    );
  },

  changePassword: (payload) => {
    return mutate("changePassword", actions.changePassword, payload);
  },

  updateMe: (payload) => {
    return mutate("updateMe", actions.updateMe, payload);
  },

  updateConfigPriceCarForPartner: (payload) => {
    return mutate(
      "updateConfigPriceCarForPartner",
      actions.updateConfigPriceCarForPartner,
      payload
    );
  },

  updateConfig: (payload) => {
    return mutate("updateConfig", actions.updateConfig, payload);
  },

  getAllCarTypeWOPaging: (payload) => {
    return query(
      "getAllCarTypeWOPaging",
      actions.getAllCarTypeWOPaging,
      payload
    );
  },

  getAllConfigForPartner: (payload) => {
    return query(
      "getAllConfigForPartner",
      actions.getAllConfigForPartner,
      payload
    );
  },

  getConfigPriceHoliday: (payload) => {
    return query(
      "getConfigPriceHoliday",
      actions.getConfigPriceHoliday,
      payload
    );
  },
};
