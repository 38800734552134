import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notificationApi } from "../apis";
import { toast } from "react-hot-toast";

export const createNewNotification = async (query) => {
  try {
    const payload = {
      type: query?.type,
      title: query?.title,
      body: query?.body,
      option: query?.option,
      images: query?.images,
      workPlaceId: query?.workPlaceId,
      userId: query?.userId,
      content: query?.content,
      couponId: query?.couponId
    };

    await notificationApi.adminSendNoti(payload);
  } catch (error) {
    throw new Error(error);
  }
};


export const getAllNotification = createAsyncThunk(
  "/notification/getAllNotification",
  async (query) => {
    try {
      const response = await notificationApi.getAllNotification(query);
      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    notifications: {
      list: [],
      total: 0
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllNotification.fulfilled, (state, { payload }) => {
      state.notifications.list = payload?.data || [];
      state.notifications.total = payload?.total || [];
    });
  },
});

export default notificationSlice.reducer;
