import { useEffect, useRef, useState } from "react";


const useIntersectionObserver = (node, callback, options) => {
    const [intersection, setIntersection] = useState([]);

    const observer = useRef(null);

    useEffect(() => {
        if (!node) {
            if (observer.current) {
                observer.current.disconnect()
            }
            return
        }

        observer.current = new window.IntersectionObserver((entries) => {
            setIntersection(entries)
        }, options)

        observer.current.observe(node)

        return () => {
            if (observer.current) {
                observer.current.disconnect()
            }
        }
    }, [node])

    useEffect(() => {
        callback?.(intersection)
    }, [intersection])

    return intersection;
}

export default useIntersectionObserver;