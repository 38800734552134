import React from "react";
import { useSelector } from "react-redux";

import CardItemAccumulate from "../card-item";

const TabPackage = () => {
  const { listAccumulate } = useSelector((state) => state.accumulate);

  return (
    <div className="mt-4">
      <h3 className="text-black text-base font-bold">Gói dài hạn</h3>

      <div className="mt-4 flex flex-col gap-3">
        {listAccumulate?.records?.map((item, index) => (
          <div key={index}>
            <CardItemAccumulate item={item} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TabPackage;
