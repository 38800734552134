import React from "react";
import { Check } from "react-feather";

const StepRegister = () => {
  return (
    <div className="h-[230px]">
      <div className="flex justify-center mt-2">
        <div className="p-3  rounded-full inline-block bg-gradient-to-r from-primary to-primary-1">
          <Check size={30} color="#fff" />
        </div>
      </div>

      <h4 className="text-black text-xl mt-5 text-center uppercase">
        Bạn đã đăng ký thành công và nhận đc
      </h4>

      <div className="flex justify-center mt-5">
        <div className="font-bold text-white px-5 py-2 inline-block rounded-xl bg-gradient-to-l from-primary to-primary-1">
          +50 $EGO
        </div>
      </div>
    </div>
  );
};

export default StepRegister;
