import toast from "react-hot-toast";
import React, { useState } from "react";
import { useAuth } from "components/containers/auth";

import StepClaim from "./components/step-claim";
import ButtonSystem from "components/e-control/button-system";

import { web3Api } from "../apis";
import logo from "../assets/logo-web3.png";
import { formatNumberToAmount } from "utility/number-utils";
import { getAccountInfo } from "modules/authentication/store";
import { dispatch } from "root-stores";

const Web3EgoPage = () => {
  const { user } = useAuth();

  const [loading, setLoading] = useState();

  const handleClaim = async () => {
    toast(
      "Việc rút Token về sẽ được thông báo trên các kênh truyền thông của Công Ty",
      {
        icon: "📣",
        style: {
          borderRadius: "10px",
          color: "#0b823c",
        },
      }
    );
    // try {
    //   setLoading(true);

    //   const res = await web3Api.claimEgoToken();

    //   if (res === "success") {
    //     toast.success("Nhận token thành công");
    //     dispatch(getAccountInfo());
    //   }
    // } catch (e) {
    //   toast.error(e?.message);
    // } finally {
    //   setLoading(false);
    // }
  };

  return (
    <div className="max-w-[900px] m-auto bg-white min-h-[100vh] p-3">
      <div className="pb-4">
        <div className="flex items-center justify-between gap-x-4">
          <h4 className="text-black text-base font-semibold max-w-[280px]">
            Tham gia sự kiện Airdrop của Siêu Ứng Dụng eGO
          </h4>

          <img src={logo} alt="logo" className="w-14 h-14" />
        </div>

        <div className="p-3 border border-primary rounded-md flex items-center justify-between text-black mt-4">
          <p className="flex flex-col items-start gap-y-2 font-semibold">
            Tổng Airdrop
            <span className="text-2xl font-bold text-primary">
              {formatNumberToAmount(user?.egoToken)} $EGO
            </span>
          </p>

          <ButtonSystem
            // disabled={user?.egoToken === 0}
            onClick={handleClaim}
            loading={loading}
            color="primary"
            className="px-10 opacity-50"
          >
            Claim
          </ButtonSystem>
        </div>
      </div>

      <StepClaim />
    </div>
  );
};

export default Web3EgoPage;
