import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { carApi } from "../apis";
import { toast } from "react-hot-toast";

export const getAllCars = createAsyncThunk("/car/getAll", async (query) => {
  try {
    const response = await carApi.getAllCar({ q: query });
    return response;
  } catch (error) {
    toast.error(error?.message);
    throw new Error(error);
  }
});

export const createNewCar = async (query) => {
  let payload = {};
  payload = {
    data: {
      images: query?.carInfo?.images,
      carCode: query?.carInfo?.carCode,
      carTypeId: query?.carTypeId,
      licensePlateNumber: query?.carInfo?.licensePlateNumber,
      businessType: "TAXI",
      carCompany: query?.carInfo?.carCompany,
      category: query?.carInfo?.category,
      color: query?.carInfo?.color,
      fuelType: query?.carInfo?.fuelType,
      manufacturingYear: query?.carInfo?.manufacturingYear,
      address: query?.carInfo?.address,
      price: parseFloat(query?.carInfo?.price),
      description: query?.carInfo?.description,
      location: [
        parseFloat(query?.carInfo?.long),
        parseFloat(query?.carInfo?.lat),
      ],
      workPlaceId: query?.workplaceId,
      tagIds: query?.tagIds,
      carFeatureIds: query?.carFeatureIds,
      // districtId: query?.carInfo?.districtId,
      transmisstionType: query?.carInfo?.transtation,
    },
  };
  try {
    await carApi.createCar(payload);
  } catch (error) {
    throw new Error(error);
  }
};

export const getAllWorkPlaceWOPaging = createAsyncThunk(
  "/location/getWorkPlace",
  async () => {
    try {
      const response = await carApi.getAllWorkPlaceWOPaging();
      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getAllCarType = createAsyncThunk(
  "/car/getAllCarType",
  async (query) => {
    try {
      const response = await carApi.getAllCarType({ q: query });

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getAllCarOwner = createAsyncThunk(
  "/car/getAllCarOwner",
  async (query) => {
    try {
      const response = await carApi.getAllCarOwner(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);
export const getAllCarTypeWOPagingByWorkPlace = createAsyncThunk(
  "/car/getAllCarTypeWOPagingByWorkPlace",
  async (query) => {
    try {
      const payload = {
        workPlaceId: query,
      };
      const response = await carApi.getAllCarTypeWOPagingByWorkPlace(payload);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const carSlice = createSlice({
  name: "car",
  initialState: {
    cars: {
      list: [],
      total: 0,
      countCars: 0,
      countActiveCars: 0,
      countInactiveCars: 0,
    },

    workplaceSelect: [],

    types: {
      list: [],
      total: 0,
    },

    typeSelect: [],
    carCategories: [],

    carHost: {
      list: [],
      total: 0,
    },
  },

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getAllCars.fulfilled, (state, { payload }) => {
      state.cars.list = payload?.data || [];
      state.cars.total = (payload?.total && payload?.total) || 0;
      state.cars.countCars = payload?.getStatCar[0]?.countCars || 0;
      state.cars.countActiveCars =
        (payload?.getStatCar && payload?.getStatCar[0]?.countActiveCars) || 0;
      state.cars.countInactiveCars =
        (payload?.getStatCar && payload?.getStatCar[0]?.countInactiveCars) || 0;
    });

    builder.addCase(getAllWorkPlaceWOPaging.fulfilled, (state, { payload }) => {
      state.workplaceSelect = payload || [];
    });

    builder.addCase(getAllCarType.fulfilled, (state, { payload }) => {
      state.types.list = payload?.data || [];
      state.types.total = payload?.total;
    });

    builder.addCase(
      getAllCarTypeWOPagingByWorkPlace.fulfilled,
      (state, { payload }) => {
        state.typeSelect = payload || [];
        state.carCategories = payload || [];
      }
    );

    builder.addCase(getAllCarOwner.fulfilled, (state, { payload }) => {
      state.carHost.list = payload?.data || [];
      state.carHost.total = payload?.total || [];
    });
  },
});

export default carSlice.reducer;
