import React from "react";
import { useSelector } from "react-redux";

import "./index.scss";

const TabDescription = () => {
  const { unit } = useSelector((state) => state.invest);

  return (
    <div
      className="text-blue-950 companyInfo-tab"
      dangerouslySetInnerHTML={{ __html: unit?.companyInfo }}
    />
  );
};

export default TabDescription;
