import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { workPlacePartnerApi } from "../apis";

export const getAllPartnerWorkPlace = createAsyncThunk(
  "/workPlace/getAllPartnerWorkPlace",
  async (query) => {
    try {
      const response = await workPlacePartnerApi.getAllPartnerWorkPlace(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const createPartnerWorkPlace = async (query) => {
  try {
    const payload = {
      data: {
        address: query?.address,
        districtId: query?.districtId,
        name: query?.name,
        provinceId: query?.provinceId,
        wardId: query?.wardId,
      },
    };

    await workPlacePartnerApi.createPartnerWorkPlace(payload);
  } catch (error) {
    throw new Error(error);
  }
};

export const getListProvince = createAsyncThunk(
  "/workPlace/getProvince",
  async (query) => {
    try {
      const response = await workPlacePartnerApi.getProvince(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getListDistrict = createAsyncThunk(
  "/workPlace/getDistrict",
  async (query) => {
    try {
      const response = await workPlacePartnerApi.getDistrict(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getListWard = createAsyncThunk(
  "/workPlace/getWard",
  async (query) => {
    try {
      const response = await workPlacePartnerApi.getWard(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const workPlaceSlice = createSlice({
  name: "workPlacePartner",
  initialState: {
    workPlaces: {
      list: [],
      total: 0,
    },

    province: {
      list: [],
    },

    district: {
      list: [],
    },

    ward: {
      list: [],
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllPartnerWorkPlace.fulfilled, (state, { payload }) => {
      state.workPlaces.list = payload?.data || [];
      state.workPlaces.total = payload?.total || 0;
    });

    builder.addCase(getListProvince.fulfilled, (state, { payload }) => {
      state.province.list = payload || [];
    });

    builder.addCase(getListDistrict.fulfilled, (state, { payload }) => {
      state.district.list = payload || [];
    });

    builder.addCase(getListWard.fulfilled, (state, { payload }) => {
      state.ward.list = payload || [];
    });
  },
});

export default workPlaceSlice.reducer;
