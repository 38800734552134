import "./index.scss";

const BardSystem = ({ children, type = "primary", bgColor, colorText }) => {
  return (
    <span
      className={`bard-system badge ${bgColor ? "" : "text-bg-" + type}`}
      style={{ backgroundColor: bgColor, color: colorText }}
    >
      {children}
    </span>
  );
};

export default BardSystem;
