const classNames = require("classnames");
const { Controller } = require("react-hook-form");
const { FormGroup, Label, FormFeedback } = require("reactstrap");

const FormGroupSystem = ({ groupClass, labelClass, label, form, ...props }) => {
  const {
    control,
    formState: { errors },
  } = form;

  // console.log("errors?.[props.name]", errors?.[props.name]);

  return (
    <FormGroup className={classNames([groupClass, ""])}>
      <Label className={classNames(["text-sm", labelClass])}>{label}</Label>

      <Controller {...props} control={control} />

      {errors?.[props.name] && (
        <FormFeedback className="block">
          {errors?.[props.name]?.message || "Please input this field"}
        </FormFeedback>
      )}
    </FormGroup>
  );
};

export default FormGroupSystem;
