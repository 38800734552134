import ButtonSystem from 'components/e-control/button-system';
import InputSystem from 'components/e-control/forms/form-elements/input-system';
import { Controller, useForm } from "react-hook-form";

const TripActionForm = ({ accept, refuse }) => {
    const { control, resetField, getValues } = useForm({});

    const acceptTrip = async () => {
        const { tripId } = getValues();
        accept?.(tripId)
        // resetField();
    };

    const refuseTrip = () => {
        const { tripId } = getValues();
        refuse?.(tripId);
        resetField();
    };

    return (
        <div
            className="flex items-end gap-x-2"
        >
            <div className="form-item">
                <label>Nhập dispatch id</label>
                <Controller
                    name="tripId"
                    control={control}

                    render={({ field }) => (
                        <InputSystem
                            placeholder='nhập trip id'
                            {...field}
                        />
                    )}
                />
            </div>

            <div className="flex items-center gap-x-2">
                <ButtonSystem
                    type='button'
                    className='me-1'
                    color='primary'

                    onClick={acceptTrip}
                >
                    Nhận chuyến
                </ButtonSystem>

                <ButtonSystem
                    type='button'
                    className='me-1'
                    color='primary'
                    onClick={refuseTrip}
                >
                    Từ chối
                </ButtonSystem>
            </div>

        </div>
    )
};

export default TripActionForm;