"use client";

import errorImage from 'assets/images/icons/error.svg';
import ButtonSystem from 'components/e-control/button-system';
import { ErrorBoundary } from "react-error-boundary";

const ErrorMessage = ({ error }) => {

    return (
        <div className="h-full flex-col w-full flex items-center jusify-center">
            <div className="w-full h-full flex flex-col gap-y-8 md:flex-row items-center justify-center">
                <img
                    src={errorImage}
                    alt="error_ego_app"
                    className="w-40 md:w-auto animate__animated animate__rubberBand"
                />

                <div className="flex flex-col items-center justify-between">
                    <h1 className="font-bold text-primary text-[20px]">Opp!!! Đã có lỗi xảy ra</h1>
                    <p className="mt-2 text-secondary-2">Các bạn vui lòng quay lại sau</p>
                    <div className="flex items-center justify-center mt-8">
                        <ButtonSystem color="primary"
                            onClick={() => window.location.reload()}
                        >
                            Tải lại trang
                        </ButtonSystem>
                    </div>

                    {process.env.NODE_ENV !== 'production' && (<p>{error.message}</p>)}
                </div>
            </div>
        </div>
    )
}

const ErrorBoundaryContainer = ({ children }) => {

    return (
        <ErrorBoundary
            fallbackRender={ErrorMessage}
        >
            {children}
        </ErrorBoundary>
    )
}

export default ErrorBoundaryContainer;