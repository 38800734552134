import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import "./index.scss";

import { useSelector } from "react-redux";

import classNames from "classnames";
import { useConfirmContext } from "components/containers/confirm-container";
import PageWrapper from "components/containers/page/page-wrapper";
import TabPageContent from "components/containers/tab-page-content";
import TablePageContent from "components/containers/table-page-content";
import { investApi } from "../apis";
import AuthPaymentTable from "./table";
import InputSystem from "components/e-control/forms/form-elements/input-system";

const AuthPaymentPage = () => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [listUser, setListUser] = useState([]);

  const [currentTab, setCurrentTab] = useState(1);
  const [search, setSearch] = useState("");

  const { setOpenConfirmation, confirmDialog } = useConfirmContext();

  // Store
  const { cars } = useSelector((state) => state.car);

  const pagination = useMemo(() => {
    return {
      page,
      total: cars?.total || 0,
      onPageChange: (page) => setPage(page),
    };
  }, [page, cars?.total]);

  // Handle get list car
  const loadListPayment = async () => {
    try {
      setLoading(true);

      const params = {
        page: page,
        limit: 10,
        filter: {
          eKycStatus: currentTab === 1 ? "PENDING" : "APPROVE",
          role: "CLIENT",
        },
        search,
        order: { updatedAt: -1 },
      };

      //   const response = await investApi.getAllUser(params);
      //   setListUser(response?.data)
    } catch (error) {
      return;
    } finally {
      setLoading(false);
    }
  };

  /**
   * @description handle add/edit success
   */

  const refuseContact = async (item) => {
    const isConfirm = await confirmDialog({
      messages: ["Bạn có chắc từ chối xác thực người dùng này không?"],
    });

    if (!isConfirm) {
      return;
    }

    const payload = {
      id: item?.id,
      data: {
        eKycStatus: "REFUSE",
      },
    };

    try {
      setLoading(true);

      const response = await investApi.updateUser(payload);

      if (response?.id) {
        toast.success("Từ chối xác thực người dùng thành công!");
        loadListPayment();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const approveUser = async (item) => {
    const isConfirm = await confirmDialog({
      messages: ["Bạn có chắc xác thực người dùng này không?"],
    });

    if (!isConfirm) {
      return;
    }

    const payload = {
      id: item?.id,
      data: {
        eKycStatus: "APPROVE",
      },
    };

    try {
      setLoading(true);

      const response = await investApi.updateUser(payload);

      if (response?.id) {
        toast.success("Xác thực người dùng thành công!");
        loadListPayment();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadListPayment();
  }, [currentTab, search]);

  return (
    <>
      <PageWrapper
        title="Xác thực thanh toán"
        breadcrumbs={[
          {
            title: "Xác thực thanh toán",
          },
        ]}
      >
        <div className="flex items-center justify-between mb-3">
          <span className="text-sm">Tổng: {cars?.total || 0}</span>

          <InputSystem
            className="max-w-[200px] text-sm"
            value={search}
            placeholder="Tìm theo mã đơn hàng..."
            onChange={(e) => {
              setSearch(e?.target?.value);
            }}
          />
        </div>

        <TabPageContent
          tabs={
            <>
              {[
                {
                  key: 1,
                  value: "pending",
                  title: "Thanh toán chờ xác thực",
                },

                {
                  key: 2,
                  value: "history",
                  title: "Thanh toán đã xác thực",
                },
              ].map((tab) => (
                <div
                  key={tab.key}
                  className="w-full md:w-fit bg-background-accent md:bg-background flex justify-center"
                >
                  <div
                    className={classNames(
                      "transition-all flex-[2] md:flex-1 flex items-center justify-center rounded-md md:rounded-b-none py-2 px-4 cursor-pointer text-[12px] whitespace-nowrap w-fit",
                      currentTab === tab.key &&
                      "bg-primary text-white md:bg-background-accent"
                    )}
                    onClick={() => {
                      return setCurrentTab(tab?.key);
                    }}
                  >
                    {tab.title}
                  </div>
                </div>
              ))}
            </>
          }
        >
          <TablePageContent>
            {currentTab === 1 && (
              <AuthPaymentTable
                partners={listUser}
                loading={loading}
                pagination={pagination}
                refuseDriverCar={refuseContact}
                approveDriverCar={approveUser}
              />
            )}

            {currentTab === 2 && (
              <AuthPaymentTable
                partners={listUser}
                loading={loading}
                pagination={pagination}
              />
            )}
          </TablePageContent>
        </TabPageContent>
      </PageWrapper>
    </>
  );
};

export default AuthPaymentPage;
