import { mutate } from "configs/http-config";
import { claimEgoToken, submitInviteCode } from "./schema";

export const web3Api = {
  submitInviteCode: (payload) => {
    return mutate("submitInviteCode", submitInviteCode, payload);
  },

  claimEgoToken: () => {
    return mutate("claimEgoToken", claimEgoToken);
  },
};
