import { ArrowLeft } from "lucide-react";
import { useMemo, useState } from "react";

import Popup from "modules/invest/client/components/popup";
import { web3Apis } from "modules/wallet-web3/apis";
import { selectCrypto } from "modules/wallet-web3/stores";
import toast from "react-hot-toast";
import { dispatch } from "root-stores";
import CryptoList from "../crypto-list";
import QRScreen from "./qr";
import { useSelector } from "react-redux";

const ReceiveScreen = (props) => {
  const [step, setStep] = useState(0);
  const [coin, setCoin] = useState(null);
  const { selectedCoin } = useSelector((state) => state.web3);

  const ReceiveCoinHeader = (
    <div className="flex items-center gap-x-12">
      <div
        className=""
        onClick={() => {
          setStep((prev) => prev - 1);
        }}
      >
        <ArrowLeft className="size-4" />
      </div>
      <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">{`Receive ${selectedCoin?.symbol}`}</span>
    </div>
  );

  const TransferStep = (
    <div className="flex items-center gap-x-12">
      <div
        className=""
        onClick={() => {
          setStep((prev) => prev - 1);
        }}
      >
        <ArrowLeft className="size-4" />
      </div>
      <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">{`Transfer`}</span>
    </div>
  );

  const defaultHeader = (
    <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">{`Receive`}</span>
  );

  const title = useMemo(() => {
    switch (step) {
      case 1:
        return ReceiveCoinHeader;

      default:
        return defaultHeader;
    }
  }, [step, coin, selectedCoin]);

  const handleSelectCoin = async (coin) => {
    try {
      const response = await web3Apis.receiveCrypto(coin.symbol.toUpperCase());

      dispatch(selectCrypto({ ...coin, ...response }));
      setStep((prev) => prev + 1);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Popup header={title} {...props}>
      <div className="h-[75vh]">
        {step === 0 && (
          <div className="flex flex-col gap-5">
            <CryptoList onSelect={handleSelectCoin} />
          </div>
        )}

        {step === 1 && <QRScreen coin={coin} />}
      </div>
    </Popup>
  );
};

export default ReceiveScreen;
