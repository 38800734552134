import driver from 'assets/images/icons/shipper.png';
import classNames from "classnames";
import { DragItem, DropList } from 'components/utils/drag-drop';
import ViewCondition from 'components/utils/view-condition';
import { useCallback, useMemo } from "react";
import { Card } from "reactstrap";

const MobileTableViewPort = (tableProps) => {
    const { template: Template, children, columns, data, labelClass, valueClass, rowClass, emptyText, onDragEnd, isDragable } = tableProps;

    const filterColumns = useMemo(() => {
        return columns.filter(col => !col.notShowMobile)
    }, [columns])

    const renderValue = (col, row, index = 0) => {
        if (col.dataIndex === 'action') {
            return null
        }

        if (col.render) {
            return col.render(row?.[col?.key] || row?.[col?.dataIndex], row, index);
        }

        return row?.[col?.key] || row?.[col?.dataIndex] || ''
    }

    const renderValueByKey = (key, row) => {
        const col = columns.find(col => col.key === key);

        if (!col) {
            return null;
        }

        return renderValue(col, row)
    }

    const renderDataByColumns = useCallback((col, row, index) => {
        return (
            <div key={col.dataIndex} className={classNames("grid grid-cols-3 mt-2 justify-items-start items-start gap-x-2", rowClass)}>
                <div className={labelClass}>
                    {col.title && (<h3 className="text-secondary-2">{col.title}:</h3>)}
                </div>

                <div className={classNames("col-span-2 -mt-0.5", valueClass)}>
                    {renderValue(col, row, index)}
                </div>
            </div>
        )
    }, [columns])

    const actionCol = columns.find(col => col.key === 'action');

    const DragComponent = useCallback(() => {
        if (isDragable) {
            return () => <></>
        }
        return DragItem
    }, [isDragable, data])

    if (!data || !data.length) {
        return (
            <div className="min-h-[50vh] w-full flex items-center justify-center">
                <div className="flex flex-col items-center">
                    <div className="w-32 h-32 animate__animated animate__backInLeft ">
                        <img src={driver} alt="ego" className="animate__animated animate__rubberBand animate__delay-1s hover:animate__animated hover:animate__rubberBand" />
                    </div>

                    <p className="text-sm">{emptyText || 'Không có dữ liệu'}</p>
                </div>
            </div>
        )
    }

    return (
        <DropList dragEnd={onDragEnd}>
            {Template && (
                data?.map((item, index) => {
                    if (isDragable) {
                        return (
                            <DragItem
                                key={index}
                                index={index}
                                draggableId={item?.id || ''}
                                className="cursor-move"
                            >
                                <Template item={item} key={index} colValue={renderValueByKey} />
                            </DragItem>
                        )
                    }

                    return <Template item={item} key={index} colValue={renderValueByKey} />
                })
            )}

            {children && children(data)}

            {(!Template && !children) && data.map((item, index) => {
                if (isDragable) {
                    return (
                        <DragItem
                            key={index}
                            index={index}
                            draggableId={item?.id || ''}
                            className="cursor-move"
                        >
                            <Card key={index} className="mb-3 p-3">
                                <ViewCondition rIf={actionCol?.isDropdown}>
                                    <div className="flex justify-end">
                                        {actionCol?.render?.(item, item, index)}
                                    </div>
                                </ViewCondition>

                                {filterColumns.map((col, index) => {
                                    return renderDataByColumns(col, item, index)
                                })}

                                <ViewCondition rIf={!actionCol?.isDropdown}>
                                    <div className="flex items-center justify-center gap-x-4 mt-2">
                                        {actionCol?.render?.(item, item, index)}
                                    </div>
                                </ViewCondition>
                            </Card>
                        </DragItem>
                    )
                }

                return (
                    <Card key={index} className="mb-3 p-3">
                        <ViewCondition rIf={actionCol?.isDropdown}>
                            <div className="flex justify-end">
                                {actionCol?.render?.(item, item, index)}
                            </div>
                        </ViewCondition>

                        {filterColumns.map((col, index) => {
                            return renderDataByColumns(col, item, index)
                        })}

                        <ViewCondition rIf={!actionCol?.isDropdown}>
                            <div className="flex items-center justify-center gap-x-4 mt-2">
                                {actionCol?.render?.(item, item, index)}
                            </div>
                        </ViewCondition>
                    </Card>
                )
            })}
        </DropList>
    )
}

export default MobileTableViewPort;