import { initializeApp } from "@firebase/app";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "@firebase/auth";
import { getStorage } from "@firebase/storage";

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export const storage = getStorage();

export { auth, RecaptchaVerifier, signInWithPhoneNumber };
