/* eslint-disable react/no-unescaped-entities */

import "./index.scss";

const TabScan = ({ info }) => {
  return (
    <div className="flex justify-center h-full">
      <img src={info?.QRCode} alt={"payment"} className="object-fill h-full" />
    </div>
  );
};

export default TabScan;
