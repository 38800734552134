/* eslint-disable multiline-ternary */
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Card } from "reactstrap";

import { useConfirmContext } from "components/containers/confirm-container";
import AvatarSystem from "components/controls/avatar-system";
import BardSystem from "components/e-control/bard-system";
import TooltipSystem from "components/e-control/tooltip-system";
import { RANK_TYPE } from "constants";
import {
    Gem,
    GraduationCap,
    Lock,
    PenIcon,
    Unlock
} from "lucide-react";
import { setUserStringee } from "modules/ego-system/call-stringee/stores";
import { dispatch } from "root-stores";
import {
    formatNumberToAmount,
    formatNumberToAmountEnableDecimal,
} from "utility/number-utils";
import Utils from "utility/time";
import { formatPhoneNumberToShow } from "utils";

const CardInvestDetail = ({ loadDetailUser, onChangePass, onStatus }) => {
    const { userDetail } = useSelector((state) => state.user);
    const { confirmDialog } = useConfirmContext();

    const [modal, setModal] = useState(false);
    const [modalPoint, setModalPoint] = useState(false);

    const [page, setPage] = useState(1);

    const [payment, setPayment] = useState({ list: [], total: 0, stats: {} });

    const pagination = useMemo(() => {
        return {
            page: page,
            total: payment.total || 0,
            onPageChange: (page) => setPage(page),
        };
    }, [page, payment.total]);

    const listBody = [
        {
            text: "SĐT",
            content: formatPhoneNumberToShow(userDetail?.phone),
            show: userDetail?.phone,
        },

        {
            text: "Email",
            content: userDetail?.email,
            show: userDetail?.email,
        },

        {
            text: "Address",
            content: userDetail?.address,
            show: userDetail?.address,
        },

        {
            text: "CCCD",
            content: userDetail?.identityCard,
            show: userDetail?.identityCard,
        },

        {
            text: "Giới tính",
            content: userDetail?.gender,
            show: userDetail?.gender,
        },

        {
            text: "Số dư",
            content: formatNumberToAmountEnableDecimal(
                String(userDetail?.wallet?.balance),
                0,
                "VNĐ"
            ),
            action: (
                <div
                    onClick={() => setModal(true)}
                    className="rounded-full bg-primary p-2 text-white"
                >
                    <PenIcon className="w-4 h-4" />
                </div>
            ),
            show: true,
        },

        {
            text: "Trạng thái",
            content: userDetail?.isBlock ? (
                <TooltipSystem title="Khoá">
                    <Lock className="w-5 h-5 text-red-error" />
                </TooltipSystem>
            ) : (
                <TooltipSystem title="Mở khoá">
                    <Unlock className="w-5 h-5 text-primary" />
                </TooltipSystem>
            ),
            show: true,
        },
    ];

    const handleCall = (row) => {
        dispatch(setUserStringee(row));
    };


    return (
        <>
            <div className="space-y-4">
                <Card className="mb-0 rounded-md p-4 space-y-4 relative">
                    <div className="flex flex-col items-center gap-2">
                        <AvatarSystem
                            name={userDetail?.name}
                            srcImg={userDetail?.avatar}
                            imgClass="w-[80px] h-[80px]"
                        />

                        <h1 className="text-primary uppercase text-lg font-bold">
                            {userDetail?.name}
                        </h1>

                        <div className="flex items-center justify-center gap-x-8">
                            <div className="flex items-center gap-x-2">
                                <div className="p-1 rounded-md bg-primary/30 flex items-center justify-center">
                                    <GraduationCap className="w-6 h-6 text-primary" />
                                </div>

                                <p className="text-sm">
                                    <BardSystem
                                        colorText="#000"
                                        bgColor={RANK_TYPE?.[userDetail?.rank]?.COLOR}
                                    >
                                        {RANK_TYPE?.[userDetail?.rank]?.LABEL}
                                    </BardSystem>
                                </p>
                            </div>

                            <div className="flex items-center gap-x-2">
                                <div className="p-1 rounded-md bg-[cyan]/30 flex items-center justify-center">
                                    <Gem className="w-5 h-5 text-[cyan]" />
                                </div>

                                <p className="text-sm">
                                    {formatNumberToAmount(userDetail?.pointReward || 0)}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="h-[1px] w-full bg-border-color" />

                    <div className="">
                        <div className="space-y-2">
                            {listBody
                                ?.filter((item) => item?.show)
                                ?.map((item, index) => (
                                    <div
                                        key={index}
                                        className="flex items-center gap-x-2 align-middle"
                                    >
                                        <div className="text-sm text-secondary-2">
                                            {item?.text} :{" "}
                                        </div>
                                        <div className="flex items-center gap-x-2">
                                            <span className="text-sm md:text-md">{item?.content}</span>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </Card>

                <Card className="mb-0 rounded-md p-4 space-y-2">
                    <div className="flex items-center justify-between">
                        <div className="text-sm text-secondary-2">Ngày đăng ký: </div>
                        <div className="flex items-center gap-x-2 text-sm">
                            {Utils.formatDateUtc(userDetail?.createdAt)}
                        </div>
                    </div>

                    <div className="flex items-center justify-between">
                        <div className="text-sm text-secondary-2">Ngày cập nhật: </div>
                        <div className="flex items-center gap-x-2 text-sm">
                            {Utils.formatDateUtc(userDetail?.updatedAt)}
                        </div>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default CardInvestDetail;
