import { createContext, useContext } from "react";
import useDeviceDetect from "utility/hooks/useDeviceDetect";

const ViewDetectedContext = createContext();

const ViewDetected = (props) => {

    const {
        deviceType,
        isIpad,
        isIphone,
        isTabletDevice,
        isMobileDevice,
        isDesktopDevice,
    } = useDeviceDetect()

    return (
        <ViewDetectedContext.Provider
            value={{
                isIpad,
                isIphone,
                deviceType,
                isTabletDevice,
                isMobileDevice,
                isDesktopDevice,
            }}
        >
            {props.children}
        </ViewDetectedContext.Provider>
    );
};

export const useViewDetected = () => useContext(ViewDetectedContext);

export default ViewDetected;