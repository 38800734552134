import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { bookingCarRentalApi } from "../apis";
import { toast } from "react-hot-toast";

export const getAllRentCarTrip = createAsyncThunk(
  "/bookings-car-rental/getAllRentCarTrip",
  async (query) => {
    try {
      const response = await bookingCarRentalApi.getAllRentCarTrip(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getAllRentCarByLocation = createAsyncThunk(
  "/bookings-car-rental/getAllRentCarByLocation",
  async (query) => {
    try {
      const response = await bookingCarRentalApi.getAllRentCarByLocation(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const BookingCarRentalSlice = createSlice({
  name: "bookingsCarRental",
  initialState: {
    list: [],
    total: 0,
    stats: {},

    carSelect: {
      list: [],
      total: 0,
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllRentCarTrip.fulfilled, (state, { payload }) => {
      state.list = payload?.data || [];
      state.total = payload?.total || 0;
      state.stats = payload?.getStatRentCarTrip || {};
    });

    builder.addCase(getAllRentCarByLocation.fulfilled, (state, { payload }) => {
      state.carSelect.list = payload?.data || [];
      state.carSelect.total = payload?.total || 0;
    });
  },
});

export default BookingCarRentalSlice.reducer;
