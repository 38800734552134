import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { settingApis } from "../apis";
import {
  FUEL_TYPE,
  COLOR_LABEL,
  TRANSPORTATION_TYPE,
  CAR_MANUFACTURER,
  BIKE_MANUFACTURER,
} from "constants";

export const getAllVehicleSettingByType = createAsyncThunk(
  "/setting/getAllVehicleSettingByType",
  async (query) => {
    try {
      const response = await settingApis.getAllCarInfoSetting(query);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getAllPartnerWorkPlaceWOPaging = createAsyncThunk(
  "/setting/getAllPartnerWorkPlaceWOPaging",
  async () => {
    try {
      const response = await settingApis.getAllPartnerWorkPlaceWOPaging();
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getAllPartnerDepartmentWOPaging = createAsyncThunk(
  "/setting/getAllPartnerDepartmentWOPaging",
  async (query) => {
    try {
      const response = await settingApis.getAllPartnerDepartmentWOPaging(query);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const getAllCarTypeWOPaging = createAsyncThunk(
  "/setting/getAllCarTypeWOPaging",
  async (query) => {
    try {
      const response = await settingApis.getAllCarTypeWOPaging(query);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
);

const generateColorToOptions = (arr = []) => {
  return arr.map((item) => ({
    value: item,
    label: COLOR_LABEL[item]?.LABEL ?? item,
    color: COLOR_LABEL[item]?.COLOR,
  }));
};

const generateManufactureToOptions = (arr = [], type) => {
  const objectTypes =
    type === TRANSPORTATION_TYPE.CAR ? CAR_MANUFACTURER : BIKE_MANUFACTURER;
  return arr.map((item) => {
    return { value: item, label: objectTypes[item] ?? item };
  });
};

const generateFuelToOptions = (arr = []) => {
  return arr.map((item) => {
    return {
      value: item,
      label: FUEL_TYPE[item]?.LABEL ?? item,
      color: FUEL_TYPE[item]?.COLOR,
    };
  });
};

const generateYearToOptions = (arr = []) => {
  return arr
    .sort((a, b) => a - b)
    .map((item) => ({
      value: item,
      label: item,
    }));
};

export const partnerSettingSlice = createSlice({
  name: "setting",
  initialState: {
    vehicleSettings: {
      manufacturers: [],
      vehicleColors: [],
      vehicleYears: [],
      fuelTypes: [],
    },

    listTypeVehicle: [],

    partnerDepartment: {
      list: [],
    },

    partnerWorkPlace: {
      list: [],
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      getAllVehicleSettingByType.fulfilled,
      (state, { payload }) => {
        state.vehicleSettings.vehicleColors = generateColorToOptions(
          payload?.colors || []
        );
        state.vehicleSettings.manufacturers = generateManufactureToOptions(
          payload?.carCompanies || [],
          payload?.type
        );
        state.vehicleSettings.vehicleYears = generateYearToOptions(
          payload?.manufacturingYears || []
        );
        state.vehicleSettings.fuelTypes = generateFuelToOptions(
          payload?.fuelTypes || []
        );
      }
    );

    builder.addCase(getAllCarTypeWOPaging.fulfilled, (state, { payload }) => {
      state.listTypeVehicle = payload || [];
    });

    builder.addCase(
      getAllPartnerDepartmentWOPaging.fulfilled,
      (state, { payload }) => {
        state.partnerDepartment.list = payload || [];
      }
    );

    builder.addCase(
      getAllPartnerWorkPlaceWOPaging.fulfilled,
      (state, { payload }) => {
        state.partnerWorkPlace.list = payload || [];
      }
    );
  },
});

export default partnerSettingSlice.reducer;
