import toast from "react-hot-toast";
import { dispatch } from "root-stores";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import usePagination from "utility/hooks/usePagination";
import { useConfirmContext } from "components/containers/confirm-container";

import TablePageContent, {
  TableFilter,
} from "components/containers/table-page-content";
import PageWrapper from "components/containers/page/page-wrapper";

import { INVEST_ROUTES } from "router/invest-routes";
import { PAYMENT_METHODS } from "modules/ego-system/accumulate/components/buy-form-accumulate";

import { accumulateApi } from "../../../apis/index";
import { getAllSavingsPackage } from "modules/ego-system/accumulate/stores";

import OrderHistoryAccumulateTable from "../../components/accumulate-order-history/table";
import HistoryOrderFilterAccumulate from "../../components/accumulate-order-history/filter";
import ModalDetailAccumulateOrder from "../../components/accumulate-order-history/modal-detail-order";

import "./index.scss";

const OrderHistoryAccumulateAdmin = () => {
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [dataDetail, setDataDetail] = useState({});
  const [isOpenDetail, setIsOpenDetail] = useState(false);

  const { savingAccumulate } = useSelector((state) => state.accumulate);
  const { page, pagination, setPage } = usePagination(savingAccumulate?.total);

  const paramId = searchParams.get("id");

  const [filter, setFilter] = useState({
    isPaid: false,
    search: "",
    paymentMethod: PAYMENT_METHODS.COMPANY,
  });

  const { confirmDialog } = useConfirmContext();

  const handleFilter = (value) => {
    setFilter({
      ...filter,
      ...value,
    });

    setPage(1);
  };

  // Handle get list payment
  const loadListOrder = async () => {
    const isDeleteStatus = filter?.isPaid === "DELETED";

    try {
      setLoading(true);

      const params = {
        page: page,
        limit: 10,
        filter: {
          ...(!isDeleteStatus && {
            isPaid: filter?.isPaid,
          }),

          // ...(isDeleteStatus && {
          //   status: filter?.isPaid,
          // }),

          paymentMethod: filter?.paymentMethod,
        },

        search: filter?.search,
      };

      await dispatch(getAllSavingsPackage(params));
    } catch (error) {
      toast.error(error?.message);
      return;
    } finally {
      setLoading(false);
    }
  };

  const approvePayment = async (item) => {
    const isConfirm = await confirmDialog({
      messages: ["Bạn có chắc đơn hàng này đã thanh toán không?"],
    });

    if (!isConfirm) {
      return;
    }

    try {
      await accumulateApi.confirmPaymentSavingsPackage(item?.id);

      toast.success("Xác thực thanh toán thành công!");
      loadListOrder();
      setIsOpenDetail(false);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const deletePayment = async (item) => {
    const isConfirm = await confirmDialog({
      messages: ["Bạn có chắc xoá đơn hàng này không?"],
    });

    if (!isConfirm) {
      return;
    }

    const payload = {
      id: item?.id,
    };

    try {
      await accumulateApi.deleteOneSavingsPlan(payload);

      toast.success("Cập nhật trạng thái đơn hàng thành công!");
      loadListOrder();
      setIsOpenDetail(false);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const loadDetailPayments = async (id) => {
    try {
      const response = await accumulateApi.getOneSavingsPackage(id);

      setDataDetail(response);
      setIsOpenDetail(true);
    } catch (error) {
      toast.error(error?.message);
      return;
    }
  };

  const viewDetailOrder = (item) => {
    loadDetailPayments(item?.id);
  };

  const handleToggleModal = () => {
    setIsOpenDetail(!isOpenDetail);
    setDataDetail({});
  };

  useEffect(() => {
    loadListOrder();
  }, [filter, page]);

  useEffect(() => {
    if (paramId) {
      window.history.replaceState(
        null,
        "",
        INVEST_ROUTES.ACCUMULATE_PROGRAM_ORDER
      );

      if (paramId) {
        loadDetailPayments(paramId);
      }
    }
  }, [paramId]);

  return (
    <>
      <PageWrapper
        title="Lịch sử tích luỹ"
        breadcrumbs={[
          {
            title: "Lịch sử tích luỹ",
          },
        ]}
      >
        <TablePageContent>
          <div className="flex items-end justify-between mb-3">
            <span className="text-sm">
              Tổng: {savingAccumulate?.total || 0}
            </span>

            <TableFilter>
              <HistoryOrderFilterAccumulate
                filter={filter}
                handleFilter={handleFilter}
              />
            </TableFilter>
          </div>

          <OrderHistoryAccumulateTable
            data={savingAccumulate?.records}
            loading={loading}
            pagination={pagination}
            filter={filter}
            approvePayment={approvePayment}
            deletePayment={deletePayment}
            viewDetailOrder={viewDetailOrder}
          />
        </TablePageContent>
      </PageWrapper>

      <ModalDetailAccumulateOrder
        isOpen={isOpenDetail}
        data={dataDetail}
        toggle={handleToggleModal}
        approvePayment={approvePayment}
      />
    </>
  );
};

export default OrderHistoryAccumulateAdmin;
