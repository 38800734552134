import { LOCALE, LANGUAGE_OPTION } from "constants";
import { createContext, useContext, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import localUtils from "utility/local-utils";

const Context = createContext();

export const langs = [
  {
    lang: LANGUAGE_OPTION.EN.LANG,
    icon: <ReactCountryFlag className="country-flag" countryCode="en" svg />,
  },

  {
    lang: LANGUAGE_OPTION.VN.LANG,
    icon: <ReactCountryFlag className="country-flag" countryCode="vn" svg />,
  },
];

export const DEFAULT_LANG = LANGUAGE_OPTION.VN.LANG;

const Language = ({ children }) => {
  const { i18n, t } = useTranslation();

  const [currentLangs, setCurrentLang] = useState(
    localUtils.get(LOCALE) || DEFAULT_LANG
  );

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setCurrentLang(lang);
    localUtils.set(LOCALE, lang);
    window.location.reload();
  };

  return (
    <Context.Provider
      value={{
        t,
        langs,
        currentLangs,
        changeLanguage,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Language;

export const useLang = () => useContext(Context);
