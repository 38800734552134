// ** React Import
import { useCallback } from "react";

// ** Third Party Component
import { Button } from "reactstrap";

// ** Custom Component Import
import ModalSystem from "../modal-system";
import ViewCondition from "components/utils/view-condition";

// ** Style Import
import "./index.scss";

const ConfirmSystem = (props) => {
  const { config, onCloseConfirm, ...restProps } = props;
  const {
    title,
    messages,
    Component,
    onCancel,
    onConfirm,
    hasFooter = true,
    okButtonProps,
    cancelButtonProps,
    componentProps,
  } = config;

  const handleConfirmClose = () => {
    onCloseConfirm?.();
    onCancel?.();
  };

  const handleConfirmButtonClick = (context) => {
    onConfirm?.(context);

    onCloseConfirm?.();
  };

  const FooterComponent = useCallback(() => {
    if (!hasFooter) {
      return null;
    }

    return (
      <div className="confirm-system__footer">
        <Button
          className="confirm-system__footer-confirm"
          color={okButtonProps.color}
          onClick={() => handleConfirmButtonClick(true)}
        >
          {okButtonProps.text}
        </Button>

        <Button
          className="confirm-system__footer-cancel"
          color={cancelButtonProps.color}
          onClick={handleConfirmClose}
        >
          {cancelButtonProps.text}
        </Button>
      </div>
    );
  }, [okButtonProps, cancelButtonProps, handleConfirmButtonClick, hasFooter]);

  return (
    <ModalSystem
      size="md"
      {...restProps}
      header={title}
      className="confirm-system mx-auto w-full md:w-[500px]"
      footer={hasFooter ? <FooterComponent /> : null}
      onClosed={handleConfirmClose}
    >
      <ViewCondition rIf={messages?.length}>
        {messages.map((message, index) => (
          <p key={index} className="confirm-system__message">
            {message}
          </p>
        ))}
      </ViewCondition>

      <ViewCondition rIf={Component && !messages?.length}>
        <Component
          onCancel={handleConfirmClose}
          onConfirm={handleConfirmButtonClick}
          {...componentProps}
        />
      </ViewCondition>
    </ModalSystem>
  );
};

export default ConfirmSystem;
