import classNames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Form, Modal, ModalBody, ModalHeader } from "reactstrap";

import ViewCondition from "components/utils/view-condition";
import ViewDevice from "components/utils/view-device";
import { X } from "lucide-react";
import { ChevronLeft } from "react-feather";
import { DEVICE_DETECT } from "utility/hooks/useResponsive";
import "./index.scss";

const DrawerSystem = (props) => {
  const {
    onClose,
    header = null,
    actions = null,
    hasClose = false,
    bodyClassName,
    wrapperClassName,
    className,
    size = "sm",
    headerClassName,
    children,
    form,
    onSubmit,
    ...rest
  } = props;

  const formSubmit = (formData) => {
    onSubmit?.(formData);
  };

  return (
    <Modal
      toggle={onClose}
      contentClassName="overflow-hidden drawer-system"
      modalClassName={classNames("modal-slide-in drawer-system-wrapper", {
        [wrapperClassName]: wrapperClassName,
      })}
      className={classNames({
        [className]: className,
        "sidebar-xl": size === "xl",
        "sidebar-lg": size === "lg",
        "sidebar-sm": size === "sm",
        "sidebar-full": size === "full",
      })}
      dia
      {...rest}
    >
      <ViewCondition rIf={form}>
        <Form
          {...form}
          onSubmit={form?.handleSubmit?.(formSubmit)}
          className="h-full overflow-hidden flex flex-col"
        >
          <ModalHeader
            className={classNames({
              [headerClassName]: headerClassName,
            })}
            close={null}
            toggle={onClose}
          >
            <div className="flex justify-between items-center w-full gap-x-4 whitespace-nowrap">
              <div className="truncate ... flex items-center justify-start gap-x-2">
                <div onClick={onClose} className="md:hidden visible block">
                  <ChevronLeft className="w-6 h-6 text-primary" />
                </div>

                {header}
              </div>

              <ViewDevice is={[DEVICE_DETECT.MOBILE, DEVICE_DETECT.TABLET]}>
                {actions}
              </ViewDevice>
            </div>
          </ModalHeader>

          <PerfectScrollbar options={{ wheelPropagation: false }}>
            <ModalBody className="h-full pb-4">{children}</ModalBody>
          </PerfectScrollbar>

          <ViewDevice>
            <div className="p-4">{actions}</div>
          </ViewDevice>
        </Form>
      </ViewCondition>

      <ViewCondition rIf={!form}>
        <ModalHeader
          className={classNames({
            [headerClassName]: headerClassName,
          })}
          close={null}
          toggle={onClose}
        >
          <div className="flex flex-wrap justify-between items-center w-full">
            <div className="truncate ... flex items-center justify-start gap-x-2">
              {!hasClose && (
                <div onClick={onClose} className="md:hidden visible block">
                  <ChevronLeft className="w-6 h-6 text-primary" />
                </div>
              )}

              {header}
            </div>

            <ViewDevice is={[DEVICE_DETECT.MOBILE, DEVICE_DETECT.TABLET]}>
              {actions}
            </ViewDevice>

            {hasClose && (
              <div
                onClick={onClose}
                className="bg-background-accent modal-header-close rounded-md p-1 md:block visible hidden cursor-pointer"
              >
                <X className="w-4 h-4" />
              </div>
            )}
          </div>
        </ModalHeader>

        <PerfectScrollbar
          options={{ wheelPropagation: false }}
          className="pb-4"
        >
          <ModalBody className={classNames("h-full", bodyClassName)}>
            {children}
          </ModalBody>
        </PerfectScrollbar>

        <ViewDevice>
          <div className="p-4">{actions}</div>
        </ViewDevice>
      </ViewCondition>
    </Modal>
  );
};

export default DrawerSystem;
