'use client';

import MobileDetect from 'mobile-detect';
import { DEVICE_DETECT } from '../useResponsive';

const useDeviceDetect = () => {
    if (typeof window !== 'undefined') {
        const userAgent = window.navigator.userAgent
        const userAgentAllowSettingRequestDesktopWebsite = userAgent.toLowerCase()

        const deviceDetectInfo = new MobileDetect(userAgent)

        const isIphone = (
            userAgentAllowSettingRequestDesktopWebsite.indexOf('ipad') > -1 ||
            userAgentAllowSettingRequestDesktopWebsite.indexOf('iphone') > -1 ||
            (
                'ontouchend' in document &&
                userAgentAllowSettingRequestDesktopWebsite.indexOf('macintosh') > -1
            )
        )

        const isIpad = (
            userAgentAllowSettingRequestDesktopWebsite.indexOf('ipad') > -1 ||
            (
                'ontouchend' in document &&
                userAgentAllowSettingRequestDesktopWebsite.indexOf('macintosh') > -1
            )
        )

        const isTabletDevice = !!deviceDetectInfo.tablet() || isIpad
        const isMobileDevice = !isTabletDevice && (!!deviceDetectInfo.phone() || isIphone)
        const isDesktopDevice = !isTabletDevice && !isMobileDevice

        const deviceType = (() => {
            if (isDesktopDevice) {
                return DEVICE_DETECT.DESKTOP;
            }

            if (isMobileDevice || isIphone) {
                return DEVICE_DETECT.MOBILE;
            }

            if (isTabletDevice || isIpad) {
                return DEVICE_DETECT.TABLET;
            }
        })()


        const normalizedUserAgent = userAgent.toLowerCase();
        const standalone = navigator.standalone;
        const isIos = /ip(ad|hone|od)/.test(normalizedUserAgent) || navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;
        const isAndroid = /android/.test(normalizedUserAgent);
        const isSafari = /safari/.test(normalizedUserAgent);
        const isWebview = (isAndroid && /; wv\)/.test(normalizedUserAgent)) || (isIos && !standalone && !isSafari);


        return {
            isWebview,
            deviceType,
            isIpad,
            isIphone,
            isTabletDevice,
            isMobileDevice,
            isDesktopDevice,
        }
    }
};

export default useDeviceDetect;