import { LOCALE } from "constants";

const localUtils = {
  set: (key, value) => {
    localStorage.setItem(key, value);
  },

  get: (key) => {
    if (typeof localStorage !== "undefined") {
      return localStorage.getItem(key);
    }
    return null;
  },

  remove: (key) => {
    localStorage.removeItem(key);
  },

  clearAll: () => {
    Object.values(LOCALE).forEach((key) => localUtils.remove(key));
  },
};

export default localUtils;
