import { createAsyncThunk } from "@reduxjs/toolkit"
import { stockApi } from "modules/shop/stock-products/apis"

// stock
export const getAllShopProductWarehouse = createAsyncThunk(
    "/stock/getAllShopProductWarehouse",
    async (query) => {
        try {
            const response = await stockApi.getAllShopProductWarehouse(query)
            return response
        } catch (error) {
            throw new Error(error)
        }
    })

export const stocks = {
    list: [],
    total: 0,
}

export const stockBuilder = (builder) => {
    builder.addCase(getAllShopProductWarehouse.fulfilled, (state, { payload }) => {
        state.stocks.list = payload?.data || [];
        state.stocks.total = payload?.total || 0;
    });
}