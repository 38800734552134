/* eslint-disable no-unsafe-optional-chaining */
// ** React Imports

// ** Third Party Components
import { useEffect } from "react";
import styled from "styled-components";
import useDebounce from "utility/hooks/useDebounce";
import { Controller, useForm } from "react-hook-form";
import { Form, FormGroup, Label } from "reactstrap";

// ** Styles
import InputSystem from "components/e-control/forms/form-elements/input-system";
import SelectSystem from "components/e-control/forms/form-elements/select-system";

import { PAYMENT_METHODS } from "modules/invest/client/components/buy-form";

import "./index.scss";

const StyleSelect = styled(SelectSystem)`
  .select__single-value {
    color: rgb(23 37 84 / var(--tw-text-opacity)) !important;
  }
`;

const HistoryOrderFilterAccumulate = ({ filter, handleFilter, onClose }) => {
  const { handleSubmit, control, watch } = useForm({
    defaultValues: {
      ...filter,
    },
  });

  const { search, isPaid, paymentMethod } = watch();

  useDebounce(
    () => {
      handleFilter({
        search,
      });
    },
    [search],
    400
  );

  useEffect(() => {
    handleFilter({
      isPaid,
    });
  }, [isPaid]);

  useEffect(() => {
    handleFilter({
      paymentMethod,
    });
  }, [paymentMethod]);

  const onSubmit = (data) => {
    const { search, isPaid, paymentMethod } = data;

    handleFilter({
      ...filter,
      isPaid: isPaid,
      paymentMethod,
      search: search,
    });
  };

  return (
    <Form
      className="invest-filter-admin lg:px-3 px-0 lg:flex-row flex-col"
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormGroup className="booking-filter__group">
        <Label className="booking-filter__group-label text-primary" sm={12}>
          Tìm kiếm
        </Label>
        <Controller
          name="search"
          control={control}
          render={({ field }) => (
            <InputSystem
              id="search"
              name="search"
              placeholder="Tìm theo mã đơn hàng"
              type="text"
              className="custom-input-search "
              {...field}
            />
          )}
        />
      </FormGroup>

      <FormGroup className="booking-filter__group">
        <Label className="booking-filter__group-label text-primary" sm={12}>
          Phương thức thanh toán{" "}
        </Label>
        <Controller
          name="paymentMethod"
          control={control}
          render={({ field }) => (
            <StyleSelect
              {...field}
              data={[
                {
                  value: PAYMENT_METHODS.EWALLET,
                  label: "Ví EGO",
                },

                {
                  value: PAYMENT_METHODS.COMPANY,
                  label: "Chuyển khoản",
                },
              ]}
              classNamePrefix="select"
              placeholder="Chọn phương thức thanh toán"
            />
          )}
        />
      </FormGroup>

      <FormGroup className="booking-filter__group">
        <Label className="booking-filter__group-label text-primary" sm={12}>
          Trạng thái
        </Label>
        <Controller
          name="isPaid"
          control={control}
          render={({ field }) => (
            <StyleSelect
              {...field}
              data={[
                {
                  value: false,
                  label: "Chưa thanh toán",
                },

                {
                  value: true,
                  label: "Đã thanh toán",
                },

                // {
                //   value: "DELETED",
                //   label: "Đã xoá",
                // },
              ]}
              classNamePrefix="select"
              placeholder="Chọn trạng thái"
            />
          )}
        />
      </FormGroup>
    </Form>
  );
};

export default HistoryOrderFilterAccumulate;
