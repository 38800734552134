/* eslint-disable react/no-unescaped-entities */
import { CopyCheck } from "lucide-react";
import Utils from "utility/time";

const TabCopy = ({ info }) => {
  return (
    <div>
      <h3 className="text-primary">
        Hãy chuyển khoản đến tài khoản ngân hàng theo thông tin dưới đây để
        thanh toán
      </h3>

      <div className="flex flex-col gap-4 mt-4">
        <div className="space-y-2">
          <h4 className="text-black">Chủ tài khoản</h4>
          <p className="text-primary line-clamp-1 flex items-center justify-between">
            {info?.companyName}{" "}
            <CopyCheck
              size={16}
              color="#198754"
              onClick={() => Utils.copyToClipboard(info?.companyName)}
            />
          </p>
        </div>

        <div className="space-y-2">
          <h4 className="text-black">Số tài khoản</h4>
          <p className="text-primary line-clamp-1 flex items-center justify-between">
            {info?.companyNum}
            <CopyCheck
              size={16}
              color="#198754"
              onClick={() => Utils.copyToClipboard(info?.companyNum)}
            />
          </p>
        </div>

        <div className="space-y-2">
          <h4 className="text-black">Tên ngân hàng</h4>
          <p className="text-primary line-clamp-1 flex items-center justify-between">
            {info?.companyBank}

            <CopyCheck
              size={16}
              color="#198754"
              onClick={() => Utils.copyToClipboard(info?.companyBank)}
            />
          </p>
        </div>

        <div className="space-y-2">
          <h4 className="text-black">Nội dung chuyển khoản</h4>
          <p className="text-primary line-clamp-1 flex items-center justify-between">
            {info?.content}

            <CopyCheck
              size={16}
              color="#198754"
              onClick={() => Utils.copyToClipboard(info?.content)}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default TabCopy;
