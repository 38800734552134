import { DragItem, DropList } from "components/utils/drag-drop";
import Table from "rc-table";

const BodyRow = (props) => {
  return (
    <DragItem
      key={props["data-row-key"]?.index || 0}
      draggableId={props["data-row-key"]?.row?.id || ""}
      index={props["data-row-key"]?.index || 0}
      className="cursor-move"
      tab="tr"
    >
      {props.children}
    </DragItem>
  );
};

const LargeViewPortTable = (tableProps) => {
  const { isDragable, onDragEnd, ...restTableProps } = tableProps;

  return (
    <DropList dragEnd={onDragEnd}>
      <Table
        rowKey={(row, index) => ({ row, index })}
        {...restTableProps}
        components={{
          body: {
            row: isDragable ? BodyRow : null,
          },
        }}
      />
    </DropList>
  );
};

export default LargeViewPortTable;
