import classNames from 'classnames'

import {
    DragDropContext,
    Draggable,
    Droppable
} from 'react-beautiful-dnd'

export const DropList = ({
    children,
    className,
    dropListId = 'list',
    dragEnd,
    ...restProps
}) => {
    const getListStyle = (snapshot) => {
        if (snapshot.isDraggingOver) {
            const listElement = document.querySelector(
                `[data-rbd-droppable-id="${dropListId}"]`
            )

            if (listElement) {
                return {
                    height: `${listElement.clientHeight}px`,
                }
            }
        }

        return {}
    }

    const handleDragEnd = (result) => {
        dragEnd?.({
            draggableId: result.draggableId,
            sourceIndex: result.source.index,
            destinationIndex: result.destination?.index,
        })
    }

    return (
        <DragDropContext onDragEnd={handleDragEnd} {...restProps}>
            <Droppable droppableId={dropListId}>
                {(provided, snapshot) => (
                    <div
                        className={classNames(className)}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={getListStyle(snapshot)}
                    >
                        {children}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}

export const DragItem = (props) => {
    // ** props
    const { children, className, tab = 'div', ...dragProps } = props

    console.log(dragProps)

    const Tab = tab

    return (
        <Draggable {...dragProps} className="w-full">
            {(provided) => (
                <Tab
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={classNames('', className)}
                >
                    {children}
                </Tab>
            )}
        </Draggable>
    )
}
