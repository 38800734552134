import { ROLE, ACCOUNT_BUSINESS_TYPE, TRANSPORTATION_BUSINESS_TYPES } from "constants";
import TestMap from "modules/ego-system/test-map"
import { lazy } from "react"
import { KEYS } from "utility/permission/key-config";

const MapPage = lazy(() => import('modules/ego-system/maps'))
const DriverMapPage = lazy(() => import('modules/ego-system/gg-map/components/driver-map'));
const PickMarkerMap = lazy(() => import('modules/ego-system/gg-map/components/pick-marker-map'));

export const MAP_ROUTES = {
    MAIN: '/driver-map',
    GG_MAP: '/pick-marker',
    MAP_TEST: '/test-map/123123',
    GG_DRIVER_MAP: '/driver-map',
}

const MapRoutes = [
    {
        path: MAP_ROUTES.MAIN,
        element: <DriverMapPage />,
        title: 'Bản đồ điều hướng xe',
        meta: {
            permissionKey: KEYS.EGO.MAP,
        }
    },

    {
        path: MAP_ROUTES.GG_MAP,
        element: <PickMarkerMap />,
        meta: {
            permissionKey: KEYS.EGO.MAP,
        }
    },

    {
        path: MAP_ROUTES.MAP_TEST,
        element: <TestMap />,
        meta: {
            permissionKey: KEYS.EGO.MAP,
        }
    },

    {
        path: MAP_ROUTES.GG_DRIVER_MAP,
        element: <DriverMapPage />,
        meta: {
            permissionKey: KEYS.EGO.MAP,
        }
    }
]

export default MapRoutes