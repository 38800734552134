// ** React Import
import { useMemo } from "react";

// ** Third Party Component

// ** Style Import
import classNames from "classnames";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import "./index.scss";

const DropdownSystem = (props) => {
  // ** Props
  const {
    children,
    itemClass,
    bodyClass,
    className,
    options = [],
    valueKey = "value",
    labelKey = "label",
    moreItem,
    direction = 'bottom',
    noBorder = true,
    onMenuSelect,
  } = props;

  const menuItems = useMemo(() => {
    if (Array.isArray(options) && !options.length) {
      return [];
    }

    return options.map((item) => ({
      label: item[labelKey],
      value: item[valueKey],
    }));
  }, [valueKey, labelKey, options]);

  const handleItemSelected = (event, selectValue) => {
    onMenuSelect?.(selectValue);
  };

  return (
    <UncontrolledDropdown
      className="me-2"
      direction={direction}
    >
      <DropdownToggle
        color="primary"
        className={classNames(["p-0 !bg-transparent focus:!bg-transparent hover:none", className, noBorder && "border-0"])}
      >
        {children}
      </DropdownToggle>
      <DropdownMenu container="body" className={bodyClass}>
        {!!menuItems.length && menuItems.map((item, index) => (
          <DropdownItem key={index} className={classNames(["w-full text-sm", itemClass])}
            onClick={(event) => handleItemSelected(event, item.value)}
            onTouchEnd={(event) => handleItemSelected(event, item.value)}
          >
            {item.label}
          </DropdownItem>
        ))}

        {moreItem}

        {
          !menuItems.length && (
            <DropdownItem className={classNames(["w-full text-sm", itemClass])}>
              Không có dữ liệu
            </DropdownItem>
          )
        }
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

DropdownSystem.DropdownItem = DropdownItem

export default DropdownSystem;
