import { createContext, useContext, useEffect, useRef } from "react";
import { renderToStaticMarkup } from "react-dom/server";

import classNames from "classnames";

import PaginationSystem from "components/e-control/pagination";

import ComponentSpinner from "components/controls/spinner/Loading-spinner";
import ViewDevice from "components/utils/view-device";
import { DEVICE_DETECT } from "utility/hooks/useResponsive";
import "./index.scss";
import LargeViewPortTable from "./large-viewport";
import MobileTableViewPort from "./mobile-viewport";

const TableSystem = (props) => {
  // ** Props
  const {
    pagination,
    containerClassName,
    tableClassName,
    loading = false,
    isMobileView = true,
    template,
    children,
    ...tableProps
  } = props;

  // ** Refs
  const tableRef = useRef();

  useEffect(() => {
    const tableBody = tableRef.current.querySelector(".rc-table-content");
    const loadingElement = document.createElement("div");
    loadingElement.className = "table-system__loading";
    const loadingContainer = renderToStaticMarkup(<ComponentSpinner />);

    loadingElement.innerHTML = loadingContainer;

    tableBody?.appendChild(loadingElement);

    return () => {
      tableBody?.removeChild(loadingElement);
    };
  }, []);

  useEffect(() => {
    const loadingElement = tableRef.current.querySelector(
      ".table-system__loading"
    );

    if (loadingElement) {
      if (loading) {
        loadingElement.classList.add("show");
      } else {
        loadingElement.classList.remove("show");
      }
    }
  }, [loading]);

  return (
    <>
      <div
        ref={tableRef}
        className={classNames([
          "table-system overflow-hidden h-full flex flex-col justify-between pb-2",
          containerClassName,
        ])}
      >
        {isMobileView && (
          <div className="md:pb-0">
            <ViewDevice is={[DEVICE_DETECT.DESKTOP, DEVICE_DETECT.TABLET]}>
              <LargeViewPortTable
                id="table-system-ref"
                useFixedHeader={true}
                emptyText={
                  <p className="table-system__empty">Chưa có dữ liệu</p>
                }
                className={classNames([
                  "table-system__table overflow-y-auto flex-1 flex flex-col",
                  tableClassName,
                ])}
                {...tableProps}
              />
            </ViewDevice>

            <ViewDevice is={[DEVICE_DETECT.MOBILE]}>
              <MobileTableViewPort template={template} {...tableProps}>
                {children}
              </MobileTableViewPort>
            </ViewDevice>
          </div>
        )}

        {!isMobileView && (
          <LargeViewPortTable
            id="table-system-ref"
            useFixedHeader={true}
            emptyText={<p className="table-system__empty">Chưa có dữ liệu</p>}
            className={classNames([
              "table-system__table overflow-y-auto flex-1 flex flex-col pb-[40px] md:pb-0",
              tableClassName,
            ])}
            {...tableProps}
          />
        )}

        {!!tableProps?.data?.length && (
          <div className="table-system__pagination flex justify-center mt-0 mb-2 md:mb-0 md:mt-4">
            <PaginationSystem
              {...pagination}
              onPageChange={(page) => {
                const handleScrollToTop = () => {
                  const appContent = document.querySelector(".app-content");
                  if (!appContent || appContent.scrollTop === 0) {
                    return;
                  }

                  appContent.scrollTo({ top: 0, behavior: "smooth" });
                };

                handleScrollToTop();
                pagination?.onPageChange(page);
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default TableSystem;

const TableContext = createContext();

export const TableWrapperContext = ({ children, value }) => {
  return (
    <TableContext.Provider value={value}>{children}</TableContext.Provider>
  );
};

export const useTableWrapperContext = () => {
  return useContext(TableContext);
};
