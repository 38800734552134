import { useSubscription } from "@apollo/client";

const useSocket = (action, variables = {}, type) => {
    const { data } = useSubscription(
        action,
        {
            variables: {...variables},
        }
    )

    return data?.[type] || data;
};

export default useSocket;