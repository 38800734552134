import { gql } from "@apollo/client";
import { query } from "configs/http-config";

const dashboardAction = {
  getAllBooking: gql`
    query getAllBooking {
      getAllBooking {
        getStatBooking
      }
    }
  `,

  getStatForDashboard: gql`
    query getStatForDashboard($workPlaceId: String) {
      getStatForDashboard(workPlaceId: $workPlaceId)
    }
  `,

  getStatDashboardForAdmin: gql `
    query getStatDashboardForAdmin {
      getStatDashboardForAdmin
    }
  `
};

export const dashboardApi = {
  getAllBooking: (payload) => {
    return query("getAllBooking", dashboardAction.getAllBooking, payload);
  },

  getStatForDashboard: (payload) => {
    return query(
      "getStatForDashboard",
      dashboardAction.getStatForDashboard,
      payload
    );
  },

  getStatDashboardForAdmin: (payload) => {
    return query(
      "getStatDashboardForAdmin",
      dashboardAction.getStatDashboardForAdmin,
      payload
    );
  },
};
