import { gql } from "@apollo/client";
import { mutate, query } from "configs/http-config";

const incurredShema = {
  getAllReasonIncurred: gql`
    query getAllReasonIncurred(
      $page: Int
      $limit: Int
      $filter: Mixed
      $order: Mixed
      $search: String
    ) {
      getAllReasonIncurred(
        q: {
          limit: $limit
          page: $page
          filter: $filter
          order: $order
          search: $search
        }
      ) {
        data {
          cleanFee
          createdAt
          deodoriFee
          desCleanFee
          desDeodoriFee
          desOverlimtFee
          desOvertimeFee
          id
          overlimitFee
          overtimeFee
          partner {
            id
            name
          }
          partnerId
          type
        }
        pagination {
          page
          total
        }
        total
      }
    }
  `,

  getDescriptionForReasonIncurred: gql`
    query getDescriptionForReasonIncurred {
      getDescriptionForReasonIncurred
    }
  `,

  getOneReasonIncurredByType: gql`
    query getOneReasonIncurredByType($type: String!) {
      getOneReasonIncurredByType(type: $type) {
        cleanFee
        createdAt
        deodoriFee
        desCleanFee
        desDeodoriFee
        desOverlimtFee
        desOvertimeFee
        id
        overlimitFee
        overtimeFee
        partnerId
        type
        updatedAt
      }
    }
  `,

  createReasonIncurred: gql`
    mutation createReasonIncurred($data: CreateReasonIncurredInput!) {
      createReasonIncurred(data: $data) {
        id
      }
    }
  `,

  updateReasonIncurred: gql`
    mutation updateReasonIncurred($id: ID!, $data: UpdateReasonIncurredInput!) {
      updateReasonIncurred(id: $id, data: $data) {
        id
      }
    }
  `,

  deleteOneReasonIncurred: gql`
    mutation deleteOneReasonIncurred(
      $id: ID!
    ) {
      deleteOneReasonIncurred(id: $id) {
        id
      }
    }
  `,
};

export const incurredPartnerApi = {
  getAllReasonIncurred: (payload) => {
    return query(
      "getAllReasonIncurred",
      incurredShema.getAllReasonIncurred,
      payload
    );
  },

  getDescriptionForReasonIncurred: () => {
    return query(
      "getDescriptionForReasonIncurred",
      incurredShema.getDescriptionForReasonIncurred
    );
  },

  createReasonIncurred: (payload) => {
    return mutate(
      "createReasonIncurred",
      incurredShema.createReasonIncurred,
      payload
    );
  },

  updateReasonIncurred: (payload) => {
    return mutate(
      "updateReasonIncurred",
      incurredShema.updateReasonIncurred,
      payload
    );
  },

  deleteOneReasonIncurred: (payload) => {
    return mutate(
      "deleteOneReasonIncurred",
      incurredShema.deleteOneReasonIncurred,
      payload
    );
  },

  getOneReasonIncurredByType: (payload) => {
    return query(
      "getOneReasonIncurredByType",
      incurredShema.getOneReasonIncurredByType,
      payload
    );
  },
};
