import { useEffect, useState } from "react";

// ** Utils

// ** Constants

// ** Third Party Components
import { Filter } from "react-feather";

// ** Style Import
import ButtonSystem from "components/e-control/button-system";
import InputSystem from "components/e-control/forms/form-elements/input-system";
import SelectSystem from "components/e-control/forms/form-elements/select-system";
import { HOTLINE_OPTIONS } from "modules/ego-system/hotline/constants";
import { setType } from "modules/ego-system/hotline/store";
import { dispatch } from "root-stores";
import "./index.scss";

const HotlineBookingFilter = ({ onFilter, initialFilter }) => {
  const [filter, setFilter] = useState({
    bookingCode: "",
    bookingType: HOTLINE_OPTIONS.BOOKING_CREATE_TYPE.BOOKING,
  });

  const bookingTypeOptions = [
    {
      label: "Booking",
      value: HOTLINE_OPTIONS.BOOKING_CREATE_TYPE.BOOKING,
    },
    {
      label: "Yêu cầu",
      value: HOTLINE_OPTIONS.BOOKING_CREATE_TYPE.REQUEST,
    },
  ];

  const handleFilter = () => {
    dispatch(setType(filter.bookingType));

    onFilter?.({
      search: filter.bookingCode,
      type: filter.bookingType,
    });
  };

  useEffect(() => {
    if (initialFilter) {
      console.log(initialFilter)
      setFilter({ ...filter, ...initialFilter });
    }
  }, []);

  return (
    <div className="hotline-filter">
      <InputSystem 
        type="text"
        placeholder="Tìm kiếm mã chuyến xe"
        className="hotline-filter__input"
        onChange={(event) => setFilter({ ...filter, bookingCode: event.target.value })}
      />

      <SelectSystem
        isClearable={false}
        value={filter.bookingType || HOTLINE_OPTIONS.BOOKING_CREATE_TYPE.BOOKING}
        data={bookingTypeOptions}

        classNamePrefix="select"
        className="react-select"
        placeholder="Chọn loại booking"
        onChange={(value) => setFilter({ ...filter, bookingType: value })}
      />

      <ButtonSystem color="primary" onClick={handleFilter}>
        <Filter size={16} />
      </ButtonSystem>
    </div>
  );
};

export default HotlineBookingFilter;
