const MAP_OPTIONS = {
    DEFAULT_ZOOM: 5,
    BOUND: {
        VIETNAM: {
            west: 101.486552,
            south: 8.59975962975,
            east: 114.0465341,
            north: 23.3520633001,
        }
    },

    DEFAULT_MARKER: {
        EARTH_OCEAN: {
            LOCATION: [114.12310249583375, 14.454570445060451],
            TEXT: "Biển Đông",
            TYPE: 'sea-marker'
        },

        PARACEL_ISLAND: {
            LOCATION: [112.76801361775169, 16.307642142800436],
            TEXT: 'Quần Đảo Hoàng Sa',
            TYPE: 'island-marker'
        },

        SPRATLY_ISLAND: {
            LOCATION: [115.82646556466112, 10.72479549861769],
            TEXT: 'Quần Đảo Trường Sa',
            TYPE: 'island-marker'
        },
    },

    BOOKING_TYPE: {
        EXPRESS: 1,
        TAXI: 2,
    },

    BOOKING_STATUS: {
        PENDING: 'PENDING',
        PROCESS: 'STARTING',
        READY: 'READY',
        ARRIVED: 'ARRIVED',
        COMPLETED: 'SUCCESS',
        CANCEL: 'CANCEL',
        REFUSED: 'REFUSED',
    },

    UPDATE_BOOKING_STATUS: {
        READY: 'READY',
        CANCEL: 'CANCEL',
        SUCCESS: 'SUCCESS',
        STARTING: 'STARTING',
        REFUSED: 'REFUSED',      
    },

    MAP_VIEW_TYPE: {
        DRIVERS: 1,
        BOOKINGS: 2,
        DRIVER_PICK_UP: 3,
        BOOKING_DETAIL: 4,
    },

    MAP_CONFIG: {
        DEFAULT_CENTER: [95.228857, 15.5549266],
        DEFAULT_ZOOM: 6,
    },

    DRIVER_STATUS: {
        ON_TRIP: 'ON_TRIP',
        NAVIGATING: 'NAVIGATING',
        NORMAL: 'WAITING_FOR_TRIP',
    },

    DRIVER_STATUS_TYPE: {
        UPDATE_STATUS: "UPDATE_STATUS",
        UPDATE_ISACTIVED: "UPDATE_ISACTIVED",
    },

    BOOKING_LOGS: {
        NEW_BOOKING: {
            TEXT: 'Receive Booking',
        },
        NAVIGATING: {
            TEXT: 'Book Driver',
        },
        DRIVER_ACCEPT_BOOKING: {
            TEXT: 'Driver accepted booking',
        },
        ARRIVED: {
            TEXT: 'Driver arrived',
        },
        START_TRIP: {
            TEXT: 'The trip started',
        },
        FINISH_TRIP: {
            TEXT: 'The trip completed',
        },
        CLIENT_CANCEL_BOOKING: {
            TEXT: 'Client canceled'
        },
        DRIVER_REFUSE_BOOKING: {
            TEXT: 'Driver canceled the booking'
        },

        DRIVER_CANCEL_BOOKING: {
            TEXT: 'Driver canceled the booking'
        }
    }
}

export default MAP_OPTIONS;