import { gql } from "@apollo/client";
import { query } from "configs/http-config";

const LocationAction = {
  getProvince: gql`
    query getProvince($countryId: ID) {
      getProvince(countryId: $countryId) {
        id
        province
      }
    }
  `,

  suggestCoordinate: gql`
    query suggestCoordinate($provinceId: ID, $districtId: ID) {
      suggestCoordinate(provinceId: $provinceId, districtId: $districtId)
    }
  `,

  getAllWorkPlace: gql`
    query getAllWorkPlace($q: QueryGetListInput) {
      getAllWorkPlace(q: $q) {
        data {
          id

          name
          location
          landMarkIds
          isAutoPickDriver
          totalNewClientByWeek
          totalPartnersByWorkPlaces
          isActived
          districtId
          serviceTypes
          provinceId
          carTypeIds
        }
        total
      }
    }
  `,

  getAllDistrictWOPaging: gql`
    query getAllDistrictWOPaging($workPlaceId: ID!) {
      getAllDistrictWOPaging(workPlaceId: $workPlaceId) {
        id
        name
      }
    }
  `,

  getAllCarFeatureWOPaging: gql`
    query getAllCarFeatureWOPaging {
      getAllCarFeatureWOPaging {
        icon
        id
        name
      }
    }
  `,

  getOneWorkPlace: gql`
    query getOneWorkPlace($id: ID!) {
      getOneWorkPlace(id: $id) {
        id
        location
        name
        isAutoPickDriver
        getStatWorkPlace
        createdAt
        serviceTypes
        updatedAt
      }
    }
  `,

  getAllLandMark: gql`
    query getAllLandMark($page: Int, $limit: Int, $filter: Mixed) {
      getAllLandMark(
        q: {
          limit: $limit
          page: $page
          order: { createdAt: -1 }
          filter: $filter
        }
      ) {
        data {
          id
          name
          address
          description
          workPlaceId
          location
          workPlace {
            name
          }
          images
          contactPhone

          tags {
            id
            name
          }
          tagIds
          operatingTime
          rate
          createdAt
          updatedAt
        }
        total
      }
    }
  `,

  getAllTag: gql`
    query getAllTag($page: Int, $limit: Int, $filter: Mixed) {
      getAllTag(
        q: {
          limit: $limit
          page: $page
          order: { createdAt: -1 }
          filter: $filter
        }
      ) {
        data {
          id
          name
          type
          createdAt
          updatedAt
        }
        total
      }
    }
  `,

  getAllWorkPlaceWOPaging: gql`
    query getAllWorkPlaceWOPaging {
      getAllWorkPlaceWOPaging {
        id
        name
        location
        landMarkIds
      }
    }
  `,

  getAllTagWOPaging: gql`
    query getAllTagWOPaging($type: String!) {
      getAllTagWOPaging(type: $type) {
        id
        name
        createdAt
        updatedAt
      }
    }
  `,

  getOneLandMark: gql`
    query getOneLandMark($id: ID!) {
      getOneLandMark(id: $id) {
        id
        name
        address
        description
        workPlaceId
        location
        workPlace {
          name
        }
        images
        contactPhone

        tags {
          id
          name
        }
        tagIds
        operatingTime
        rate
        createdAt
        updatedAt
      }
    }
  `,

  createWorkPlace: gql`
    mutation createWorkPlace($data: CreateWorkPlaceInput!) {
      createWorkPlace(data: $data) {
        id
        name
      }
    }
  `,

  createTag: gql`
    mutation createTag($data: CreateTagInput!) {
      createTag(data: $data) {
        id
        name
      }
    }
  `,

  createLandMark: gql`
    mutation createLandMark($data: CreateLandMarkInput!) {
      createLandMark(data: $data) {
        id
        name
      }
    }
  `,

  updateWorkPlace: gql`
    mutation updateWorkPlace($id: ID!, $data: UpdateWorkPlaceInput!) {
      updateWorkPlace(id: $id, data: $data) {
        id
        name
      }
    }
  `,

  updateTag: gql`
    mutation updateTag($id: ID!, $data: UpdateTagInput!) {
      updateTag(id: $id, data: $data) {
        id
        name
      }
    }
  `,

  updateLandMark: gql`
    mutation updateLandMark($id: ID!, $data: UpdateLandMarkInput!) {
      updateLandMark(id: $id, data: $data) {
        id
        name
      }
    }
  `,

  deleteOneWorkPlace: gql`
    mutation deleteOneWorkPlace($id: ID!) {
      deleteOneWorkPlace(id: $id) {
        id
        name
      }
    }
  `,

  deleteOneTag: gql`
    mutation deleteOneTag($id: ID!) {
      deleteOneTag(id: $id) {
        id
        name
      }
    }
  `,

  deleteOneLandMark: gql`
    mutation deleteOneLandMark($id: ID!) {
      deleteOneLandMark(id: $id) {
        id
        name
      }
    }
  `,

  suggestCoordinateByAddress: gql`
    mutation suggestCoordinateByAddress($address: String!) {
      suggestCoordinateByAddress(address: $address)
    }
  `,

  turnOnAutoPickDriver: gql`
    mutation turnOnAutoPickDriver($id: ID!) {
      turnOnAutoPickDriver(id: $id)
    }
  `,

  turnOffAutoPickDriver: gql`
    mutation turnOffAutoPickDriver($id: ID!) {
      turnOffAutoPickDriver(id: $id)
    }
  `,

  getAllCountry: gql`
    query GetAllCountry {
      getAllCountry {
        data {
          id
          name
          currencyUnit
          createdAt
          updatedAt
        }
        total
      }
    }
  `,
};

export const LocationApi = {
  getCountry: () => {
    return query("getAllCountry", LocationAction.getAllCountry);
  },

  getProvince: (payload) => {
    return query("getProvince", LocationAction.getProvince, payload);
  },

  suggestCoordinate: (payload) => {
    return query(
      "suggestCoordinate",
      LocationAction.suggestCoordinate,
      payload
    );
  },

  getAllWorkPlace: (payload) => {
    return query("getAllWorkPlace", LocationAction.getAllWorkPlace, payload);
  },

  getAllDistrict: (payload) => {
    return query(
      "getAllDistrictWOPaging",
      LocationAction.getAllDistrictWOPaging,
      payload
    );
  },

  getAllLandMark: (payload) => {
    return query("getAllLandMark", LocationAction.getAllLandMark, payload);
  },

  getAllTag: (payload) => {
    return query("getAllTag", LocationAction.getAllTag, payload);
  },

  getAllCarFeatureWOPaging: () => {
    return query(
      "getAllCarFeatureWOPaging",
      LocationAction.getAllCarFeatureWOPaging
    );
  },

  getAllWorkPlaceWOPaging: () => {
    return query(
      "getAllWorkPlaceWOPaging",
      LocationAction.getAllWorkPlaceWOPaging
    );
  },

  getAllTagWOPaging: (payload) => {
    return query(
      "getAllTagWOPaging",
      LocationAction.getAllTagWOPaging,
      payload
    );
  },

  getOneLandMark: (payload) => {
    return query("getOneLandMark", LocationAction.getOneLandMark, payload);
  },

  getOneWorkPlace: (payload) => {
    return query("getOneWorkPlace", LocationAction.getOneWorkPlace, payload);
  },

  createWorkPlace: (payload) => {
    return query("createWorkPlace", LocationAction.createWorkPlace, payload);
  },

  createTag: (payload) => {
    return query("createTag", LocationAction.createTag, payload);
  },

  createLandMark: (payload) => {
    return query("createLandMark", LocationAction.createLandMark, payload);
  },

  updateWorkPlace: (payload) => {
    return query("updateWorkPlace", LocationAction.updateWorkPlace, payload);
  },

  updateTag: (payload) => {
    return query("updateTag", LocationAction.updateTag, payload);
  },

  updateLandMark: (payload) => {
    return query("updateLandMark", LocationAction.updateLandMark, payload);
  },

  deleteOneWorkPlace: (payload) => {
    return query(
      "deleteOneWorkPlace",
      LocationAction.deleteOneWorkPlace,
      payload
    );
  },

  deleteOneTag: (payload) => {
    return query("deleteOneTag", LocationAction.deleteOneTag, payload);
  },

  deleteOneLandMark: (payload) => {
    return query(
      "deleteOneLandMark",
      LocationAction.deleteOneLandMark,
      payload
    );
  },

  suggestCoordinateByAddress: (payload) => {
    return query(
      "suggestCoordinateByAddress",
      LocationAction.suggestCoordinateByAddress,
      payload
    );
  },

  turnOnAutoPickDriver: (payload) => {
    return query(
      "turnOnAutoPickDriver",
      LocationAction.turnOnAutoPickDriver,
      payload
    );
  },

  turnOffAutoPickDriver: (payload) => {
    return query(
      "turnOffAutoPickDriver",
      LocationAction.turnOffAutoPickDriver,
      payload
    );
  },
};
