import TabCopy from "./tab-copy";
import TabScan from "./tab-scan";

import classNames from "classnames";
import ButtonSystem from "components/e-control/button-system";
import { useState } from "react";
import "./index.scss";

const TabEnum = {
  QR: 1,
  INFO: 2,
};

const FormInfoQrCode = ({ info, handleConfirmPayment }) => {
  const [tab, setTab] = useState(TabEnum.QR);

  return (
    <div className="custom-tab-qr pb-4">
      <div className="flex flex-col gap-y-8">
        <div className="flex items-center gap-x-3">
          <div
            className={classNames(
              "text-sm text-blue-950 flex-[2] py-2 rounded-md text-center",
              tab === TabEnum.QR && "bg-primary text-white"
            )}
            onClick={() => setTab(TabEnum.QR)}
          >
            Quét mã QR
          </div>
          <div
            className={classNames(
              "text-sm text-blue-950 flex-[2] py-2 rounded-md text-center",
              tab === TabEnum.INFO && "bg-primary text-white"
            )}
            onClick={() => setTab(TabEnum.INFO)}
          >
            Thông tin tài khoản
          </div>
        </div>
        
        <div className="h-[430px]">
          {tab === TabEnum.QR && (
            <TabScan handleConfirmPayment={handleConfirmPayment} info={info} />
          )}

          {tab === TabEnum.INFO && (
            <TabCopy handleConfirmPayment={handleConfirmPayment} info={info} />
          )}
        </div>

        <div
          className="space-y-1"
        >
          <ButtonSystem
            color="primary"
            className="w-full"
            onClick={handleConfirmPayment}
          >
            Tôi đã chuyển tiền
          </ButtonSystem>
          
          <p className="text-blue-950 text-center text-sm">
            Sau khi chuyển tiền thành công, Hãy ấn vào nút đã chuyển tiền để xác
            nhận
          </p>
        </div>

      </div>
    </div>
  );
};

export default FormInfoQrCode;
