import { Activity } from "lucide-react";
import React from "react";

const CardConvert = () => {
  return (
    <div className="mt-3">
      <h4 className="text-base text-[#eb9a3d] font-medium">WBUSDT là gì</h4>

      <div className="p-4 border border-gray-300 text-[#000] rounded-lg shadow-sm mt-1">
        <div className="flex justify-between items-center gap-x-2">
          <div className="flex items-center gap-x-1">
            <span>APR tham khảo</span>

            <Activity size={12} color="#0b823c" />
          </div>

          <p>Tỉ lệ chuyển đổi</p>
        </div>

        <div className="flex justify-between items-center gap-x-2 mt-1">
          <div className="flex items-center gap-x-1">
            <span className="text-primary text-2xl font-extrabold">2.74%</span>
          </div>

          <p className="bg-gray-200 rounded p-1 text-xs">
            1WBUSDT ~ 1.0489093USDT
          </p>
        </div>
      </div>
    </div>
  );
};

export default CardConvert;
