import moment from "moment";
import { useConfirmContext } from "components/containers/confirm-container";

import AccuMulateProgramTemplate from "./template";
import MoreDropdown from "components/views/more-dropdown";
import TooltipSystem from "components/e-control/tooltip-system";
import TableSystem, { TableWrapperContext } from "components/e-control/table";
import DropdownSystem from "components/e-control/forms/form-elements/dropdown-system";

import { DATE_TIME_FORMAT } from "constants";
import { formatNumberToAmount } from "utility/number-utils";

const AccumulateProgramTable = ({ onEdit, onDelete, ...props }) => {
  const { confirmDialog } = useConfirmContext();

  const handleDeleteClick = async (row) => {
    const result = await confirmDialog({
      messages: ["Bạn chắc chắn xóa chương trình này không?"],
      options: {},
    });

    if (result) {
      onDelete?.(row);
    }
  };

  const handleEditClick = (row) => {
    onEdit?.(row);
  };

  const menuDropdown = [
    {
      label: "Chỉnh sửa",
      value: handleEditClick,
    },

    {
      label: "Xoá",
      value: (row) => handleDeleteClick(row),
    },
  ];

  const generateMenuByStatus = (status) => {
    return menuDropdown.filter(
      (item) => !item.status || item.status.includes(status)
    );
  };

  const columns = [
    {
      dataIndex: "createdAt",
      key: "createdAt",
      title: "Thời gian",
      render: (item) => moment(item).format(DATE_TIME_FORMAT.DMY),
    },

    {
      dataIndex: "image",
      key: "image",
      title: "Hình ảnh",
      render: (item) => (
        <div className="flex items-center gap-x-2">
          <img
            src={item}
            alt={item}
            className="flex-0 object-cover size-10 rounded-full"
          />
        </div>
      ),
    },

    {
      dataIndex: "name",
      key: "name",
      title: "Chương trình",
      width: 300,
      render: (item) => (
        <TooltipSystem title={item}>
          <span className="line-clamp-1">{item}</span>
        </TooltipSystem>
      ),
    },

    {
      dataIndex: "ratePYear",
      key: "ratePYear",
      title: "Lãi suất(%)",
      className: "number",
      render: (item) => formatNumberToAmount(item * 100),
    },

    {
      dataIndex: "savingsTerm",
      key: "savingsTerm",
      title: "Kì hạn(tháng)",
      className: "number",
      render: (item) => item,
    },

    {
      dataIndex: "max",
      key: "max",
      title: "Tối đa",
      className: "number",
      render: (item) => formatNumberToAmount(item),
    },

    {
      dataIndex: "min",
      key: "min",
      title: "Tối thiểu",
      className: "number",
      render: (item) => formatNumberToAmount(item),
    },

    {
      dataIndex: "actions",
      key: "actions",
      title: "",
      fixed: "right",
      className: "actions",
      width: "50px",
      render: (_, row) => (
        <DropdownSystem
          options={generateMenuByStatus(row.status)}
          onMenuSelect={(callback) => callback?.(row)}
        >
          <MoreDropdown />
        </DropdownSystem>
      ),
    },
  ];

  return (
    <TableWrapperContext
      value={{
        menuDropdown: generateMenuByStatus,
      }}
    >
      <TableSystem
        {...props}
        template={AccuMulateProgramTemplate}
        columns={columns}
        scroll={{ x: 1280 }}
      />
    </TableWrapperContext>
  );
};
export default AccumulateProgramTable;
