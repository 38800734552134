import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { staffApi } from "../apis";
import { toast } from "react-hot-toast";
import { filterObject, formatPhoneNumberToServer } from "utils";

export const getAllManager = createAsyncThunk(
  "/staff/getAllManager",
  async (query) => {
    try {
      const response = await staffApi.getAllManager(query);
      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const createNewStaff = async (query) => {
  try {
    const payload = {
      name: query?.name,
      email: query?.email,
      phoneNumber: formatPhoneNumberToServer(query?.phone),
      address: query?.address,
      avatar: query?.avatar,
      workPlaceId: query?.workingProvinceId,
      role: query?.role,
      password: query?.password,
    };

    await staffApi.createManagerForAdmin(filterObject(payload));
  } catch (error) {
    throw new Error(error);
  }
};

export const staffSlice = createSlice({
  name: "staff",
  initialState: {
    staffs: {
      list: [],
      total: 0,
      getStatManager: {},

      // totalAllStaff: 0,
      // totalBookingManager: 0,
      // totalGeneralManager: 0,
      // totalHeadquaters: 0,
      // totalSupportManager: 0,
      // totalSwitchOperator: 0,
    },
  },

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getAllManager.fulfilled, (state, { payload }) => {
      console.log("payload :>>", payload);
      state.staffs.list = payload?.data || [];
      state.staffs.total = payload?.total || 0;
      state.staffs.getStatManager = payload?.getStatManager;
      //   payload?.getStatManager[0]?.totalAllStaff || 0;
      // state.staffs.totalBookingManager =
      //   payload?.getStatManager[0]?.totalBookingManager || 0;
      // state.staffs.totalHeadquaters =
      //   payload?.getStatManager[0]?.totalHeadquaters || 0;
      // state.staffs.totalSupportManager =
      //   payload?.getStatManager[0]?.totalSupportManager || 0;
      // state.staffs.totalGeneralManager =
      //   payload?.getStatManager[0]?.totalGeneralManager || 0;
      // state.staffs.totalSwitchOperator =
      //   payload?.getStatManager[0]?.totalSwitchboardOperator || 0;
      // state.staffs.totalPartnerManager =
      //   payload?.getStatManager[0]?.totalPartnerManager || 0;
    });
  },
});

export default staffSlice.reducer;
