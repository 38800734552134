import { Check, X } from "lucide-react";
import React, { useMemo, useState } from "react";
import { useAuth } from "components/containers/auth";

import StepKyc from "../step-kyc";
import StepRefLink from "../step-ref-link";
import StepRegister from "../step-register";
import Popup from "modules/invest/client/components/popup";

import banner from "../../../assets/banner-web3.svg";

import "./index.scss";
import { dispatch } from "root-stores";
import { getAccountInfo } from "modules/authentication/store";

const StepClaim = () => {
  const [isPopUpStep1, setIsPopUpStep1] = useState(false);
  const [isPopUpStep2, setIsPopUpStep2] = useState(false);
  const [isPopUpStep3, setIsPopUpStep3] = useState(false);

  const { user } = useAuth();

  const isKyc = useMemo(() => {
    return user?.eKycStatus !== "NOT_YET_AUTH";
  }, [user]);

  const isConnectUser = useMemo(() => {
    return user?.userConnect > 0;
  }, [user]);

  const handleCompleteSubmit = () => {
    setIsPopUpStep3(false);
    dispatch(getAccountInfo());
  };

  return (
    <>
      <div className="mt-3">
        <h4 className="text-black text-lg font-bold">
          Bắt đầu kiếm tiền chỉ với 3 bước
        </h4>

        <div className="container-step-web3">
          <div className="step pt-0 items-start">
            <div className="step-number -translate-y-3 -translate-x-[16px] rotate-45 ">
              <span className="rotate-[320deg]">1</span>
            </div>

            <div
              className="step-content -translate-y-3"
              onClick={() => setIsPopUpStep1(true)}
            >
              <div className="text-base  mb-0 flex items-center justify-between gap-x-3">
                <p className="flex-1 text-black font-semibold">
                  Tải App EGO đăng ký TK
                </p>
              </div>

              <p className="mt-1 text-gray-400">Xác minh số điện thoại</p>
            </div>

            <div className="-translate-y-3 w-[35px] h-[35px] border border-primary rounded-full flex items-center justify-center">
              <Check size={15} color="#0b823c" />
            </div>

            <div className="-translate-y-3 border border-primary px-4 py-2 rounded-lg text-sm text-primary w-16 h-[35px] font-bold flex items-center justify-center">
              +50
            </div>
          </div>

          <div className="step pt-0 items-start">
            <div className="step-number -translate-y-3 -translate-x-[16px] rotate-45">
              <span className="rotate-[320deg]">2</span>
            </div>

            <div
              className="step-content -translate-y-3"
              onClick={() => setIsPopUpStep2(true)}
            >
              <div className="text-base  mb-0 flex items-center justify-between gap-x-3">
                <p className="flex-1 text-black font-semibold">
                  Thực hiện eKYC
                </p>
              </div>

              <p className="mt-1 text-gray-400">Chụp 2 mặt CCCD + ảnh Selfie</p>
            </div>

            {isKyc ? (
              <div className="-translate-y-3 w-[35px] h-[35px] flex items-center justify-center border border-primary rounded-full">
                <Check size={15} color="#0b823c" />
              </div>
            ) : (
              <div className="-translate-y-3 w-[35px] h-[35px] flex items-center justify-center border border-[red] rounded-full">
                <X size={15} color="red" />
              </div>
            )}

            <div className="-translate-y-3 border border-primary px-4 py-2 rounded-lg text-sm text-primary w-16 h-[35px] flex items-center justify-center font-bold">
              +50
            </div>
          </div>

          <div className="step pt-0 items-start border-l-0">
            <div className="step-number -translate-y-3 -translate-x-[14px] rotate-45">
              <span className="rotate-[320deg]">3</span>
            </div>

            <div
              className="step-content -translate-y-3"
              onClick={() => setIsPopUpStep3(true)}
            >
              <div className="text-base  mb-0 flex items-center justify-between gap-x-3">
                <p className="flex-1 text-black font-semibold">
                  Chia sẻ mã giới thiệu
                </p>
              </div>

              <p className="mt-1 text-gray-400">
                Gữi mã giới thiệu cho bạn bè để nhận Token và chiết khấu dịch vụ
              </p>
            </div>

            {isConnectUser ? (
              <div className="-translate-y-3 w-[35px] h-[35px] flex items-center justify-center border border-primary rounded-full">
                <Check size={15} color="#0b823c" />
              </div>
            ) : (
              <div className="-translate-y-3 w-[35px] h-[35px] flex items-center justify-center border border-[red] rounded-full">
                <X size={15} color="red" />
              </div>
            )}

            <div className="-translate-y-3 border border-primary px-2 py-2 rounded-lg text-sm text-primary font-bold w-16 h-[35px] flex items-center justify-center">
              +5/user
            </div>
          </div>
        </div>
      </div>

      <div className="-mt-3" onClick={() => setIsPopUpStep3(true)}>
        <img src={banner} alt="banner" className="w-full h-full" />
      </div>

      <Popup
        header="Bước 1"
        isOpen={isPopUpStep1}
        onClose={() => setIsPopUpStep1(false)}
      >
        <StepRegister />
      </Popup>

      <Popup
        header="Bước 2"
        isOpen={isPopUpStep2}
        onClose={() => setIsPopUpStep2(false)}
      >
        <StepKyc />
      </Popup>

      <Popup
        header="Bước 3"
        isOpen={isPopUpStep3}
        onClose={() => setIsPopUpStep3(false)}
      >
        <StepRefLink handleCompleteSubmit={handleCompleteSubmit} />
      </Popup>
    </>
  );
};

export default StepClaim;
