import { CONSTANTS } from "constants";
import useDeviceDetect from "utility/hooks/useDeviceDetect";
import localUtils from "utility/local-utils";
// eslint-disable-next-line import/no-unresolved
import { parseDataFromLocalStorage } from "utils";

const withKeyboardDetectFocusInput = (WappedComponent) => {
    return (props) => {
        const {
            isWebview,
            isIphone,
            isTabletDevice,
            isMobileDevice,
        } = useDeviceDetect()


        const handleFocusIn = () => {
            // const isKeyBoardShow = parseDataFromLocalStorage(localUtils.get(CONSTANTS.LOCAL_STORAGE.IS_KEYBOARD_SHOW));

            const keyboardSize = parseDataFromLocalStorage(localUtils.get(CONSTANTS.LOCAL_STORAGE.DEVICE_INFO))
            if (
                (isIphone ||
                    isTabletDevice ||
                    isMobileDevice)
                && isWebview
            ) {
                document.documentElement.style.setProperty('--padding-bottom-by-keyboard', (keyboardSize?.sizeKeyboard || 250) + 'px');
            } else {
                document.documentElement.style.setProperty('--padding-bottom-by-keyboard', '0px');
            }
        }

        const handleFocusOut = () => {
            document.documentElement.style.setProperty('--padding-bottom-by-keyboard', '0px');
        }

        return <WappedComponent {...props} focusIn={handleFocusIn} focusOut={handleFocusOut} />
    }
}

export default withKeyboardDetectFocusInput;