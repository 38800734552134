import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { bookingApi } from "../apis";
import { toast } from "react-hot-toast";

export const getAllBookings = createAsyncThunk(
  "/driver/getAllBookingByDrivers",
  async (query) => {
    try {
      const response = await bookingApi.getAllBooking(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getBookingDetail = createAsyncThunk(
  "/driver/getOneDrivers",
  async (query) => {
    try {
      const response = await bookingApi.getOneBooking(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getAllActivityHistory = createAsyncThunk(
  "/driver/getAllActivityHistory",
  async (query) => {
    try {
      const response = await bookingApi.getAllActivityHistory(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getConfigBookingDistances = createAsyncThunk(
  "/driver/getConfigBookingDistances",
  async () => {
    try {
      const response = await bookingApi.getConfigBookingDistance();

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const getListCarSolution = createAsyncThunk(
  "/driver/getListCarSolution",
  async (query) => {
    try {
      const response = await bookingApi.checkCarSolution(query);

      return response;
    } catch (error) {
      toast.error(error?.message);
      throw new Error(error);
    }
  }
);

export const BookingSlice = createSlice({
  name: "bookings",
  initialState: {
    list: [],
    total: 0,
    stats: {},

    bookingDetail: {},

    activityBooking: [],

    configBookingDistance: 0,

    listTypeCar: [],
  },

  extraReducers: (builder) => {
    builder.addCase(getAllBookings.fulfilled, (state, { payload }) => {
      state.list = payload?.data || [];
      state.total = payload?.total || 0;
      state.stats = payload?.getStatBooking || {};
    });

    builder.addCase(getBookingDetail.fulfilled, (state, { payload }) => {
      state.bookingDetail = payload || {};
    });

    builder.addCase(getAllActivityHistory.fulfilled, (state, { payload }) => {
      state.activityBooking = payload?.data || [];
    });

    builder.addCase(
      getConfigBookingDistances.fulfilled,
      (state, { payload }) => {
        state.configBookingDistance = payload?.bookingDistance || 0;
      }
    );

    builder.addCase(getListCarSolution.fulfilled, (state, { payload }) => {
      state.listTypeCar = payload || [];
    });
  },
});

// export const {} = driverSlice.actions;

export default BookingSlice.reducer;
