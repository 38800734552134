import { DATE_TIME_FORMAT } from "constants";
import moment from "moment/moment";

/**
 * @param {String} fromDate
 * @param {String} toDate
 * @param {String} type compare
 */
export const calculateDifferenceTime = (fromDate, toDate, type = "miliseconds") => {
    return moment(toDate).diff(moment(fromDate), type);
};

/**
 * @description Format date time string to local time
 * @param { String } dateString
 * @param { String } formatTime
 * @returns { String }
 */
export const formatTimeToLocalTime = (dateString, formatTime = DATE_TIME_FORMAT.DMY) => {
    return moment(dateString).format(formatTime);
};