import { gql } from "@apollo/client";

export const CryptoFragment = gql`
  fragment CryptoType on Crypto {
    id
    smartContract
    symbol
    network
    logo
    price
    price24hChange
    exchangeRate
    myBalance
    networkFee
    feeConvertToUsdt
    createdAt
    updatedAt
  }
`;

/// USDT, BNB, EVND, EUSD, EGO, ENET

/// query

export const getAllCrypto = gql`
  ${CryptoFragment}
  query GetAllCrypto {
    getAllCrypto {
      data {
        ...CryptoType
      }
      total
    }
  }
`;

/// mutation
export const sendCrypto = gql`
  mutation SendCrypto(
    $amount: Float!
    $address: String!
    $typeCrypto: String!
  ) {
    sendCrypto(amount: $amount, address: $address, typeCrypto: $typeCrypto)
  }
`;

export const receiveCrypto = gql`
  mutation ReceiveCrypto($typeCrypto: String!) {
    receiveCrypto(typeCrypto: $typeCrypto) {
      typeCrypto
      address
    }
  }
`;

export const getAllCryptoTransHistory = gql`
  query getAllCryptoTransHistory($q: QueryGetListInput) {
    getAllCryptoTransHistory(q: $q) {
      data {
        amount
        createdAt
        updatedAt
        from
        requestId
        to
        type
        typeCrypto
        user {
          name
        }
        feeBNB
        feeUSDT
        status
        request {
          balance
          status
          sendAddress
          receiveAddress
        }
      }
      total
    }
  }
`;
