import { gql } from "@apollo/client";
import { query } from "configs/http-config";

const dashboardPartnerAction = {
  getStatDashboardForPartner: gql`
    query getStatDashboardForPartner($partnerId: ID!) {
      getStatDashboardForPartner(partnerId: $partnerId)
    }
  `,
};

export const dashboardPartnerApi = {
  getStatDashboardForPartner: (payload) => {
    return query(
      "getStatDashboardForPartner",
      dashboardPartnerAction.getStatDashboardForPartner,
      payload
    );
  },
};
