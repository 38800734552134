/* eslint-disable implicit-arrow-linebreak */

import React, { useMemo } from "react";
import { CopyCheck } from "lucide-react";
import { useAuth } from "components/containers/auth";

import Utils from "utility/time";
import FormInputRef from "./form-input-ref";
import FormDashBoardRef from "./form-dashboard-ref";

const StepRefLink = ({ handleCompleteSubmit }) => {
  const { user } = useAuth();

  const isTypeReferralCode = useMemo(() => {
    return user?.referralCode !== null;
  }, [user]);

  return (
    <div>
      {isTypeReferralCode ? (
        <>
          <FormInputRef isTypeReferralCode={isTypeReferralCode} />

          <div className="text-black py-4 border border-l-0 border-r-0 border-primary">
            <h4 className="text-black">Chia sẻ mã giới thiệu của bạn</h4>

            <div className="flex items-center justify-between mt-2">
              <span className="font-bold">
                {`https://go.ecorp.global/dl?inviteCode=${user?.inviteCode}`}
              </span>

              <div
                className="cursor-pointer"
                onClick={() =>
                  Utils.copyToClipboard(
                    `https://go.ecorp.global/dl?inviteCode=${user?.inviteCode}`
                  )
                }
              >
                <CopyCheck size={16} color="#0b823c" />
              </div>
            </div>
          </div>

          <div className="mt-3">
            <FormDashBoardRef />
          </div>
        </>
      ) : (
        <>
          <h4 className="text-xl text-red-500">
            Bạn chưa nhập mã người giới thiệu
          </h4>

          <FormInputRef
            isTypeReferralCode={isTypeReferralCode}
            handleCompleteSubmit={handleCompleteSubmit}
          />
        </>
      )}
    </div>
  );
};

export default StepRefLink;
