const classNames = require("classnames");
const { useEffect, useRef } = require("react");

const TabPageContent = ({
  tabs,
  actions,
  children,
  tabClass,
  headerClass,
  contentClass,
  containerClass,
}) => {
  const contentRef = useRef();

  useEffect(() => {
    if (!contentRef.current) {
      return;
    }
  }, []);

  return (
    <div className={classNames("flex flex-col", containerClass)}>
      <div
        className={classNames(
          "w-full flex items-end justify-between overflow-hidden space-x-2",
          headerClass
        )}
      >
        {tabs && (
          <div
            className={classNames(
              "flex items-center flex-[2] overflow-x-auto",
              tabClass
            )}
          >
            {tabs}
          </div>
        )}

        {actions && <div className="">{actions}</div>}
      </div>

      <div
        ref={contentRef}
        className={classNames(
          "flex-[2] overflow-hidden w-full mb-0",
          contentClass
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default TabPageContent;
