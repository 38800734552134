import { createSlice } from '@reduxjs/toolkit';

const accountPermissionSlice = createSlice({
    name: 'accountPermission',
    initialState: {
        admins: [],
        partners: [],
    },
    reducers: {
        setPartner: (state, { payload }) => {
            state.partners = payload
        },

        setAdmin: (state, { payload }) => {
            state.admins = payload;
        },
    },
});

export const {
    actions: {
        setPartner,
        setAdmin,
    },
    reducer,
} = accountPermissionSlice;

export default reducer;