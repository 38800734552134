import { gql } from "@apollo/client";
import { mutate, query } from "configs/http-config"

const stockAction = {
    getAllInventory: gql `
        query getAllInventory(
            $page: Int
            $limit: Int
            $search: String
            $filter: Mixed
        ) {
            getAllInventory(
                q: {
                    limit: $limit
                    page: $page
                    search: $search
                    filter: $filter
                    order: { createdAt: -1}
                }
            ) {
                data {
                    createdAt
                    purchasePrice
                    quantity
                    id
                    sellingPrice
                    shopProduct {
                        id 
                        name
                        code
                    }
                    shopProductId
                    user {
                        id 
                        name
                    }
                    userId
                }
                pagination {
                    page
                    total
                }
                total
            }
        }
    `,

    addInventory: gql `
        mutation addInventory ($data: AddInventoryInput!) {
            addInventory (data: $data) {
                id
            }
        }
    `,

    updateInventory: gql `
        mutation updateInventory($id: ID!, $data: UpdateInventoryInput!){
            updateInventory(id: $id, data: $data){
                id
            }
        }
    `,

    deleteOneInventory: gql `
        mutation deleteOneInventory($id: ID!){
            deleteOneInventory(id: $id){
                id
            }
        }
    `
};

export const stockApi = {
    getAllInventory: (payload) => {
        return query(
            "getAllInventory",
            stockAction.getAllInventory,
            payload
        );
    },

    addInventory: (payload) => {
        return mutate(
            "addInventory",
            stockAction.addInventory,
            payload
        );
    },

    updateInventory: (payload) => {
        return mutate(
            "updateInventory",
            stockAction.updateInventory,
            payload
        );
    },

    deleteOneInventory: (payload) => {
        return mutate(
            "deleteOneInventory",
            stockAction.deleteOneInventory,
            payload
        )
    }
}
