import React from "react";

import "./index.scss";

const StepEthStaking = () => {
  return (
    <>
      <div className="mt-4">
        <div className="stack-step-web3">
          <div className="step pt-0 items-start">
            <div className="step-number -translate-y-3 -translate-x-[10px] rotate-45 ">
              <span className="rotate-[320deg]" />
            </div>

            <div className="step-content -translate-y-3">
              <div className="text-base mb-0 flex items-center justify-between gap-x-3">
                <p className="flex-1 ">Ngày staking </p>

                <span className="flex-1 font-medium text-right">
                  2024-09-09 21:56
                </span>
              </div>
            </div>
          </div>

          <div className="step pt-0 items-start border-l-0">
            <div className="step-number -translate-y-3 -translate-x-[8.5px] rotate-45">
              <span className="rotate-[320deg]" />
            </div>

            <div className="step-content -translate-y-3">
              <div className="text-base  mb-0 flex items-center justify-between gap-x-3">
                <p className="flex-1 ">Phần thưởng bắt đầu tích luỹ</p>

                <span className="flex-1 font-medium text-right">
                  2024-09-09 07:00
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepEthStaking;
