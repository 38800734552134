import { useMemo } from "react";

// ** Third Party Components
import classNames from "classnames";
import PropTypes from "prop-types";

// ** Style
import "./index.scss";

const CheckBoxSystem = (props) => {
  // ** Props
  const {
    value,
    id = "checkbox-id",
    onChange,
    label = "Label Check Box",
    className,
    classNameLabel,
    classNameInput,
    ...restProps
  } = props;

  const checked = useMemo(() => {
    if (typeof value === "boolean" && value === true) {
      return true;
    }

    return false;
  }, [value]);

  const handleInputChange = (event) => {
    onChange?.(event.target.checked);
  };

  return (
    <div
      className={classNames([
        "form-check form-check-inline checkbox-system",
        className,
      ])}
    >
      <input
        type="checkbox"
        id={`checkbox-system-${id}`}
        className={classNames(
          "form-check-input checkbox-system__input flex-shrink-0",
          classNameInput
        )}
        checked={checked}
        {...restProps}
        onChange={(event) => handleInputChange(event)}
      />
      <label
        className={classNames(
          classNameLabel,
          "form-check-label checkbox-system__label ml-2"
        )}
        htmlFor={`checkbox-system-${id}`}
      >
        {label}
      </label>
    </div>
  );
};

CheckBoxSystem.propTypes = {
  className: PropTypes.string,
  value: PropTypes.any.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CheckBoxSystem;
