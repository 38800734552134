import { useCallback, useEffect, useRef } from "react";

// ** Hocs
import withGGMap from "apps/maps/hocs/with-map";

// ** Style Import
import './index.scss';
import HotlineBookingWrapper from "./components/booking-wrapper";
import MapBookingDetail from "./components/map-booking-detail";
import classNames from "classnames";
import useSocket from "../maps/hooks/useSocket";
import { mapActions } from "../maps/apis";
import { useSelector } from "react-redux";
import MAP_OPTIONS from "../gg-map/constants";
import toast from "react-hot-toast";
import { HOTLINE_OPTIONS } from "./constants";

const HotlinePage = ({ children, isCreateModal = false, closeCreateModal }) => {

    // ** Refs
    const mapWrapperRef = useRef();
    const hotlineBookingRef = useRef();

    // ** Stores
    const {
        workplaceIds,
    } = useSelector((state) => state.map);

    // ** subcribe booking update status
    const bookingUpdatedStatus = useSocket(
        mapActions.BookingUpdate,
        { workplaceIds: [...workplaceIds] },
        "newUpdateTrip4Admin"
    );

    const requestUpdatedStatus = useSocket(
        mapActions.RequestUpdate,
        { workplaceIds: [...workplaceIds] },
        "newUpdateTaxiRequest"
    );

    // ** update booking
    const updateBooking = useCallback(
        (booking) => {
            const { status, driver } = booking;

            if (status === MAP_OPTIONS.UPDATE_BOOKING_STATUS.READY || status === MAP_OPTIONS.UPDATE_BOOKING_STATUS.STARTING) {
                toast.success(`Tài xế ${driver?.name} chấp nhận chuyến xe!`);
        
                hotlineBookingRef.current?.loadBookingByFilter?.()
            }
        },
        []
    );

    useEffect(() => {
        if (requestUpdatedStatus) {
            toast.success(`Tài xế chấp nhận chuyến xe!`);
            hotlineBookingRef.current?.loadBookingByFilter?.()
        }
    }, [requestUpdatedStatus])

    useEffect(() => {
        if (bookingUpdatedStatus) {
            updateBooking(bookingUpdatedStatus);
        }
    }, [bookingUpdatedStatus]);

    useEffect(() => {
        const handleResizeContent = () => {
            if (mapWrapperRef.current) {
                const mapRect = mapWrapperRef.current.getBoundingClientRect();
                mapWrapperRef.current.style.setProperty('--map-wrapper-top', mapRect.top + 'px');
            }
        };

        handleResizeContent();

        window.addEventListener('resize', handleResizeContent);

        return () => {
            window.removeEventListener('resize', handleResizeContent);
        }
    }, []);

    return (
        <>
            <div ref={mapWrapperRef} className={classNames(["hotline", { 'hotline__is-modal': isCreateModal }])}>
                <div className="hotline__map">
                    <MapBookingDetail onOpenBookingForm={() => hotlineBookingRef.current?.handleAddBooking?.()}>
                        {children}
                    </MapBookingDetail>
                </div>

                <div className="hotline__booking">
                    <HotlineBookingWrapper
                        hotlineBookingRef={hotlineBookingRef}
                        isCreateModal={isCreateModal}
                        closeCreateModal={closeCreateModal}
                    />
                </div>
            </div>
        </>
    )
};

export default withGGMap(HotlinePage);