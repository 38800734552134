import { useRef, useEffect } from "react";

import "./index.scss";
import toast from "react-hot-toast";
import { Input } from "reactstrap";

const AutoCompleteInput = ({ map, className, onSelect, onEmit, ...props }) => {
  const inputRef = useRef();

  const handleInputChange = (event) => {
    const value = event.target.value;

    setTimeout(() => {
      if (!value) {
        onEmit({
          address: "",
          location: null,
        });
      }
    }, 200);
  };

  useEffect(() => {
    const autoComplete = new window.google.maps.places.Autocomplete(
      inputRef.current
    );

    const handlePlaceChange = () => {
      const place = autoComplete.getPlace();

      if (!place.geometry || !place.geometry.location) {
        toast.error("Địa điểm này không có sẵn");
      }

      if (place?.geometry?.viewport || place?.geometry?.location) {
        onSelect?.(place?.geometry?.location);
        onEmit?.({
          address: place?.formatted_address,
          location: place?.geometry?.location,
        });
      }
    };

    autoComplete.addListener("place_changed", handlePlaceChange);
  }, [map]);

  useEffect(() => {
    if (props?.value === undefined) {
      inputRef.current.value = "";

      return;
    }
    inputRef.current.value = props?.value;
  }, [props?.value]);

  return (
    <div className={`autocomplete-input-wrapper ${className}`}>
      <input
        ref={inputRef}
        className="autocomplete__input w-full"
        placeholder="Tìm kiếm và chọn địa chỉ"
        onChange={handleInputChange}
      />
    </div>
  );
};

export default AutoCompleteInput;
