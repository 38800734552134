import ModalSystem from "components/e-control/modal-system";
import { createContext, useContext, useMemo, useState } from "react";

import HotlinePage from "modules/ego-system/hotline";
import { useSelector } from "react-redux";
import { ROLE } from "constants";

const HotlineBookingContext = createContext();

const CreateHolineBookingContext = ({ children }) => {
  const [open, setOpen] = useState(false);

  const { userData } = useSelector((state) => state.auth);

  const role = userData?.role;

  const isShowCall = useMemo(() => {
    return (
      role === ROLE.SWITCHBOARD_OPERATOR ||
      role === ROLE.PARTNER ||
      role === ROLE.ADMIN
    );
  }, [role]);

  return (
    <HotlineBookingContext.Provider
      value={{
        openHotlineBookingCreatorModal: setOpen,
      }}
    >
      {isShowCall && (
        <>
          {children}
          <ModalSystem
            isOpen={open}
            header="Tạo booking"
            onClosed={() => setOpen(false)}
          >
            <HotlinePage
              isCreateModal
              closeCreateModal={() => setOpen(false)}
            />
          </ModalSystem>
        </>
      )}
    </HotlineBookingContext.Provider>
  );
};

export const useHotlineCreateBooking = () => useContext(HotlineBookingContext);

export default CreateHolineBookingContext;
